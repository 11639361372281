import {
  Circle as CircleStyle,
  Fill,
  RegularShape,
  Stroke,
  Style,
  Text,
} from 'ol/style';

import { COLORS } from './colorsConstants';

const MEASUREMENTS_CONSTANTS = {
  PADDING: {
    DEFAULT_PADDING: [2, 2, 2, 2],
  },
  COLORS: {
    WHITE: COLORS.WHITE,
    GREY: COLORS.GREY300,
    BROWN_RED: COLORS.BROWN_RED,
    BROWN_RED_TRANSPARENCY: 'rgba(255,255,0,0.05)',
  },
  WIDTH: {
    STROKE: 1.5,
  },
  RADIUS: {
    IMAGE: 4,
  },
  OFFSETS: {
    DEFAULT_OFFSET_Y: -15,
    DEFAULT_OFFSET_X: 15,
    LABEL_OFFSET_X: 15,
  },
  FONT: {
    CAPITAL: '12px Calibri,sans-serif',
    REGULAR: '10px Calibri,sans-serif',
  },
  POINTS: {
    IMAGE: 3,
  },
  BASELINES: {
    BOTTOM: 'bottom' as CanvasTextBaseline,
  },
  DISPLACEMENTS: {
    DEFAULT: [0, 10],
  },
  TEXT_ALIGN: {
    LEFT: 'left' as CanvasTextAlign,
    RIGHT: 'right',
  },
};

const PolygonStyle = new Style({
  stroke: new Stroke({
    color: MEASUREMENTS_CONSTANTS.COLORS.BROWN_RED,
    width: MEASUREMENTS_CONSTANTS.WIDTH.STROKE,
  }),
  fill: new Fill({
    color: MEASUREMENTS_CONSTANTS.COLORS.BROWN_RED_TRANSPARENCY,
  }),
  image: new CircleStyle({
    radius: MEASUREMENTS_CONSTANTS.RADIUS.IMAGE,
    stroke: new Stroke({
      color: MEASUREMENTS_CONSTANTS.COLORS.GREY,
      width: MEASUREMENTS_CONSTANTS.WIDTH.STROKE,
    }),
    fill: new Fill({
      color: MEASUREMENTS_CONSTANTS.COLORS.WHITE,
    }),
  }),
});

const pointStyle = new Style({
  image: new CircleStyle({
    radius: MEASUREMENTS_CONSTANTS.RADIUS.IMAGE,
    stroke: new Stroke({
      color: MEASUREMENTS_CONSTANTS.COLORS.GREY,
    }),
    fill: new Fill({
      color: COLORS.WHITE,
    }),
  }),
});

const labelStyle = new Style({
  text: new Text({
    font: MEASUREMENTS_CONSTANTS.FONT.CAPITAL,
    fill: new Fill({
      color: MEASUREMENTS_CONSTANTS.COLORS.WHITE,
    }),
    backgroundFill: new Fill({
      color: MEASUREMENTS_CONSTANTS.COLORS.GREY,
    }),
    padding: MEASUREMENTS_CONSTANTS.PADDING.DEFAULT_PADDING,
    textBaseline: MEASUREMENTS_CONSTANTS.BASELINES.BOTTOM,
    offsetY: MEASUREMENTS_CONSTANTS.OFFSETS.DEFAULT_OFFSET_Y,
    offsetX: MEASUREMENTS_CONSTANTS.OFFSETS.LABEL_OFFSET_X,
  }),
  image: new RegularShape({
    radius: MEASUREMENTS_CONSTANTS.RADIUS.IMAGE,
    points: MEASUREMENTS_CONSTANTS.POINTS.IMAGE,
    angle: Math.PI,
    displacement: MEASUREMENTS_CONSTANTS.DISPLACEMENTS.DEFAULT,
    fill: new Fill({
      color: MEASUREMENTS_CONSTANTS.COLORS.GREY,
    }),
  }),
});

const tipStyle = new Style({
  text: new Text({
    font: MEASUREMENTS_CONSTANTS.FONT.REGULAR,
    fill: new Fill({
      color: MEASUREMENTS_CONSTANTS.COLORS.GREY,
    }),
    backgroundFill: new Fill({
      color: MEASUREMENTS_CONSTANTS.COLORS.WHITE,
    }),
    padding: MEASUREMENTS_CONSTANTS.PADDING.DEFAULT_PADDING,
    textAlign: 'left',
    offsetX: MEASUREMENTS_CONSTANTS.OFFSETS.DEFAULT_OFFSET_X,
  }),
});

const modifyStyle = new Style({
  image: new CircleStyle({
    radius: MEASUREMENTS_CONSTANTS.RADIUS.IMAGE,
    stroke: new Stroke({
      color: MEASUREMENTS_CONSTANTS.COLORS.GREY,
    }),
    fill: new Fill({
      color: MEASUREMENTS_CONSTANTS.COLORS.WHITE,
    }),
  }),
  text: new Text({
    text: '',
    font: MEASUREMENTS_CONSTANTS.FONT.REGULAR,
    fill: new Fill({
      color: MEASUREMENTS_CONSTANTS.COLORS.WHITE,
    }),
    backgroundFill: new Fill({
      color: MEASUREMENTS_CONSTANTS.COLORS.WHITE,
    }),
    padding: MEASUREMENTS_CONSTANTS.PADDING.DEFAULT_PADDING,
    textAlign: MEASUREMENTS_CONSTANTS.TEXT_ALIGN.LEFT,
    offsetX: MEASUREMENTS_CONSTANTS.OFFSETS.DEFAULT_OFFSET_X,
  }),
});

const segmentStyle = new Style({
  text: new Text({
    font: MEASUREMENTS_CONSTANTS.FONT.REGULAR,
    fill: new Fill({
      color: MEASUREMENTS_CONSTANTS.COLORS.WHITE,
    }),
    backgroundFill: new Fill({
      color: MEASUREMENTS_CONSTANTS.COLORS.GREY,
    }),
    padding: MEASUREMENTS_CONSTANTS.PADDING.DEFAULT_PADDING,
    textBaseline: MEASUREMENTS_CONSTANTS.BASELINES.BOTTOM,
    offsetY: MEASUREMENTS_CONSTANTS.OFFSETS.DEFAULT_OFFSET_Y,
  }),
  image: new RegularShape({
    radius: MEASUREMENTS_CONSTANTS.RADIUS.IMAGE,
    points: MEASUREMENTS_CONSTANTS.POINTS.IMAGE,
    angle: Math.PI,
    displacement: MEASUREMENTS_CONSTANTS.DISPLACEMENTS.DEFAULT,
    fill: new Fill({
      color: MEASUREMENTS_CONSTANTS.COLORS.GREY,
    }),
  }),
});

export const STYLES = {
  SEGMENT_STYLE: segmentStyle,
  MODIFY_STYLE: modifyStyle,
  POLYGON_STYLE: PolygonStyle,
  POINT_STYLE: pointStyle,
  TIP_STYLE: tipStyle,
  LABEL_STYLE: labelStyle,
};

export const MEASURE_ACTIONS = {
  MEASURE_XY: {
    TYPE: 'measure.xy',
    GEOMETRY_TYPE: 'Point',
  },
  MEASURE_DISTANCE: {
    TYPE: 'measure.distance',
    GEOMETRY_TYPE: 'LineString',
  },
  MEASURE_AREA: {
    TYPE: 'measure.area',
    GEOMETRY_TYPE: 'Polygon',
  },
  MEASURE_CLEAR: {
    TYPE: 'measure.clear',
    GEOMETRY_TYPE: null,
  },
  MEASURE_HIDE: {
    TYPE: 'measure.hide',
    GEOMETRY_TYPE: null,
  },
  MEASURE_SHOW: {
    TYPE: 'measure.show',
    GEOMETRY_TYPE: null,
  },
  MEASURE_NONE: {
    TYPE: 'measure.show',
    GEOMETRY_TYPE: null,
  },
};

export const NAMING = {
  DEFAULT_MEASURING_LAYER_NAME: 'system.layer.measurements',
  LAYER_PROPERTY_NAME: 'category',
};

export const MEASURE_LIMITS = {
  COORDINATES: 7,
  DISTANCE: 100,
  AREA: 10000,
  TOLERANCE: 2,
};
