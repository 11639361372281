import { COMMANDS, TlManagementKeys } from '../../../../constants/commands';
import { IFiltersSettings } from '../../../../ts/models/table.model';
import { IPhasesData } from '../PhaseHold/PhaseHold.model';

export type TParam = string | number;

export interface IPostCommandProps {
  id: number;
  command: N<number>;
  cmdTitle: COMMANDS;
  cmdInfo: string;
  selectedDate?: number;
  durationTime?: U<number>;
  phasesValue?: U<N<IPhasesData[]>>;
  disabledTime?: number;
}

export interface IChangePhaseTime {
  phase: number;
  time: number;
  spanTime: number;
}

export interface ICoordManagment extends IChangePhaseTime {
  spanGreenBlinkTime: number;
  spanYellowTime: number;
  spanRedYellowTime: number;
}

export interface IParamsSender {
  param1: TParam;
  param2?: TParam;
  param3?: TParam;
  param4?: TParam;
  jparam1?: N<IChangePhaseTime[] | ICoordManagment[]>;
}

type TParams = Required<IParamsSender>;

interface ICommandsInfo extends TParams {
  cmd: COMMANDS;
  comment: string;
}

export interface IRequestSender {
  commandInfo: ICommandsInfo;
}

export interface ICommands {
  cmd_datetime: string;
  cmd_sender: string;
  cmd_status: string;
  cmd_type: string;
  device_id: number;
  dtbegin_command: string;
  dtend_command: string;
  externaldevice_id: number;
  jcmd_sender_add_info: {
    cmd: string;
    param1: number;
    param2: number;
    param3: number;
    param4?: string;
  };
}

export interface IHeaderCommands {
  readonly: boolean;
  referens: string;
  title: string;
  type: 'string' | 'number';
  visible: boolean;
  filterSettings: IFiltersSettings;
}

export interface ICommandsPlannedTable {
  dataRows: N<ICommands[]>;
  headerItem: IHeaderCommands[];
}

export const isTabControlKey = (key: string): key is TlManagementKeys =>
  Object.values(TlManagementKeys).some((el) => el === key);
