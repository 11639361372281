import { Card } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';
import { MdDeleteOutline } from 'react-icons/md';
import { TbLine, TbPoint, TbPolygon, TbRulerMeasure } from 'react-icons/tb';

import {
  hideFeatures,
  showFeatures,
} from '../../../apiGIS/layers/utils/flashFeatures';
import {
  getMeasurements,
  IMeasurementResult,
  removeMeasurementFeature,
} from '../../../apiGIS/utils/measurement';
import { MEASURE_ACTIONS } from '../../../constants/measurementsConstants';
import { MAP_BUTTONS_NAMES } from '../../../constants/uiComponentConstants';
import rootStore from '../../../stores/rootStore/rootStore';
import { MapButtonsCode } from '../../../ts/enums/userData';
import { IMeasureAction } from '../../Map/Map.model';
import ButtonIco from '../../ui-kit/ButtonIco/ButtonIco';
import { PLACEMENT_MAP_BTNS } from '../LayersButton/LayersButton';

import { FLASH_FEATURE_TIMEOUT, MEASUREMENTS_CONTENT } from './constants';
import { MeasurementItem } from './MeasurementItem';
import { MeasurementResultItem } from './MeasurementResultItem';

import styles from './MeasurementsButton.module.scss';

const LOCAL_STYLES = {
  panelBodyStyle: {
    margin: 0,
    padding: 0,
  },
  iconSize: 24,
};

interface IMeasurementItemDefinition {
  title: string;
  icon: JSX.Element;
  type: string;
}

const MEASUREMENT_ITEMS: IMeasurementItemDefinition[] = [
  {
    title: MEASUREMENTS_CONTENT.pointMeasureTooltip,
    icon: <TbPoint size={LOCAL_STYLES.iconSize} />,
    type: MEASURE_ACTIONS.MEASURE_XY.TYPE,
  },
  {
    title: MEASUREMENTS_CONTENT.polylineMeasureTooltip,
    icon: <TbLine size={LOCAL_STYLES.iconSize} />,
    type: MEASURE_ACTIONS.MEASURE_DISTANCE.TYPE,
  },
  {
    title: MEASUREMENTS_CONTENT.polygonMeasureTooltip,
    icon: <TbPolygon size={LOCAL_STYLES.iconSize} />,
    type: MEASURE_ACTIONS.MEASURE_AREA.TYPE,
  },
  {
    title: MEASUREMENTS_CONTENT.clearMeasurements,
    icon: <MdDeleteOutline size={LOCAL_STYLES.iconSize} />,
    type: MEASURE_ACTIONS.MEASURE_CLEAR.TYPE,
  },
];

const MeasurementsButton: FC = () => {
  const { uiStore } = rootStore;
  const {
    activeMapButton,
    mapButtonsTipsDelay,
    setKeyValue,
    isMeasurementBtnGis,
  } = uiStore;

  const { map } = rootStore.mapStore;
  const { measurementsChange, setGisValue } = rootStore.gisDataStore;
  const { hasAccess } = rootStore.userDataStore;
  const [isShowPanel, setShowPanel] = useState(false);
  const [currentType, setCurrentType] = useState(
    MEASURE_ACTIONS.MEASURE_CLEAR.TYPE
  );
  const [measurements, setMeasurements] = useState(getMeasurements(map));

  const [info, setInfo] = useState(false);

  const popOverProps = {
    placement: PLACEMENT_MAP_BTNS,
    tipsDelay: mapButtonsTipsDelay,
  } as const;

  const measure = (item: IMeasureAction) => {
    if (item.type && item.type === currentType) {
      return;
    }

    setCurrentType(item.type || MEASURE_ACTIONS.MEASURE_CLEAR.TYPE);

    setGisValue('measureAction', item);
  };

  const [measurementItems] = useState(MEASUREMENT_ITEMS);

  const togglePanel = () => {
    setShowPanel(!isShowPanel);
  };

  useEffect(
    () => {
      if (isShowPanel) {
        measure({ type: MEASURE_ACTIONS.MEASURE_SHOW.TYPE });
        setKeyValue('activeMapButton', MAP_BUTTONS_NAMES.MEASUREMENTS);
      } else {
        measure({ type: MEASURE_ACTIONS.MEASURE_HIDE.TYPE });
      }
    },
    // eslint-disable-next-line
    [isShowPanel, setKeyValue]
  );

  useEffect(() => {
    if (activeMapButton !== MAP_BUTTONS_NAMES.MEASUREMENTS) {
      setShowPanel(false);
    }
  }, [activeMapButton]);

  useEffect(() => {
    setMeasurements(getMeasurements(map));
  }, [info, map, measurementsChange]);

  const isAccess = hasAccess(MapButtonsCode.MeasurementsButton);

  if (!isAccess || !isMeasurementBtnGis) return null;

  const flashFeature = (item: IMeasurementResult) => {
    const features = [item.feature];

    showFeatures(features);
    setTimeout(() => {
      hideFeatures(features);
    }, FLASH_FEATURE_TIMEOUT);
  };

  const removeMeasurement = (item: IMeasurementResult) => {
    removeMeasurementFeature(map, item.feature);
    setMeasurements(getMeasurements(map));
  };

  return (
    <>
      <ButtonIco
        onClick={togglePanel}
        isActive={isShowPanel}
        popoverProps={{
          ...popOverProps,
          content: MEASUREMENTS_CONTENT.content,
        }}
      >
        <TbRulerMeasure />
      </ButtonIco>

      {isShowPanel ? (
        <Card
          bordered={false}
          className={styles.measurementsPanel}
          bodyStyle={LOCAL_STYLES.panelBodyStyle}
        >
          <div className={styles.measurementsContainer}>
            {measurementItems.map((item, index) => {
              return (
                <MeasurementItem
                  key={index}
                  title={item.title}
                  onClick={() => {
                    measure(item);
                  }}
                  icon={item.icon}
                  delay={mapButtonsTipsDelay}
                  num={index}
                  count={measurementItems.length + 1}
                  className={
                    item.type === currentType &&
                    item.type !== MEASURE_ACTIONS.MEASURE_CLEAR.TYPE
                      ? styles.itemSelected
                      : styles.item
                  }
                />
              );
            })}

            <div>
              <MeasurementItem
                title={MEASUREMENTS_CONTENT.measurementsResults}
                onClick={() => {
                  setInfo(!info);
                }}
                icon={
                  info ? (
                    <HiChevronUp size={LOCAL_STYLES.iconSize} />
                  ) : (
                    <HiChevronDown size={LOCAL_STYLES.iconSize} />
                  )
                }
                delay={mapButtonsTipsDelay}
                num={0}
                count={1}
                className={''}
              />
            </div>
          </div>

          <>
            {info ? (
              <div className={styles.measurementsResultContainer}>
                <div className={styles.measurementsResultTitle}>
                  {MEASUREMENTS_CONTENT.measurementsResults}
                </div>
                <div className={styles.measurementsList}>
                  {measurements.length > 0 ? (
                    measurements.map((item, index) => {
                      return (
                        <MeasurementResultItem
                          key={index}
                          item={item}
                          onMouseOver={() => {
                            flashFeature(item);
                          }}
                          onClose={() => {
                            removeMeasurement(item);
                          }}
                        />
                      );
                    })
                  ) : (
                    <div className={styles.noMeasurements}>
                      {MEASUREMENTS_CONTENT.noMeasurements}
                    </div>
                  )}
                </div>
              </div>
            ) : null}
          </>
        </Card>
      ) : null}
    </>
  );
};

export default observer(MeasurementsButton);
