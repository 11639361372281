import { Dayjs } from 'dayjs';

import { DATE_FORMAT_TO_SHOW } from '../../../../../constants/constants';
import { secondsToTime } from '../../PhaseHold/helpers/secondsToTime';

export const getTimerInfo = (
  startTime: Dayjs,
  endTime: Dayjs,
  phaseNum: number,
  sec: number,
  isPhaseHold: boolean
) => {
  const titlePhaseTimer = !isPhaseHold
    ? `До удержания фазы №${phaseNum}`
    : `Удержание фазы №${phaseNum}`;

  const duration = secondsToTime(endTime.diff(startTime, 's'));

  const titlePhaseDate = `${
    !isPhaseHold ? 'Включение фазы начнётся' : 'Фаза будет удержана до'
  }
: ${startTime.format(DATE_FORMAT_TO_SHOW)}, длительностью: ${duration}`;

  const time = secondsToTime(sec).split(':');

  return { titlePhaseTimer, titlePhaseDate, time };
};
