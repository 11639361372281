import { InputNumber } from 'antd';
import { Fill, RegularShape, Stroke } from 'ol/style';
import ImageStyle from 'ol/style/Image';
import { useEffect, useState } from 'react';

import styles from '../../LayersButton.module.scss';

import {
  DEFAULT_SHAPE_RADIUS,
  MAX_SHAPE_RADIUS,
  MIN_POINTS_COUNT,
  MIN_SHAPE_RADIUS,
  STEP_POINTS_COUNT,
  STEP_SHAPE_RADIUS,
} from './constants';
import FillSelector from './FillSelector';
import StrokeSelector from './StrokeSelector';

const getFill = (image: ImageStyle | RegularShape | null | undefined) => {
  if (!image) {
    return undefined;
  }

  if (image && image instanceof RegularShape && image.getFill()) {
    return image.getFill();
  }

  return undefined;
};

const getStroke = (image: ImageStyle | RegularShape | null | undefined) => {
  if (!image) {
    return undefined;
  }

  if (image && image instanceof RegularShape && image.getStroke()) {
    return image.getStroke();
  }

  return undefined;
};

const getPoints = (image: ImageStyle | RegularShape | null | undefined) => {
  if (!image) {
    return undefined;
  }

  if (image && image instanceof RegularShape && image.getPoints()) {
    return image.getPoints();
  }

  return MIN_POINTS_COUNT;
};

const RegularShapeSelector = (props: {
  onChange: (evt: any) => void;
  image?: ImageStyle | RegularShape | null | undefined;
}) => {
  const [image] = useState(props?.image);
  const [fill, setFill] = useState(getFill(props?.image));
  const [stroke, setStroke] = useState(getStroke(props?.image));
  const [points, setPoints] = useState(getPoints(props?.image));
  const [radius, setRadius] = useState(DEFAULT_SHAPE_RADIUS);

  useEffect(() => {
    const value = new RegularShape({
      points: points || MIN_POINTS_COUNT,
      radius,
    });

    fill && value.setFill(fill);
    stroke && value.setStroke(stroke);

    props.onChange(value);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fill, stroke, points, radius]);

  const updateFill = (value: Fill | undefined) => {
    setFill(value);
  };

  const updateStroke = (value: Stroke | undefined) => {
    setStroke(value);
  };

  const updatePoints = (value: number | null) => {
    value && value >= MIN_POINTS_COUNT && setPoints(value);
  };

  const updateRadius = (value: number | null) => {
    value && setRadius(value);
  };

  if (image && !(image instanceof RegularShapeSelector)) {
    return null;
  }

  return (
    <>
      <FillSelector fill={fill} onChange={updateFill} />
      <StrokeSelector stroke={stroke} onChange={updateStroke} />
      <div className={styles.rowLine}>
        <div className={styles.blockContainer}>
          <div className={styles.inputTitle}>Количество вершин</div>
          <InputNumber
            defaultValue={MIN_POINTS_COUNT}
            value={points}
            min={MIN_POINTS_COUNT}
            step={STEP_POINTS_COUNT}
            onChange={updatePoints}
          />
        </div>

        <div className={styles.blockContainer}>
          <div className={styles.inputTitle}>Радиус</div>
          <InputNumber
            defaultValue={DEFAULT_SHAPE_RADIUS}
            value={radius}
            min={MIN_SHAPE_RADIUS}
            max={MAX_SHAPE_RADIUS}
            step={STEP_SHAPE_RADIUS}
            onChange={updateRadius}
          />
        </div>
      </div>
    </>
  );
};

export default RegularShapeSelector;
