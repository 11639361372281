import type { FeatureLike } from 'ol/Feature';
import { VectorImage } from 'ol/layer';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Fill, Icon, Style, Text } from 'ol/style';
import type { StyleLike } from 'ol/style/Style';

import busIcon from '../../../assets/icons/map/bus.svg';
import directionTS from '../../../assets/icons/map/direction_tc.svg';
import routeFill from '../../../assets/icons/map/route_fill.svg';
import { ILayer } from '../../../stores/gisDataStore/gisDataStore.model';
import {
  CategoryLayerGIS,
  FeaturePropertiesCategoryLayer,
} from '../../../ts/enums/enums';
import { parcer } from '../index';
import { DrawingInfoRenderer } from '../utils/fromRenderer/renderer.model';

const createStyleByCategory = (
  layer: ILayer,
  vector: U<VectorLayer<VectorSource>> | U<VectorImage<VectorSource>>,
  renderer: DrawingInfoRenderer | undefined
): N<StyleLike> => {
  const category = layer?.category?.category ?? undefined;
  let style = null;

  switch (category) {
    case CategoryLayerGIS.Social: {
      break;
    }
    case CategoryLayerGIS.Common: {
      parcer.fromEsri(vector, renderer);
      break;
    }
    case CategoryLayerGIS.Weather: {
      break;
    }
    case CategoryLayerGIS.PublicTransport: {
      style = (feature: FeatureLike, resolution: number): Style[] => {
        const isVisibleText = resolution < 2.5;

        const angle =
          Number(feature.get(FeaturePropertiesCategoryLayer.Direction)) || 0;
        const direction = angle * (Math.PI / 180);

        const labelPositionLeft = angle > 0 && angle < 180;

        const textStyle = new Style({
          image: new Icon({
            anchor: [labelPositionLeft ? 1 : 0, 0.5],
            scale: [1.7, 1.6],
            src: routeFill,
          }),
          text: new Text({
            font: 'font-family: Noto Sans',
            text: feature.get(FeaturePropertiesCategoryLayer.Route),
            scale: 1.6,
            offsetX: labelPositionLeft ? -32 : 32,
            fill: new Fill({
              color: '#000000',
            }),
          }),
        });

        const directionStyle = new Style({
          image: new Icon({
            anchor: [0.5, 0.5],
            scale: [1.5, 1.5],
            rotation: direction,
            src: directionTS,
          }),
        });

        const bus = new Style({
          image: new Icon({
            anchor: [0.5, 0.5],
            scale: [1.4, 1.4],
            src: busIcon,
          }),
        });

        const style = [directionStyle, bus];

        if (isVisibleText) {
          style.unshift(textStyle);
        }

        return style;
      };
      break;
    }
    case CategoryLayerGIS.Raster: {
      break;
    }
    default: {
      parcer.fromEsri(vector, renderer);
      break;
    }
  }

  return style;
};

export { createStyleByCategory };
