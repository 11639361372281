import Feature from 'ol/Feature';
import EsriJSON from 'ol/format/EsriJSON';
import VectorSource from 'ol/source/Vector';

import {
  DEFAULT_NAME_FIELD_OBJECTID,
  OUTSR,
  WKID,
} from '../../../constants/constants';
import { FeatureDeviceProps } from '../../../ts/enums/enums';
import { getScaleResolution } from '../../layers/helpers';
import {
  ICheckLayerVisibility,
  ICreateSource,
  IFeatureSet,
  IGetEsriFeatures,
  ILoadServiceFeatures,
} from '../../models/sources/sourceDefinitions';
import { getMaxAllowableOffset } from '../utils';

import http from './../../http';

const tilesCountWidth = 2;
const tilesCountHeight = 2;

export const GIS_SYSTEM = 'gis';

interface ISubDomainCounter {
  current: number;
  count: number;
}

const subDomainsMem = new Map<string, ISubDomainCounter>();

const isLayerVisible = (params: ICheckLayerVisibility) => {
  const { map, id, layerDefinition } = params;

  if (!map) {
    return;
  }

  const resolution = map.getView().getResolution();

  if (layerDefinition) {
    const { minScale, maxScale } = layerDefinition;

    const minResolution = getScaleResolution(map, maxScale);
    const maxResolution = getScaleResolution(map, minScale);

    if (
      resolution &&
      (resolution < minResolution || resolution > maxResolution)
    ) {
      return false;
    }
  }

  const layer = map
    .getLayers()
    .getArray()
    .find((item) => item.get('id') === id);

  if (layer) {
    return layer.getVisible();
  }

  return false;
};

const createQueryWorkers = () => {
  const workersCount = tilesCountHeight * tilesCountWidth;
  const workers: Worker[] = [];

  for (let i = 0; i < workersCount; i++) {
    const worker = new Worker(new URL('./queryWorker.js', import.meta.url));

    workers.push(worker);
  }

  return workers;
};

const createSource = (props: ICreateSource): VectorSource => {
  const { map, layer, layerDefinition, objectIdFieldName } = props;

  const {
    url,
    id,
    refreshInterval,
    definitionExpression,
    outFields,
    subDomains,
  } = layerDefinition;

  if (
    objectIdFieldName &&
    !outFields.includes('*') &&
    !outFields.includes(objectIdFieldName)
  ) {
    outFields.push(objectIdFieldName);
  }

  const isDynamicMode = layerDefinition.dynamic;

  const workers = createQueryWorkers();

  if (map && !isDynamicMode) {
    const source = new VectorSource({});
    const ignoreConditions = true;

    loadServiceFeatures({
      id,
      map,
      source,
      url,
      definitionExpression,
      outFields,
      workers,
      ignoreConditions,
      subDomains,
    });

    return source;
  }

  const source = new VectorSource({
    features: [],
  });

  if (map && refreshInterval) {
    setInterval(() => {
      loadServiceFeatures({
        id,
        map,
        source,
        url,
        definitionExpression,
        outFields,
        workers,
        subDomains,
      });
    }, refreshInterval);
  }

  map &&
    layer &&
    layer.on('change:visible', () => {
      if (isLayerVisible({ map, id, layerDefinition })) {
        loadServiceFeatures({
          id,
          map,
          source,
          url,
          definitionExpression,
          outFields,
          workers,
          subDomains,
        });
      }
    });

  map &&
    layer &&
    map.on('moveend', () => {
      if (isLayerVisible({ map, id, layerDefinition })) {
        loadServiceFeatures({
          id,
          map,
          source,
          url,
          definitionExpression,
          outFields,
          workers,
          subDomains,
        });
      }
    });

  return source;
};

const getSubDomainUrl = (url: string, subDomains?: string[]) => {
  if (!subDomains || subDomains.length === 0) {
    return url;
  }

  const urlParts = url.split('://');

  if (urlParts.length !== 2) {
    return url;
  }

  const count = subDomains.length;

  let memItem: U<ISubDomainCounter> = subDomainsMem.get(url);

  if (!memItem) {
    memItem = {
      current: 0,
      count,
    } as ISubDomainCounter;

    subDomainsMem.set(url, memItem);
  } else {
    memItem.current = memItem.current + 1;

    if (memItem.current >= memItem.count) {
      memItem.current = 0;
    }
  }

  const subDomain = subDomains[memItem.current];

  return [urlParts[0], '://', subDomain, '.', urlParts[1]].join('');
};

export const loadServiceFeatures = async (params: ILoadServiceFeatures) => {
  const {
    map,
    id,
    where,
    url,
    source,
    definitionExpression,
    workers,
    outFields,
    ignoreConditions,
    subDomains,
  } = params;

  if (!map) {
    return;
  }

  if (!ignoreConditions && !isLayerVisible({ map, id })) {
    return;
  }

  const maxCoordinateValue = 1e10;
  const extent = ignoreConditions
    ? [
        -maxCoordinateValue,
        -maxCoordinateValue,
        maxCoordinateValue,
        maxCoordinateValue,
      ]
    : map.getView().calculateExtent();

  const maxAllowableOffset = getMaxAllowableOffset(map);

  const [xmin, ymin, xmax, ymax] = extent;

  const dx = (xmax - xmin) / tilesCountWidth;
  const dy = (ymax - ymin) / tilesCountHeight;

  const requests = [];

  let counter = 0;

  for (let i = 0; i < tilesCountWidth; i++) {
    for (let j = 0; j < tilesCountHeight; j++) {
      const xMin = xmin + dx * i;
      const yMin = ymin + dy * j;
      const xMax = xmin + dx * (i + 1);
      const yMax = ymin + dy * (j + 1);

      const bbox = `{"xmin":${xMin},"ymin":${yMin},"xmax":${xMax},"ymax":${yMax},"spatialReference":{"wkid":${WKID}}}`;

      const worker =
        workers && workers.length === tilesCountWidth * tilesCountHeight
          ? workers[counter]
          : undefined;

      counter++;

      requests.push(
        queryFeatures({
          id,
          bbox,
          url: getSubDomainUrl(url, subDomains),
          where: where || definitionExpression,
          maxAllowableOffset,
          outFields,
          worker,
        })
      );
    }
  }

  Promise.all(requests).then((responses) => {
    let features: Feature[] = [];

    for (let i = 0; i < responses.length; i++) {
      const responseFeatures = responses[i].features;

      for (let j = 0; j < responseFeatures.length; j += 1) {
        responseFeatures[j].set(FeatureDeviceProps.System, GIS_SYSTEM);
      }

      features = features.concat(responseFeatures);
    }

    source.clear();

    source.addFeatures(features);
  });
};

const queryFeatures = async (
  params: IGetEsriFeatures
): Promise<{ features: Feature[]; objectIdFieldName: string }> => {
  const { url, bbox, where, maxAllowableOffset, worker, num } = params;

  const requestUrl = `${url}/query`;

  const outFields =
    params.outFields && params.outFields.length > 0
      ? params.outFields.join(',')
      : undefined;

  if (worker) {
    try {
      const action: any = await new Promise((resolve) => {
        worker.postMessage({
          requestUrl,
          f: 'json',
          returnGeometry: true,
          spatialRel: 'esriSpatialRelIntersects',
          geometry: bbox,
          geometryType: 'esriGeometryEnvelope',
          outSR: OUTSR,
          where,
          maxAllowableOffset,
          outFields,
          resultRecordCount: num,
        });

        // @ts-ignore
        worker.onmessage = (evt) => {
          resolve(evt);
        };
      });

      const data = action.data as IFeatureSet;

      const { objectIdFieldName } = data;

      const format = new EsriJSON();
      const features = format.readFeatures(data, {}) as Feature[];

      return { features, objectIdFieldName };
    } catch (e) {
      console.log(e);

      return {
        features: [],
        objectIdFieldName: DEFAULT_NAME_FIELD_OBJECTID,
      };
    }
  }

  try {
    const response = await http.get(requestUrl, {
      params: {
        f: 'json',
        returnGeometry: true,
        spatialRel: 'esriSpatialRelIntersects',
        geometry: bbox,
        geometryType: 'esriGeometryEnvelope',
        outSR: OUTSR,
        where,
        maxAllowableOffset,
        outFields,
      },
    });

    const data = response.data as IFeatureSet;

    const { objectIdFieldName } = data;

    const format = new EsriJSON();
    const features = format.readFeatures(data, {}) as Feature[];

    return { features, objectIdFieldName };
  } catch (e) {
    return { features: [], objectIdFieldName: DEFAULT_NAME_FIELD_OBJECTID };
  }
};

export { createSource, queryFeatures };
