import { BORDER_R_XS } from '../../../../constants/themeTokens';
import ButtonsBlock from '../../../ui-kit/ButtonsBlock/ButtonsBlock';
import BasemapsButton from '../../BasemapsButton/BasemapsButton';
import LayersBtns from '../../LayersButton/LayersButton';
import MeasurementsButton from '../../MeasurementsButton/MeasurementsButton';
import SearchButton from '../../SearchButton/SearchButton';
import TrafficButton from '../../TrafficButton/TrafficButton';
import TrafficGraphButton from '../../TrafficGraphButton/TrafficGraphButton';
import WeatherButton from '../../WeatherButton/WeatherButton';

import styles from './MapMainButtons.module.scss';

const MapMainButtons = () => {
  return (
    <ButtonsBlock borderRadius={BORDER_R_XS} className={styles.btnsBlock}>
      <SearchButton />
      <LayersBtns />
      <MeasurementsButton />
      <BasemapsButton />
      <WeatherButton />
      <TrafficButton />
      <TrafficGraphButton />
    </ButtonsBlock>
  );
};

export default MapMainButtons;
