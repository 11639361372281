import adminObjects from './adminObjects/';
import { catalogs } from './catalogs/catalogs';
import development from './development';
import filesApi from './files';
import integration from './integration';
import { libraries } from './libraries/libraries';
import mapObjects from './mapObjects';
import media from './media';
import pointUds from './pointUds';
import { reports } from './reports/reports';
import { server } from './server/server';
import testingPanel from './testingPanel';
import user from './user';
import xeoma from './xeoma';

export default {
  mapObjects,
  server,
  catalogs,
  media,
  adminObjects,
  testingPanel,
  filesApi,
  user,
  xeoma,
  pointUds,
  libraries,
  reports,
  development,
  integration,
};
