import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import setLaneEnabled from './helpers/setLaneEnabled';
import VideoDetectorIcon, { VideoDtIconProps } from './VideoDetectorIcon';

const VideoDetector: FC<Omit<VideoDtIconProps, 'handleDt'>> = (props) => {
  const { linkedDetectorId } = props.camera;

  const handleDt = () => setLaneEnabled(linkedDetectorId);

  return <VideoDetectorIcon {...props} handleDt={handleDt} />;
};

export default observer(VideoDetector);
