import { List, Popover } from 'antd';
import React from 'react';
import { HiMagnifyingGlassPlus } from 'react-icons/hi2';
import { LiaRouteSolid } from 'react-icons/lia';

import { ActionItem } from '../../../ui-kit/GisFeatureActionItem/ActionItem';
import { PLACEMENT, TITLE_SUBSTRING_SIZE } from '../constants';
import { ISearchItem } from '../search.model';

import { SearchResultIcon } from './SearchResultIcon';

import styles from '../SearchButton.module.scss';

const SearchResultItem = (props: {
  item: ISearchItem;
  onMouseOver: () => Promise<void>;
  onMouseLeave: () => Promise<void>;
  onZoomClick: () => Promise<void>;
  onDirectionClick: () => void;
}) => {
  const shortTitle =
    props.item.title.length > TITLE_SUBSTRING_SIZE
      ? props.item.title.substring(0, TITLE_SUBSTRING_SIZE) + '...'
      : props.item.title;

  return (
    <div className={styles.listItem}>
      <List.Item
        onMouseOver={props.onMouseOver}
        onMouseLeave={props.onMouseLeave}
        className={styles.searchResultListItem}
      >
        <div>
          <Popover
            title={props.item.title}
            placement={PLACEMENT}
            overlayStyle={{ width: 300 }}
          >
            <div className={styles.listItemTitle}>
              <SearchResultIcon item={props.item} />
              {shortTitle}
            </div>
          </Popover>

          <div className={styles.actionsContainer}>
            <ActionItem
              icon={HiMagnifyingGlassPlus}
              text="перейти к объекту"
              onClick={props.onZoomClick}
            />

            <ActionItem
              icon={LiaRouteSolid}
              text="маршрут"
              onClick={props.onDirectionClick}
            />
          </div>
        </div>
      </List.Item>
    </div>
  );
};

export default SearchResultItem;
