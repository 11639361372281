import { PaginationConfig } from 'antd/lib/pagination';

import { SECOND } from '../../../constants/constants';

import { ISearchItem } from './search.model';

export const SEARCH_STYLES = {
  panelBodyStyle: {
    margin: 0,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    width: 510,
  },
};

export const PLACEMENT = 'left';
export const HIDE_FEATURE_TTL = 10 * SECOND;

export const SEARCH_CONTENT = {
  content: 'Поиск',
  contentDisabled: 'Для выбранного региона поиск недоступен',
  searchTitle: 'Поиск',
  searchSettingsTitle: 'Параметры поиска',
  directionsTitle: 'Маршруты',
  settings: {
    geocodeHint:
      'Будет производиться поиск по сервисам геокодирования ГИС. ' +
      'Данный режим поиска позволяет находить объекты при небольших ошибках в написании или по неполному поисковому запросу',
    layersHint:
      'Будет производиться поиск по слоям, добавленным в сеанс из ГИС. ' +
      'Необходимо учитывать, что в запросе будут участвовать только настроенные через консоль администратора поля, ' +
      'а также, что будет искаться точное совпадение',
    coordinatesHint:
      'Будет производиться поиск по слоям координатам. ' +
      'Вводите координаты в следующем порядке - широта, долгота (например, 55.161, 37.285)',
  },
};

export const ERROR_GEOCODING_OPERATION = 'ERROR_GEOCODING_OPERATION';

export const MAX_FEATURES_FROM_LAYER = 3;

export const SEARCH_TYPE_NONE = 'none';
export const SEARCH_TYPE_GEOCODER = 'geocoder';
export const SEARCH_TYPE_LAYER = 'layer';
export const SEARCH_TYPE_COORDINATES = 'coordinates';

export const SEARCH_PAGE_SETTINGS: PaginationConfig = {
  position: 'bottom',
  align: 'center',
  size: 'default',
  showQuickJumper: false,
  showSizeChanger: false,
  showLessItems: true,
  hideOnSinglePage: true,
} as const;

export const DIRECTION_PAGE_SETTINGS: PaginationConfig = {
  position: 'bottom',
  align: 'center',
  size: 'default',
  showQuickJumper: false,
  showSizeChanger: false,
  showLessItems: true,
  hideOnSinglePage: true,
} as const;

export const DEFAULT_PAGE_SETTINGS: PaginationConfig = {
  disabled: true,
  hideOnSinglePage: true,
  pageSize: undefined,
};

export const MODES = {
  SEARCH: 'search',
  DIRECTIONS: 'directions',
};

export const SEARCH_PANEL_CONTAINER_ID = 'search_panel';
export const DIRECTIONS_PANEL_CONTAINER_ID = 'directions_panel';
export const SETTINGS_PANEL_ID = 'settings_panel';

export const SEARCH_TEMPORARY_LAYER_ID = 'search_layer_results';

export const SEARCH_RESULT_ICON_SIZE = 16;
export const START_POINT_ICON_SIZE = 12;

export const START_POINT_STYLE = { marginLeft: -10, padding: 4 };

export const LIST_SEARCH_ITEM_MAX_SIZE = 75;
export const LIST_DIRECTION_ITEM_MAX_SIZE = 45;
export const PAGER_HEIGHT = 60;
export const INPUT_BLOCK_HEIGHT = 50;
export const TITLE_SUBSTRING_SIZE = 60;
export const MIN_SEARCH_LENGTH = 3;

export const DEFAULT_DIRECTION_POINT: ISearchItem = {
  title: '',
  type: '',
};
