import { Card } from 'antd';
import { FC, useState } from 'react';

import { ICsdData, ITimePoints } from '../../ts/models/signalPrograms.model';
import SignalProgramsBand from '../SignalProgramsBand/SignalProgramsBand';

import DirectionNames from './DirectionNames/DirectionNames';
import EmptyContainer from './EmptyContainer/EmptyContainer';
import {
  INoData,
  SignalProgramsBandProps,
} from './model/SignalProgramsGraph.model';
import Program, { IProgram } from './Program/Program';
import Settings from './Settings/Settings';
import TimeLine from './TimeLine/TimeLine';
import Title from './Title/Title';

import styles from './NewSignalProgram.module.scss';

interface INewSignalProgram {
  id: number;
  isStatic: boolean;
  phasesSet: ITimePoints[];
  csdData: ICsdData | undefined;
  phaseGroupNum?: N<number>;
  title?: string;
  isEmpty?: boolean;
  isError?: boolean;
}

const NoData = ({
  text = 'Недостаточно данных для отрисовки программы.',
}: INoData) => <h2 className={styles.noData}>{text}</h2>;

const SPECIAL_MODE =
  'Для отображения сигнальной программы необходимо включить "Рабочий штатный режим"';

const NewSignalProgram: FC<INewSignalProgram> = ({
  id,
  isStatic,
  phasesSet,
  csdData,
  title,
  phaseGroupNum,
  isEmpty = false,
  isError = false,
}) => {
  const [width, setWidth] = useState<number>(0);

  if (!csdData || !csdData.circle || !phasesSet.length || !csdData.directions)
    return <NoData />;

  if (isEmpty && !isStatic) return <EmptyContainer text={SPECIAL_MODE} />;

  const step = width / csdData.circle;

  const defaultProps = {
    phasesSet,
    isStatic,
  };

  const programProps: IProgram = {
    ...defaultProps,
    step,
    csdData,
    setWidth,
  };

  const sgnProgramsBandProps: SignalProgramsBandProps = {
    isStatic,
    phasesSet,
    len: csdData.circle,
    isEmpty,
    isError,
    width,
  };

  return (
    <Card className={styles.container} key={id}>
      <div className={styles.wrapper}>
        <Title
          id={id}
          isStatic={isStatic}
          title={title}
          phaseGroupNum={phaseGroupNum}
        />
        <Settings phasesSet={phasesSet} isStatic={isStatic} />
        <div className={styles.programRow}>
          <DirectionNames isStatic={isStatic} csdData={csdData} />
          <div className={styles.column}>
            <Program {...programProps} />
            <TimeLine width={width} maxCount={csdData.circle} />
            <SignalProgramsBand {...sgnProgramsBandProps} />
          </div>
        </div>
      </div>
    </Card>
  );
};

export default NewSignalProgram;
