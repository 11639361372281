import { IValuesDatesArr } from '../../../constants/constants';

export interface TDtInfo {
  caption: string | JSX.Element;
  valueDt: string;
  dsc: string;
}

interface IGetTitleDtProps {
  captions: (string | JSX.Element)[];
  valuesArr: [string, IValuesDatesArr][];
}

export const getTitleDetectors = ({ captions, valuesArr }: IGetTitleDtProps) =>
  captions.map((el) => {
    const titleArr =
      valuesArr.find(
        (valArr) => valArr[1].min === el || valArr[1].value === el
      ) ?? valuesArr[0];
    const valueDt = titleArr[0];
    const dsc = titleArr[1].value;

    return { caption: el, valueDt, dsc };
  });
