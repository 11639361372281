import { useEffect, useMemo, useState } from 'react';

import rootStore from '../../../../../stores/rootStore/rootStore';
import { ITimePoints } from '../../../../../ts/models/signalPrograms.model';
import getCsdData, {
  CsdDataValues,
} from '../../../../NewSignalProgram/helpers/getCsdData';
import { getCurrentSgnProgramInfo } from '../helpers/getCurrentSgnProgramInfo';

interface SgnProgram {
  phasesSet: ITimePoints[];
  csdData: CsdDataValues['csdData'];
}

const useCurrentProgram = (id: number) => {
  const { tls } = rootStore.mapDataStore;

  const [sgnProgramValue, setSgnProgramValue] = useState<N<SgnProgram>>(null);

  const [isError, setIsError] = useState(false);

  const { tlData } = useMemo(
    () => getCurrentSgnProgramInfo(tls, id),
    [id, tls]
  );

  useEffect(() => {
    setIsError(false);

    const res = getCsdData({
      isStatic: true,
      data: tlData,
    });

    if (!res?.csdData || !res?.timePoints?.length) return setIsError(true);

    const { csdData, timePoints } = res;

    setSgnProgramValue({ csdData, phasesSet: timePoints });
  }, [tlData]);

  return { isError, sgnProgramValue };
};

export default useCurrentProgram;
