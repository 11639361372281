import { VERSION } from '../../../../constants/versions';
import { IRenderer } from '../../../../ts/models/gis/renderer.model';
import { getAppPathHeaders } from '../../../helpers/getAppPathHeaders';
import http from '../../../http';
import tryCatchWrapper from '../../../tryCatchWrapper';

const GIS_RENDERERS = `${VERSION}/integration/gis/renderers-configuration`;

const REQUEST_PARAMS = {
  isExternalError: true,
};

const renderersApi = {
  getList: (appPath: string, id: number) =>
    tryCatchWrapper(
      async (): Promise<IRenderer[]> => {
        const res = await http.get(`${GIS_RENDERERS}`, {
          headers: getAppPathHeaders(appPath),
          params: { regionId: id },
        });

        return res?.data;
      },
      {
        ...REQUEST_PARAMS,
        errorMessage: 'Ошибка получения доступных стилей',
        ignoreCodes: [423],
      }
    ),
};

export default renderersApi;
