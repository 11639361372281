import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { IoIosWarning } from 'react-icons/io';

import { COLORS } from '../../../constants/colorsConstants';
import eventBus from '../../../eventBus';
import { BroadcastIncorrectTimeProps } from '../../../eventBus/events/models/event.model';
import useDayInfo, { StyleBlock } from '../../../hooks/useDayInfo';
import rootStore from '../../../stores/rootStore/rootStore';
import NotifyTimeDescription from '../../Socket/NotifyTimeDescription/NotifyTimeDescription';
import Popover, { PopoverWithoutChildren } from '../../ui-kit/Popover/Popover';

import styles from './InfoBlock.module.scss';

interface IInfoItems {
  firstRaw: string[] | string;
  secondRaw: string;
  style: StyleBlock;
}

const { InfoTop } = StyleBlock;

function InfoBlock() {
  const { initialMapCenter, interfaceTipsDelay } = rootStore.uiStore;
  const [incorrectTimeInfo, setIncorrectTimeInfo] =
    useState<N<BroadcastIncorrectTimeProps>>(null);

  const infoItems: IInfoItems[] = useDayInfo(initialMapCenter);

  useEffect(() => {
    const unsubscribe =
      eventBus.incorrectTimeChange.subscribe(setIncorrectTimeInfo);

    return () => unsubscribe();
  }, []);

  return (
    <section className={styles.infoWrapper}>
      {infoItems.map(({ firstRaw, secondRaw, style }) => {
        if (!firstRaw && !secondRaw) return null;

        const isWarning =
          style === InfoTop && incorrectTimeInfo?.isCorrectTime === false;

        const popoverProps: PopoverWithoutChildren = {
          tipsDelay: isWarning ? interfaceTipsDelay : null,
          placement: 'right',
          content:
            incorrectTimeInfo?.timeDiff && incorrectTimeInfo?.dateServerDJS ? (
              <NotifyTimeDescription
                time={incorrectTimeInfo.timeDiff}
                serverTime={incorrectTimeInfo.dateServerDJS}
              />
            ) : (
              ''
            ),
        };

        return (
          <Popover key={style} {...popoverProps}>
            <div
              key={style}
              className={classNames(styles[style], {
                [styles.warning]: isWarning,
              })}
            >
              {style === InfoTop ? (
                <span>
                  {firstRaw[0]}
                  <span className={styles.colon}>:</span>
                  {firstRaw[1]}
                  {isWarning && (
                    <IoIosWarning
                      size={11}
                      className={styles.warningIco}
                      color={COLORS.WARNING}
                    />
                  )}
                </span>
              ) : (
                <span>{firstRaw}</span>
              )}

              <span className={styles.secondRaw}>{secondRaw}</span>
            </div>
          </Popover>
        );
      })}
    </section>
  );
}

export default observer(InfoBlock);
