import classNames from 'classnames';
import { Dayjs } from 'dayjs';
import { FC } from 'react';

import 'dayjs/locale/ru';

import { ReactComponent as InfoIcon } from '../../../../assets/icons/confirm_icon.svg';
import { ReactComponent as InfoIconPhaseHold } from '../../../../assets/icons/icon_phase_hold.svg';

import { getTimerInfo } from './helpers/getTimerInfo';
import useTimer from './hooks/useTimer';

import styles from './Timer.module.scss';

interface TimerProps {
  startTime: Dayjs;
  endTime: Dayjs;
  phaseNum: number;
  className?: string;
}

export const SEC = 1000;

const Timer: FC<TimerProps> = ({
  phaseNum,
  startTime,
  endTime,
  className = '',
}) => {
  const { sec, isPhaseHold } = useTimer(startTime, endTime);

  if (!sec) return null;

  const {
    time: [h, m, s],
    titlePhaseDate,
    titlePhaseTimer,
  } = getTimerInfo(startTime, endTime, phaseNum, sec, isPhaseHold);

  const icon = !isPhaseHold ? (
    <InfoIcon className={styles.infoIcon} />
  ) : (
    <InfoIconPhaseHold className={styles.infoIcon} />
  );

  const containerStyle = classNames({
    [styles.rowContainer]: true,
    [className]: className,
  });

  return (
    <div className={containerStyle}>
      {icon}
      <div className={styles.columnContainer}>
        <span>
          {`${titlePhaseTimer}: `}
          <p className={styles.time}>
            {h.toString().padStart(2, '0')}
            <strong> : </strong>
            {m.toString().padStart(2, '0')}
            <strong> : </strong>
            {s.toString().padStart(2, '0')}
          </p>
        </span>
        <span>{titlePhaseDate}</span>
      </div>
    </div>
  );
};

export default Timer;
