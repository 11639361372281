import { Collapse } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC, useCallback, useEffect, useState } from 'react';

import { filesApi } from '../../../../api/files/files';
import rootStore from '../../../../stores/rootStore/rootStore';
import DefaultChecked from '../../../ui-kit/DefaultChecked/DefaultChecked';
import { FilesDataType } from '../../models/documents.model';

import FileList from './FileList/FileList';
import PanelHeader from './PanelHeader/PanelHeader';

import styles from './DocumentsList.module.scss';

const { Panel } = Collapse;

interface IDocumentList {
  id: number;
}

const DocumentsList: FC<IDocumentList> = ({ id }) => {
  const { isUpdate, setDocumentsKeysValues, isDocListLoading } =
    rootStore.documentsStore;
  const [data, setData] = useState<FilesDataType[]>([]);

  const getFileList = useCallback(async () => {
    const res = await filesApi.fetchFilesList(id, '.detailed.documents');

    setDocumentsKeysValues({
      isUpdate: false,
      isDocListLoading: false,
    });

    setData(res?.data ?? []);
  }, [id, setDocumentsKeysValues]);

  useEffect(() => {
    setDocumentsKeysValues({
      isDocListLoading: true,
    });

    getFileList();
  }, [getFileList, setDocumentsKeysValues]);

  useEffect(() => {
    if (isUpdate) {
      setDocumentsKeysValues({
        isDocListLoading: true,
      });

      getFileList();
    }
  }, [getFileList, isUpdate, setDocumentsKeysValues]);

  return (
    <div className={styles.wrapper}>
      <DefaultChecked
        isLoading={isDocListLoading}
        isEmptyData={!data.length}
        isBackdropFilter
      >
        <div className={styles.collapse}>
          <Collapse defaultActiveKey={['1']}>
            {data.map((item) => (
              <Panel
                header={<PanelHeader data={item} id={id} />}
                key={item.lb_file_category_id}
              >
                <FileList fileListItem={item} />
              </Panel>
            ))}
          </Collapse>
        </div>
      </DefaultChecked>
    </div>
  );
};

export default observer(DocumentsList);
