/* eslint-disable camelcase */
import { BiWater } from 'react-icons/bi';
import {
  BsArrowUp,
  BsCloudHail,
  BsCloudSnow,
  BsDroplet,
  BsDropletHalf,
  BsSnow,
  BsUmbrella,
} from 'react-icons/bs';
import { CiTempHigh } from 'react-icons/ci';
import {
  FaTemperatureHigh,
  FaWind,
  FaTemperatureLow,
  FaSoundcloud,
  FaCloudversify,
} from 'react-icons/fa';
import { GiIceCube } from 'react-icons/gi';
import { GrSoundcloud } from 'react-icons/gr';
import { LiaTemperatureLowSolid } from 'react-icons/lia';
import { MdCarRepair } from 'react-icons/md';
import { RiWaterPercentFill, RiWaterPercentLine } from 'react-icons/ri';
import {
  TiWeatherDownpour,
  TiWeatherShower,
  TiWeatherSunny,
} from 'react-icons/ti';
import { WiNightSnowThunderstorm } from 'react-icons/wi';

import { ReactComponent as FreezingDrizzleIco } from '../../assets/icons/weather/precipitation/freezing_drizzle.svg';
import { ReactComponent as IceCrystalsIco } from '../../assets/icons/weather/precipitation/ice_crystals.svg';
import { ReactComponent as IceGritsIco } from '../../assets/icons/weather/precipitation/ice_grits.svg';
import { ReactComponent as SnowGrainsIco } from '../../assets/icons/weather/precipitation/snow_grains.svg';
import { ReactComponent as SnowGroatsIco } from '../../assets/icons/weather/precipitation/snow_groats.svg';
import { MeteoKeys } from '../../ts/models/MapObjects/meteo.model';

export enum PrecipitationValues {
  Missing = 'Missing',
  Drizzle = 'Drizzle',
  FreezingDrizzle = 'FreezingDrizzle',
  IceGrits = 'IceGrits',
  IceCrystals = 'IceCrystals',
  SnowGrains = 'SnowGrains',
  SnowGroats = 'SnowGroats',
  Rain = 'Rain',
  FreezingRain = 'FreezingRain',
  SnowWithRain = 'SnowWithRain',
  Snow = 'Snow',
  Hail = 'Hail',
  Unknown = 'Unknown',
}

const ADDITIONS = {
  WEAK: 'cлабый',
  NONE: '',
  STRONG: 'сильный',
  VERY_STRONG: 'очень сильный',
} as const;

const { WEAK, NONE, STRONG, VERY_STRONG } = ADDITIONS;

const RAIN_OPTIONS = [
  {
    INTENSITY: {
      MIN: null,
      MAX: 0.17,
    },
    LABEL: WEAK,
  },
  {
    INTENSITY: {
      MIN: 0.17,
      MAX: 1.17,
    },
    LABEL: NONE,
  },
  {
    INTENSITY: {
      MIN: 1.17,
      MAX: 4.08,
    },
    LABEL: STRONG,
  },
  {
    INTENSITY: {
      MIN: 4.08,
      MAX: null,
    },
    LABEL: VERY_STRONG,
  },
];

const SNOW_OPTIONS = [
  {
    INTENSITY: {
      MIN: null,
      MAX: 0.17,
    },
    LABEL: WEAK,
  },
  {
    INTENSITY: {
      MIN: 0.17,
      MAX: 0.42,
    },
    LABEL: NONE,
  },
  {
    INTENSITY: {
      MIN: 0.42,
      MAX: 1.58,
    },
    LABEL: STRONG,
  },
  {
    INTENSITY: {
      MIN: 1.58,
      MAX: null,
    },
    LABEL: VERY_STRONG,
  },
];

export const PRECIPITATION = [
  {
    CODE: 0,
    VALUE: PrecipitationValues.Missing,
    TEXT: 'Нет осадков',
    ICON: <TiWeatherSunny />,
    OPTIONS: null,
  },
  {
    CODE: 51,
    VALUE: PrecipitationValues.Drizzle,
    TEXT: 'Морось',
    ICON: <TiWeatherShower />,
    OPTIONS: null,
  },
  {
    CODE: 52,
    VALUE: PrecipitationValues.FreezingDrizzle,
    TEXT: 'Замерзающая морось',
    ICON: <FreezingDrizzleIco />,
    OPTIONS: null,
  },
  {
    CODE: 96,
    VALUE: PrecipitationValues.Rain,
    TEXT: 'Дождь',
    ICON: <TiWeatherDownpour />,
    OPTIONS: RAIN_OPTIONS,
  },
  {
    CODE: 103,
    VALUE: PrecipitationValues.FreezingRain,
    TEXT: 'Ледяной дождь',
    ICON: <TiWeatherDownpour />,
    OPTIONS: RAIN_OPTIONS,
  },
  {
    CODE: 105,
    VALUE: PrecipitationValues.SnowWithRain,
    TEXT: 'Снег с дождём',
    ICON: <WiNightSnowThunderstorm />,
    OPTIONS: SNOW_OPTIONS,
  },
  {
    CODE: 106,
    VALUE: PrecipitationValues.IceGrits,
    TEXT: 'Ледяная крупа',
    ICON: <IceGritsIco />,
    OPTIONS: null,
  },
  {
    CODE: 107,
    VALUE: PrecipitationValues.IceCrystals,
    TEXT: 'Ледяные кристаллы',
    ICON: <IceCrystalsIco />,
    OPTIONS: null,
  },
  {
    CODE: 112,
    VALUE: PrecipitationValues.Snow,
    TEXT: 'Снег',
    ICON: <BsCloudSnow />,
    OPTIONS: SNOW_OPTIONS,
  },
  {
    CODE: 113,
    VALUE: PrecipitationValues.SnowGrains,
    TEXT: 'Снежные зерна',
    ICON: <SnowGrainsIco />,
    OPTIONS: null,
  },
  {
    CODE: 114,
    VALUE: PrecipitationValues.SnowGroats,
    TEXT: 'Снежная крупа',
    ICON: <SnowGroatsIco />,
    OPTIONS: null,
  },
  {
    CODE: 144,
    VALUE: PrecipitationValues.Hail,
    TEXT: 'Град',
    ICON: <BsCloudHail />,
    OPTIONS: null,
  },
  {
    CODE: 255,
    VALUE: PrecipitationValues.Unknown,
    TEXT: 'Неизвестный тип',
    ICON: null,
    OPTIONS: null,
  },
] as const;

export enum ConditionRoadwayValues {
  NotPossible = 'NotPossible',
  Dry = 'Dry',
  Wetly = 'Wetly',
  Wet = 'Wet',
  Ice = 'Ice',
  Snow = 'Snow',
  DeicingMaterial = 'DeicingMaterial',
  SnowWithIce = 'SnowWithIce',
  DrySmallTracesSnow = 'DrySmallTracesSnow',
  CriticalMoisture = 'CriticalMoisture',
  Frost = 'Frost',
  WetDeicingMaterials = 'WetDeicingMaterials',
  WetlyDeicingMaterials = 'WetlyDeicingMaterials',
  IceDeicingMaterials = 'IceDeicingMaterials',
  FreezingWater = 'FreezingWater',
  Slush = 'Slush',
  SnowSprinkledSand = 'SnowSprinkledSand',
}

export const CONDITION_ROADWAY = [
  {
    CODE: 0,
    VALUE: ConditionRoadwayValues.NotPossible,
    TEXT: 'Измерение невозможно',
  },
  {
    CODE: 1,
    VALUE: ConditionRoadwayValues.Dry,
    TEXT: 'Сухо',
  },
  {
    CODE: 2,
    VALUE: ConditionRoadwayValues.Wetly,
    TEXT: 'Влажно',
  },
  {
    CODE: 3,
    VALUE: ConditionRoadwayValues.Wet,
    TEXT: 'Мокро',
  },
  {
    CODE: 5,
    VALUE: ConditionRoadwayValues.Ice,
    TEXT: 'Лёд',
  },
  {
    CODE: 6,
    VALUE: ConditionRoadwayValues.Snow,
    TEXT: 'Снег',
  },
  {
    CODE: 9,
    VALUE: ConditionRoadwayValues.DeicingMaterial,
    TEXT: 'Противогололёдные материалы',
  },
  {
    CODE: 10,
    VALUE: ConditionRoadwayValues.SnowWithIce,
    TEXT: 'Снег со льдом',
  },
  {
    CODE: 11,
    VALUE: ConditionRoadwayValues.DrySmallTracesSnow,
    TEXT: 'Сухо, небольшие следы снега/льда',
  },
  {
    CODE: 21,
    VALUE: ConditionRoadwayValues.CriticalMoisture,
    TEXT: 'Критическая влага',
  },
  {
    CODE: 22,
    VALUE: ConditionRoadwayValues.Frost,
    TEXT: 'Иней',
  },
  {
    CODE: 29,
    VALUE: ConditionRoadwayValues.WetDeicingMaterials,
    TEXT: 'Влажно, противогололедные материалы',
  },
  {
    CODE: 39,
    VALUE: ConditionRoadwayValues.WetlyDeicingMaterials,
    TEXT: 'Мокро, противогололедные материалы',
  },
  {
    CODE: 49,
    VALUE: ConditionRoadwayValues.SnowSprinkledSand,
    TEXT: 'Снег, посыпанный песком',
  },
  {
    CODE: 59,
    VALUE: ConditionRoadwayValues.IceDeicingMaterials,
    TEXT: 'Лед, противогололедные материалы',
  },
  {
    CODE: 73,
    VALUE: ConditionRoadwayValues.FreezingWater,
    TEXT: 'Замерзающая вода',
  },
  {
    CODE: 76,
    VALUE: ConditionRoadwayValues.Slush,
    TEXT: 'Слякоть',
  },
] as const;

export const CONDITION_ROADWAY_CODES = CONDITION_ROADWAY.map(
  ({ CODE }) => CODE
);
export const PRECIPITATION_CODES = PRECIPITATION.map(({ CODE }) => CODE);

export enum MeteoProcessType {
  Round = 'Round',
  Dotted = 'Dotted',
  Nothing = 'Nothing',
  RoundConcentration = 'RoundConcentration',
}

const { Dotted, Round, Nothing, RoundConcentration } = MeteoProcessType;
const VALUE_GAS_TYPE = `мг/м3`;

export const ROUND_CONCENTRATE = 4;

export enum MeteoInfoKeys {
  EnvTemp = 'envTemp',
  Pressure = 'pressure',
  RoadGrip = 'roadGrip',
  Humidity = 'humidity',
  Precipitation = 'precipitation',
  WindDir = 'windDir',
  WindSpeed = 'windSpeed',
  DewPoint = 'dewPoint',
  RoadTemp = 'roadTemp',
  AiaFreezePoint = 'aiaFreezePoint',
  AiaPercent = 'aiaPercent',
  IceH = 'iceH',
  IcePercent = 'icePercent',
  SnowH = 'snowH',
  WaterH = 'waterH',
  GasCO = 'gasCO',
  GasNO = 'gasNO',
  GasNO2 = 'gasNO2',
  GasSO2 = 'gasSO2',
}

export interface MeteoDataInfo {
  key: MeteoInfoKeys;
  icon: JSX.Element;
  processType: MeteoProcessType;
  valueType: string;
  tooltip: string;
}

type MeteoDataObj = Record<MeteoKeys, MeteoDataInfo>;

export const METEO_INFO: MeteoDataObj = {
  env_temp: {
    key: MeteoInfoKeys.EnvTemp,
    icon: <FaTemperatureHigh />,
    processType: Dotted,
    valueType: '°С',
    tooltip: 'Температура окружающей среды, °С',
  },
  pressure: {
    key: MeteoInfoKeys.Pressure,
    icon: <LiaTemperatureLowSolid />,
    processType: Round,
    valueType: 'мм рт. ст.',
    tooltip: 'Абсолютное атмосферное давление, мм рт.ст.',
  },
  road_grip: {
    key: MeteoInfoKeys.RoadGrip,
    icon: <MdCarRepair />,
    processType: Dotted,
    valueType: '',
    tooltip: 'Коэффициент сцепления с дорогой (0…1)',
  },
  humidity: {
    key: MeteoInfoKeys.Humidity,
    icon: <BsDroplet />,
    processType: Round,
    valueType: '%',
    tooltip: 'Относительная влажность воздуха, %',
  },
  precipitation_intensity: {
    key: MeteoInfoKeys.Precipitation,
    icon: <BsUmbrella />,
    processType: Dotted,
    valueType: 'мм/ч',
    tooltip: 'Интенсивность осадков, мм/час',
  },
  wind_direction: {
    key: MeteoInfoKeys.WindDir,
    icon: <BsArrowUp />,
    processType: Nothing,
    valueType: '',
    tooltip: 'Метеорологическое направление ветра, °',
  },
  wind_speed: {
    key: MeteoInfoKeys.WindSpeed,
    icon: <FaWind />,
    processType: Round,
    valueType: 'м/с',
    tooltip: 'Скорость ветра, м/с',
  },
  dew_point: {
    key: MeteoInfoKeys.DewPoint,
    icon: <BsDropletHalf />,
    processType: Dotted,
    valueType: '°С',
    tooltip: 'Температура точки росы, °',
  },
  road_temp: {
    key: MeteoInfoKeys.RoadTemp,
    icon: <CiTempHigh />,
    processType: Dotted,
    valueType: '°С',
    tooltip: 'Температура дорожного полотна, °',
  },
  aia_freeze_point: {
    key: MeteoInfoKeys.AiaFreezePoint,
    icon: <FaTemperatureLow />,
    processType: Dotted,
    valueType: '°С',
    tooltip: 'Температура замерзания противогололедных материалов, °',
  },
  aia_percent: {
    key: MeteoInfoKeys.AiaPercent,
    icon: <RiWaterPercentLine />,
    processType: Round,
    valueType: '%',
    tooltip: 'Процент содержания противогололедных материалов в каше, %',
  },
  ice_height: {
    key: MeteoInfoKeys.IceH,
    icon: <BsSnow />,
    processType: Round,
    valueType: 'мм',
    tooltip: 'Толщина слоя снега на дороге, мм',
  },
  ice_percent: {
    key: MeteoInfoKeys.IcePercent,
    icon: <RiWaterPercentFill />,
    processType: Round,
    valueType: '%',
    tooltip: 'Процент льда в снеге на дороге, %',
  },
  snow_height: {
    key: MeteoInfoKeys.SnowH,
    icon: <GiIceCube />,
    processType: Round,
    valueType: 'мм',
    tooltip: 'Толщина слоя льда на дороге, мм',
  },
  water_height: {
    key: MeteoInfoKeys.WaterH,
    icon: <BiWater />,
    processType: Round,
    valueType: 'мм',
    tooltip: 'Толщина слоя воды на дороге, мм',
  },
  GAS_CO: {
    key: MeteoInfoKeys.GasCO,
    icon: <FaSoundcloud />,
    processType: RoundConcentration,
    valueType: VALUE_GAS_TYPE,
    tooltip: `Концентрация угарного газа, ${VALUE_GAS_TYPE}`,
  },
  GAS_NO: {
    icon: <GrSoundcloud />,
    key: MeteoInfoKeys.GasNO,
    processType: RoundConcentration,
    valueType: VALUE_GAS_TYPE,
    tooltip: `Концентрация оксида азота, ${VALUE_GAS_TYPE}`,
  },
  GAS_NO2: {
    key: MeteoInfoKeys.GasNO2,
    icon: <FaSoundcloud color="var(--danger)" />,
    processType: RoundConcentration,
    valueType: VALUE_GAS_TYPE,
    tooltip: `Концентрация диоксида азота, ${VALUE_GAS_TYPE}`,
  },
  GAS_SO2: {
    key: MeteoInfoKeys.GasSO2,
    icon: <FaCloudversify />,
    processType: RoundConcentration,
    valueType: VALUE_GAS_TYPE,
    tooltip: `Концентрация сернистого газа, ${VALUE_GAS_TYPE}`,
  },
};
