import { useEffect, useMemo, useState } from 'react';

import { IHeaderItem } from '../../../../PhaseHold/PhaseHold.model';
import ModalSignalProgram from '../../../../PlanSelect/ModalSignalProgram/ModalSignalProgram';
import EditSignalProgramTable from '../../../../TemporaryPlan/EditSgnProgramTable/EditSgnProgramTable';
import { getDurationMinArray } from '../../../../TemporaryPlan/helpers/getDurationMinArray';
import { UseEditModalProps } from '../../../model/currentPlan.model';

const useEditModal = ({
  isValidateTable,
  dataTable,
  setIsValidateTable,
  setDataTable,
  openEditForm,
  baseProgramProps,
  defaultPhasesSet,
}: UseEditModalProps) => {
  const [columns, setColumns] = useState<IHeaderItem[]>([]);

  useEffect(() => {
    setColumns([]);
  }, [openEditForm]);

  const minDurations = useMemo(() => {
    return getDurationMinArray(defaultPhasesSet);
  }, [defaultPhasesSet]);

  const editBodyProps = {
    content: (
      <EditSignalProgramTable
        setIsValidateTable={setIsValidateTable}
        dataTable={dataTable}
        setDataTable={setDataTable}
        isPhasesTable
        durationMinArray={minDurations}
        columns={columns}
        setColumns={setColumns}
      />
    ),
    image: (
      <ModalSignalProgram
        {...baseProgramProps}
        isError={baseProgramProps.isError || !isValidateTable}
      />
    ),
    phasesEdit: true,
    isPhasesTable: true,
  };

  return { editBodyProps };
};

export default useEditModal;
