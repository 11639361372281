import type { CanvasFilterOptions } from 'ol-ext/filter/CanvasFilter';

export const BASEMAPS_PROPS = {
  CATEGORY_PROPERTY: 'category',
  CATEGORY_PROPERTY_VALUE: 'basemap',
};

export const BASEMAPS_TYPES = {
  XYZ: 'esri/layers/OpenStreetMapLayer',
  VECTOR: 'esri/layers/VectorTileLayer',
};

export const BW_BASEMAP_FILTER: CanvasFilterOptions = {
  blur: 0,
  brightness: 100,
  hueRotate: 0,
  invert: 0,
  saturate: 0,
  sepia: 0,
  shadow: undefined,
  shadowBlur: 0,
  shadowColor: 0,
  url: '',
  grayscale: 180,
  contrast: 120,
};
