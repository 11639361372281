import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/ru';

import { DATE_FORMAT } from '../../../../../../../constants/constants';

import { getTimeZones } from './getTimeZone';

export const MINUTES_IN_HOUR = 60;

export interface IGetTimeWithZoneProps {
  timeZone?: number;
  dateJS?: Dayjs;
  dateFormat?: string;
  isUTCZero?: boolean;
}

interface ReturnValue {
  dateFormatted: string;
  dateFormattedJS: Dayjs;
}

type FuncGetTimeWithTZ = (options?: IGetTimeWithZoneProps) => ReturnValue;

export const getTimeWithTimeZone: FuncGetTimeWithTZ = ({
  timeZone = getTimeZones().timeZoneRegion,
  dateJS = dayjs(),
  dateFormat = DATE_FORMAT,
  isUTCZero = false,
} = {}) => {
  const utc = !isUTCZero ? dateJS.utcOffset() / MINUTES_IN_HOUR : 0;

  const difTimeZone = timeZone - utc;

  const dateFormattedJS = dateJS.add(difTimeZone, 'hour').locale('ru');

  const dateFormatted = dateFormattedJS.format(dateFormat);

  return { dateFormatted, dateFormattedJS };
};
