import { FC } from 'react';

import rootStore from '../../../../../stores/rootStore/rootStore';
import Popover, {
  PopoverWithoutChildren,
} from '../../../../ui-kit/Popover/Popover';
import { ChartBDKeys } from '../Chart/constants/constants';
import { IDataGraph } from '../PopupDetector.model';

import styles from './CountBlock.module.scss';

interface CountBlockProps {
  radioValue: ChartBDKeys;
  activeCell: U<IDataGraph>;
}

const CountBlock: FC<CountBlockProps> = ({ radioValue, activeCell }) => {
  const { interfaceTipsDelay } = rootStore.uiStore;

  if (!activeCell) return null;

  const isPassageCount = radioValue === ChartBDKeys.PassageCount;
  const titleTooltip = isPassageCount
    ? `Интенсивность ТС`
    : `средняя скорость ТС`;

  const units = isPassageCount ? '' : 'км/ч';

  const popoverProps: PopoverWithoutChildren = {
    tipsDelay: interfaceTipsDelay,
    content: `${titleTooltip}: ${activeCell.comparableValue} ${units}`,
    placement: 'left',
  };

  return (
    <Popover {...popoverProps}>
      <span
        className={styles.infoBlock}
        style={{ backgroundColor: activeCell.status }}
      >
        {activeCell.comparableValue}
      </span>
    </Popover>
  );
};

export default CountBlock;
