import { LibraryKeys } from '../../../../../api/libraries/enums/enums';
import { UNKNOWN_STATUS } from '../../../../../constants/adminConstants';
import { findBy } from '../../../../../helpers/findBy';
import rootStore from '../../../../../stores/rootStore/rootStore';

const { AdapterStatus } = LibraryKeys;

interface StatusInfo {
  name: string;
  dsc: string;
  mnemocode: string;
}

export const UNDEFINED_STATUS = {
  name: 'Не определено',
  dsc: 'Не определен статус работы адаптера',
  mnemocode: UNKNOWN_STATUS,
} as const;

const getAdapterStatusDict = (status: Nullish<string>): N<StatusInfo> => {
  const adapterStatusDict = rootStore.uiStore.dictionaries[AdapterStatus];

  if (!adapterStatusDict.length) return null;

  const adapterStatusMnemo = findBy(
    adapterStatusDict,
    status ?? UNKNOWN_STATUS,
    'mnemocode'
  );

  const adapterStatusName = findBy(
    adapterStatusDict,
    status ?? UNDEFINED_STATUS.name,
    'name'
  );

  const adapterStatus = adapterStatusMnemo ?? adapterStatusName;

  const statusInfo: StatusInfo = {
    name: adapterStatus?.name ?? UNDEFINED_STATUS.name,
    dsc: adapterStatus?.dsc ?? UNDEFINED_STATUS.dsc,
    mnemocode: adapterStatus?.mnemocode ?? UNDEFINED_STATUS.mnemocode,
  };

  return statusInfo;
};

export default getAdapterStatusDict;
