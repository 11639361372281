export const MAP_BUTTONS_NAMES = {
  SEARCH: 'search',
  BASEMAPS: 'basemaps',
  MEASUREMENTS: 'measurements',
  LAYERS: 'layers',
  WEATHER: 'weather',
  TRAFFIC: 'traffic',
  TRAFFIC_GRAPH: 'trafficGraph',
  NONE: 'none',
};
