import { z } from 'zod';

import { VERSION } from '../../../../constants/versions';
import { TableData } from '../../../../ts/models/table.model';
import { Path } from '../../../api.model';
import http from '../../../http';
import tryCatchWrapper from '../../../tryCatchWrapper';

import { PlannedCommandsSchema } from './plannedCommands.zod';

export type CommandsPlannedTable = z.infer<typeof PlannedCommandsSchema>;

const OBJECTS = VERSION + '/objects';
const TL = OBJECTS + '/tl/';

const plannedCommandsApi = {
  getPlannedCommands: (
    id: number,
    selectedDate: string[],
    appPath: Path,
    isBlockedNotify = false
  ) =>
    tryCatchWrapper(
      async () => {
        const plannedCommands = await http.get<TableData>(
          `${TL + id}/command/planned`,
          {
            params: {
              dateFrom: selectedDate[0],
              dateTo: selectedDate[1],
            },
          }
        );

        return plannedCommands.data;
      },
      {
        isBlockedNotify,
        errorMessage: 'PLANNED_CMD_ERROR',
        notifyErrorDsc: '',
      }
    ),
};

export default plannedCommandsApi;
