import { FC } from 'react';

import parseTLMode from '../../../../../../../helpers/parseTLMode';
import { System } from '../../../../../../../ts/enums/enums';
import { TlMode } from '../../../../../../../ts/models/tl.model';
import { getAvatarIcon } from '../../../../../../DetailedStatistics/Monitoring/SystemMonitoring/helpers/helpers';

import styles from './StatusBlock.module.scss';

interface StatusBlockProps {
  mode: TlMode;
}

const StatusBlock: FC<StatusBlockProps> = ({ mode }) => {
  const color = parseTLMode(mode).color;

  return (
    <div className={styles.container}>
      <span style={{ color }}>{getAvatarIcon(System.Lights)}</span>
    </div>
  );
};

export default StatusBlock;
