import { FC } from 'react';

import { ICamera } from '../../../../ts/models/camera.model';
import { getCameraInfo } from '../../../VideoCamera/helpers/getCameraInfo/getCameraInfo';
import VideoCamera from '../../../VideoCamera/VideoCamera';

import styles from './CamerasEqual.module.scss';

interface CamerasEqualProps {
  cameras: ICamera[];
}

const CamerasEqual: FC<CamerasEqualProps> = ({ cameras }) => {
  return (
    <div className={styles.container}>
      {cameras
        ? cameras.map((camera, i) => {
            const cameraData = getCameraInfo(camera);

            return (
              <div className={styles.cameraWrap} key={`camera_${i}`}>
                <VideoCamera cameraData={cameraData} />
              </div>
            );
          })
        : null}
    </div>
  );
};

export default CamerasEqual;
