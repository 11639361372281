import classNames from 'classnames';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import rootStore from '../../../../../stores/rootStore/rootStore';
import { MessageType } from '../../../../../ts/models/logsPanel.model';
import Popover from '../../../../ui-kit/Popover/Popover';

import styles from './MessageBlock.module.scss';
interface IMessageBock {
  item: MessageType;
  isMe: boolean;
}

const MessageBlock: FC<IMessageBock> = ({ item, isMe }) => {
  const { interfaceTipsDelay } = rootStore.uiStore;
  const { additional, timestamp, type, message } = item;

  const isAdditionalInfo = additional
    ? Boolean(Object.keys(additional).length)
    : false;

  const dateFormated = dayjs(timestamp)
    .locale('ru')
    .format('D MMM YYYY, HH:mm:ss');

  const popoverProps = {
    tipsDelay: interfaceTipsDelay,
    content: additional ? (
      <div>
        {Object.entries(additional).map(([key, value]) => (
          <p key={key}>
            {key}: {value === null ? 'null' : value}
          </p>
        ))}
      </div>
    ) : (
      ''
    ),
    placement: 'right',
  } as const;

  return (
    <Popover
      {...popoverProps}
      tipsDelay={isAdditionalInfo ? interfaceTipsDelay : null}
    >
      <div className={styles.messageBlock}>
        <p
          className={classNames(styles.time, styles[type], isMe && styles.isMe)}
        >
          {dateFormated}
        </p>
        <p
          className={classNames(
            styles.message,
            item.type !== 'default' && styles[type]
          )}
        >
          {message}
        </p>
      </div>
    </Popover>
  );
};

export default observer(MessageBlock);
