import { Map } from 'ol';

import { FeatureLayer } from '../../../apiGIS/layers/FeatureLayer';
import { Z_INDEX_LAYER } from '../../../constants/mapConstants';
import { ILayer } from '../../../stores/gisDataStore/gisDataStore.model';
import rootStore from '../../../stores/rootStore/rootStore';
import { VisibleLayer } from '../Map.model';

export const setVisibleLayers = (map: Map, layersState: ILayer[]) =>
  layersState.reduce((res: VisibleLayer[], layerDefinition) => {
    const { setGisValue } = rootStore.gisDataStore;

    const featureLayer = new FeatureLayer({
      layerDefinition,
      map,
    });

    const layer = featureLayer.getInstance();

    if (!layer) return res;
    const { id, visible, group } = layerDefinition;

    map.addLayer(layer);

    layer.setVisible(visible);
    layer.setZIndex(Z_INDEX_LAYER);

    layer.on('change:visible', (evt) => {
      const { layersVisibilityProxy } = rootStore.gisDataStore;

      const state = layersVisibilityProxy
        ? JSON.parse(layersVisibilityProxy)
        : {};

      state[id] = !evt.oldValue;

      setGisValue('layersVisibility', JSON.stringify(state));
    });

    res.push({ id, visible, group });

    return res;
  }, []);

export const removeVisibleLayers = (
  map: Map,
  visibleLayers: VisibleLayer[]
) => {
  const layers = map.getAllLayers();
  const visibleLayersIds = visibleLayers.map(({ id }) => id);

  layers.forEach((layer) => {
    const id = layer.get('id');

    visibleLayersIds.includes(id) && map.removeLayer(layer);
  });
};
