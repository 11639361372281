import { Modal } from 'antd';
import { ReactNode, FC } from 'react';

import styles from '../PhaseHold.module.scss';

export type ModalConfirmProps = Omit<IModalConfirm, 'children'>;

interface IModalConfirm {
  children: ReactNode;
  icon: ReactNode;
  modalTitle: string;
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
  onOkConfirm: () => void;
  width: string;
  okText?: string;
  okButtonProps?: any;
  handleCancel?: () => void;
}

const ModalConfirm: FC<IModalConfirm> = ({
  children,
  icon,
  modalTitle,
  openModal,
  setOpenModal,
  onOkConfirm,
  handleCancel,
  width,
  okButtonProps,
  okText = 'Отправить',
}) => {
  return (
    <Modal
      title={
        <div className={styles.titleContainer}>
          {icon}
          <strong>{modalTitle}</strong>
        </div>
      }
      styles={{ body: { maxHeight: 800, overflowY: 'auto' } }}
      okText={okText}
      width={width}
      open={openModal}
      cancelText="Отмена"
      centered
      onCancel={() => {
        setOpenModal(false);
        handleCancel?.();
      }}
      onOk={onOkConfirm}
      okButtonProps={okButtonProps}
    >
      {children}
    </Modal>
  );
};

export default ModalConfirm;
