import { observer } from 'mobx-react-lite';
import { boundingExtent } from 'ol/extent';
import Feature from 'ol/Feature';
import { Point } from 'ol/geom';
import Cluster from 'ol/source/Cluster';
import SelectCluster from 'ol-ext/interaction/SelectCluster';
import AnimatedCluster from 'ol-ext/layer/AnimatedCluster';
import { FC, useEffect, useMemo, useState } from 'react';

import {
  CLUSTERS_INFO,
  EMPTY_DURATION_CLUSTER,
  NULLABLE_ANIMATION_DURATION,
} from '../../../../constants/mapClusterConstants';
import { CENTERING } from '../../../../constants/mapConstants';
import rootStore from '../../../../stores/rootStore/rootStore';
import { FeatureDeviceProps, FeaturesTypes } from '../../../../ts/enums/enums';
import Clusters from '../../../Map/Clusters/Clusters';
import { IFeaturesArr } from '../../../Map/helpers/getFeaturesCluster';
import { getIsCoordinatesOnExtent } from '../../../Map/helpers/getIsCoordinatesOnExtent';
import { DURATION_CORRECTION } from '../../../Map/helpers/setClustersZoomChange';
import handleZoomToExtent from '../../../Map/helpers/zoomHandlers/handleZoomToExtent';

import {
  PADDING_EXTENT,
  SELECT_INTERACTIONS,
  Z_INDEX,
} from './constants/constants';
import { addFeaturesCluster } from './helpers/addFeaturesCluster';
import {
  getIconZoomRatio,
  getOpenClusterStyle,
  getStyleForCluster,
  StyleCache,
} from './helpers/clusterStyles';
import { getEmptyCluster } from './helpers/getEmptyCluster';
import { getSelectInteraction } from './helpers/getSelectInteraction';
import {
  checkIfTwoDimensional,
  generateNewClusterSource,
  generateAnimatedClusters,
} from './helpers/helpers';
import { setCenterObjCartography } from './helpers/setCenterObjCartography';
import { updateFeatures } from './helpers/updateFeatures';

const { DISTANCE, LOCATION_DISTANCE } = CLUSTERS_INFO;

interface ChartClusterLayerProps {
  features: Feature<Point>[];
}

export interface IClusterSource {
  clusterAnimated: N<AnimatedCluster>;
  cluster: N<Cluster>;
}

let timerOpenClusterId: N<NodeJS.Timeout> = null;

const ChartClusterLayer: FC<ChartClusterLayerProps> = ({
  features,
}: ChartClusterLayerProps) => {
  const { map } = rootStore.mapStore;
  const {
    isClusters,
    mapIconsSize,
    infoData,
    clickedCartographyObj,
    isNeedOpenedCluster,
    setInfoData,
  } = rootStore.uiStore;
  const { selectedFeatureSomeArray, selectedIds, coordGroupArray } =
    rootStore.scriptsControlStore;
  const {
    clickedCluster,
    isClusterOpen,
    clusters,
    clusterMapObj,
    featureMode,
    setClusters,
  } = rootStore.clustersStore;
  const {
    currentZoom,
    isCrossroadBorder,
    selectedFeature,
    getErrorsTl,
    getCoordinatesById,
    isMapMoveEnd,
  } = rootStore.mapDataStore;

  const [clusterSource, setClusterSource] = useState<IClusterSource>({
    clusterAnimated: null,
    cluster: null,
  });

  const [featuresSelect, setFeaturesSelect] = useState<IFeaturesArr[]>([]);

  const isTwoDimensional = checkIfTwoDimensional(features);
  const distance = isTwoDimensional ? LOCATION_DISTANCE : DISTANCE;

  const isOpen = useMemo(
    () => isNeedOpenedCluster && isClusterOpen,
    [isClusterOpen, isNeedOpenedCluster]
  );

  useEffect(() => {
    if (!map) return;

    // Исходный соурс
    const cluster = generateNewClusterSource();
    const clusterAnimated = generateAnimatedClusters(cluster);

    map.addLayer(clusterAnimated);
    clusterAnimated.setZIndex(Z_INDEX);
    setClusterSource({ clusterAnimated, cluster });

    return () => {
      if (map) {
        map.removeLayer(clusterAnimated);
      }
    };
  }, [map, isCrossroadBorder]);

  useEffect(() => {
    if (!map) return;

    const selectInteraction = new SelectCluster(SELECT_INTERACTIONS);

    map.addInteraction(selectInteraction);
  }, [map]);

  useEffect(() => {
    if (!map || !selectedFeature) return;

    const durDif = 0;
    const duration = EMPTY_DURATION_CLUSTER + durDif;

    const timeoutId = setTimeout(() => {
      const selectInteraction = getSelectInteraction(map);
      const sourceSelect = selectInteraction?.getLayer();
      const isEmptyCluster =
        clickedCluster && !sourceSelect?.getSource()?.getFeatures()?.length;

      if (!isEmptyCluster) return;
      selectInteraction?.selectCluster(selectedFeature);
    }, duration);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [clickedCluster, map, selectedFeature]);

  useEffect(() => {
    if (!map) return;

    const sourceSelect = getSelectInteraction(map);

    if (isNeedOpenedCluster && !sourceSelect) {
      const selectAnim = new SelectCluster({
        ...SELECT_INTERACTIONS,
      });

      map.addInteraction(selectAnim);

      const select = getSelectInteraction(map);

      clusterMapObj && select?.selectCluster(clusterMapObj);

      return;
    }

    if (!isNeedOpenedCluster && sourceSelect) {
      sourceSelect.clear();
      map.removeInteraction(sourceSelect);
    }
  }, [map, isNeedOpenedCluster, clusterMapObj, setInfoData]);

  useEffect(() => {
    if (!map || !isOpen) return;

    const sourceSelect = getSelectInteraction(map)?.getLayer();

    const size = getIconZoomRatio(mapIconsSize);
    const styleCache: StyleCache = {};

    sourceSelect?.setStyle((feature) =>
      getOpenClusterStyle({
        features: feature,
        infoDataId: infoData?.id,
        scale: size,
        styleCache,
      })
    );
  }, [infoData?.id, map, isOpen, mapIconsSize]);

  useEffect(() => {
    timerOpenClusterId && clearTimeout(timerOpenClusterId);

    if (!map || !clickedCartographyObj || !isMapMoveEnd) return;

    const isFeatureOnExtent = getIsCoordinatesOnExtent(
      getCoordinatesById(clickedCartographyObj.id) ?? [],
      // @ts-ignore
      map.getView().calculateExtent()
    );

    const duration = isFeatureOnExtent
      ? NULLABLE_ANIMATION_DURATION
      : CENTERING.ANIMATION_DURATION + DURATION_CORRECTION;

    timerOpenClusterId = setTimeout(() => {
      setCenterObjCartography({
        map,
        clickedCartographyObj,
      });
    }, duration);
  }, [map, clickedCartographyObj, getCoordinatesById, isMapMoveEnd]);

  useEffect(() => {
    if (!clusterSource.cluster) return;

    const isNeedCluster = !isCrossroadBorder && isClusters;

    clusterSource.cluster.setDistance(isNeedCluster ? distance : 0);
  }, [isClusters, distance, clusterSource, isCrossroadBorder]);

  useEffect(() => {
    if (!map || !clusterSource.cluster) return;

    const source = clusterSource.cluster.getSource();

    source?.refresh();
    if (!source) return;

    if (!features.length) return source.clear();

    addFeaturesCluster(source, features);
  }, [clusterSource, features, map]);

  useEffect(() => {
    if (!clusterSource.clusterAnimated) return;

    const styleCache: StyleCache = {};

    const scale = getIconZoomRatio(mapIconsSize);

    clusterSource.clusterAnimated.setStyle((feature) => {
      const cluster: Feature<Point>[] =
        feature.get(FeaturesTypes.Features) ?? [];

      const isCluster = cluster.length > 1;
      const item = cluster.at(0);

      if (!isCluster && item) {
        const idFeature = item.get(FeatureDeviceProps.Id);
        const isSelectedFeature = infoData?.id === idFeature;
        const isChosenFeature = cluster.some((el) =>
          selectedFeatureSomeArray.some(
            ({ id }) => id === el.get(FeatureDeviceProps.Id)
          )
        );

        if (isSelectedFeature || isChosenFeature) return getEmptyCluster();
      }

      updateFeatures(cluster, features);

      return getStyleForCluster({
        feature,
        activeId: infoData?.id,
        styleCache,
        clickedCluster,
        featureMode,
        scale,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    features,
    clusterSource,
    map,
    mapIconsSize,
    infoData,
    isNeedOpenedCluster,
    selectedFeatureSomeArray.length,
    clickedCluster,
    isClusterOpen,
    featureMode,
  ]);

  useEffect(() => {
    const features = clusterSource.cluster?.getFeatures() as U<
      Feature<Point>[]
    >;

    if (!map || !features?.length) return;

    setClusters(features);
  }, [clusterSource, map, setClusters, currentZoom, features, isClusters]);

  useEffect(() => {
    getErrorsTl(features, featuresSelect);
  }, [features, getErrorsTl, clusters, featuresSelect, selectedIds]);

  useEffect(() => {
    if (!coordGroupArray.length || !map) return;

    handleZoomToExtent(map, boundingExtent(coordGroupArray), PADDING_EXTENT);
  }, [coordGroupArray, map]);

  return <Clusters setFeaturesSelect={setFeaturesSelect} />;
};

export default observer(ChartClusterLayer);
