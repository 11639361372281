import { Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { uniqueId } from 'lodash';
import { observer } from 'mobx-react-lite';
import { FC, MouseEventHandler } from 'react';

import rootStore from '../../../../../stores/rootStore/rootStore';
import Popover from '../../../../ui-kit/Popover/Popover';
import { TDtInfo } from '../TitleBtns/helpers/getTitleDetectors';

import styles from './RadioButtonsGraph.module.scss';

interface RadioButtonsGraphProps {
  btnsDscArr: TDtInfo[];
  value: string;
  isDisabled?: boolean;
  size?: SizeType;
  radioContainerClassName?: string;
  onChange?: (e: RadioChangeEvent) => void;
  onClick?: MouseEventHandler<HTMLInputElement>;
}

const RadioButtonsGraph: FC<RadioButtonsGraphProps> = ({
  btnsDscArr,
  value,
  size,
  isDisabled = false,
  radioContainerClassName = styles.radioGroup,
  onChange,
  onClick,
}) => {
  const { interfaceTipsDelay } = rootStore.uiStore;

  return (
    <Radio.Group
      onChange={onChange}
      value={value}
      className={radioContainerClassName}
      buttonStyle="solid"
      size={size}
      disabled={isDisabled}
    >
      {btnsDscArr.map(({ caption, valueDt, dsc }) => (
        <Popover tipsDelay={interfaceTipsDelay} content={dsc} key={uniqueId()}>
          <Radio.Button value={valueDt} onClick={onClick}>
            {caption}
          </Radio.Button>
        </Popover>
      ))}
    </Radio.Group>
  );
};

export default observer(RadioButtonsGraph);
