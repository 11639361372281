import { FC, useEffect, useState } from 'react';
import { BsDownload } from 'react-icons/bs';

import { exportFile } from '../../../../../helpers/export/helpers';
import { FileExtensions } from '../../../../../ts/models/table.model';
import ButtonOnlyIcon from '../../../ButtonOnlyIcon/ButtonOnlyIcon';
import { DataValue } from '../../models/chart.model';
interface IExport {
  data: DataValue[];
  title: string;
}

const ExportButton: FC<IExport> = ({ data, title }) => {
  const [exportData, setExportData] = useState<DataValue[]>([]);

  const handleExport = () => {
    exportFile(exportData, FileExtensions.xlsx, title);
  };

  useEffect(() => {
    setExportData(
      data.map(({ index, time, ...rest }) => ({
        'Дата и время': time,
        ...rest,
      }))
    );
  }, [data]);

  const isDisabled = !exportData.length;

  const popoverContent = !isDisabled
    ? 'Экспортировать данные'
    : 'Недостаточно данных для экспорта';

  return (
    <ButtonOnlyIcon
      isDisabled={isDisabled}
      onClick={handleExport}
      tip={popoverContent}
      isSmall
    >
      <BsDownload />
    </ButtonOnlyIcon>
  );
};

export default ExportButton;
