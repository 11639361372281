import { Slider as SliderAntD } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import rootStore from '../../../../stores/rootStore/rootStore';
import Popover from '../../../ui-kit/Popover/Popover';
import getSliderProps from '../../helpers/getSliderProps';
import Item from '../Item/Item';

interface SliderProps {
  label: string;
  onChange: (val: number) => void;
  value: number;
  min: number;
  max: number;
  defaultValue?: number;
  step?: number;
  disabled?: boolean;
  isVisible?: boolean;
  tooltipText?: string;
}

const Slider: FC<SliderProps> = ({
  label,
  value,
  onChange,
  min,
  max,
  defaultValue,
  step,
  disabled,
  isVisible = true,
  tooltipText,
}) => {
  if (!isVisible) return null;

  const { interfaceTipsDelay } = rootStore.uiStore;

  return (
    <Popover
      placement="right"
      content={tooltipText}
      tipsDelay={interfaceTipsDelay}
    >
      <div>
        <Item label={label}>
          <SliderAntD
            {...getSliderProps(min, max, defaultValue, step)}
            onChange={onChange}
            value={value}
            disabled={disabled}
          />
        </Item>
      </div>
    </Popover>
  );
};

export default observer(Slider);
