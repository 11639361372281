/* eslint-disable camelcase */
import { z } from 'zod';

import {
  DirectionsTypes,
  VehicleTypes,
} from '../../../../components/InfoPanel/SystemsInfo/PopupDetector/PopupDetector.model';
import { NN, NNInt } from '../../../zod/utils.zod';

export const DirectionsScheme = z.object({
  speed_avg: NN,
  speed_max: NN,
  speed_min: NN,
  passage_count: NNInt,
  passage_count_with_speed: NNInt,
  lb_direction_id: z.nativeEnum(DirectionsTypes),
  lb_vehicle_type_id: z.nativeEnum(VehicleTypes).optional(),
  db3_lane: NNInt.nullable().optional(),
});

const DataScheme = z.object({
  caption: z.string(),
  date_from: z.string(),
  date_to: z.string(),
  interval: z.number(),
  info: DirectionsScheme.array(),
});

export const DataNamesScheme = z.object({
  direction_id: z.nativeEnum(DirectionsTypes),
  direction_hl: z.string(),
});

export const DetectorsDataSchema = z.object({
  jver: z.string(),
  directionsNames: DataNamesScheme.array(),
  data: DataScheme.array(),
});

export const LanesInfoSchema = DirectionsScheme.extend({
  lane: NNInt,
});

export const LanesDataSchema = DataScheme.extend({
  info: LanesInfoSchema.array(),
});

export const DetectorsLanesSchema = DetectorsDataSchema.extend({
  data: LanesDataSchema.array(),
});
