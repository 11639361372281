import { Card } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC, useState, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { findById } from '../../../helpers/findBy';
import parseTLMode from '../../../helpers/parseTLMode';
import { getTLModeName } from '../../../helpers/statusHelpers';
import rootStore from '../../../stores/rootStore/rootStore';
import { ICsdData, ITimePoints } from '../../../ts/models/signalPrograms.model';
import { DetailedItemProps } from '../../DetailedMain/DetailedWrapper/constants/constants';
import getCsdData from '../../NewSignalProgram/helpers/getCsdData';
import NewSignalProgram from '../../NewSignalProgram/NewSignalProgram';
import EmptyImg from '../../ui-kit/Errors/EmptyImg/EmptyImg';

import PhaseCardsList from './PhaseCardsList/PhaseCardsList';
import TLGeneralInfo from './TLGeneralInfo/TLGeneralInfo';

import styles from './TlMainInfo.module.scss';

const TlMainInfo: FC<DetailedItemProps> = ({ id, curentActiveKey }) => {
  const { activeKey } = rootStore.uiStore;
  const { tls } = rootStore.mapDataStore;

  const [phasesSet, setPhasesSet] = useState<ITimePoints[]>([]);
  const [csdData, setCsdData] = useState<ICsdData>();
  const [isError, setIsError] = useState<boolean>(false);

  const tl = findById(tls, id);

  useEffect(() => {
    setIsError(false);

    const res = getCsdData({
      id,
      isStatic: false,
      tlsData: tls,
    });

    if (!res) return;

    const { timePoints, csdData } = res;

    if (!timePoints.length) return setIsError((prev) => true);

    setPhasesSet(timePoints);
    setCsdData(csdData);
  }, [id, tls]);

  if (activeKey !== curentActiveKey || !tl) return null;

  const { tlGeneral, tlStatus, extId, dai_jdt83 } = tl;
  const { mag } = tlGeneral;
  const { mode, source } = tlStatus;
  const { isEmpty } = parseTLMode(mode);
  const name = getTLModeName(mode);

  const generalInfoProps = { mag, name, mode, source };
  const imgProps = {
    extId,
    phasesSet,
    tlStatus,
    id,
    isError,
    images: dai_jdt83?.images ?? [],
  };

  return (
    <div className={styles.container}>
      <Card className={styles.schemeCRs}>
        <ErrorBoundary FallbackComponent={() => <EmptyImg />} resetKeys={[id]}>
          <PhaseCardsList phases={imgProps} isDetailed />
        </ErrorBoundary>
      </Card>

      <div className={styles.subwrap}>
        <TLGeneralInfo {...generalInfoProps} />
        <NewSignalProgram
          id={id}
          isStatic={false}
          phasesSet={phasesSet}
          csdData={csdData}
          isEmpty={isEmpty}
        />
      </div>
    </div>
  );
};

export default observer(TlMainInfo);
