export const EXPORT_DATA_TEXT = 'Экспортировать данные';

export const OPTION_BUTTON_OPTIONS = {
  size: 'small',
  type: 'text',
} as const;

export const OK_TEXT = 'Экпортировать';
export const CANCEL_TEXT = 'Отмена';

export const CONFIRM_PLACEMENT = 'topLeft';
