import { PaginationConfig } from 'antd/lib/pagination';
import { DefaultOptionType } from 'antd/lib/select';

export const TRAFFIC_LAYER_LINK = 'traffic.link.layer';
export const TRAFFIC_LAYER_VERTEX = 'traffic.vertex.layer';
export const TRAFFIC_LAYER_VERTEX_TEXT = 'traffic.vertex.text.layer';

export const DEFAULT_SCENE_ZOOM = 11;

export const MAX_NAME_CHARS_LENGTH = 25;
export const TEXT_LAYER_LABEL_SIZE = 10;
export const TEXT_LAYER_OFFSET_LABEL: [number, number] = [15, 0];
export const TEXT_LAYER_PADDING: [number, number, number, number] = [
  1, 1, 1, 1,
];
export const TEXT_LAYER_FONT_COLOR: [number, number, number] = [0, 0, 0];

export const LINK_MIN_WIDTH = 0.1;
export const LINK_MAX_WIDTH = 10;

export const VERTEX_EDGES = 6;
export const VERTEX_COLOR: [number, number, number] = [255, 0, 0];
export const VERTEX_RADIUS = 30;

export const NODE_MERGE_OPTION_ATTRIBUTE = 'merge.type.attribute';
export const NODE_MERGE_OPTION_LAYER = 'merge.type.layer';
export const NODE_MERGE_OPTION_NONE = 'merge.type.none';

export const NODE_MERGE_OPTIONS: DefaultOptionType[] = [
  { label: 'По идентфикатору перекрёстка', value: NODE_MERGE_OPTION_ATTRIBUTE },
  { label: 'По геометрии слоя c зонами', value: NODE_MERGE_OPTION_LAYER },
];

export const RADIUS_INF_CURVE = 6;

export const HOUR_INTERVALS_OPTIONS: DefaultOptionType[] = [
  { label: '00:00 - 01:00', value: 0 },
  { label: '01:00 - 02:00', value: 1 },
  { label: '02:00 - 03:00', value: 2 },
  { label: '03:00 - 04:00', value: 3 },
  { label: '04:00 - 05:00', value: 4 },
  { label: '05:00 - 06:00', value: 5 },
  { label: '06:00 - 07:00', value: 6 },
  { label: '07:00 - 08:00', value: 7 },
  { label: '08:00 - 09:00', value: 8 },
  { label: '09:00 - 10:00', value: 9 },
  { label: '10:00 - 11:00', value: 10 },
  { label: '11:00 - 12:00', value: 11 },
  { label: '12:00 - 13:00', value: 12 },
  { label: '13:00 - 14:00', value: 13 },
  { label: '14:00 - 15:00', value: 14 },
  { label: '15:00 - 16:00', value: 15 },
  { label: '16:00 - 17:00', value: 16 },
  { label: '17:00 - 18:00', value: 17 },
  { label: '18:00 - 19:00', value: 18 },
  { label: '19:00 - 20:00', value: 19 },
  { label: '20:00 - 21:00', value: 20 },
  { label: '21:00 - 22:00', value: 21 },
  { label: '22:00 - 23:00', value: 22 },
  { label: '23:00 - 00:00', value: 23 },
];

export const LAYER_ID = 'VrnZones_tmp';
export const POLYGONS_TEST_SERVICE_URL =
  'https://gis.eputs.org/feature-server/gis/osut/main/' + LAYER_ID;
export const DEFAULT_BBOX = `{"xmin":0,"ymin":0,"xmax":10000000000,"ymax":1000000000,"spatialReference":{"wkid":102100}}`;
export const MAX_RESULTS_FROM_SERVICE = 1000;
export const DEFAULT_WHERE = '1=1';

export const START_NODE_COLOR: [number, number, number, number] = [
  0, 150, 200, 255,
];
export const TARGET_NODE_COLOR: [number, number, number, number] = [
  255, 0, 0, 225,
];

export const DEFAULT_PAGE_SETTINGS: PaginationConfig = {
  hideOnSinglePage: true,
  pageSize: 10,
};
