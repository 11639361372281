export const COLOR_GIS_WHITE = 'rgb(255, 255, 255)';

export const COLOR_GIS_DIRECTION_RED = 'rgb(255,12,74)';

const index = {
  COLOR_GIS_DIRECTION_RED,
  COLOR_GIS_WHITE,
};

export { index };
