import { useCallback, useEffect, useState } from 'react';

import { ZOOM } from '../../../../constants/mapConstants';
import rootStore from '../../../../stores/rootStore/rootStore';
import { TLDetailedCode } from '../../../../ts/enums/userData';
import { DEVICES_EMPTY, DEVICE_TYPE } from '../constants/constants';
import getWarningForIncorrectCoordinate from '../helpers/getWarningForIncorrectCoordinate';
import { setIsCorrectCoordForDevices } from '../helpers/setIsCorrectCoord';
import {
  isActualDeviceType,
  Device,
  LogTableData,
} from '../PointUdsOverlays.model';

const useIncorrectCoordinateDevices = () => {
  const { detectors, cameras, pointsUds, tls, meteo, isCrossroadBorder } =
    rootStore.mapDataStore;

  const { map } = rootStore.mapStore;
  const { markers, isIncorrectCoordinatesLog } = rootStore.uiStore;
  const { hasAccess } = rootStore.userDataStore;

  const [list, setList] = useState(DEVICES_EMPTY);

  useEffect(() => {
    const tlsList = setIsCorrectCoordForDevices(tls, pointsUds);

    setList((prev) => ({ ...prev, tlsList }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tls, pointsUds, setList]);

  useEffect(() => {
    const cmsList = setIsCorrectCoordForDevices(cameras, pointsUds);

    setList((prev) => ({ ...prev, cmsList }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cameras, pointsUds, setList]);

  useEffect(() => {
    const dtsList = setIsCorrectCoordForDevices(detectors, pointsUds);

    setList((prev) => ({ ...prev, dtsList }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detectors, pointsUds, setList]);

  useEffect(() => {
    const mtsList = setIsCorrectCoordForDevices(meteo, pointsUds);

    setList((prev) => ({ ...prev, mtsList }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meteo, pointsUds, setList]);

  const getWarn = useCallback(() => {
    const zoom = map?.getView().getZoom() ?? 0;

    if (zoom < ZOOM.CROSSROAD_BORDER) return;
    const { isLights, isCameras, isDetectors, isMeteo } = markers;

    const devices = [
      ...(isLights ? tls : []),
      ...(isCameras ? cameras : []),
      ...(isDetectors ? detectors : []),
      ...(isMeteo ? meteo : []),
    ];

    getWarningForIncorrectCoordinate(devices, pointsUds);
  }, [tls, cameras, detectors, meteo, pointsUds, markers, map]);

  useEffect(() => {
    if (isCrossroadBorder) {
      getWarn();
      map?.on('moveend', getWarn);
    }

    return () => {
      map?.un('moveend', getWarn);
    };
  }, [isCrossroadBorder, map, getWarn]);

  const getList = useCallback(
    () =>
      Object.values(list)
        .flat()
        .reduce((acc: LogTableData[], device: Device) => {
          const { deviceTypeId, deviceId, caption, pointUdsUid, pointUdsName } =
            device.deviceInfo;

          isActualDeviceType(deviceTypeId) &&
            acc.push({
              deviceId,
              caption,
              type: DEVICE_TYPE?.[deviceTypeId],
              pointUdsName,
              pointUdsUid,
            });

          return acc;
        }, []),
    [list]
  );

  useEffect(() => {
    if (!hasAccess(TLDetailedCode.DevelopTools)) return;

    const list = getList();

    if (!list.length || !isIncorrectCoordinatesLog) return;

    console.table(list);
  }, [getList, isIncorrectCoordinatesLog, hasAccess]);
};

export default useIncorrectCoordinateDevices;
