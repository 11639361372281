import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react-lite';
import { CSSProperties, FC, cloneElement, useState } from 'react';

import rootStore from '../../../stores/rootStore/rootStore';
import Confirm from '../Confirm/Confirm';
import Popover from '../Popover/Popover';

import {
  TPartialConfirm,
  TPartialPopover,
  TTipConfirm,
  TTipContent,
} from './model/buttonIcon.model';

import styles from './ButtonOnlyIcon.module.scss';

interface IButtonOnlyIconProps {
  children: JSX.Element;
  onClick: (e?: React.MouseEvent<HTMLElement>) => unknown;
  isActive?: boolean;
  isHoverOpacity?: boolean;
  isDisabled?: boolean;
  isSmall?: boolean;
  tip?: TTipContent;
  tipConfirm?: TTipConfirm;
  isDanger?: boolean;
  popoverProps?: TPartialPopover;
  confirmProps?: TPartialConfirm;
  style?: CSSProperties;
  className?: string;
  isHover?: boolean;
}

const ButtonOnlyIcon: FC<IButtonOnlyIconProps> = ({
  isActive,
  isSmall,
  isHoverOpacity = false,
  isDisabled,
  isDanger,
  isHover = true,
  tip,
  children,
  onClick,
  tipConfirm,
  popoverProps = {},
  confirmProps = {},
  className = '',
  style,
}) => {
  const { interfaceTipsDelay } = rootStore.uiStore;
  const [isVisibleConfirm, setIsVisibleConfirm] = useState(false);

  const content = !popoverProps?.content ? tip ?? '' : popoverProps.content;
  const titleConfirm = !confirmProps?.title
    ? tipConfirm ?? ''
    : confirmProps.title;

  const isOpacity = isHoverOpacity || (!isDisabled && (isDanger || isActive));

  const btnStyles = classNames({
    [styles.mainBtn]: true,
    [styles.active]: isActive && !isDisabled,
    [styles.small]: isSmall,
    [styles.hover]: !isDisabled && !isDanger && !isHoverOpacity && isHover,
    [styles.disabled]: isDisabled,
    [styles.danger]: isDanger,
    [styles.hoverOpacity]: isOpacity,
    [className]: className,
  });

  const onClickHandler = !isDisabled ? onClick : undefined;

  if (tipConfirm || !isEmpty(confirmProps)) {
    return (
      <Confirm
        {...confirmProps}
        onConfirm={onClickHandler}
        onOpenChange={setIsVisibleConfirm}
        title={titleConfirm}
        disabled={isDisabled}
      >
        <>
          <Popover
            tipsDelay={interfaceTipsDelay}
            {...popoverProps}
            content={!isVisibleConfirm ? content : ''}
          >
            <>
              {cloneElement(children, {
                className: btnStyles,
                style,
              })}
            </>
          </Popover>
        </>
      </Confirm>
    );
  }

  return (
    <Popover tipsDelay={interfaceTipsDelay} {...popoverProps} content={content}>
      <>
        {cloneElement(children, {
          className: btnStyles,
          onClick: onClickHandler,
          style,
        })}
      </>
    </Popover>
  );
};

export default observer(ButtonOnlyIcon);
