import { ICON_SIZE } from '../components/Constructor/constants/constants';
import { MapObjectFull } from '../ts/enums/enums';
import { CircleElEnum } from '../ts/enums/tl';
import { CircleElsDynamic, TrafficLane } from '../ts/models/constructor.model';

import { COLORS, DEFAULT_CROSSROAD_COLORS } from './colorsConstants';
import { DEFAULT_PHASE_IMG_PATH, TL_CIRCLE_ZOOM } from './constants';

export const DEFAULT_CIRCLE = {
  SIZE: 100,
  SIZE_RATE: 1,
  DIAMETER: 300,
  DIAMETER_RATE: 20,
  BORDER_WIDTH: 8,
  HUE: 0,
  OPACITY: 20,
  DASH: [4, 4] as XY,
  STROKE_WIDTH: 2,
  CROSSWALK_WIDTH: 20,
  CROSSWALK_LENGTH: 1,
  CROSSWALK_SIZE: 50,
  CROSSWALK_SIZE_RATE: 0.5,
};

export const CIRCLE_ELEMENT_OBJ = {
  type: CircleElEnum.Gathered,
  isCircleElement: false,
  isDashed: false,
  isDirectPlus: false,
  isAdditionalTop: false,
  isDashAnimation: false,
  isOpacityAnimation: false,
  isColorAnimation: false,
  color: 'green',
  dash: DEFAULT_CIRCLE.DASH,
  animation: null,
  hue: DEFAULT_CIRCLE.HUE,
};

export const CIRCLE_ELEMENT_DYNAMIC = {
  angle: 0,
  bottomAngle: 0,
  additionalTopAngle: 0,
  offsetX: 0,
  offsetY: 0,
  size: 150,
  strokeWidth: DEFAULT_CIRCLE.STROKE_WIDTH,
  sizeRate: DEFAULT_CIRCLE.CROSSWALK_LENGTH,
};

export const DEFAULT_CIRCLE_ELS_DYNAMIC: CircleElsDynamic = {
  [TL_CIRCLE_ZOOM.START]: [],
};

export const PHASES_DEFAULT = [1, 31, 32, 33, 34].map((code) => ({
  phaseNumber: null,
  directions: [],
  code,
  image: DEFAULT_PHASE_IMG_PATH,
  cache: Math.random(),
}));

export const FINAL_PREFIX = 'Фин.: ';

const { MAIN, MAIN_TRANSITION, CROSSWALK, CROSSWALK_TRANSITION } =
  DEFAULT_CROSSROAD_COLORS;

export const CROSSROAD_DEFAULT = {
  id: null,
  isLoaded: false,
  isDirections: false,
  isMainTact: true,
  isBlinkingTransitionTact: true,
  isTrafficLanesOffset: true,
  isCamerasOffset: true,
  phases: [],
  angle: 0,
  offsetX: 0,
  offsetY: 0,
  size: 200,
  color: MAIN,
  colorTransition: MAIN_TRANSITION,
  crosswalkColor: CROSSWALK,
  crosswalkColorTransition: CROSSWALK_TRANSITION,
  roadOpacity: 1,
  isBorders: true,
  isMarkup: true,
  isBlackRoad: false,
  isTls: true,
  isCrosswalksMarkup: true,
};

const DEVICE_GENERAL = {
  id: null,
  caption: '',
  isEnabled: false,
  isDeleted: false,
};

export const CAMERA_DEFAULT = {
  ...DEVICE_GENERAL,
  isExtended: false,
  isShownId: false,
  offsetX: 0,
  offsetY: 0,
  size: ICON_SIZE.DEFAULT,
  distance: 50,
  widthRate: 1,
  angle: 0,
  arcHeight: 10,
  preDistanceRate: 0.4,
  color: 'purple',
  opacity: 0.4,
  final: null,
  linkedDetectorId: null,
};

export const METEO_DEFAULT = {
  ...DEVICE_GENERAL,
  type: MapObjectFull.meteo,
  isShownId: false,
  offsetX: 0,
  offsetY: 0,
  size: ICON_SIZE.DEFAULT,
  final: null,
};

export const VIEW_DEFAULT = {
  isDataSelectors: true,
  isShowCenterSettings: true,
  isCrossroad: false,
  isTrafficLanes: false,
  isTL: false,
  isCameras: false,
  isMeteos: false,
  isActionBtns: true,
  isCircleCenter: true,
  isCircle: true,
  isCircleFill: false,
};

export const LANE_SPAN = 0.5;

export const LANE_PARAM_DEFAULT = {
  id: 1,
  span: LANE_SPAN,
  width: 4,
  offset: 0,
  zoneUID: null,
  number: null,
};

export const LANE_DIRECTION_DEFAULT = {
  isApplied: false,
  isMain: true,
  angle: 0,
  offsetX: 0,
  offsetY: 0,
  mainOffsetX: 0,
  mainOffsetY: 0,
  mainWidth: 4,
  width: 4,
  length: 100,
  color: COLORS.WARNING,
  opacity: 1,
  laneParams: [LANE_PARAM_DEFAULT],
};

export const TRAFFIC_LANE_DEFAULT: TrafficLane = {
  ...DEVICE_GENERAL,
  ...LANE_DIRECTION_DEFAULT,
  id: 0,
  dtIconOffsetX: 0,
  dtIconOffsetY: 0,
  isGradient: true,
  comment: '',
  linkedCameraId: null,
  toCamera: LANE_DIRECTION_DEFAULT,
  fromCamera: LANE_DIRECTION_DEFAULT,
};

export const TL_DEFAULT = {
  ...DEVICE_GENERAL,
  isIcon: false,
  iconOffsetX: 0,
  iconOffsetY: 0,
  iconSize: ICON_SIZE.DEFAULT,
  isArrowDirSignatures: true,
  isActiveArrow: true,
};

export const POINT_UDS_NAME_LENGTH = 150;

export const TEXT_AREA_PROPS = {
  showCount: true,
  allowClear: true,
  maxLength: 500,
  rows: 6,
} as const;

export const ACTIVE_EL_COLOR = COLORS.SUCCESS;
export const IS_THE_SAME_PROJECTION_COLOR = false;
