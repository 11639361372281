import { FC } from 'react';

import { FilterInfo } from '../../models/chart.model';

import FilterGroup from './FilterGroup/FilterGroup';

import styles from './FilterButtons.module.scss';

interface FilterButtonsProps {
  filterInfo: FilterInfo[];
  isLoading: boolean;
}

const FilterButtons: FC<FilterButtonsProps> = ({ filterInfo, isLoading }) => {
  return (
    <div className={styles.container}>
      {filterInfo.map((el) => (
        <FilterGroup key={el.filterType} {...el} isLoading={isLoading} />
      ))}
    </div>
  );
};

export default FilterButtons;
