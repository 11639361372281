import { Button, Card, DatePicker, Select } from 'antd';
import React from 'react';
import { HiMinus, HiPlus } from 'react-icons/hi';

import { HOUR_INTERVALS_OPTIONS, NODE_MERGE_OPTIONS } from './constants';
import { getMaxDate, getMinDate } from './helpers';

import styles from './TrafficGraphButton.module.scss';

const GraphSettingsPanel = (props: {
  onChange: (value: ((prevState: string) => string) | string) => void;
  mergeMethod: string;
  dateStart: unknown;
  onDateChange: (value: any) => void;
  onDecreaseTime: () => void;
  timeOffset: number;
  onTimeOffsetChange: (value: ((prevState: number) => number) | number) => void;
  onIncreaseTime: () => void;
}) => {
  const buttonProps = {
    shape: 'circle',
    size: 'small',
    type: 'text',
  } as const;

  return (
    <Card
      className={styles.trafficGraphPanel}
      styles={{ body: { padding: 10 } }}
    >
      <div>Граф корреспонденции</div>

      <div className={styles.title}>Способ объединения узлов</div>

      <Select
        className={styles.mergeTypeField}
        onChange={props.onChange}
        options={NODE_MERGE_OPTIONS}
        value={props.mergeMethod}
      />

      <div className={styles.title}>Дата / время</div>
      <div className={styles.timeSelectorsContainer}>
        <DatePicker
          value={props.dateStart}
          onChange={props.onDateChange}
          minDate={getMinDate()}
          maxDate={getMaxDate()}
          className={styles.timeSelectorItem}
        />
        <div className={styles.hourSelectorItem}>
          <Button onClick={props.onDecreaseTime} {...buttonProps}>
            <HiMinus />
          </Button>
          <Select
            className={styles.hoursSelectorItem}
            value={props.timeOffset}
            options={HOUR_INTERVALS_OPTIONS}
            onChange={props.onTimeOffsetChange}
          ></Select>
          <Button onClick={props.onIncreaseTime} {...buttonProps}>
            <HiPlus />
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default GraphSettingsPanel;
