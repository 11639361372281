import {
  lineString as turfLineString,
  point as turfPoint,
  toMercator,
  toMercator as turfToMercator,
  toWgs84,
  toWgs84 as turfToWgs84,
} from '@turf/turf';
import DOMPurify from 'dompurify';
import { Feature } from 'ol';
import { Geometry, LineString, Point, Polygon } from 'ol/geom';

import { WKID_MERCATOR } from '../../../apiGIS/constants/srs';
import {
  flyTo,
  hideFeatures,
  showFeatures,
} from '../../../apiGIS/layers/utils/flashFeatures';
import { createTemporaryLayer } from '../../../apiGIS/layers/utils/temporary';
import { IGetEsriFeatures } from '../../../apiGIS/models/sources/sourceDefinitions';
import {
  IDirectionPoint,
  IDirectionResult,
} from '../../../apiGIS/models/tasks/tasks.model';
import { queryFeatures } from '../../../apiGIS/sources/FeatureServerSource';
import { styleFunction } from '../../../apiGIS/styles/custom/markerStyle';
import { getDirectionVariants } from '../../../apiGIS/tasks/direction';
import { geocode } from '../../../apiGIS/tasks/geocode';
import { getMapPoint } from '../../../apiGIS/tasks/interactions';
import {
  DEFAULT_NAME_FIELD_OBJECTID,
  SECOND,
} from '../../../constants/constants';
import { ILayer } from '../../../stores/gisDataStore/gisDataStore.model';
import { TMap } from '../../../stores/mapStore/mapStore.model';

import {
  ERROR_GEOCODING_OPERATION,
  HIDE_FEATURE_TTL,
  LIST_DIRECTION_ITEM_MAX_SIZE,
  LIST_SEARCH_ITEM_MAX_SIZE,
  MAX_FEATURES_FROM_LAYER,
  PAGER_HEIGHT,
  INPUT_BLOCK_HEIGHT,
  SEARCH_TEMPORARY_LAYER_ID,
  SEARCH_TYPE_COORDINATES,
  SEARCH_TYPE_GEOCODER,
  SEARCH_TYPE_LAYER,
} from './constants';
import { IQueryFeaturesResult, ISearchItem } from './search.model';

export const getMapCenterText = (map: TMap) => {
  const defaultResponse = `55.1, 37.2`;

  if (!map) {
    return defaultResponse;
  }

  const center = map.getView().getCenter();

  if (!center) {
    return defaultResponse;
  }

  const point = toWgs84(turfPoint(center));

  const [x, y] = point.geometry.coordinates;

  return [y, x].map((value) => value.toFixed(1)).join(', ');
};

const searchSanitize = (text: string): string => {
  if (!text) {
    return '';
  }

  const sqlKeywords = [
    'drop',
    'table',
    'select',
    'insert',
    'truncate',
    'view',
    'update',
  ];

  let outTextParts = text;

  for (let i = 0; i < sqlKeywords.length; i++) {
    const word = sqlKeywords[i];

    outTextParts = outTextParts.split(word).join(' ');
  }

  return DOMPurify.sanitize(outTextParts);
};

const geomToLatLon = (geom: Geometry | undefined): number[] | undefined => {
  if (!geom) {
    return;
  }

  if (geom instanceof LineString || geom instanceof Polygon) {
    const coordinates = geom.getFlatCoordinates();

    const [x, y] = coordinates;

    const wgsPoint = turfToWgs84(turfPoint([x, y]));

    return wgsPoint.geometry.coordinates;
  }

  if (geom instanceof Point) {
    const coordinates = geom.getCoordinates();

    const [x, y] = coordinates;

    const wgsPoint = turfToWgs84(turfPoint([x, y]));

    return wgsPoint.geometry.coordinates;
  }

  return;
};

const locationToGeometry = (lon: number, lat: number) => {
  const point = toMercator(turfPoint([lon, lat]));

  return new Point(point.geometry.coordinates);
};

const geocoderSearch = async (
  map: TMap,
  text: string,
  regionExtent: number[] | undefined
): Promise<ISearchItem[]> => {
  const sanitizedText = searchSanitize(text);

  const results = await geocode(sanitizedText);

  return results
    .filter((item) => {
      const { code } = item.quality;

      return code !== ERROR_GEOCODING_OPERATION;
    })
    .filter((item) => {
      const { location } = item;

      const { lat, lon } = location;

      if (regionExtent) {
        const [xmin, ymin, xmax, ymax] = regionExtent;

        return lon >= xmin && lon <= xmax && lat >= ymin && lat <= ymax;
      }

      return true;
    })
    .map((item) => {
      const { address, location } = item;

      const { lat, lon } = location;

      const { coordinates } = turfToMercator(turfPoint([lon, lat])).geometry;

      const feature = new Feature({
        geometry: new Point(coordinates),
        id: Math.random(),
      });

      const result: ISearchItem = {
        title: address,
        type: SEARCH_TYPE_GEOCODER,
        feature,
      };

      return result;
    });
};

const layersSearch = async (
  map: TMap,
  text: string,
  regionExtent: number[] | undefined,
  layersDefinitions: ILayer[]
): Promise<ISearchItem[]> => {
  if (!text || !map || !layersDefinitions) {
    return [];
  }

  const results: ISearchItem[] = [];

  const queries = [];

  const sanitizedText = searchSanitize(text);

  for (let i = 0; i < layersDefinitions.length; i++) {
    const layer = layersDefinitions[i];

    const { searchFields, tooltipDefinition } = layer;

    if (layer?.category?.category !== 'category.common') {
      continue;
    }

    if (!searchFields || searchFields.length === 0) {
      continue;
    }

    const where = searchFields
      .map((field) => {
        return `${field} LIKE '%${sanitizedText}%'`;
      })
      .join(` OR `);

    const params: IGetEsriFeatures = {
      bbox: '',
      url: layer.url,
      where,
      num: MAX_FEATURES_FROM_LAYER,
    };

    if (regionExtent) {
      const [xmin, ymin, xmax, ymax] = regionExtent;
      const radius = turfToMercator(
        turfLineString([
          [xmin, ymin],
          [xmax, ymax],
        ])
      );

      const { coordinates } = radius.geometry;

      const [p1, p2] = coordinates;

      params.bbox = `{"xmin":${p1[0]},"ymin":${p1[1]},"xmax":${p2[0]},"ymax":${p2[1]},"spatialReference":{"wkid":${WKID_MERCATOR}}}`;
    }

    const query = new Promise((resolve) => {
      queryFeatures(params)
        .then((data) => {
          const result: IQueryFeaturesResult = {
            featuresSet: data,
            tooltipDefinition,
          };

          resolve(result);
        })
        .catch(() => {
          console.log(`err`);

          const result: IQueryFeaturesResult = {
            featuresSet: {
              features: [],
              objectIdFieldName: DEFAULT_NAME_FIELD_OBJECTID,
            },
            tooltipDefinition: {
              fields: [],
              template: '',
            },
          };

          resolve(result);
        });
    });

    queries.push(query);
  }

  const responses = await Promise.all(queries);

  for (let i = 0; i < responses.length; i++) {
    const data = responses[i] as IQueryFeaturesResult;

    const { featuresSet, tooltipDefinition } = data;

    const { features } = featuresSet;

    const items: ISearchItem[] = features
      .filter((feature: Feature) => {
        const geometry = feature.getGeometry();

        return !!geometry;
      })
      .map((feature: Feature) => {
        const properties = feature.getProperties();

        const { fields } = tooltipDefinition;

        const title =
          fields
            ?.map((field: string) => properties[field])
            .filter((value) => !!value)
            .join(', ') || properties[DEFAULT_NAME_FIELD_OBJECTID];

        const result: ISearchItem = {
          title,
          type: SEARCH_TYPE_LAYER,
          feature,
        };

        return result;
      });

    items.forEach((item) => results.push(item));
  }

  return results;
};

const coordinatesSearch = async (
  map: TMap,
  text: string,
  regionExtent: number[] | undefined
): Promise<ISearchItem[]> => {
  if (!text) {
    return [];
  }

  const sanitizedText = searchSanitize(text);

  const parts = sanitizedText
    .split(' ')
    .join('')
    .split(',')
    .map((item) => Number(item));

  if (parts.length !== 2) {
    return [];
  }

  const results = [];

  const [lat, lon] = parts;

  if (!(Number.isFinite(lat) && Number.isFinite(lon))) {
    return [];
  }

  if (regionExtent) {
    const [xmin, ymin, xmax, ymax] = regionExtent;

    if (lon < xmin || lon > xmax || lat < ymin || lat > ymax) {
      return [];
    }
  }

  const { coordinates } = turfToMercator(turfPoint([lon, lat])).geometry;

  const feature = new Feature({
    geometry: new Point(coordinates),
    id: Math.random(),
  });

  const result: ISearchItem = {
    title: `Координата (широта: ${lat}, долгота: ${lon})`,
    type: SEARCH_TYPE_COORDINATES,
    feature,
  };

  const latLonText = [lon, lat].join(',');
  const geocodeResults = await geocode(latLonText, 1);

  if (geocodeResults.length > 0) {
    const [geocodeResult] = geocodeResults;

    const { address, location } = geocodeResult;
    const { code } = geocodeResult.quality;

    if (code !== ERROR_GEOCODING_OPERATION) {
      result.title += `. Ближайший адрес: ${address} (широта: ${location.lat}, долгота: ${location.lon})`;
    }
  }

  results.push(result);

  return results;
};

function hasProvider(provider: string, providers?: string[]) {
  if (!providers || providers.length === 0) {
    return true;
  }

  return providers.find((element) => element === provider);
}

export const getSearchItems = async (
  map: TMap,
  text: string,
  extent: number[] | undefined,
  layersDefinitions: ILayer[],
  providers?: string[]
): Promise<ISearchItem[]> => {
  const hasGeocoderProvider = hasProvider(SEARCH_TYPE_GEOCODER, providers);
  const hasLayersProvider = hasProvider(SEARCH_TYPE_LAYER, providers);
  const hasCoordinatesProvider = hasProvider(
    SEARCH_TYPE_COORDINATES,
    providers
  );

  console.log(providers);

  const geocodeItems: ISearchItem[] = hasGeocoderProvider
    ? await geocoderSearch(map, text, extent)
    : [];

  const layersItems: ISearchItem[] = hasLayersProvider
    ? await layersSearch(map, text, extent, layersDefinitions)
    : [];

  const coordinateItems: ISearchItem[] = hasCoordinatesProvider
    ? await coordinatesSearch(map, text, extent)
    : [];

  let items: ISearchItem[] = [];

  geocodeItems.forEach((item) => items.push(item));
  layersItems.forEach((item) => items.push(item));
  coordinateItems.forEach((item) => items.push(item));

  items = items.map((item: ISearchItem, index) => {
    const { type } = item;

    if (type !== SEARCH_TYPE_LAYER) {
      item.feature?.set('num', String(index + 1));
    }

    return item;
  });

  return items;
};

export const timeToDuration = (time: number): string => {
  const timeValue = new Date(time);

  const seconds = timeValue.getSeconds().toString().padStart(2, '0');
  const minutes = timeValue.getMinutes().toString().padStart(2, '0');

  return `${minutes}:${seconds}`;
};

export const getDirectionsItems = async (
  point1: ISearchItem,
  point2: ISearchItem
): Promise<IDirectionResult[]> => {
  const { feature: feature1 } = point1;
  const { feature: feature2 } = point2;

  if (feature1 && feature2) {
    const p1 = geomToLatLon(feature1.getGeometry());
    const p2 = geomToLatLon(feature2.getGeometry());

    if (!p1 || !p2) {
      return [];
    }

    const [lon1, lat1] = p1;
    const [lon2, lat2] = p2;

    const directionPoint1: IDirectionPoint = {
      x: lon1,
      y: lat1,
      address: point1.title,
    };

    const directionPoint2: IDirectionPoint = {
      x: lon2,
      y: lat2,
      address: point2.title,
    };

    let variants = await getDirectionVariants(directionPoint1, directionPoint2);

    variants = variants.map((variant) => {
      const { instructions } = variant;

      let timeSummary = 0;
      let distance = 0;

      instructions.forEach((instruction) => {
        timeSummary += instruction.time * SECOND;
        distance += instruction.distance;
      });

      variant.distance = Number(distance.toFixed(2));

      variant.time = timeToDuration(timeSummary);

      return variant;
    });

    return variants;
  }

  return [];
};

export const getLocationFromMap = async (
  map: TMap
): Promise<ISearchItem | undefined> => {
  const action = await getMapPoint(map);

  const { feature } = action;

  if (feature) {
    const geometry = feature.getGeometry();

    if (geometry instanceof Point) {
      const mapPointCoordinates = geometry.getCoordinates();

      const [x, y] = mapPointCoordinates;

      const mapPointWGS84 = turfToWgs84(turfPoint([x, y]));

      const [mapPointLon, mapPointLat] = mapPointWGS84.geometry.coordinates;

      const text = `${mapPointLon},${mapPointLat}`;

      const feature = new Feature({
        geometry: new Point(mapPointCoordinates),
        id: Math.random(),
      });

      const locations = await geocode(text, 1);

      const defaultResponse = {
        title: `${mapPointLat}, ${mapPointLon}`,
        type: SEARCH_TYPE_GEOCODER,
        feature,
      };

      if (locations && locations.length > 0) {
        const [item] = locations;

        const { quality } = item;

        const { code } = quality;

        if (code !== ERROR_GEOCODING_OPERATION) {
          const { address } = item;

          return {
            title: address,
            type: SEARCH_TYPE_GEOCODER,
            feature,
          };
        } else {
          return defaultResponse;
        }
      } else {
        return defaultResponse;
      }
    }
  }

  return undefined;
};

export const clearSearchResults = (map: TMap) => {
  if (!map) {
    return;
  }

  const layer = createTemporaryLayer(map, SEARCH_TEMPORARY_LAYER_ID);

  if (!layer) {
    return;
  }

  layer.getSource()?.clear();
};

export const drawSearchResults = (map: TMap, items: ISearchItem[]) => {
  if (!map) {
    return;
  }

  const layer = createTemporaryLayer(map, SEARCH_TEMPORARY_LAYER_ID);

  if (!layer) {
    return;
  }

  clearSearchResults(map);

  const features = items
    .filter((item) => !!item)
    .map((item: ISearchItem, index: number) => {
      const { feature } = item;

      if (!feature || (feature && !feature.getGeometry())) {
        return undefined;
      }

      const geom = feature?.getGeometry() ? feature.getGeometry() : undefined;

      if (!geom) {
        return undefined;
      }

      const location = geomToLatLon(geom);

      if (!location) {
        return undefined;
      }

      const geometry = locationToGeometry(location[0], location[1]);

      return new Feature({
        geometry,
        num: index + 1,
      });
    })
    .filter((item) => !!item);

  const source = layer.getSource();

  if (source) {
    source.clear(true);

    features.forEach((feature) => {
      feature && source.addFeature(feature);
    });
  }

  const style = styleFunction();

  layer.setStyle(style);
};

export const showFeature = async (item: ISearchItem) => {
  if (!item) {
    return;
  }

  const { feature } = item;

  hideFeatures([]);

  feature && showFeatures([feature]);

  setTimeout(() => {
    feature && hideFeatures([feature]);
  }, HIDE_FEATURE_TTL);
};

export const hideFeature = async (item: ISearchItem) => {
  if (!item) {
    return;
  }

  const { feature } = item;

  feature && hideFeatures([feature]);
};

export const zoomTo = async (item: ISearchItem) => {
  if (!item) {
    return;
  }

  const { feature } = item;

  flyTo(feature);
};

export const getSearchPageSize = (elementId: string) => {
  const defaultSize = Math.round(
    document.body.clientHeight / LIST_SEARCH_ITEM_MAX_SIZE
  );

  const element = document.getElementById(elementId);

  if (!element) {
    return defaultSize;
  }

  const height = element.clientHeight - PAGER_HEIGHT;

  return Math.floor(height / LIST_SEARCH_ITEM_MAX_SIZE);
};

export const getDirectionPageSize = (elementId: string) => {
  const defaultSize = Math.round(
    document.body.clientHeight / LIST_DIRECTION_ITEM_MAX_SIZE
  );

  const element = document.getElementById(elementId);

  if (!element) {
    return defaultSize;
  }

  const height = element.clientHeight - PAGER_HEIGHT - INPUT_BLOCK_HEIGHT;

  return Math.floor(height / LIST_DIRECTION_ITEM_MAX_SIZE);
};
