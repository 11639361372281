import { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';

import rootStore from '../../../../../stores/rootStore/rootStore';
import { Detector } from '../../../../../ts/models/mapObject.model';
import { FilterDataValues } from '../../../../ui-kit/Chart/models/chart.model';
import {
  DATES,
  INITIAL_DATE_INFO,
  INTERVALS,
  INTERVALS_NAMES,
  INTERVAL_DATES,
} from '../../constants/constants';
import { IDateInfo, RadioValue } from '../../model/graph.model';
import { changeDateGraph } from '../helpers/changeDateGraph';
import { getTitlePopover } from '../helpers/getTitlePopover';
import SelectPeriodModal from '../SelectPeriodModal/SelectPeriodModal';
import { getTimeWithTimeZone } from '../TitleBtns/TimeLocalCard/helpers/getTimeWithTimeZone';
import { getTimeZones } from '../TitleBtns/TimeLocalCard/helpers/getTimeZone';

import useDatePicker from './useDatePicker';
import useFilterGraph from './useFilterGraph';

interface IUseIntancyGraph {
  graphTitle: RadioValue;
  activeDetector: U<Detector>;
  standardInterval: U<INTERVALS_NAMES>;
  generalDtPeriodInfo: N<IDateInfo>;
  filterDtData: FilterDataValues;
  setStandardInterval: SetState<U<INTERVALS_NAMES>>;
}

const useIntancyGraph = ({
  graphTitle,
  activeDetector,
  standardInterval,
  generalDtPeriodInfo,
  filterDtData,
  setStandardInterval,
}: IUseIntancyGraph) => {
  const { setGeneralDtInfo } = rootStore.detailedStore;

  const [isConfirm, setIsConfirm] = useState<boolean>(false);
  const [dateInfo, setDateInfo] = useState<N<IDateInfo>>(INITIAL_DATE_INFO);
  const [isModal, setIsModal] = useState<boolean>(false);

  const {
    modalConfirmProps,
    onChangeChecked,
    rangePickerProps,
    setPickerValue,
    timePickerProps,
  } = useDatePicker({
    isModal,
    standardInterval,
    setDateInfo,
    setIsConfirm,
    setIsModal,
  });

  const {
    filterInfo,
    dataGraph,
    colors,
    isError,
    isLoading,
    handleUpdateGraph,
  } = useFilterGraph({
    dateInfo,
    activeDetector,
    isConfirm,
    graphTitle,
    filterDtData,
  });

  const changeDate = (value: keyof typeof DATES) => {
    const timeZone = getTimeZones()?.timeZoneRegion;

    if (!timeZone || !dateInfo?.selectedDate) return;

    const formatTimeTZ = dateInfo.selectedDate.map(
      (date) => getTimeWithTimeZone({ timeZone, dateJS: date }).dateFormattedJS
    ) as [Dayjs, Dayjs];

    if (DATES[value].value === DATES.SELECT_TIME.value) {
      setIsModal(true);

      const standardLabel = INTERVALS.find(
        ({ duration }) => duration === dateInfo.interval
      )?.label;

      const rangePicker =
        dateInfo.interval !== INTERVAL_DATES.DAY && dateInfo.isNeedUtcFormatted
          ? formatTimeTZ
          : dateInfo.selectedDate;

      setStandardInterval(standardLabel);

      setPickerValue((prev) => ({
        ...prev,
        rangePicker,
        timePicker: null,
      }));

      return;
    }

    const dateGr = changeDateGraph(value);

    setIsConfirm(false);
    setGeneralDtInfo(null);
    setDateInfo(dateGr);
  };

  useEffect(() => {
    return () => {
      setGeneralDtInfo(null);
    };
  }, [setGeneralDtInfo]);

  useEffect(() => {
    if (!generalDtPeriodInfo) return;

    setDateInfo({ ...generalDtPeriodInfo });
    setIsConfirm(true);
  }, [generalDtPeriodInfo]);

  const title = getTitlePopover();

  const modalBodyProps = {
    content: (
      <SelectPeriodModal
        title={title}
        //@ts-ignore
        rangePickerProps={rangePickerProps}
        timePickerProps={timePickerProps}
        standardInterval={standardInterval}
        setStandardInterval={setStandardInterval}
        onChangeChecked={onChangeChecked}
      />
    ),
  };

  return {
    changeDate,
    handleUpdateGraph,
    modalConfirmProps,
    modalBodyProps,
    isLoading,
    isError,
    dataGraph,
    dateInfo,
    colors,
    filterInfo,
  };
};

export default useIntancyGraph;
