import { observer } from 'mobx-react-lite';

import { COMMANDS_CONTENT } from '../../../../../constants/commands';
import { TL_STATUS_HEX } from '../../../../../constants/tlStatusConstants';
import { findById } from '../../../../../helpers/findBy';
import rootStore from '../../../../../stores/rootStore/rootStore';
import NoData from '../../../../ui-kit/Errors/NoData/NoData';
import CommandCard, {
  CommandCardProps,
} from '../../ManagementCommands/CommandCard/CommandCard';

import styles from './StatusBlock.module.scss';

const StatusBlock = () => {
  const { infoData } = rootStore.uiStore;
  const { tls } = rootStore.mapDataStore;

  const tl = findById(tls, infoData?.id);

  if (!tl) return <NoData isNeedBackground={false} />;

  return (
    <div className={styles.container}>
      {COMMANDS_CONTENT.map(({ id, tip, body, disabled }) => {
        if (!disabled) return null;

        const commandCardProps: CommandCardProps = {
          title: body,
          colorCommand: TL_STATUS_HEX[id],
          selected: id === tl.mode,
          infoTooltip: tip,
          mode: id,
          isStatus: true,
        };

        return <CommandCard key={id} {...commandCardProps} />;
      })}
    </div>
  );
};

export default observer(StatusBlock);
