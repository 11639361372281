import { checkIsInPointExtent } from '../../../../helpers/checkIsInExtent';
import rootStore from '../../../../stores/rootStore/rootStore';
import { PointUds } from '../../../../ts/models/pointUds.model';
import { DEVICE_BY_TYPE_ID } from '../constants/constants';
import {
  CorrectCoordArgs,
  Device,
  isActualDeviceType,
} from '../PointUdsOverlays.model';

const setIsCorrectCoord = ({ device, pointsUds, idx }: CorrectCoordArgs) => {
  const { setIsCorrectCoordinate } = rootStore.mapDataStore;
  const { longitude, latitude, deviceInfo } = device;
  const { pointUdsUid, deviceTypeId } = deviceInfo;

  const type =
    isActualDeviceType(deviceTypeId) && DEVICE_BY_TYPE_ID[deviceTypeId];

  const point =
    pointUdsUid &&
    pointsUds.find(
      ({ basicProfileInfo }) => pointUdsUid === basicProfileInfo.point_uds_uid
    );

  if (!point || !type) return true;

  const { centerPoint } = point.basicProfileInfo;

  const isInExtent = checkIsInPointExtent(centerPoint, longitude, latitude);

  setIsCorrectCoordinate(idx, type, isInExtent);

  return isInExtent;
};

export const setIsCorrectCoordForDevices = (
  devices: Device[],
  pointsUds: PointUds[]
) =>
  devices.reduce((res: Device[], device, idx) => {
    const isInExtent = setIsCorrectCoord({ device, pointsUds, idx });

    isInExtent || res.push(device);

    return res;
  }, []);
