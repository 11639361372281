import { Card, Button } from 'antd';
import { isNumber } from 'lodash';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';

import { CONTROL_ACCESS_DEVICES } from '../../../../constants/constants';
import NOTICE from '../../../../constants/notificationConstants';
import { getTLModeName } from '../../../../helpers/statusHelpers';
import { checkDisabledControl } from '../../../../stores/mapDataStore/helpers/checkDisabledControl';
import rootStore from '../../../../stores/rootStore/rootStore';
import { TL } from '../../../../ts/models/tl.model';
import { StatusInfoBtn } from '../../../ui-kit/InfoBtn/constants/constants';
import InfoBtn from '../../../ui-kit/InfoBtn/InfoBtn';
import { getTimeWithTimeZone } from '../../TlDetectors/IntancyGraph/TitleBtns/TimeLocalCard/helpers/getTimeWithTimeZone';
import useTimerPlannedComand from '../hooks/useTimerPlannedComand';
import Timer from '../Timer/Timer';

import ActiveButtonWrapper from './ActiveButtonWrapper/ActiveButtonWrapper';
import WarningNotify from './WarningNotify/WarningNotify';

import styles from './StatusCard.module.scss';

export interface IStatusCardProps {
  activeTL: TL;
  isOpenTutorialTooltip: U<boolean>;
  setIsOpenTutorialTooltip: SetState<U<boolean>>;
}

const { ReadAndControl } = CONTROL_ACCESS_DEVICES;

const StatusCard: FC<IStatusCardProps> = ({
  activeTL,
  isOpenTutorialTooltip,
  setIsOpenTutorialTooltip,
}) => {
  const { stopTimerTrigger, isActiveControlBtn, setIsNotDetailed } =
    rootStore.detailedStore;
  const { isActiveCoordTL } = rootStore.scriptsControlStore;

  const [updateTime, setUpdateTime] = useState(
    getTimeWithTimeZone().dateFormatted
  );

  const { deviceStatusId, controlAccess } = activeTL.deviceInfo;

  const { timerInfo, isError } = useTimerPlannedComand({
    id: activeTL.id,
    stopTimerTrigger,
  });

  useEffect(() => {
    const timeRcvNormalize = getTimeWithTimeZone().dateFormatted;

    setUpdateTime(timeRcvNormalize);
  }, [activeTL.tlStatus.mode]);

  const onClick = () => {
    setIsOpenTutorialTooltip(undefined);
    setIsNotDetailed('isActiveControlBtn');
  };

  const isSpecialMode = checkDisabledControl(deviceStatusId);
  const isAccessControl = controlAccess === ReadAndControl;

  const actionText = !isActiveControlBtn ? 'Активировать' : 'Отключить';
  const isDisabledBtn = !isAccessControl || isSpecialMode;

  const mode = activeTL.tlStatus.mode;
  const name = isNumber(mode) ? getTLModeName(mode) : null;

  return (
    <Card className={styles.statusCard}>
      <div className={styles.columnBlocks}>
        <h3>Текущий режим: {name ?? 'Неизвестен'}</h3>
        <h3>Получено: {updateTime}</h3>
        <WarningNotify isActiveCoordTL={isActiveCoordTL} />
        {timerInfo ? <Timer {...timerInfo} /> : null}
        <InfoBtn
          isShow={isError}
          status={StatusInfoBtn.Error}
          popoverContent={NOTICE.PLANNED_CMD_ERROR}
        />
      </div>
      <ActiveButtonWrapper
        openTutorialTooltip={isOpenTutorialTooltip}
        isAccessControl={isAccessControl}
        deviceStatusId={deviceStatusId}
        isActiveBtn={isActiveControlBtn}
        onClick={onClick}
      >
        <Button
          type="primary"
          onClick={onClick}
          disabled={isDisabledBtn}
          className={styles.activateBtn}
        >
          {actionText}
        </Button>
      </ActiveButtonWrapper>
    </Card>
  );
};

export default observer(StatusCard);
