import { Card, Tabs } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC, useEffect } from 'react';

import { TlManagementKeys } from '../../../../constants/commands';
import rootStore from '../../../../stores/rootStore/rootStore';
import { isTabControlKey } from '../model/postCommands.model';
import { ManageCardProps } from '../model/tlManagement.model';

import ConfirmBtn from './ConfirmBtn/ConfirmBtn';
import getIsNeedSwitchTab from './helpers/getIsNeedSwitchTab';
import { getTabpanesManagment } from './helpers/getTabpanesManagment';

import styles from './ManageCard.module.scss';

const { Management } = TlManagementKeys;

const ManageCard: FC<ManageCardProps> = ({
  activeTL,
  setIsOpenTutorialTooltip,
}) => {
  const { isActiveControlBtn, activeControlKey, setKeyValue } =
    rootStore.detailedStore;

  const TABPANES = getTabpanesManagment(activeTL.id);

  const handleClickManageCard = () => {
    !isActiveControlBtn && setIsOpenTutorialTooltip(!isActiveControlBtn);
  };

  const handleChange = (key: string) => {
    isTabControlKey(key) && setKeyValue('activeControlKey', key);
  };

  useEffect(() => {
    const isNeedSwitch = getIsNeedSwitchTab(activeTL, activeControlKey);

    if (isNeedSwitch) {
      setKeyValue('activeControlKey', Management);
    }
  }, [activeControlKey, activeTL, setKeyValue]);

  return (
    <Card className={styles.manageCard} onClick={handleClickManageCard}>
      <Tabs
        className={styles.tabsCustom}
        popupClassName={styles.tooltipDotter}
        defaultActiveKey={Management}
        items={TABPANES}
        onChange={handleChange}
        activeKey={activeControlKey}
      />
      <ConfirmBtn />
    </Card>
  );
};

export default observer(ManageCard);
