import { FC } from 'react';

import styles from './Title.module.scss';

interface TitleProps {
  title: string;
}

const Title: FC<TitleProps> = ({ title }) => {
  return <h4 className={styles.title}>{title}</h4>;
};

export default Title;
