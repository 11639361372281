/* eslint-disable camelcase */
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

import { UNKNOWN_STATUS } from '../../../../../constants/adminConstants';
import { COLORS } from '../../../../../constants/colorsConstants';
import { SECOND } from '../../../../../constants/constants';
import { FormattedAdapter } from '../../ASOMonitoringContainer/helpers/getFormattedAdapterStatistic';
import {
  ADAPTER_LABELS,
  STATUS_ADAPTER,
} from '../constants/monitoringConstants';

import getAdapterStatusDict from './getAdapterStatusDict';
import { FormattedStatistic } from './getSystemNames';

const { WARNING, SUCCESS, NOT_ALLOWED, DARK_PINK, DANGEROUS, GREY100 } = COLORS;

dayjs.extend(duration);

type StatusAdapter = keyof typeof STATUS_ADAPTER;

export const STATUS_COLORS: Record<
  StatusAdapter | typeof UNKNOWN_STATUS,
  string
> = {
  OK: SUCCESS,
  WARNING,
  ERROR: DANGEROUS,
  TERMINATED: DARK_PINK,
  SIGTERM: GREY100,
  UNDEFINED: NOT_ALLOWED,
};

const getWorkHours = (sec: U<number>) => {
  if (!sec) return { workTime: sec, hours: 0 };

  const duration = dayjs.duration(sec * SECOND);

  const days = Math.floor(duration.asDays());
  const hours = duration.hours();
  const minutes = `${Math.floor(duration.minutes())} мин.`;

  const floorHours = Math.floor(hours);

  if (!floorHours) {
    return { workTime: minutes, hours: 0 };
  }

  return {
    workTime: `${days ? `${days}д.` : ''} ${floorHours} ч. ${minutes}`,
    hours: floorHours,
  };
};

export const isAdapterStatus = (status: U<string>): status is StatusAdapter => {
  return status ? status in STATUS_COLORS : false;
};

const CATEGORY_REGULAR = /(?=(\d{3})+(?!\d))/g;

const getCorrectNumberByCategory = (number: Nullish<number>) =>
  number?.toString()?.replace(CATEGORY_REGULAR, ' ');

export const getAdapterStatistic = (adapter: FormattedStatistic) => {
  const { sc_system_id, add_info, systemName, regions } = adapter;
  const { adapter: adapterObj } = add_info;

  const { workTime, hours } = getWorkHours(adapterObj?.uptime_sec);
  const error = adapterObj?.error?.jerror;
  const errorValue = error && Object.keys(error).length ? error : null;

  const dtFrom10MinValue = getCorrectNumberByCategory(
    adapterObj?.statistics?.received_passes_from_last_10min
  );

  const dtFrom10Min = Number(dtFrom10MinValue);

  const tlFrom10MinValue = getCorrectNumberByCategory(
    adapterObj?.statistics?.events_from_last_10min
  );
  const tlFrom10Min = Number(tlFrom10MinValue);

  const info: FormattedAdapter = {
    systemId: { label: ADAPTER_LABELS.systemId, value: sc_system_id },
    systemName: {
      label: ADAPTER_LABELS.systemName,
      value: systemName,
    },
    regions: {
      label: ADAPTER_LABELS.regions,
      value: regions,
    },
    hostName: {
      label: ADAPTER_LABELS.hostName,
      value: adapterObj?.host_id,
    },
    sessionUid: {
      label: ADAPTER_LABELS.sessionUid,
      value: adapterObj?.session_uuid,
    },
    workTime: {
      label: ADAPTER_LABELS.workTime,
      value: workTime,
      labelColor: !hours ? DARK_PINK : undefined,
    },
    status: {
      label: ADAPTER_LABELS.status,
      value:
        getAdapterStatusDict(adapterObj?.status)?.name ?? adapterObj?.status,
      color:
        adapterObj && isAdapterStatus(adapterObj.status)
          ? STATUS_COLORS[adapterObj.status]
          : NOT_ALLOWED,
    },
    passesDtFromStart: {
      label: ADAPTER_LABELS.passesDtFromStart,
      value: getCorrectNumberByCategory(
        adapterObj?.statistics?.received_passes_from_start
      ),
    },
    passesDtFrom10Min: {
      label: ADAPTER_LABELS.passesDtFrom10Min,
      value: dtFrom10MinValue,
      labelColor: !isNaN(dtFrom10Min) && !dtFrom10Min ? DARK_PINK : undefined,
    },
    passesTLFromStart: {
      label: ADAPTER_LABELS.passesTLFromStart,
      value: getCorrectNumberByCategory(
        adapterObj?.statistics?.events_from_start
      ),
    },
    passesTLFrom10Min: {
      label: ADAPTER_LABELS.passesTLFrom10Min,
      value: tlFrom10MinValue,
      labelColor: !isNaN(tlFrom10Min) && !tlFrom10Min ? DARK_PINK : undefined,
    },
    lastTime: {
      label: ADAPTER_LABELS.lastTime,
      value: adapterObj?.lastTimeTZ,
    },
    error: {
      label: ADAPTER_LABELS.error,
      value: errorValue,
      isJson: true,
    },
  };

  return info;
};
