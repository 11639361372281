/* eslint-disable camelcase */

import { BarColors } from '../../../../../../constants/colorsConstants';
import { IColors } from '../../helpers/getDetectorsBarsStatus';

export enum ChartKeys {
  Vehicles = 'vehicles',
  AvSpeed = 'avSpeed',
}

export enum ChartBDKeys {
  PassageCount = 'passage_count',
  SpeedAvg = 'speed_avg',
}

export const CHART_NAMES: Record<ChartKeys, ChartBDKeys> = {
  vehicles: ChartBDKeys.PassageCount,
  avSpeed: ChartBDKeys.SpeedAvg,
};

export enum RADIO_GRAPH {
  Vehicles = 0,
  AvSpeed = 1,
}

export const DAY_INTERVAL = 3600;

export const AVERAGE_COLORS: IColors = {
  average: BarColors.Average,
  dangerous: BarColors.Normal,
  normal: BarColors.Dangerous,
};

export const INTANCY_COLORS: IColors = {
  average: BarColors.Average,
  dangerous: BarColors.Dangerous,
  normal: BarColors.Normal,
};
