import { DATE_FORMAT_TO_SHOW } from '../../../../../../../constants/constants';
import { getTimeWithTimeZone } from '../../../../../../TrafficLightDetailed/TlDetectors/IntancyGraph/TitleBtns/TimeLocalCard/helpers/getTimeWithTimeZone';
import { TSelectDates } from '../../../../../../TrafficLightDetailed/TlDetectors/model/graph.model';

const getSelectedPeriod = (
  selectedDate: TSelectDates,
  isNeedFormatted: boolean
) => {
  return selectedDate
    .map((date) =>
      isNeedFormatted
        ? getTimeWithTimeZone({ dateJS: date, dateFormat: DATE_FORMAT_TO_SHOW })
            .dateFormatted
        : date.format(DATE_FORMAT_TO_SHOW)
    )
    .join(' - ');
};

export default getSelectedPeriod;
