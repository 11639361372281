import { Heatmap as HeatmapLayer } from 'ol/layer';
import VectorLayer from 'ol/layer/Vector';

import { Z_INDEX_LAYER } from '../../../constants/mapConstants';
import { ILayer } from '../../../stores/gisDataStore/gisDataStore.model';
import layersService from '../../../stores/mapDataStore/layers.service';
import { TMap } from '../../../stores/mapStore/mapStore.model';
import rootStore from '../../../stores/rootStore/rootStore';
import { IRenderer } from '../../../ts/models/gis/renderer.model';
import { IServiceDefinition } from '../../models/services/servicesDefinitions';
import { instance as socketPoolInstance } from '../../socketServerPool';
import { createSource as createRESTSource } from '../../sources/FeatureServerSource';
import { createSource as createSocketSource } from '../../sources/SocketDataSource';
import { createStyleByCategory } from '../../styles';
import { RendererTypes } from '../../styles/constants';
import { getScaleResolution, updateHeatmapProps, WebGLLayer } from '../helpers';

export const GIS_APP_PATH = '.apiGis.Layers.FeatureLayer';

export type PropsFeatureLayer = {
  layerDefinition: ILayer;
  map: TMap;
};

class FeatureLayer {
  #layer: N<HeatmapLayer | WebGLLayer | VectorLayer<any>> = null;
  #rendererDefinition: N<IRenderer> = null;
  #layerDefinition: N<IServiceDefinition> = null;
  #serviceDefinition: N<IServiceDefinition> = null;

  constructor(props: PropsFeatureLayer) {
    this.layer = this.initLayer(props);
    this.init(props);
  }

  getLayer(): N<HeatmapLayer | WebGLLayer | VectorLayer<any>> {
    return this.#layer;
  }

  set layer(value: HeatmapLayer | WebGLLayer | VectorLayer<any>) {
    this.#layer = value;
  }

  getRendererDefinition(): N<IRenderer> {
    return this.#rendererDefinition;
  }

  set rendererDefinition(value: IRenderer) {
    this.#rendererDefinition = value;
  }

  getLayerDefinition(): N<IServiceDefinition> {
    return this.#layerDefinition;
  }

  set layerDefinition(value: IServiceDefinition) {
    this.#layerDefinition = value;
  }

  get serviceDefinition() {
    return this.#serviceDefinition;
  }

  set serviceDefinition(value: IServiceDefinition | null) {
    this.#serviceDefinition = value;
  }

  getInstance(): N<HeatmapLayer | WebGLLayer | VectorLayer<any>> {
    return this.getLayer();
  }

  initLayer(
    props: PropsFeatureLayer
  ): HeatmapLayer | WebGLLayer | VectorLayer<any> {
    const { map, layerDefinition } = props;
    const { id, minScale, maxScale } = layerDefinition;

    const { renderers } = rootStore.gisDataStore;

    const rendererDefinition = renderers.find(
      (renderer) => renderer.className === id
    );

    if (rendererDefinition) {
      this.rendererDefinition = rendererDefinition;
    }

    const rendererType = rendererDefinition?.renderer?.type ?? null;

    const minResolution = getScaleResolution(map, maxScale);
    const maxResolution = getScaleResolution(map, minScale);

    const layer =
      rendererType !== RendererTypes.HeatMap
        ? new WebGLLayer({
            className: id,
          })
        : new HeatmapLayer({
            className: id,
          });

    layer.setMinResolution(minResolution);
    layer.setMaxResolution(maxResolution);

    updateHeatmapProps(rendererType, rendererDefinition, layer);

    const categoryId = layerDefinition?.category?.category ?? undefined;

    layer.set('category', categoryId);
    layer.setZIndex(Z_INDEX_LAYER);
    layer.set('id', layerDefinition.id);

    return layer;
  }

  async init(props: PropsFeatureLayer) {
    await this.initDefinition(props.layerDefinition);
    await this.initSource(props);
    this.initRenderer();
    this.initStyle(props);
  }

  async initSource(props: PropsFeatureLayer) {
    const { map, layerDefinition } = props;
    const { socketDefinition } = layerDefinition;
    const socketPool = socketPoolInstance();
    const objectIdField = this.getLayerDefinition()?.objectIdField;

    let source = null;

    const layer = this.getLayer();

    if (!layer) {
      return;
    }

    if (socketDefinition && socketDefinition.path && socketDefinition.url) {
      source = createSocketSource({
        map,
        layer,
        socketPool,
        layerDefinition,
      });
    }

    if (
      !socketDefinition ||
      (socketDefinition && !socketDefinition.path && !socketDefinition.url)
    ) {
      source = createRESTSource({
        map,
        layer,
        socketPool,
        layerDefinition,
        objectIdFieldName: objectIdField,
      });
    }

    this.getLayer()?.setSource(source);
  }

  initStyle(props: PropsFeatureLayer) {
    const { layerDefinition } = props;

    const layer = this.getLayer();

    if (!layer) {
      return;
    }

    const style =
      layer instanceof HeatmapLayer
        ? null
        : createStyleByCategory(
            layerDefinition,
            layer,
            this.getRendererDefinition()?.renderer
          );

    if (style) {
      layer.setStyle(style);
    }
  }

  initRenderer() {
    const rendererDefinition = this.getRendererDefinition();

    if (!rendererDefinition) {
      return;
    }

    const { labelings } = rootStore.gisDataStore;

    const labeling = labelings.find(
      (labeling) => labeling.className === this.getLayerDefinition()?.id
    );

    rendererDefinition.labelingInfo = labeling?.labelingInfo;
    this.rendererDefinition = rendererDefinition;
  }

  async initDefinition(layerDefinition: ILayer) {
    const { url } = layerDefinition;
    const { data } = await layersService.getDefinition(GIS_APP_PATH, url);

    this.layerDefinition = data;
  }
}

export { FeatureLayer };
