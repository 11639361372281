import { MEDIA } from '../../../../constants/constants';
import { TL_DETAILED } from '../../../../constants/tlDetailedConstants';
import cacheStore from '../../../../stores/cacheStore/cacheStore';
import { TLPhaseCodes } from '../../../../ts/enums/tl';
import { TL } from '../../../../ts/models/tl.model';

import { checkPHID } from './checkPHID';
import { filterPHID } from './filterPHID';

const {
  PHASE_ID: { COMMON },
} = TL_DETAILED;

export interface IPhasesData {
  phaseID: string;
  phaseIMG: string;
  main: N<number>;
  code: N<TLPhaseCodes>;
}

export const getImgPhases = (activeTl: TL): N<IPhasesData[]> => {
  const { cachedImages, setCachedImages } = cacheStore;

  const extId = activeTl.extId;
  const images = activeTl.dai_jdt83?.images ?? [];

  const imgInfo = images.map(({ image, code }) => ({ url: image, code }));

  const phases = !!imgInfo.length && filterPHID(imgInfo);

  if (!phases) return null;

  const updPhases = phases.map(({ phaseID, url, code }, index) => {
    const phaseIMG = MEDIA + url;

    if (phaseID === COMMON) {
      if (`${extId}_${checkPHID(phaseID)}` in cachedImages) {
        const resCommonUrl = cachedImages[`${extId}_${checkPHID(phaseID)}`];

        return { phaseID, phaseIMG: resCommonUrl, main: null, code };
      }

      return {
        phaseID,
        phaseIMG,
        main: null,
        code,
      };
    }

    if (`${extId}_${checkPHID(phaseID)}` in cachedImages) {
      const resultUrl = cachedImages[`${extId}_${checkPHID(phaseID)}`];

      return { phaseID, phaseIMG: resultUrl, main: images[index]?.main, code };
    }

    setCachedImages(`${extId}_${checkPHID(phaseID)}`, phaseIMG);

    return { phaseID, phaseIMG, main: images[index]?.main, code };
  });

  if (!updPhases.length) return null;

  return updPhases.map((el) => {
    el.phaseID = el.phaseID.split('_')[0];

    return el;
  });
};
