import { FC } from 'react';

import { ChartBDKeys } from '../constants/constants';

import styles from './InfoBlock.module.scss';

interface InfoBlockProps {
  activeDate: string;
  radioValue: ChartBDKeys;
}

const InfoBlock: FC<InfoBlockProps> = ({ activeDate, radioValue }) => {
  const title =
    radioValue === ChartBDKeys.PassageCount ? 'кол-во ТС' : 'ср. скорость';
  const label = `${title} ${activeDate}`;

  return <p className={styles.timeTitle}>{label}</p>;
};

export default InfoBlock;
