import { FC } from 'react';

import { ReactComponent as InfoIcon } from '../../../../../../assets/icons/confirm_icon.svg';
import ModalBody from '../../../PhaseHold/ModalBody/ModalBody';
import ModalConfirm, {
  ModalConfirmProps,
} from '../../../PhaseHold/ModalConfirm/ModalConfirm';
import { UseEditModalProps } from '../../model/currentPlan.model';

import useEditModal from './hooks/useEditModal';

import styles from './EditModal.module.scss';

interface EditModalProps {
  editProps: Omit<ModalConfirmProps, 'icon'>;
  editTableProps: UseEditModalProps;
}

const EditModal: FC<EditModalProps> = ({ editProps, editTableProps }) => {
  const { editBodyProps } = useEditModal(editTableProps);

  return (
    <ModalConfirm
      {...editProps}
      icon={<InfoIcon className={styles.iconInfo} />}
    >
      <ModalBody {...editBodyProps} />
    </ModalConfirm>
  );
};

export default EditModal;
