import { FC } from 'react';

import { IServerData } from '../../NJSInfo.model';
import InfoBox from '../InfoBox/InfoBox';

import styles from './OsInfo.module.scss';

interface OsInfoProps {
  data: IServerData;
}

const osInfoTitle = 'Информация о нагрузке системы';

const OsInfo: FC<OsInfoProps> = ({ data }) => {
  return (
    <InfoBox title={osInfoTitle} className={styles.infoBox}>
      <ul>
        {Object.entries(data.os).map(([key, value], i) => (
          <li key={i}>
            <span>{key}</span>: {value}
          </li>
        ))}
      </ul>
    </InfoBox>
  );
};

export default OsInfo;
