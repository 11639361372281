import { Feature } from 'ol';
import type { FeatureLike } from 'ol/Feature';
import { SimpleGeometry } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Fill, Stroke, Style, Text } from 'ol/style';

import rootStore from '../../../stores/rootStore/rootStore';
import { FeatureType } from '../../../ts/models/mapObject.model';
import { TEMPORARY_GRAPHICS_STYLE } from '../../constants/styles';
import { ICON_STYLE } from '../../styles/predefined/graphics';
import { WebGLLayer } from '../helpers';

const TEMPORARY_GRAPHICS_LAYER_ID = 'temporary-graphics';

const TEMPORARY_LAYER_Z_INDEX = 1000;

const FLY_TO_PROPS = {
  maxZoom: 18,
  duration: 500,
};

export const styleFunction = () => {
  return (
    feature: FeatureLike | Feature,
    resolution: number
  ): Style | undefined => {
    if (!feature) {
      return;
    }

    const properties = feature.getProperties();

    const num = properties['objectNumber'] || '';

    const dynamicWidth =
      TEMPORARY_GRAPHICS_STYLE.STROKE_WIDTH / resolution < 2
        ? 2
        : TEMPORARY_GRAPHICS_STYLE.STROKE_WIDTH / resolution;

    return new Style({
      image: ICON_STYLE,
      text: new Text({
        text: num,
        font: 'bold 16px arial',
        offsetY: 8,
        stroke: new Stroke({
          width: TEMPORARY_GRAPHICS_STYLE.TEXT_STROKE_WIDTH,
          color: TEMPORARY_GRAPHICS_STYLE.TEXT_STROKE_COLOR,
        }),
      }),
      stroke: new Stroke({
        color: TEMPORARY_GRAPHICS_STYLE.STROKE_COLOR,
        width: dynamicWidth,
      }),
      fill: new Fill({
        color: TEMPORARY_GRAPHICS_STYLE.FILL_COLOR,
      }),
    });
  };
};

export const showFeatures = (features: FeatureLike[] | Feature[]) => {
  const map = rootStore.mapStore.map;

  if (!map || !features || !Array.isArray(features)) {
    return;
  }

  const layers = map.getLayers().getArray();

  const source = new VectorSource({ features });

  let temporaryLayer = layers
    .filter((item) => item && item instanceof VectorLayer)
    .find((item) => item.get('id') === TEMPORARY_GRAPHICS_LAYER_ID);

  if (!temporaryLayer) {
    temporaryLayer = new VectorLayer({
      source: new VectorSource({ features: [] }),
    });

    temporaryLayer instanceof VectorLayer &&
      temporaryLayer.setStyle(styleFunction());

    temporaryLayer.set('id', TEMPORARY_GRAPHICS_LAYER_ID);

    map.addLayer(temporaryLayer);
  }

  temporaryLayer instanceof VectorLayer && temporaryLayer.setSource(source);

  temporaryLayer.setZIndex(TEMPORARY_LAYER_Z_INDEX);
};

export const hideFeatures = (features?: FeatureLike[] | Feature[]) => {
  const map = rootStore.mapStore.map;

  if (!map || !features) {
    return;
  }

  const layers = map.getLayers().getArray();

  const temporaryLayer = layers.find(
    (item) => item.get('id') === TEMPORARY_GRAPHICS_LAYER_ID
  );

  if (
    temporaryLayer &&
    (temporaryLayer instanceof VectorLayer ||
      temporaryLayer instanceof WebGLLayer)
  ) {
    if (!features) {
      temporaryLayer.getSource().clear();

      return;
    }

    features.forEach((feature) =>
      temporaryLayer.getSource().removeFeature(feature)
    );
  }
};

export const flyTo = (feature: FeatureType) => {
  const map = rootStore.mapStore.map;

  if (!map || !feature) {
    return;
  }

  if (feature && feature.getGeometry()) {
    const geometry = feature.getGeometry() as SimpleGeometry;

    map.getView().fit(geometry, FLY_TO_PROPS);
  }
};
