/* eslint-disable camelcase */
import { z } from 'zod';

export enum ModelStatus {
  Work = 1,
  NotWork = 2,
}

const ModuleSchema = z.object({
  isDisabled: z.boolean(),
  isVisible: z.boolean(),
  name: z.string(),
  status: z.nativeEnum(ModelStatus),
  moduleType: z.string(),
  url: z.string().nullable(),
});

const LicenceOptionsSchema = z.object({
  data: ModuleSchema.array(),
});

export const RegionDataOptionalSchema = z.object({
  licenceOptions: LicenceOptionsSchema,
  region_capital: z.string(),
});

export const RegionDataRequiredSchema = z.object({
  id: z.number(),
  name: z.string(),
  extent: z.tuple([z.number(), z.number(), z.number(), z.number()]),
  region: z.string(),
  initialZoom: z.number(),
  mapCenterCoords: z.tuple([z.number(), z.number()]),
  timeZone: z.number(),
});

const RegionDataSchema = RegionDataRequiredSchema.merge(
  RegionDataOptionalSchema
);

export type IRegionData = z.infer<typeof RegionDataSchema>;
export type TModules = z.infer<typeof ModuleSchema>;
export type Extent = IRegionData['extent'];

const videoStreamsProxyItemScheme = z.object({
  sourceHost: z.string(),
  proxyHosts: z.array(z.string()),
});

export const UrlConfigurationsScheme = z.object({
  antMediaServer: z.string(),
  gisFeatureServer: z.string(),
  gisApplicationServer: z.string(),
  videoStreamsProxyList: z.array(videoStreamsProxyItemScheme),
  reportServer: z.string(),
});

export type UrlConfigurations = z.infer<typeof UrlConfigurationsScheme>;
