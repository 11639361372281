/* eslint-disable camelcase*/
import { z } from 'zod';

import { COMMANDS } from '../../../../constants/commands';
import { ColumnTypes } from '../../../../ts/models/table.model';
import { NNInt } from '../../../zod/utils.zod';

const CommandsEnum = z.nativeEnum(COMMANDS);

const param = z.number().or(z.string());

const CommandInfoSchema = z.object({
  param1: param,
});

const SenderAddInfoSchema = z.object({
  command: CommandInfoSchema,
});

export const CommandsSchema = z.object({
  uid: z.string(),
  cmd_status: z.string(),
  cmd_type: CommandsEnum,
  device_id: NNInt,
  dtbegin_command: z.string(),
  dtend_command: z.string().nullable(),
  externaldevice_id: NNInt.nullable(),
  jcmd_sender_add_info: SenderAddInfoSchema,
});

export const CommandsListSchema = CommandsSchema.array();

const FilterSchema = z.object({
  text: z.string(),
  value: z.boolean().or(z.string().or(z.number())),
});

const FilterSettingsSchema = z.object({
  sorted: z.boolean(),
  searched: z.boolean(),
  filters: FilterSchema.array().nullable(),
});

export const CommandsHeaderItemSchema = z.object({
  readonly: z.boolean(),
  referens: z.string(),
  title: z.string(),
  type: z.nativeEnum(ColumnTypes),
  visible: z.boolean(),
  filterSettings: FilterSettingsSchema,
});

export const PlannedCommandsSchema = z.object({
  dataRows: CommandsSchema.array().nullable(),
  headerItem: CommandsHeaderItemSchema.array(),
});

export const PlannedCommandsDataSchema = z.object({
  commands: PlannedCommandsSchema,
});
