import { getLayer } from '../../../apiGIS/layers/utils/getLayer';
import { isLayerVisible } from '../../../apiGIS/layers/utils/isLayerVisible';
import { LABELING_LAYER_SETTINGS_PROPERTY } from '../../../apiGIS/styles/text/text';
import { COLORS } from '../../../constants/colorsConstants';
import { ILayer } from '../../../stores/gisDataStore/gisDataStore.model';
import { TMap } from '../../../stores/mapStore/mapStore.model';

import {
  LABELS_SETTINGS_DEFAULT_FONT_FAMILY,
  LABELS_SETTINGS_DEFAULT_FONT_SIZE,
} from './constants';
import { ILabelingSettings, ILayerDataNode } from './layers.model';

export const getLayerVisibility = (map: TMap, id: string) =>
  isLayerVisible(map, id);

export const setLayerVisibility = (map: TMap, id: string, value: boolean) => {
  const layer = getLayer(map, id);

  layer?.setVisible(value);

  const labelsLayerId = id + '_labels';

  const labelingLayer = getLayer(map, labelsLayerId);

  labelingLayer?.setVisible(value);
};

export const toggleGroupVisibility = (
  map: TMap,
  children?: ILayerDataNode[],
  value?: boolean
) => {
  if (!map || !children) {
    return;
  }

  for (let i = 0; i < children.length; i++) {
    const child: ILayerDataNode = children[i];

    const id = child.item?.id;

    if (!id) {
      continue;
    }

    const layer = getLayer(map, id);

    const visibility = !!value;

    layer && layer.setVisible(visibility);
  }
};

export const getGroupVisibility = (map: TMap, children?: ILayerDataNode[]) => {
  if (!map || !children) {
    return false;
  }

  for (let i = 0; i < children.length; i++) {
    const child: ILayerDataNode = children[i];

    const id = child.item?.id;

    if (!id) {
      continue;
    }

    const layer = getLayer(map, id);

    const visible = layer?.getVisible();

    if (!visible) {
      return false;
    }
  }

  return true;
};

export const getLayerLabelingSettings = (map?: TMap, definition?: ILayer) => {
  const settings: ILabelingSettings = {
    fontSize: LABELS_SETTINGS_DEFAULT_FONT_SIZE,
    fontFamily: LABELS_SETTINGS_DEFAULT_FONT_FAMILY,
    labelProperty: '',
    fields: [],
    strokeWidth: 1,
    strokeColor: COLORS.WHITE,
    fillColor: COLORS.BLACK,
  };

  if (!map) {
    return settings;
  }

  if (!definition) {
    return settings;
  }

  settings.labelProperty = definition.outFields[0];

  const labelsLayerId = definition.id + '_labels';

  settings.fields = (definition.fields || []).map((item) => {
    return {
      label: item.alias || item.name,
      value: item.name,
    };
  });

  const layer = getLayer(map, labelsLayerId);

  if (!layer) {
    return settings;
  }

  const labelingSettings =
    layer?.get(LABELING_LAYER_SETTINGS_PROPERTY) || settings;

  settings.labelProperty = labelingSettings.labelProperty;

  settings.fontSize = labelingSettings.fontSize;
  settings.fontFamily = labelingSettings.fontFamily;
  settings.strokeWidth = labelingSettings.strokeWidth;
  settings.strokeColor = labelingSettings.strokeColor;
  settings.fillColor = labelingSettings.fillColor;

  layer.set(LABELING_LAYER_SETTINGS_PROPERTY, settings);

  return settings;
};
