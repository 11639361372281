import { Button } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { CONTROL_ACCESS_DEVICES } from '../../../../constants/constants';
import rootStore from '../../../../stores/rootStore/rootStore';
import { IInfoPanelData } from '../../../../stores/uiStore/uiStore.model';
import { System } from '../../../../ts/enums/enums';
import { TLDetailedCode } from '../../../../ts/enums/userData';
import { DeviceControlAccess } from '../../../../ts/models/mapObject.model';
import Popover from '../../../ui-kit/Popover/Popover';

import styles from './InfoBtns.module.scss';

interface IInfoBtns {
  isLights: boolean;
  infoData: IInfoPanelData;
  controlAccess: DeviceControlAccess;
  isDisabled: boolean;
}

export const MANAGEMENT_KEY = '8';
const FIRST_KEY = '1';

const { OnlyRead, ReadAndControl } = CONTROL_ACCESS_DEVICES;

const InfoBtns: FC<IInfoBtns> = ({
  isLights,
  infoData,
  controlAccess,
  isDisabled,
}) => {
  const { setKeyValue, setIsNot, setRightPanel, interfaceTipsDelay } =
    rootStore.uiStore;

  const { isTlsDataFetched } = rootStore.mapDataStore;
  const { setPointsValue } = rootStore.pointsUdsStore;
  const { hasAccess } = rootStore.userDataStore;

  const isControlAccessRead =
    controlAccess === OnlyRead || controlAccess === ReadAndControl;

  const isManagementAccess =
    hasAccess(TLDetailedCode.Management) && isControlAccessRead;
  const isMeteo = infoData.system === System.Meteo;
  const isTl = infoData.system === System.Lights;

  const isDetailedAccess = hasAccess(TLDetailedCode.isDetailed) && !isMeteo;

  const isDetailed = isTl
    ? isDetailedAccess && isTlsDataFetched
    : isDetailedAccess;

  const openDetails = (key: string) => {
    setIsNot('isDetailedForm', true);
    setRightPanel(null);
    setKeyValue('activeKey', key);
    setPointsValue('isShowPhaseCircle', false);
  };

  const popoverProps = {
    tipsDelay: interfaceTipsDelay,
    content: 'Открыть',
    placement: 'top',
  } as const;

  const handleClick = (key: U<string>) => {
    key && openDetails(key);
  };

  if (!isDetailedAccess) return null;

  return (
    <div className={styles.buttonsBlock}>
      {isDetailed && (
        <Popover
          {...popoverProps}
          content={`${popoverProps.content} подробную информацию об объекте`}
        >
          <Button
            type="primary"
            className={styles.detailsBtn}
            onClick={() => handleClick(FIRST_KEY)}
            disabled={isDisabled || !FIRST_KEY}
          >
            Подробнее
          </Button>
        </Popover>
      )}
      {isLights && isManagementAccess && (
        <Popover
          {...popoverProps}
          content={`${popoverProps.content} управление СО`}
        >
          <Button
            type="primary"
            className={styles.detailsBtn}
            onClick={() => handleClick(MANAGEMENT_KEY)}
            disabled={isDisabled || !MANAGEMENT_KEY}
          >
            Управление СО
          </Button>
        </Popover>
      )}
    </div>
  );
};

export default observer(InfoBtns);
