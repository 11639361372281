import { ISystemsInfo } from '../../../stores/mapDataStore/mapDataStore.model';
import rootStore from '../../../stores/rootStore/rootStore';
import { System } from '../../../ts/enums/enums';

import addMarkers from './addMarkers';

const { Lights, Cameras, Detectors, Meteo } = System;

const getUpdatedDevices = (title: System) => {
  const { detectors, tls, cameras, meteo } = rootStore.mapDataStore;

  switch (title) {
    case Lights:
      return tls;
    case Cameras:
      return cameras;
    case Detectors:
      return detectors;
    case Meteo:
      return meteo;

    default:
      return null;
  }
};

const getIsMarkers = (title: System) => {
  const { markers } = rootStore.uiStore;

  const { isLights, isCameras, isDetectors, isMeteo } = markers;

  switch (title) {
    case Lights:
      return isLights;
    case Cameras:
      return isCameras;
    case Detectors:
      return isDetectors;
    case Meteo:
      return isMeteo;

    default:
      return null;
  }
};

export const getFeatures = (
  mapIconsData: ISystemsInfo[],
  isGroupDispatch = false
) => {
  const updatedMapIconsData = mapIconsData.map((item) => {
    const data = getUpdatedDevices(item.title);
    const isMarkers = getIsMarkers(item.title);

    if (data && isMarkers !== null) {
      item.data = data;
      item.isMarkers = isMarkers;
    }

    return item;
  });

  const newFeatures = updatedMapIconsData.map(({ title, data, isMarkers }) => ({
    features: addMarkers(data, title, isGroupDispatch),
    isFeatures: isMarkers,
    system: title,
  }));

  return newFeatures;
};
