import { Tag } from 'antd';
import { FC, useEffect, useState } from 'react';

import {
  ISeparateGroups,
  getAllGroupsObj,
} from '../../helpers/getAllGroupsObj';

import styles from './GroupsBox.module.scss';

interface GroupsBoxProps {
  userGroups: number[];
}

const ENTER_TEXT = 'Входит в группы ';
const NOT_ENTER_TEXT = 'Не входит в группы ';
const NJS = 'NJS';
const WEB = 'WEB';

const GroupsBox: FC<GroupsBoxProps> = ({ userGroups }) => {
  const [groupsList, setGroupsList] = useState<ISeparateGroups>({
    web: [],
    njs: [],
  });

  const setupGroupsList = async () => {
    const groups = await getAllGroupsObj();

    setGroupsList(groups);
  };

  useEffect(() => {
    setupGroupsList();
  }, []);

  return (
    <div className={styles.groupsBox}>
      <div className={styles.groups}>
        {ENTER_TEXT + WEB}
        {userGroups && groupsList ? (
          userGroups.map((groupId: number) => {
            const group = groupsList.web.find(
              (el) => Number(el.id) === Number(groupId)
            );

            if (!group) return null;

            return <Tag key={groupId}>{group.name}</Tag>;
          })
        ) : (
          <div className="ant-tag">{NOT_ENTER_TEXT + WEB}</div>
        )}
      </div>
      <div className={styles.groups}>
        {ENTER_TEXT + NJS}
        {userGroups && groupsList ? (
          userGroups.map((groupId: number) => {
            const group = groupsList.njs.find(
              (el) => Number(el.id) === Number(groupId)
            );

            if (!group) return null;

            return (
              <Tag key={groupId} color="default">
                {group.name}
              </Tag>
            );
          })
        ) : (
          <div className="ant-tag">{NOT_ENTER_TEXT + NJS}</div>
        )}
      </div>
    </div>
  );
};

export default GroupsBox;
