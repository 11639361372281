/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Switch } from 'antd';
import classNames from 'classnames';
import { useEffect, useState, FC, CSSProperties } from 'react';
import { IoIosArrowForward } from 'react-icons/io';

import { COLORS } from '../../../constants/colorsConstants';
import { MarkersDictKeys } from '../../../constants/constants';
import useScrollToElement from '../../../hooks/useScrollToElement';
import { TObjMap } from '../../../stores/mapDataStore/mapDataStore.model';
import { Events, System } from '../../../ts/enums/enums';
import { IBasicMapData } from '../../../ts/models/mapObject.model';
import { DisabledTypes } from '../../Panel/MainPanelTabs/AllSystemsTab/constants/constants';
import CollapseListBtn from '../../Svg/CollapseListBtn/CollapseListBtn';
import UnexpectedError from '../Errors/UnexpectedError/UnexpectedError';

import AccordionItem from './AccordionItem/AccordionItem';

import styles from './Accordion.module.scss';

const HEIGHT = {
  DEFAULT: 200,
  MAX: 5000,
  MIN: 0,
};

interface DataProps {
  disabledType?: N<DisabledTypes>;
}

export type DataType = (TObjMap | IBasicMapData) & DataProps;

interface AccordionProps {
  title: System | Events;
  data: DataType[];
  setIsMarkers: (key: MarkersDictKeys) => void;
  onClick: any;
  activeId: U<number>;
  isDisabled: boolean;
  isOpen?: boolean;
  isNeedScroll?: boolean;
  isEmpty?: boolean;
}

const Accordion: FC<AccordionProps> = ({
  title,
  data,
  setIsMarkers,
  onClick,
  activeId,
  isDisabled,
  isOpen,
  isNeedScroll,
  isEmpty = false,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isFullHeight, setIsFullHeight] = useState(false);

  const handleCollapse = () =>
    isDisabled && !isEmpty && setIsCollapsed(!isCollapsed);
  const handleMarkers = () => setIsMarkers(title);
  const handleIsFullHeight = () => setIsFullHeight(!isFullHeight);

  const { addToRefs, handleScroll } = useScrollToElement();

  useEffect(() => {
    if (!isNeedScroll) return;

    let timeoutId: NodeJS.Timeout;

    const getTimeoutId = (timeout: NodeJS.Timeout) => {
      timeoutId = timeout;
    };

    handleScroll(activeId, getTimeoutId);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [activeId, isNeedScroll, handleScroll]);

  useEffect(() => {
    !isDisabled && setIsCollapsed(false);
  }, [isDisabled, setIsCollapsed]);

  useEffect(() => {
    if (isOpen !== undefined) {
      setIsCollapsed(isOpen);
    }
  }, [isOpen]);

  const isOpenBtnStyle = classNames({
    [styles.collapseBtn]: true,
    [styles.isAccordionOpen]: isCollapsed,
    [styles.isEmpty]: isEmpty,
  });

  const itemsStyle = classNames({
    [styles.items]: true,
    [styles.isOpen]: isCollapsed,
    [styles.isHidden]: !isCollapsed,
  });

  const titleStyle = classNames({
    [styles.title]: true,
    [styles.isEmptyTitle]: isEmpty,
  });

  const headerInlineStyle: CSSProperties = {
    cursor: isEmpty ? 'not-allowed' : 'pointer',
    color: isEmpty ? COLORS.GREY100 : COLORS.FONT_COLOR,
  };

  const maxHeight = isFullHeight ? HEIGHT.MAX : HEIGHT.DEFAULT;

  const itemInlineStyle = {
    maxHeight: isCollapsed && !isEmpty ? maxHeight : HEIGHT.MIN,
  };

  return (
    <UnexpectedError>
      <section className={styles.container}>
        <div
          className={styles.header}
          style={headerInlineStyle}
          onClick={handleCollapse}
        >
          <button className={isOpenBtnStyle}>
            <IoIosArrowForward />
          </button>

          <div className={styles.headerContent}>
            <div className={styles.info}>
              <h5 className={titleStyle}>{title}</h5>

              <span className={styles.amount}>{data.length}</span>
            </div>

            <div className={styles.btnsBlock}>
              <Switch
                checked={isDisabled}
                onChange={(value: boolean, e: any) => {
                  e.stopPropagation();
                  handleMarkers();
                }}
                size="small"
              />
            </div>
          </div>
        </div>

        <div style={itemInlineStyle} className={itemsStyle} role="presentation">
          <ul className={styles.list}>
            {data.map((mapObject) => (
              <AccordionItem
                key={mapObject.id}
                mapObject={mapObject}
                system={title}
                activeId={activeId}
                onClick={onClick}
                addToRefs={addToRefs}
              />
            ))}
          </ul>

          <CollapseListBtn
            onClick={handleIsFullHeight}
            isToTop={isFullHeight}
          />
        </div>
      </section>
    </UnexpectedError>
  );
};

export default Accordion;
