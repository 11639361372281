import { FC } from 'react';

import { TDeviceStatus } from '../../../../../../../constants/devicesConstants';
import { getDeviceColor } from '../../../../../../MapBtns/MapInfoWrapper/InfoBtn/ContentInfo/helpers/getMapInfo';

import styles from './DeviceIcon.module.scss';

interface IDeviceIcon {
  status: TDeviceStatus;
  iconSystem: JSX.Element;
}

const DeviceIcon: FC<IDeviceIcon> = ({ status, iconSystem }) => {
  return (
    <div className={styles.statusIco} style={{ color: getDeviceColor(status) }}>
      {iconSystem}
    </div>
  );
};

export default DeviceIcon;
