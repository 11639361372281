import { FC } from 'react';

import {
  IIteractionButton,
  TableData,
} from '../../../../ts/models/table.model';
import Export from '../Export/Export';
import IteractionButtons from '../IteractionButtons/IteractionButtons';

import styles from './Footer.module.scss';

export interface FooterProps {
  iteractionButtons: U<IIteractionButton[]>;
  exportBtnTitle: string;
  tableData: TableData;
}

const Footer: FC<FooterProps> = ({
  tableData,
  exportBtnTitle,
  iteractionButtons,
}) => {
  return (
    <div className={styles.btnsRow}>
      <IteractionButtons iteractionButtons={iteractionButtons} />

      {exportBtnTitle && tableData.dataRows.length ? (
        <Export
          data={tableData.dataRows}
          headers={tableData.headerItem}
          title={exportBtnTitle}
        />
      ) : null}
    </div>
  );
};

export default Footer;
