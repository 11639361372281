import { isString } from 'lodash';
import { action, flow, makeAutoObservable } from 'mobx';

import { OSM_ITEM } from '../../apiGIS/constants/basemaps/sources';
import { IWeatherDataset } from '../../components/Map/IWeatherTimeModel';
import { IMeasureAction } from '../../components/Map/Map.model';
import { ILabeling, IRenderer } from '../../ts/models/gis/renderer.model';
import basemapsService from '../mapDataStore/basemaps.service';
import labelingService from '../mapDataStore/labeling.service';
import layersService from '../mapDataStore/layers.service';
import renderersService from '../mapDataStore/renderers.service';
import weatherService from '../mapDataStore/serverapi.service';
import RootStore from '../rootStore/rootStore';
import { StoreUtils } from '../storeUtils/storeUtils';

import { INITIAL_WEATHER_LAYER } from './constants/gisDataConstants';
import {
  GisBaseMapData,
  GisLabelData,
  IBaseMapItem,
  ILayer,
  IVisibleLayer,
  VIEW_MODE,
  WeatherData,
} from './gisDataStore.model';

class GisDataStore {
  rootStore;
  basemap: IBaseMapItem = {};
  basemaps: IBaseMapItem[] = [];
  labelings: ILabeling[] = [];
  renderers: IRenderer[] = [];
  layers: ILayer[] = [];
  measureAction: IMeasureAction = {};
  visibleLayers: IVisibleLayer[] = [];
  weatherLayer = INITIAL_WEATHER_LAYER;
  weatherItems: IWeatherDataset[] = [];
  layersVisibility: string | null = null;
  measurementsChange = -1;
  hasIntegrationLayersGIS = true;
  hasIntegrationBasemapsGIS = true;
  hasIntegrationLabelingsGIS = true;
  hasIntegrationWeatherGIS = true;
  viewMode = VIEW_MODE['2D'];
  setGisValue;

  constructor(rootStore: typeof RootStore) {
    makeAutoObservable(this, {
      rootStore: false,
      fetchBasemaps: flow.bound,
      fetchLabeling: flow.bound,
      fetchWeather: flow.bound,
      loadStartMapSettings: action.bound,
      fetchRenderers: action.bound,
      fetchLayers: action.bound,
    });
    this.rootStore = rootStore;

    const utils = new StoreUtils(this);

    this.setGisValue = utils.setKeyValue;
  }

  *fetchWeather(regionId: number) {
    const { data, hasIntegrationGIS }: WeatherData =
      yield weatherService.execute(regionId);

    this.weatherItems = data.map((item) => {
      return item;
    });

    this.hasIntegrationWeatherGIS = hasIntegrationGIS;
  }

  *fetchBasemaps(regionId: number) {
    const { data, hasIntegrationGIS }: GisBaseMapData =
      yield basemapsService.getList('gis', regionId);

    this.basemaps = data.map((item) => {
      const result: IBaseMapItem = {
        id: item.id,
        url: item.url,
        type: item.type,
        imageSrc: item.picture,
        subDomains: item.subDomains || [],
        style: item.style || [],
        text: item.id,
        maxZoom: item.maxZoom,
        description: item.description,
      };

      return result;
    });
    this.hasIntegrationBasemapsGIS = hasIntegrationGIS;
  }

  *fetchLabeling(regionId: number) {
    const { data, hasIntegrationGIS }: GisLabelData =
      yield labelingService.getList('gis', regionId);

    this.labelings = data;
    this.hasIntegrationLabelingsGIS = hasIntegrationGIS;
  }

  async fetchRenderers(regionId: number) {
    const { data } = await renderersService.getList('gis', regionId);

    const renderers = data.map((renderer) => {
      if (isString(renderer.renderer)) {
        renderer.renderer = JSON.parse(renderer.renderer);
      }

      return renderer;
    });

    return renderers;
  }

  async fetchLayers(regionId: number) {
    const { data, hasIntegrationGIS } = await layersService.getList(
      'gis',
      regionId
    );

    const layers = data.filter((layer) => {
      return layer.load;
    });

    this.setGisValue('hasIntegrationLayersGIS', hasIntegrationGIS);

    return layers;
  }

  async loadStartMapSettings(regionId: number) {
    this.basemap = OSM_ITEM;

    this.fetchBasemaps(regionId);
    this.fetchLabeling(regionId);

    const renderersRequest = this.fetchRenderers(regionId);
    const layersRequest = this.fetchLayers(regionId);

    const dataRequests = await Promise.all([renderersRequest, layersRequest]);
    const [renderers, layers] = dataRequests;

    this.setGisValue('renderers', renderers);
    this.setGisValue('layers', layers);
  }

  get basemapProxy() {
    return this.basemap;
  }

  get basemapId() {
    return this.basemap?.id;
  }

  getBasemaps = () => {
    return this.basemaps.map(({ style, ...rest }) => {
      return rest;
    });
  };

  get layersState() {
    return this.layers;
  }

  get layersVisibilityProxy() {
    return this.layersVisibility;
  }

  get weatherDatasets() {
    return this.weatherItems.map((item) => {
      return {
        time: item.time,
        features: item.features,
        uv: item.uv,
        precipitation: item.precipitation,
      };
    });
  }

  get isLayersOn() {
    return Boolean(this.layersVisibility);
  }
}

export default GisDataStore;
