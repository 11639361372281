import { Descriptions, type DescriptionsProps } from 'antd';
import { FC } from 'react';

import { PointUdsInfoProps } from '../../SystemsInfo.model';

import PointUdsName from './PointUdsName';

const PointUdsInfo: FC<PointUdsInfoProps> = ({ deviceInfo }) => {
  const { pointUdsUid } = deviceInfo;

  const items: DescriptionsProps['items'] = [
    {
      key: '1',
      label: 'Имя',
      children: <PointUdsName deviceInfo={deviceInfo} />,
      span: 12,
    },
    {
      key: '2',
      label: 'УИ',
      children: pointUdsUid,
    },
  ];

  return <Descriptions items={items} />;
};

export default PointUdsInfo;
