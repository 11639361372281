import { useEffect, useState } from 'react';

import { SECOND, UPDATE_WEATHER_TIME } from '../constants/constants';
import { getDayInfo } from '../helpers/formatDate';
import { getWeather } from '../helpers/getWeather';
import rootStore from '../stores/rootStore/rootStore';
import { SidebarCode } from '../ts/enums/userData';

export enum StyleBlock {
  InfoTop = 'infoTop',
  InfoCenter = 'infoCenter',
  InfoBottom = 'infoBottom',
}

const useDayInfo = (mapCenter: any) => {
  const { hasAccess } = rootStore.userDataStore;
  const [{ time, timezone, day, dayName }, setDayInfo] = useState(getDayInfo());
  const [{ temp, weather }, setWeather] = useState({ temp: '', weather: '' });

  const isDisableWeather = !hasAccess(SidebarCode.Weather);

  useEffect(() => {
    const fetchWeather = async () => {
      if (isDisableWeather) return;
      setWeather(await getWeather(mapCenter));
    };

    fetchWeather();
    const timerIdDayInfo = setInterval(() => setDayInfo(getDayInfo()), SECOND);
    const timerIdWeather = setInterval(fetchWeather, UPDATE_WEATHER_TIME);

    return () => {
      clearInterval(timerIdDayInfo);
      clearInterval(timerIdWeather);
    };
  }, []); // eslint-disable-line

  return [
    { firstRaw: time, secondRaw: `GMT ${timezone}`, style: StyleBlock.InfoTop },
    {
      firstRaw: day,
      secondRaw: dayName,
      style: !isDisableWeather ? StyleBlock.InfoCenter : StyleBlock.InfoBottom,
    },
    { firstRaw: temp, secondRaw: weather, style: StyleBlock.InfoBottom },
  ];
};

export default useDayInfo;
