import { useQuery } from '@tanstack/react-query';

import { getSystemIds } from '../../../../../components/Admin/AdminSystemObject/ASOMonitoring/helpers/getSystemsIds';
import {
  AUTO_UPDATE_STATIC,
  QUERY_KEY,
} from '../../../../../constants/queryConstants';
import { StatisticAdapter } from '../adapter.zod';
import {
  StatisticProps,
  useStatisticQueryOptions,
} from '../model/adapter.model';

const { STATISTIC_ADAPTERS } = QUERY_KEY;

interface useStatisticQueryProps extends useStatisticQueryOptions {
  fetchStatistic: (props: StatisticProps) => Promise<U<StatisticAdapter[]>>;
}

const useStatisticQuery = ({
  fetchStatistic,
  appPath,
  systemIds,
  systemTypes,
  subSystemTypes,
  regionIds,
  specialKeys,
  isEnabled = true,
}: useStatisticQueryProps) => {
  const {
    data: adapterStatistics,
    isLoading,
    isError,
    isFetching,
    refetch,
  } = useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [
      STATISTIC_ADAPTERS,
      systemIds,
      systemTypes,
      subSystemTypes,
      regionIds,
      ...(specialKeys ?? []),
    ],
    queryFn: async () => {
      if (Array.isArray(regionIds) && !regionIds.length) return;

      return await fetchStatistic({
        appPath,
        systemIds: getSystemIds(systemIds),
        systemTypes: getSystemIds(systemTypes),
        subSystemTypes: getSystemIds(subSystemTypes),
        regionIds: getSystemIds(regionIds),
        isBlockedNotify: true,
      });
    },
    refetchInterval: AUTO_UPDATE_STATIC,
    enabled: isEnabled,
  });

  return { adapterStatistics, isLoading, isError, isFetching, refetch };
};

export default useStatisticQuery;
