import { isNumber } from 'lodash';
import { makeAutoObservable, observable, toJS } from 'mobx';
import { fromLonLat, toLonLat } from 'ol/proj';

import { LibraryKeys } from '../../api/libraries/enums/enums';
import { IRegionData } from '../../api/server/web/web.zod';
import getDeviceBySystem from '../../components/DetailedStatistics/Monitoring/DeviceCard/Mapper/CrossroadSchema/helpers/getDeviceBySystem';
import { IExternalResource } from '../../components/ExternalResource/ExternalResource';
import { getIsSpecialType } from '../../components/InfoPanel/helpers/helpers';
import { ShowBtns } from '../../components/TrafficLightDetailed/TlDetectors/GraphCard/ModalSettings/SettingsBody/constants/constants';
import {
  THEME_PROPERTIES,
  THEME,
  MARKERS_DICT,
  MarkersDictKeys,
} from '../../constants/constants';
import {
  INITIAL_MARKERS,
  INITIAL_SETTINGS,
} from '../../constants/initialSettingsConstants';
import {
  PanelType,
  PanelTypes,
  RightPanelType,
  System,
} from '../../ts/enums/enums';
import { IIdentifyItem } from '../../ts/models/gis/identify.model';
import { ITimePoints } from '../../ts/models/signalPrograms.model';
import ConstructorStore from '../constructorStore/constructorStore';
import RootStore from '../rootStore/rootStore';
import { StoreUtils } from '../storeUtils/storeUtils';

import { DICTIONARY_DEFAULT } from './constants/constants';
import {
  IClickedCartographyObj,
  IInfoPanelData,
  ISecTls,
  ITooltipData,
  ManagementModules,
  MapActions,
  TipType,
  TipsValues,
  LayerTooltipData,
  MarkersKeys,
  InfoData,
  LibraryValues,
} from './uiStore.model';

const { Constructor, CoordManagement, ModulesSubsystems, Cartography } =
  PanelType;
const { NoActions } = MapActions;

export const PANEL_TRANSITION_TIME = 500;

class UIStore {
  rootStore;
  key = '';
  theme = localStorage.theme ?? THEME.LIGHT;
  isSidebar = false;
  isPanel = false;
  isRightPanel = false;
  rightPanelTimeoutId: N<NodeJS.Timeout> = null;
  isCollapseRightPanel = false;
  isNotificationPanel = false;
  isWidgetLoad = INITIAL_SETTINGS.isWidgetLoad;
  isClusters = INITIAL_SETTINGS.isClusters;
  isModalLoading = false;
  //temporary delete because it works not so good
  // isCenterDependsOnPanels = INITIAL_SETTINGS.isCenterDependsOnPanels;
  interfaceTipsDelay: TipType = INITIAL_SETTINGS.interfaceTipsDelay;
  mapTipsDelay: TipType = INITIAL_SETTINGS.mapTipsDelay;
  mapButtonsTipsDelay: TipType = INITIAL_SETTINGS.mapButtonsTipsDelay;
  profileTipsDelay: TipType = INITIAL_SETTINGS.profileTipsDelay;
  gisTooltipsDelay: TipType = INITIAL_SETTINGS.gisTooltipsDelay;
  isSummaryInfo = INITIAL_SETTINGS.isSummaryInfo;
  isDetailedForm = false;
  mapIconsSize = INITIAL_SETTINGS.mapIconsSize;
  panelType: N<PanelType> = Cartography;
  rightPanelType: N<RightPanelType> = null;
  infoData: N<InfoData> = null;
  tooltipData: N<ITooltipData> = null;
  layerTooltipData: N<LayerTooltipData> = null;
  markers = INITIAL_MARKERS;
  regionData: N<IRegionData> = null;
  prevRegionData: N<IRegionData> = null;
  logoName = INITIAL_SETTINGS.logoName;
  externalResource: IExternalResource | null = null;
  isOpenInfoPanel = INITIAL_SETTINGS.isOpenInfoPanel;
  isNeedOpenedCluster = INITIAL_SETTINGS.isNeedOpenedCluster;
  isInfoBtn = INITIAL_SETTINGS.isInfoBtn;
  isAlarmAlert = INITIAL_SETTINGS.isAlarmAlert;
  isDevMapInfo = INITIAL_SETTINGS.isDevMapInfo;
  isStaticDataLog = INITIAL_SETTINGS.isStaticDataLog;
  isZodLog = INITIAL_SETTINGS.isZodLog;
  isVideoDtLinksLog = INITIAL_SETTINGS.isVideoDtLinksLog;
  isActiveDeviceLog = INITIAL_SETTINGS.isActiveDeviceLog;
  isIncorrectCoordinatesLog = INITIAL_SETTINGS.isIncorrectCoordinatesLog;
  isQueryDevTools = INITIAL_SETTINGS.isQueryDevTools;
  queryToolsBtnPosition = INITIAL_SETTINGS.queryToolsBtnPosition;
  phasesArray: ITimePoints[] = [];
  activeKey = '1';
  isMinificMapOpen = false;
  isMinificMap = INITIAL_SETTINGS.isMinificMap;
  isScaleLine = INITIAL_SETTINGS.isScaleLine;
  isWidthMode = false;
  isQueryParamsError = false;
  clickedCartographyObj: N<IClickedCartographyObj> = null;
  secTls: ISecTls[] = [];
  displayTimeCardDtShow = ShowBtns.Slider;
  activeMapButton: U<string>;
  moduleManagementType: N<ManagementModules> = null;
  dictionaries = DICTIONARY_DEFAULT;
  setKeyValue;
  setKeysValues;
  mapIdentifiedItems: IIdentifyItem[] = [];
  isInfoPopup = true;
  isCursorOnMapChildElement = false;
  isTooltip = false;
  isLayerTooltip = false;
  focusedPointUdsIconId: N<number> = null;
  isSearchBtnGis = INITIAL_SETTINGS.isSearchBtnGis;
  isLayersBtnGis = INITIAL_SETTINGS.isLayersBtnGis;
  isMeasurementBtnGis = INITIAL_SETTINGS.isMeasurementBtnGis;
  isBasemapBtnGis = INITIAL_SETTINGS.isBasemapBtnGis;
  isMeteoDataBtnGis = INITIAL_SETTINGS.isMeteoDataBtnGis;
  isTrafficBtnGis = INITIAL_SETTINGS.isTrafficBtnGis;
  isTrafficGraphBtnGis = INITIAL_SETTINGS.isTrafficGraphBtnGis;

  constructor(rootStore: typeof RootStore) {
    makeAutoObservable(this, {
      rootStore: false,
      markers: observable,
    });
    this.rootStore = rootStore;
    this.setTheme(this.theme);

    const utils = new StoreUtils(this);

    this.setKeyValue = utils.setKeyValue;
    this.setKeysValues = utils.setKeysValues;
  }

  setIsNot = (key: string, bool?: boolean | undefined) => {
    // @ts-ignore
    this[key] = bool !== undefined ? bool : !this[key];
  };

  setIsMarkers = (key: MarkersDictKeys) => {
    if (key === this.infoData?.system) {
      this.setInfoData(null);
    }

    const marker = this.markers[MARKERS_DICT[key]];

    this.markers[MARKERS_DICT[key]] = !marker;

    this.markers = { ...this.markers };
  };

  setDictionaryValue = (dictKey: LibraryKeys, value: LibraryValues[]) => {
    this.dictionaries[dictKey] = value;
  };

  setRightPanel = (panelType: N<RightPanelType>) => {
    const { rightPanelTimeoutId, rightPanelType, setIsNot, setKeyValue } = this;
    const { setSelectedFeatureSomeArray } = this.rootStore.scriptsControlStore;
    const { setSelectedClusters } = this.rootStore.clustersStore;

    rightPanelTimeoutId && clearTimeout(rightPanelTimeoutId);

    const isSpecialType = getIsSpecialType(panelType, PanelTypes.LeftPanel);

    const isSamePanelType = rightPanelType === panelType && isSpecialType;

    if (panelType === RightPanelType.IdentificationGis) {
      setSelectedClusters(null);

      setKeyValue('infoData', null);
    }

    if (!panelType || isSamePanelType) {
      setIsNot('isRightPanel', false);
      const isInfoPanel = getIsSpecialType(
        rightPanelType,
        PanelTypes.InfoPanel
      );

      if (isInfoPanel) {
        setKeyValue('clickedCartographyObj', null);
        setSelectedClusters(null);
      }

      setSelectedFeatureSomeArray(null);

      this.rightPanelTimeoutId = setTimeout(() => {
        setKeyValue('rightPanelType', null);
      }, PANEL_TRANSITION_TIME);

      return;
    }

    setIsNot('isRightPanel', true);
    setKeyValue('rightPanelType', panelType);
  };

  clearDataAsRegion = (isClearAll = false) => {
    const { setKeyValue, setIsNot, setInfoData, setRightPanel, regionData } =
      this;
    const { clearClusterStore } = this.rootStore.clustersStore;

    if (!isClearAll) {
      this.prevRegionData = regionData;
    }

    localStorage.removeItem('regionId');
    setKeyValue('regionData', null);
    setKeyValue('panelType', null);
    setIsNot('isSidebar');
    setRightPanel(null);
    setIsNot('isPanel', false);
    setIsNot('isDetailedForm', false);
    setInfoData(null);
    setKeyValue('clickedCartographyObj', null);
    clearClusterStore();

    this.rootStore.mapDataStore.setKeysValues({
      tls: [],
      cameras: [],
      detectors: [],
      meteo: [],
    });
  };

  setMarkerKey = (key: MarkersKeys, value: boolean) => {
    this.markers[key] = value;
  };

  setSecTls = (idTl: number, secTl: N<number>) => {
    if (secTl === null) {
      this.secTls = this.secTls.filter(({ id }) => id !== idTl);

      return;
    }

    const secTLItem = this.secTls.find(({ id }) => id === idTl);

    if (secTLItem) {
      secTLItem.sec = secTl;

      return;
    }

    this.secTls.push({ id: idTl, sec: secTl });
  };

  setInfoData = (
    data: IInfoPanelData | null,
    mapAction = MapActions.CenterZoom
  ): void => {
    const { setRightPanel, isDetailedForm, isActiveDeviceLog } = this;
    const { clickedCluster } = this.rootStore.clustersStore;
    const { setMapData } = this.rootStore.mapDataStore;

    if (data === null) {
      !clickedCluster && setRightPanel(null);
      this.infoData = null;
      setMapData('selectedFeature', null);
      this.clickedCartographyObj = null;

      return;
    }
    const panelType = isDetailedForm ? null : RightPanelType.InfoPanel;
    const device = getDeviceBySystem(data.id, data.system) ?? null;

    this.infoData = { ...data, mapAction, device };
    setRightPanel(panelType);
    isActiveDeviceLog && console.log('active device', toJS(device));
  };

  setTooltipData = (data: N<ITooltipData>) => (this.tooltipData = data);

  setLayerTooltipData = (data: N<LayerTooltipData>) =>
    (this.layerTooltipData = data);

  get tipsValues(): TipsValues {
    return {
      interfaceTipsDelay: this.interfaceTipsDelay,
      mapTipsDelay: this.mapTipsDelay,
      mapButtonsTipsDelay: this.mapButtonsTipsDelay,
      profileTipsDelay: this.profileTipsDelay,
      gisTooltipsDelay: this.gisTooltipsDelay,
    };
  }

  get profileValues() {
    const {
      isShowPhaseCircle,
      isUnderCircleFill,
      isPhaseCircleLabel,
      phaseCircleDiameterRate,
    } = this.rootStore.pointsUdsStore;

    return {
      ...this.markers,
      isWidgetLoad: this.isWidgetLoad,
      isClusters: this.isClusters,
      isPhaseCircleLabel,
      isUnderCircleFill,
      isSummaryInfo: this.isSummaryInfo,
      mapIconsSize: this.mapIconsSize,
      phaseCircleDiameterRate,
      isShowPhaseCircle,
      //temporary delete because it works not so good
      // isCenterDependsOnPanels: this.isCenterDependsOnPanels,
      isDevMapInfo: this.isDevMapInfo,
      isStaticDataLog: this.isStaticDataLog,
      isZodLog: this.isZodLog,
      isVideoDtLinksLog: this.isVideoDtLinksLog,
      isIncorrectCoordinatesLog: this.isIncorrectCoordinatesLog,
      isActiveDeviceLog: this.isActiveDeviceLog,
      isQueryDevTools: this.isQueryDevTools,
      queryToolsBtnPosition: this.queryToolsBtnPosition,
      isMinificMap: this.isMinificMap,
      isScaleLine: this.isScaleLine,
      isAlarmAlert: this.isAlarmAlert,
      isOpenInfoPanel: this.isOpenInfoPanel,
      isInfoBtn: this.isInfoBtn,
      isNeedOpenedCluster: this.isNeedOpenedCluster,
      isSearchBtnGis: this.isSearchBtnGis,
      isLayersBtnGis: this.isLayersBtnGis,
      isMeasurementBtnGis: this.isMeasurementBtnGis,
      isBasemapBtnGis: this.isBasemapBtnGis,
      isMeteoDataBtnGis: this.isMeteoDataBtnGis,
      isTrafficBtnGis: this.isTrafficBtnGis,
      isTrafficGraphBtnGis: this.isTrafficGraphBtnGis,
    };
  }

  get secTL() {
    return this.secTls.length ? this.secTls[0].sec : 0;
  }

  // color theme
  get isDark() {
    return this.theme === 'dark';
  }

  setTheme = (theme: string) => {
    const { style } = document.body;

    THEME_PROPERTIES.forEach((property) => {
      style.setProperty(property, `var(${property}-${theme})`);
    });
    localStorage.setItem('theme', theme);
    this.theme = theme;
  };

  // panel state
  switchPanel = (id: PanelType) => {
    const { setInfoData, panelType } = this;
    const { isConstructorLoaded, setConstructorData }: ConstructorStore =
      this.rootStore.constructorStore;
    const { clearMonitoringStore } = this.rootStore.monitoringPanelStore;

    this.isModalLoading = false;
    this.externalResource = null;

    if (id === Constructor) {
      setInfoData(null);

      if (!isConstructorLoaded) {
        setTimeout(() => setConstructorData('isConstructorLoaded', true), 200);
      }
    }

    if (id === CoordManagement) {
      setInfoData(null);
    }

    if (id !== ModulesSubsystems) {
      clearMonitoringStore();
    }

    if (id === panelType) {
      this.isPanel = !this.isPanel;
    } else {
      this.isPanel = true;
      this.panelType = id;
    }
  };

  handlePopupFromTab = (id: number, system?: System) => {
    const { setCenteredDevice, getCoordinatesById } =
      this.rootStore.mapDataStore;
    const { isDetailedForm, setInfoData } = this;
    const coordinate = getCoordinatesById(id);

    if (!isNumber(id) || !coordinate || !system) return;

    if (isDetailedForm) {
      return setInfoData(
        {
          id,
          system,
          coordinate: toLonLat(coordinate),
        },
        NoActions
      );
    }

    setCenteredDevice(id, system);
  };

  get initialMapCenter() {
    if (!this.regionData) return;

    return fromLonLat(this.regionData.mapCenterCoords);
  }

  get isOffsetRightPanel() {
    return this.isRightPanel && !this.isCollapseRightPanel;
  }

  get isDeviceStatus() {
    return this.isPanel && this.panelType === ModulesSubsystems;
  }

  get identifiedItems() {
    return this.mapIdentifiedItems;
  }

  get isModuleDetailed() {
    const { isModulesSubsystemsDetailed } = this.rootStore.monitoringPanelStore;
    const { isVideoPanel } = this.rootStore.videoWallPanelStore;
    const { isReportsDetailed } = this.rootStore.reportsPanelStore;
    const { isAdminDetailed } = this.rootStore.adminPanelStore;
    const { isTestingPanel } = this.rootStore.testingPanelStore;

    return (
      isModulesSubsystemsDetailed ||
      isVideoPanel ||
      isReportsDetailed ||
      isAdminDetailed ||
      isTestingPanel
    );
  }
}

export default UIStore;
