import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { ReactComponent as InfoIcon } from '../../../../../assets/icons/confirm_icon.svg';
import rootStore from '../../../../../stores/rootStore/rootStore';
import Img from '../../../../ui-kit/Img/Img';
import useModalPhaseHold from '../hooks/useModalPhaseHold';
import ModalBody from '../ModalBody/ModalBody';
import BodyContent from '../ModalConfirm/BodyContent/BodyContent';
import ModalConfirm from '../ModalConfirm/ModalConfirm';
import { ModalPhaseProps } from '../PhaseHold.model';

import styles from './ModalPhase.module.scss';

const ModalPhase: FC<ModalPhaseProps> = ({
  activeTL,
  isChecked,
  selectedTimeInfo,
  setSelectedTimeInfo,
  setIsChecked,
}) => {
  const { selectedPhase } = rootStore.detailedStore;

  const { imgRef, phaseContentModal, modalConfirmProps } = useModalPhaseHold({
    activeTL,
    isChecked,
    selectedTimeInfo,
    setSelectedTimeInfo,
    setIsChecked,
  });

  const title = `Подтверждаете включение режима "${
    !selectedTimeInfo.duration ? 'удержание фазы' : 'удержание фазы по времени'
  }"?`;

  const modalBodyProps = {
    content: (
      <BodyContent
        title={<strong>{title}</strong>}
        content={phaseContentModal}
      />
    ),
    image: (
      <Img
        src={imgRef.current}
        alt={`Фаза №${selectedPhase}`}
        className={styles.imgConfirm}
        isShowIcons
      />
    ),
  };

  return (
    <ModalConfirm
      {...modalConfirmProps}
      icon={<InfoIcon className={styles.iconInfo} />}
    >
      <ModalBody {...modalBodyProps} />
    </ModalConfirm>
  );
};

export default observer(ModalPhase);
