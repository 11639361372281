import { Popconfirm } from 'antd';
import { ButtonProps } from 'antd/lib';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { FC, ReactNode } from 'react';

export type ConfirmProps = Omit<ConfirmComponentProps, 'children'>;

interface ConfirmComponentProps {
  onConfirm?: (e?: React.MouseEvent<HTMLElement>) => unknown;
  onOpenChange?: (open: boolean) => void;
  children: JSX.Element;
  title?: ReactNode;
  disabled?: boolean;
  okButtonProps?: ButtonProps;
  okText?: string;
  cancelText?: string;
  overlayClassName?: string;
  isOpen?: boolean;
  onCancel?: () => void;
  placement?: TooltipPlacement;
}

const Confirm: FC<ConfirmComponentProps> = ({
  onConfirm,
  onOpenChange,
  children,
  title = 'Вы уверены?',
  disabled = false,
  okButtonProps,
  cancelText = 'Нет',
  okText = 'Да',
  overlayClassName,
  isOpen,
  onCancel,
  placement = 'top',
}) => (
  <Popconfirm
    title={title}
    cancelText={cancelText}
    okText={okText}
    onConfirm={onConfirm}
    disabled={disabled}
    okButtonProps={okButtonProps}
    onOpenChange={onOpenChange}
    overlayClassName={overlayClassName}
    open={isOpen}
    onCancel={onCancel}
    placement={placement}
  >
    {children}
  </Popconfirm>
);

export default Confirm;
