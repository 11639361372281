import { notification } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';

import { COMMANDS } from '../../../../constants/commands';
import { TIME_FORMAT } from '../../../../constants/constants';
import NOTICE from '../../../../constants/notificationConstants';
import { getTimeWithTimeZone } from '../../TlDetectors/IntancyGraph/TitleBtns/TimeLocalCard/helpers/getTimeWithTimeZone';
import { COMMANDS_VALUES } from '../constants/constants';
import { TParam } from '../model/postCommands.model';

const {
  SET_OBJECT_PLAN_TIME_ON_SCHEDULER,
  SET_OBJECT_PHASE,
  SET_OBJECT_SYNC_PLAN,
} = COMMANDS;

interface IParams {
  param2: TParam;
  param3: TParam;
}

export const validatePostCommand = (
  { param2, param3 }: IParams,
  cmd: keyof typeof COMMANDS_VALUES,
  disabledTime = 0
) => {
  const notifyMessage = {
    ...NOTICE.DATE_ERROR_PLAN,
    description: `Включение режима ${COMMANDS_VALUES[cmd]} доступно не раньше`,
  };

  let isValid = true;
  let selectedDate = 0;
  const dateNowJS = getTimeWithTimeZone().dateFormattedJS;
  const dateNow = dateNowJS.format(TIME_FORMAT);

  switch (cmd) {
    case SET_OBJECT_PHASE:
      selectedDate = Number(param2);
      break;
    case SET_OBJECT_PLAN_TIME_ON_SCHEDULER:
      selectedDate = Number(param3);
      break;
    case SET_OBJECT_SYNC_PLAN:
      selectedDate = Number(param2);
      break;
  }

  const normalDate = dayjs(Number(selectedDate) * 1000);

  if (!disabledTime && selectedDate && !dateNowJS.isBefore(normalDate)) {
    notifyMessage.description += ` текущего времени (${dateNow})`;
    isValid = false;
  }

  if (
    disabledTime &&
    !dateNowJS.add(disabledTime, 'minutes').isBefore(normalDate)
  ) {
    notifyMessage.description += `, чем через ${disabledTime} минут`;
    isValid = false;
  }

  !isValid && notification.error(notifyMessage);

  return isValid;
};
