import ArrowCustom from '../../../../../../../../TrafficLightDetailed/TlManagement/PhaseHold/Arrows/ArrowCustom';
import DotsCustom from '../../../../../../../../TrafficLightDetailed/TlManagement/PhaseHold/DotsCustom/DotsCustom';

export const SLIDER_SETTINGS = {
  infinite: false,
  draggable: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  swipeToSlide: true,
  dots: true,
  nextArrow: <ArrowCustom />,
  prevArrow: <ArrowCustom />,
  customPaging: () => <DotsCustom />,
};
