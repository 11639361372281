import { AdapterKeys } from '../models/monitoring.models';

export const STATUS_ADAPTER = {
  OK: 'OK',
  ERROR: 'ERROR',
  WARNING: 'WARNING',
  TERMINATED: 'TERMINATED',
  SIGTERM: 'SIGTERM',
} as const;

export const ADAPTER_LABELS: Record<AdapterKeys, string> = {
  systemId: 'Id системы',
  systemName: 'Название системы',
  regions: 'Регионы',
  hostName: 'Название хоста, откуда запускается ВМ',
  sessionUid: 'Уникальный идентификатор сессии',
  workTime: 'Время работы',
  status: 'Статус работы',
  passesDtFromStart: 'Получено проездов (с начала работы)',
  passesDtFrom10Min: 'Получено проездов за 10 минут',
  passesTLFromStart: 'Получено событий (с начала работы)',
  passesTLFrom10Min: 'Получено событий за 10 минут',
  lastTime: 'Время формирования сообщения с таймзоной, сек',
  error: 'Информация об ошибке',
};
