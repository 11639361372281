import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import rootStore from '../../../../stores/rootStore/rootStore';
import Cameras from '../../Cameras/Cameras';
import LinkedTLanes from '../../PointUdsOverlay/LinkedTLanes';
import { PointUdsProps } from '../PhaseCircleOverlay.model';

import LinkedDetectors from './LinkedDetectors';

const LinkedDevices: FC<PointUdsProps> = ({ pointUds }) => {
  const { isCrossroadBorder } = rootStore.mapDataStore;

  const { schemaODD, linkedDeviceIds } = pointUds.basicProfileInfo;

  const { cameras: cmIds, detectors: dtIds } = linkedDeviceIds;

  if (!isCrossroadBorder) return null;

  return (
    <>
      <Cameras linkedCams={cmIds ?? []} />
      <LinkedDetectors dtIds={dtIds} isSchemaODD={Boolean(schemaODD)} />
      <LinkedTLanes pointUds={pointUds} />
    </>
  );
};

export default observer(LinkedDevices);
