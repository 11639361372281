import { Descriptions } from 'antd';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { System } from '../../../../../../../../ts/enums/enums';

import getDescriptionInfo from './helpers/getDescriptionItems';

import styles from './Description.module.scss';

interface DescriptionProps {
  id: number;
  system: System;
  isActive: boolean;
  address: Nullish<string>;
  addressLoading: boolean;
}

const Description: FC<DescriptionProps> = ({
  id,
  system,
  isActive,
  address,
  addressLoading,
}) => {
  const items = getDescriptionInfo(id, system, address, addressLoading);

  const descriptionStyle = classNames(styles.description, {
    [styles.active]: isActive,
  });

  return (
    <Descriptions
      items={items}
      layout="vertical"
      className={descriptionStyle}
    />
  );
};

export default observer(Description);
