import classNames from 'classnames';
import { FC } from 'react';

import { TDeviceStatus } from '../../../../../../../../constants/devicesConstants';
import DeviceIcon from '../../DeviceIcon/DeviceIcon';

import styles from './DeviceAvatar.module.scss';

interface DeviceAvatarProps {
  id: number;
  status: TDeviceStatus;
  iconSystem: JSX.Element;
  isActive: boolean;
}

const DeviceAvatar: FC<DeviceAvatarProps> = ({
  id,
  status,
  iconSystem,
  isActive,
}) => {
  return (
    <div className={styles.avatar}>
      <DeviceIcon status={status} iconSystem={iconSystem} />
      <p className={classNames(styles.id, { [styles.active]: isActive })}>
        {id}
      </p>
    </div>
  );
};

export default DeviceAvatar;
