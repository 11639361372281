import { uniqueId } from 'lodash';
import React, { FC } from 'react';

import { FilesDataType } from '../../../models/documents.model';
import DocumentsItem from '../DocumentsItem/DocumentsItem';

import styles from './FileList.module.scss';

interface IFileList {
  fileListItem: FilesDataType;
}

const FileList: FC<IFileList> = ({ fileListItem }) => {
  if (!fileListItem.children)
    return <p className={styles.text}>Файлы отсутствуют</p>;

  return (
    <ul className={styles.wrapper}>
      {fileListItem.children.map((file) => (
        <DocumentsItem key={uniqueId('#')} file={file} />
      ))}
    </ul>
  );
};

export default FileList;
