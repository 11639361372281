import { ActualMapObj } from '../../../../ts/enums/enums';
import { DeviceTypeId, DevicesList } from '../PointUdsOverlays.model';

export const POINT_UDS_DISTANCE = 0.002; // it's about 200 meters

export const DEVICE_TYPE_IDS: DeviceTypeId[] = [2, 3, 4, 6];

export const DEVICE_BY_TYPE_ID: Record<DeviceTypeId, ActualMapObj> = {
  2: ActualMapObj.tls,
  3: ActualMapObj.cameras,
  4: ActualMapObj.detectors,
  6: ActualMapObj.meteo,
};

export const DEVICES_EMPTY: DevicesList = {
  tlsList: [],
  cmsList: [],
  dtsList: [],
  mtsList: [],
};

export const DEVICE_TYPE: Record<DeviceTypeId, string> = {
  2: 'trafficLight',
  3: 'camera',
  4: 'detector',
  6: 'meteo',
};

export const BROKEN_LINKS_NOT_FOUND =
  'Devices with broken links are not found.';
