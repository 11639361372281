import { Select } from 'antd';
import { FC } from 'react';

import { filterOption } from '../../../../helpers/antd';
import rootStore from '../../../../stores/rootStore/rootStore';
import { Content } from '../../../ui-kit/Popover/Popover';
import { COLORS_LIST } from '../../constants/constants';
import Item from '../Item/Item';

interface ColorProps {
  label?: string;
  color: Nullish<string>;
  colorsList?: typeof COLORS_LIST;
  onChange: (val: string) => void;
  isDisabled?: boolean;
  content?: Content;
}

const Color: FC<ColorProps> = ({
  label = 'Цвет элемента:',
  onChange,
  color,
  colorsList = COLORS_LIST,
  isDisabled,
  content,
}) => {
  const { interfaceTipsDelay } = rootStore.uiStore;

  return (
    <Item label={label} tipsDelay={interfaceTipsDelay} content={content}>
      <Select
        onChange={onChange}
        value={color}
        options={colorsList}
        showSearch
        filterOption={filterOption}
        disabled={isDisabled}
      />
    </Item>
  );
};

export default Color;
