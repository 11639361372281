import { observer } from 'mobx-react-lite';

import { findById } from '../../../../helpers/findBy';
import rootStore from '../../../../stores/rootStore/rootStore';
import { getCameraInfo } from '../../../VideoCamera/helpers/getCameraInfo/getCameraInfo';
import VideoCamera from '../../../VideoCamera/VideoCamera';

import CamerasDetailedWrapper from './CamerasDetailedWrapper/CamerasDetailedWrapper';

const CamerasDetailed = () => {
  const { sortedCameras, view } = rootStore.constructorStore;
  const { cameras } = rootStore.mapDataStore;

  const isAnyEnabled = sortedCameras.some(({ isEnabled }) => isEnabled);

  if (!view.isCameras || !isAnyEnabled) {
    return <h3>Не выбраны камеры для отображения</h3>;
  }

  return (
    <>
      {sortedCameras.map(({ id, caption, isEnabled }) => {
        if (!isEnabled) return null;

        const camera = findById(cameras, id);
        const wrapperProps = { id, caption };

        if (!camera?.srcId) {
          return (
            <CamerasDetailedWrapper key={id} {...wrapperProps}>
              <p> Отсутствует источник видео</p>
            </CamerasDetailedWrapper>
          );
        }

        const cameraData = getCameraInfo(camera);

        return (
          <CamerasDetailedWrapper key={id} {...wrapperProps}>
            <VideoCamera cameraData={cameraData} />
          </CamerasDetailedWrapper>
        );
      })}
    </>
  );
};

export default observer(CamerasDetailed);
