import { QueryClientProvider as Provider } from '@tanstack/react-query';
import { FC, ReactNode } from 'react';

import queryClient from './queryClient';

interface QueryClientProps {
  children: JSX.Element | ReactNode;
}

const QueryClientProvider: FC<QueryClientProps> = ({ children }) => (
  <Provider client={queryClient}>{children}</Provider>
);

export default QueryClientProvider;
