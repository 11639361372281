import { Icon, Stroke, Style } from 'ol/style';

import locationIcon from '../../../assets/icons/map/location.svg';
import {
  COLOR_GIS_DIRECTION_RED,
  COLOR_GIS_WHITE,
} from '../../constants/colors';
import {
  DEFAULT_IMAGE_ANCHOR,
  DEFAULT_IMAGE_SCALE,
  DIRECTION_CONSTANTS,
} from '../../constants/styles';

export const ICON_STYLE = new Icon({
  src: locationIcon,
  scale: DEFAULT_IMAGE_SCALE,
  anchorXUnits: 'fraction',
  anchorYUnits: 'fraction',
  anchor: DEFAULT_IMAGE_ANCHOR,
});

export const SEARCH_ICON_STYLE = new Icon({
  src: locationIcon,
  scale: DEFAULT_IMAGE_SCALE,
  anchorXUnits: 'fraction',
  anchorYUnits: 'fraction',
  anchor: DEFAULT_IMAGE_ANCHOR,
  color: 'rgba(255, 255, 255, 0.5)',
});

export const DIRECTION_ROUTE_STYLE = [
  new Style({
    stroke: new Stroke({
      width: DIRECTION_CONSTANTS.WIDTH_MAIN_LINE,
      color: COLOR_GIS_WHITE,
    }),
  }),
  new Style({
    stroke: new Stroke({
      width: DIRECTION_CONSTANTS.WIDTH_SECONDARY_LINE,
      color: COLOR_GIS_DIRECTION_RED,
    }),
  }),
];

export const DIRECTION_ROUTE_PASS_STYLE = [
  new Style({
    stroke: new Stroke({
      width: DIRECTION_CONSTANTS.WIDTH_OVERPASS_LINE,
      color: COLOR_GIS_WHITE,
    }),
  }),
  new Style({
    stroke: new Stroke({
      width: DIRECTION_CONSTANTS.WIDTH_OVERPASS_SECONDARY_LINE,
      color: COLOR_GIS_DIRECTION_RED,
      lineDash: DIRECTION_CONSTANTS.LINE_DASH_OVERPASS_LINE,
    }),
  }),
];
