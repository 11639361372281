import { Button } from 'antd';
import React, { FC, useEffect, useState } from 'react';

import { getReportServerStatus } from '../../../../../helpers/export/helpers';
import { FileExtensions } from '../../../../../ts/models/table.model';
import { OPTION_BUTTON_OPTIONS } from '../constants';
import { IChangeExportType } from '../model';

import styles from './ChangeExportType.module.scss';

const ChangeExportType: FC<IChangeExportType> = ({ onChange, value }) => {
  const [hasService, setHasService] = useState(false);

  const [options, setOptions] = useState<FileExtensions[]>([]);

  useEffect(() => {
    getReportServerStatus().then((status) => {
      setHasService(status);
    });
  }, [options]);

  useEffect(() => {
    if (hasService) {
      setOptions([
        FileExtensions.xlsx,
        FileExtensions.xlsxService,
        FileExtensions.csv,
      ]);
    } else {
      setOptions([FileExtensions.xlsx, FileExtensions.csv]);
    }
  }, [hasService]);

  return (
    <div className={styles.optionsContainer}>
      {options.map((option, index) => {
        return (
          <Button
            key={option}
            className={
              option === value ? styles.optionButtonActive : styles.optionButton
            }
            size={OPTION_BUTTON_OPTIONS.size}
            type={OPTION_BUTTON_OPTIONS.type}
            onClick={() => {
              onChange(option);
            }}
          >
            {option}
          </Button>
        );
      })}
    </div>
  );
};

export default ChangeExportType;
