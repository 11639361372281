import { Checkbox, DatePicker } from 'antd';
import type { DatePickerProps } from 'antd/lib';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import rootStore from '../../../../../../stores/rootStore/rootStore';
import TimePicker, {
  TimePickerBasicProps,
} from '../../../../../ui-kit/Picker/TimePicker/TimePicker';
import Popover from '../../../../../ui-kit/Popover/Popover';

import styles from './NecessarilyDuration.module.scss';

interface NecessarilyDurationProps {
  checked?: boolean;
  isActiveBtn: boolean;
  setChecked?: SetState<boolean>;
  timePickerProps: TimePickerBasicProps;
  datePickerProps: DatePickerProps;
}

const NecessarilyDuration: FC<NecessarilyDurationProps> = ({
  setChecked,
  checked,
  isActiveBtn,
  timePickerProps,
  datePickerProps,
}) => {
  const { tipsValues } = rootStore.uiStore;

  const obligatoryFieldProps = {
    content: 'Обязательное поле для заполнения',
    placement: 'right',
    tipsDelay: tipsValues.profileTipsDelay,
  } as const;

  return (
    <div className={styles.timePickerCard}>
      <div className={styles.rowContainer}>
        <Popover {...obligatoryFieldProps}>
          <div className={styles.spaceHorizontalDuration}>
            <h4>Длительность: *</h4>
            <TimePicker {...timePickerProps} disabled={!isActiveBtn} />
          </div>
        </Popover>
        <Checkbox
          checked={checked}
          disabled={!isActiveBtn}
          onChange={(e: CheckboxChangeEvent) =>
            setChecked && setChecked(e.target.checked)
          }
        >
          <h4
            className={classNames({
              [styles.labelCheckBoxDisabled]: !isActiveBtn,
            })}
          >
            Включение плана ДК в определенное время
          </h4>
        </Checkbox>
      </div>
      {checked && (
        <div className={styles.timeCardStyle}>
          <div className={styles.spaceHorizontalDuration}>
            <h4>Дата:</h4>
            <DatePicker {...datePickerProps} />
          </div>
        </div>
      )}
    </div>
  );
};

export default observer(NecessarilyDuration);
