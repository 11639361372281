import { CONTROL_ACCESS_DEVICES } from '../../../../../../../../constants/constants';
import { findById } from '../../../../../../../../helpers/findBy';
import { TL } from '../../../../../../../../ts/models/tl.model';
import { ONLY_READ_MODE } from '../../../../../../../TrafficLightDetailed/TlManagement/StatusCard/ActiveButtonWrapper/helpers/getTooltipContent';
import { IGroupsInfo } from '../../../../../model/coordControl.model';

const { ReadAndControl } = CONTROL_ACCESS_DEVICES;

const getIsTLOnStartedGroup = (coordGroups: IGroupsInfo[], tlId: number) => {
  return coordGroups.some(
    ({ tlsIds, isStarted }) => isStarted && tlsIds.some((id) => id === tlId)
  );
};

export const getTLControlInfo = (
  coordGroups: IGroupsInfo[],
  tls: TL[],
  tlId: number
) => {
  const isActiveCoordTL = getIsTLOnStartedGroup(coordGroups, tlId);
  const tl = findById(tls, tlId);

  if (!tl) return null;

  const { deviceInfo } = tl;

  const isAccessMode = deviceInfo?.controlAccess === ReadAndControl;

  const warningText = isAccessMode ? undefined : ONLY_READ_MODE;

  return { isActiveCoordTL, warningText, isAccessMode };
};
