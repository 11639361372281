import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import rootStore from '../../../../../../../stores/rootStore/rootStore';
import WarningNotify from '../../../../../../TrafficLightDetailed/TlManagement/StatusCard/WarningNotify/WarningNotify';

import ControlPanel from './ConrolPanel/ControlPanel';
import { getTLControlInfo } from './helpers/helpers';
import PhasesSlider from './PhasesSlider/PhasesSlider';

import styles from './ControlCard.module.scss';

interface ControlCardProps {
  tlId: number;
}

const ControlCard: FC<ControlCardProps> = ({ tlId }) => {
  const { tls } = rootStore.mapDataStore;
  const { coordGroups } = rootStore.scriptsControlStore;

  const tlControlInfo = getTLControlInfo(coordGroups, tls, tlId);

  if (!tlControlInfo) return null;

  const { isActiveCoordTL, warningText, isAccessMode } = tlControlInfo;

  return (
    <div className={styles.container}>
      <PhasesSlider tlId={tlId} />
      <ControlPanel tlId={tlId} isActiveCoordTL={isActiveCoordTL} />
      <WarningNotify
        isActiveCoordTL={isActiveCoordTL || !isAccessMode}
        warningTitle={warningText}
      />
    </div>
  );
};

export default observer(ControlCard);
