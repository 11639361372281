import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { DEFAULT_CIRCLE } from '../../../../constants/constructorConstants';
import rootStore from '../../../../stores/rootStore/rootStore';
import { useTLTacts, usePhaseData, useFormattedData } from '../../hooks';
import getPhaseNumber from '../helpers/getPhaseNumber';
import PhaseCircle from '../PhaseCircle/PhaseCircle';
import PhaseCircleEmpty from '../PhaseCircleEmpty/PhaseCircleEmpty';
import PhaseCircleHeader from '../PhaseCircleHeader/PhaseCircleHeader';
import PhaseCircleItems from '../PhaseCircleItems/PhaseCircleItems';
import PhaseCircleLayout from '../PhaseCircleLayout/PhaseCircleLayout';
import { IPhaseCircleOverlay } from '../PhaseCircleOverlay.model';

const { BORDER_WIDTH } = DEFAULT_CIRCLE;

const PhaseCircleMain: FC<IPhaseCircleOverlay> = ({
  selectedTL,
  color,
  pointUds,
}) => {
  const { secTls } = rootStore.uiStore;
  const { currentZoom } = rootStore.mapDataStore;

  const { id, tlStatus } = selectedTL;
  const len = tlStatus?.cycle;
  const cmd = tlStatus?.command?.full;
  const sec = secTls.find((item) => item.id === id)?.sec ?? 0;

  const [tlTacts, tlZoomTact, tlTactImages] = useTLTacts({
    selectedTL,
    currentZoom,
  });

  const formattedData = useFormattedData({ tlTacts });
  const phaseData = usePhaseData({ timer: sec, formattedData });

  if (
    !len ||
    !tlZoomTact ||
    phaseData?.phase === undefined ||
    !formattedData ||
    !pointUds
  ) {
    return <PhaseCircleEmpty selectedTL={selectedTL} color={color} isNoData />;
  }

  const { schemaODD } = pointUds.basicProfileInfo;
  const invertedTLTacts = [...tlTacts].reverse();
  const phaseNumber = getPhaseNumber(phaseData.phase + 1);
  const phaseIdx = phaseData.phase;
  const restTime = (formattedData[phaseIdx]?.stop ?? sec) - sec;
  const circleBorderWidth = tlZoomTact.borderWidth ?? BORDER_WIDTH;

  const phaseCircleProps = {
    timer: sec,
    len,
    circleBorderWidth,
    data: invertedTLTacts,
    color: phaseData.color,
  };

  const phaseCircleHeaderProps = {
    len,
    phaseNumber,
    restTime,
    amount: tlTacts.length,
    color,
    cmd,
  };

  const itemsProps = {
    tlTactImages,
    tlTacts,
    phaseIdx,
    schemaODD,
  };

  return (
    <PhaseCircleLayout id={id}>
      <PhaseCircleItems {...itemsProps} />
      <PhaseCircle {...phaseCircleProps} />
      <PhaseCircleHeader {...phaseCircleHeaderProps} />
    </PhaseCircleLayout>
  );
};

export default observer(PhaseCircleMain);
