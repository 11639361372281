import { useEffect, useMemo, useState } from 'react';

import {
  COMMANDS,
  MODES_INFO,
} from '../../../../../../../../../constants/commands';
import checkIsTLValid from '../../../../../../../../../helpers/checkIsTLValid';
import { findById } from '../../../../../../../../../helpers/findBy';
import useCircleTimer from '../../../../../../../../../hooks/useCircleTimer';
import { ICategory } from '../../../../../../../../../stores/channelsDataStore/channelsDataStore';
import { ChanelTypes, System } from '../../../../../../../../../ts/enums/enums';
import { ITimePoints } from '../../../../../../../../../ts/models/signalPrograms.model';
import { TL } from '../../../../../../../../../ts/models/tl.model';
import getCsdData from '../../../../../../../../NewSignalProgram/helpers/getCsdData';
import { getPhases } from '../../../../../../../../SignalProgramsBand/helpers/getPhases';
import useChannel from '../../../../../../../../Socket/useChannel';
import { WSData } from '../../../../../../../../TLTimeSecWrapper/TLTimeSec/TLTimeSec';
import { postCommand } from '../../../../../../../../TrafficLightDetailed/TlManagement/helpers/postCommand';
import { IPostCommandProps } from '../../../../../../../../TrafficLightDetailed/TlManagement/model/postCommands.model';

const { SET_OBJECT_PHASE_NOW } = COMMANDS;
const { RemoteControl } = MODES_INFO;
const APP_PATH =
  '.Panel.ScriptsControl.SelectModule.DispatchGroupControl.PhasesSlider';

interface UsePhaseProps {
  tls: TL[];
  tlId: number;
  categories: ICategory[];
}

const usePhase = ({ categories, tlId, tls }: UsePhaseProps) => {
  const [phasesArray, setPhasesArray] = useState<ITimePoints[]>([]);
  const [tactsArray, setTactsArray] = useState<ITimePoints[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoadingRequest, setIsLoadingRequest] = useState<boolean>(false);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [selectedPhase, setSelectedPhase] = useState<N<number>>(null);

  const clearStates = () => {
    setIsLoadingRequest(false);
    setIsOpenConfirm((prev) => !prev);
    setSelectedPhase(null);
  };

  const propsPostCommand: IPostCommandProps = {
    id: tlId,
    command: selectedPhase,
    cmdTitle: SET_OBJECT_PHASE_NOW,
    cmdInfo: `Фаза №${selectedPhase} будет удержана`,
  };

  const handlePostCommand = async () => {
    setIsLoadingRequest(true);

    await postCommand(propsPostCommand, APP_PATH);

    clearStates();
  };

  const { activeTL, isTLValid } = useMemo(() => {
    const activeTL = findById(tls, tlId);

    const isTLValid = !!activeTL && checkIsTLValid(activeTL, System.Lights);

    return { activeTL, isTLValid };
  }, [tlId, tls]);

  const wsData: N<WSData> = useChannel(
    categories,
    ChanelTypes.TlCurrentTacts,
    tlId ?? 0,
    true
  );

  const sec = useCircleTimer(activeTL?.tlStatus, tactsArray, wsData);

  useEffect(() => {
    if (!isTLValid) return setIsError(true);

    setIsError(false);

    const res = getCsdData({
      id: tlId,
      isStatic: false,
      tlsData: tls,
    });

    if (!res?.timePoints.length) return setIsError(true);

    const { timePoints } = res;

    setTactsArray(timePoints);

    const phasesArray = getPhases(timePoints);

    setPhasesArray(phasesArray);
  }, [tlId, tls, isTLValid]);

  useEffect(() => {
    clearStates();
  }, [activeTL?.tlStatus?.mode, activeTL?.deviceInfo?.controlAccess]);

  const isDisabled = activeTL?.tlStatus.mode !== RemoteControl.com;

  return {
    activeTL,
    phasesArray,
    isError,
    sec,
    isDisabled,
    isLoadingRequest,
    isOpenConfirm,
    setSelectedPhase,
    handlePostCommand,
  };
};

export default usePhase;
