import { Card } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import CircleChart from '../../../ui-kit/CircleChart/CircleChart';
import DefaultChecked from '../../../ui-kit/DefaultChecked/DefaultChecked';

import useAdaptersCard from './hooks/useAdaptersCard';

import styles from './AdaptersCard.module.scss';

interface AdaptersCardProps {
  title: string;
}

const AdaptersCard: FC<AdaptersCardProps> = ({ title }) => {
  const { dataPieChart, isError, isLoading } = useAdaptersCard();

  return (
    <Card className={styles.container}>
      <h3 className={styles.title}>{title}:</h3>
      <DefaultChecked
        isEmptyData={isError || !dataPieChart}
        isLoading={isLoading}
        noDataProps={{ isNeedBackground: false }}
      >
        {dataPieChart ? (
          <>
            <div className={styles.chartContainer}>
              <CircleChart
                data={dataPieChart.data}
                colors={dataPieChart.colors}
                isLabel={false}
              />
            </div>
            <p className={styles.footer}>
              Кол-во адаптеров: {dataPieChart.total}
            </p>
          </>
        ) : null}
      </DefaultChecked>
    </Card>
  );
};

export default observer(AdaptersCard);
