import { z } from 'zod';

import { Path } from '../../../../api/api.model';
import plannedCommandsApi from '../../../../api/mapObjects/trafficLights/commands/plannedCommands';
import { PlannedCommandsDataSchema } from '../../../../api/mapObjects/trafficLights/commands/plannedCommands.zod';
import { getDate } from '../../TlManagement/helpers/getDate';

interface IGetPlannedCommandsArray {
  id: number;
  dateIndexFrom?: number;
  dateIndexTo?: number;
  selectedDate?: string[];
  appPath: Path;
  isBlockedNotify?: boolean;
}

export type TPlannedCommands = z.infer<typeof PlannedCommandsDataSchema>;

export const getPlannedCommandsArray = async ({
  id,
  dateIndexFrom = -1,
  dateIndexTo = 1,
  selectedDate,
  appPath,
  isBlockedNotify,
}: IGetPlannedCommandsArray) => {
  const dateFrom = getDate(dateIndexFrom);
  const dateTo = getDate(dateIndexTo);

  const selectedDateDefault: string[] = [dateFrom, dateTo];

  const select = !selectedDate?.length ? selectedDateDefault : selectedDate;

  const res = await plannedCommandsApi.getPlannedCommands(
    id,
    select,
    appPath,
    isBlockedNotify
  );

  return res;
};
