import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { ReactComponent as InfoIcon } from '../../../../../../assets/icons/confirm_icon.svg';
import ModalBody from '../../../PhaseHold/ModalBody/ModalBody';
import ModalConfirm from '../../../PhaseHold/ModalConfirm/ModalConfirm';
import { UseConfirmCurrentPlanProps } from '../../model/currentPlan.model';

import useConfirmCurrentPlan from './useConfirmCurrentPlan';

import styles from './Confirm.module.scss';

const Confirm: FC<UseConfirmCurrentPlanProps> = (props) => {
  const { modalBodyProps, modalConfirmProps } = useConfirmCurrentPlan(props);

  return (
    <ModalConfirm
      {...modalConfirmProps}
      icon={<InfoIcon className={styles.iconInfo} />}
    >
      <ModalBody {...modalBodyProps} />
    </ModalConfirm>
  );
};

export default observer(Confirm);
