import { observer } from 'mobx-react-lite';

import rootStore from '../../../../../../stores/rootStore/rootStore';

import TLItem from './TLItem/TLItem';

import styles from './DispatchGroupDetailed.module.scss';

const DispatchGroupDetailed = () => {
  const { selectedFeatureSomeArray } = rootStore.scriptsControlStore;

  return (
    <div className={styles.container}>
      {[...selectedFeatureSomeArray].reverse().map(({ id }) => (
        <TLItem key={id} id={id} />
      ))}
    </div>
  );
};

export default observer(DispatchGroupDetailed);
