import packageJson from '../../package.json';
import { MarkersKeys } from '../stores/uiStore/uiStore.model';
import { Components, Events, Measurement, System } from '../ts/enums/enums';
import { Role } from '../ts/enums/userData';
import { ColumnTypes, ITableColumn } from '../ts/models/table.model';

import { CONFIG } from './configConstants';
import { ZOOM } from './mapConstants';
import {
  COMMANDS_MODE_ERRORS,
  COMMANDS_MODE_NOT_ACTIVE,
  COMMANDS_MODE_REMOTE_CONTROL,
  COMMANDS_MODE_WORK,
} from './tlStatusConstants';
import { VERSION } from './versions';

export const TRANSITION_TIME = 500;
export const SECOND = 1000;
export const MINUTE = 60 * SECOND;
export const END_TIME = 'Время завершения не установлено';

export const YEAR_FORMAT = 'YYYY';
export const WEEK_FORMAT = `WW.${YEAR_FORMAT}`;
export const MONTH_FORMAT = `MM.${YEAR_FORMAT}`;
export const TIME_FORMAT = 'HH:mm:ss';
export const DATE_DEFAULT_FORMAT = `DD.MM.${YEAR_FORMAT}`;
export const DATE_FORMAT = `${DATE_DEFAULT_FORMAT}, ${TIME_FORMAT}`;
export const DATE_FORMAT_TO_SHOW = `${DATE_DEFAULT_FORMAT} ${TIME_FORMAT}`;

export const LOCAL_STORAGE_REFRESH_TOKEN_KEY = 'refresh';

export const HOUR = 60 * MINUTE;
export const UPDATE_WEATHER_TIME = 15 * MINUTE;
export const DEFAULT_MAP_ICON_SIZE = 0.85;
export const AUTO_UPDATE_STATIC_COMPONENTS_TIME = 5 * SECOND;

export const INPUT_MAX_LEN = 50;

export const DAY_NAMES = ['ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ'];

export const COPYRIGHT = 'ООО «ОБЪЕДИНЕННЫЕ СИСТЕМЫ УПРАВЛЕНИЯ ТРАНСПОРТОМ»';

export const ALL_RIGHTS_RESERVED = 'Все права защищены';

export const TABLE_CSV_SEPARATOR = '_$_';

export const SIZE = { CHART: 360 };

const { CROSSROAD_BORDER, MAX, STEP_CONSTRUCTOR } = ZOOM;

export const TL_CIRCLE_ZOOM = {
  START: CROSSROAD_BORDER,
  END: MAX + STEP_CONSTRUCTOR,
  STEP: STEP_CONSTRUCTOR,
};

export const THEME = {
  LIGHT: 'light',
  DARK: 'dark',
};

export const THEME_PROPERTIES = [
  '--bg',
  '--bg-tint',
  '--bg-tint-contrast',
  '--bg-light-scheme',
  '--font-color',
  '--font-color-scheme',
  '--font-color-lite',
  '--font-primary',
  '--sidebar-btn',
  '--sidebar-profile',
  '--logo-sign-itc',
  '--logo-sign-sber',
  '--disabled',
  '--disabled-tint',
  '--disabled-text',
  '--disabled-with-bg',
  '--primary-log',
  '--success-log',
  '--warning-log',
  '--json-key',
  '--tooltip',
  '--hover-btn',
  '--disabled-icon',
];

export const SYSTEM: Record<string, System> = {
  LIGHTS: System.Lights,
  DETECTORS: System.Detectors,
  CAMERAS: System.Cameras,
  SPECIAL_TRANSPORT: System.SpecialTransport,
  PUBLIC_TRANSPORT: System.PublicTransport,
  METEO: System.Meteo,
};

export const MEASUREMENT: Record<string, Measurement> = {
  TRAFFIC: Measurement.Traffic,
};

export const COMPONENTS = {
  BASEMAPS: Components.Basemaps,
};

export const EVENTS: Record<keyof typeof Events, Events> = {
  RTA: Events.RTA,
  ROAD_WORKS: Events.ROAD_WORKS,
  ACTIVITIES: Events.ACTIVITIES,
  TRAFFIC_RESTRICTIONS: Events.TRAFFIC_RESTRICTIONS,
};

export type MarkersDictKeys = System | Events | Measurement;

export const MARKERS_DICT: Record<MarkersDictKeys, MarkersKeys> = {
  [System.Lights]: MarkersKeys.IsLights,
  [System.Detectors]: MarkersKeys.IsDetectors,
  [System.Cameras]: MarkersKeys.IsCameras,
  [System.SpecialTransport]: MarkersKeys.IsSpecialTransport,
  [System.PublicTransport]: MarkersKeys.IsPublicTransport,
  [System.Meteo]: MarkersKeys.IsMeteo,
  [Events.RTA]: MarkersKeys.IsRta,
  [Events.ROAD_WORKS]: MarkersKeys.IsRoadWorks,
  [Events.ACTIVITIES]: MarkersKeys.IsActivities,
  [Events.TRAFFIC_RESTRICTIONS]: MarkersKeys.IsTrafficRestrictions,
  [Measurement.Traffic]: MarkersKeys.IsTraffic,
};

export const DEVICES: Record<string, string> = {
  ...SYSTEM,
  UNKNOWN: 'Не определено',
  CAMERA_PTZ: 'Видеокамера уличная поворотная (PTZ)',
  PHOTO_VIDEO_FVF: 'Фотовидеофиксация Комплекс фотовидеофиксации  ФВФ',
  DIT: 'Динамическое информационное табло (ДИТ)',
  ZPI: 'Знак переменной информации (ЗПИ)',
  TPI: 'Табло переменной информации (ТПИ)',
  PVK: 'Пункт весового контроля (ПВК)',
  UO: 'Умные остановки (УО)',
  BUS: 'Автобус',
  TROLLEYBUS: 'Троллейбус',
  TRAM: 'Трамвай',
  MINIBUS: 'Маршрутка',
  TAXI: 'Такси',
  BIKE: 'Велосипеды',
  SCOOTER: 'Самокаты',
  CAR: 'Автомобили',
  ROAD_EQUIPMENT: 'Дорожная техника',
  VGK: 'ВГК (весогабаритный контроль)',
  ST: 'Специализированный транспорт (СТ)',
  PARKING: 'Парковки',
  JDP: 'Железнодорожный переезд',
  STATIONARY_COMPLEXES_FVF: 'Стационарные комплексы ФВФ',
  TRANSPORTATION_COMPLEXES_FVF: 'Передвижные комплексы ФВФ',
  MOBILE_COMPLEXES_FVF: 'Мобильные комплексы ФВФ',
  ACTIVE_DUMMIES_FVF: 'Активные муляжи ФВФ',
  INTEGRATION_DT: 'Интегрирующий ДТ',
  RADIO_FREQUENCY_DT_SECTION: 'Радиочастотный ДТ- сечение',
  RADIO_FREQUENCY_DT_BAND: 'Радиочастотный ДТ- полоса',
  RADIO_FREQUENCY_DT_AREAL: 'Радиочастотный ДТ- площадной',
  VIDEO_DT: 'Видеодетектор транспорта',
};

type TDevicesTypesIdKeys = keyof typeof DEVICES;
export type TDevicesIds = IntRange<1, 35>;

export const DEVICE_TYPES_ID: Record<TDevicesIds, TDevicesTypesIdKeys> = {
  1: DEVICES.UNKNOWN,
  2: DEVICES.LIGHTS,
  3: DEVICES.CAMERAS,
  4: DEVICES.DETECTORS,
  5: DEVICES.CAMERA_PTZ,
  6: DEVICES.METEO,
  7: DEVICES.PHOTO_VIDEO_FVF,
  8: DEVICES.DIT,
  9: DEVICES.ZPI,
  10: DEVICES.TPI,
  11: DEVICES.PVK,
  12: DEVICES.UO,
  13: DEVICES.BUS,
  14: DEVICES.TROLLEYBUS,
  15: DEVICES.TRAM,
  16: DEVICES.MINIBUS,
  17: DEVICES.TAXI,
  18: DEVICES.BIKE,
  19: DEVICES.SCOOTER,
  20: DEVICES.CAR,
  21: DEVICES.ROAD_EQUIPMENT,
  22: DEVICES.VGK,
  23: DEVICES.ST,
  24: DEVICES.PARKING,
  25: DEVICES.JDP,
  26: DEVICES.STATIONARY_COMPLEXES_FVF,
  27: DEVICES.TRANSPORTATION_COMPLEXES_FVF,
  28: DEVICES.MOBILE_COMPLEXES_FVF,
  29: DEVICES.ACTIVE_DUMMIES_FVF,
  30: DEVICES.INTEGRATION_DT,
  31: DEVICES.RADIO_FREQUENCY_DT_SECTION,
  32: DEVICES.RADIO_FREQUENCY_DT_BAND,
  33: DEVICES.RADIO_FREQUENCY_DT_AREAL,
  34: DEVICES.VIDEO_DT,
};

export const DEVICES_TYPES = Object.keys(DEVICE_TYPES_ID).map((el) =>
  Number(el)
) as TDevicesIds[];

export type TStatusCategory =
  | 'WORK'
  | 'NOT_ACTIVE'
  | 'ERRORS'
  | 'REMOTE_CONTROL';

export interface IStatusSets {
  mode: number[];
  isAlarm: boolean;
  color: string;
}

export const CONTROL_ACCESS_DEVICES = {
  OnlyRead: 0,
  ReadAndControl: 1,
  Unknown: -1,
  NotSupportControl: 3,
} as const;

const STATUS_WORK_ARRAY = Object.values(COMMANDS_MODE_WORK);
const STATUS_NOT_ACTIVE_ARRAY = Object.values(COMMANDS_MODE_NOT_ACTIVE);
const STATUS_ERRORS_ARRAY = Object.values(COMMANDS_MODE_ERRORS);
const STATUS_REMOTE_CONTROL_ARRAY = Object.values(COMMANDS_MODE_REMOTE_CONTROL);

export const TRAFFIC_LIGHTS_STATUS_SETS: Record<TStatusCategory, IStatusSets> =
  {
    WORK: {
      mode: STATUS_WORK_ARRAY,
      isAlarm: false,
      color: 'var(--green200)',
    },
    NOT_ACTIVE: {
      mode: STATUS_NOT_ACTIVE_ARRAY,
      isAlarm: false,
      color: 'var(--white600)',
    },
    ERRORS: {
      mode: STATUS_ERRORS_ARRAY,
      isAlarm: true,
      color: 'var(--danger)',
    },
    REMOTE_CONTROL: {
      mode: STATUS_REMOTE_CONTROL_ARRAY,
      isAlarm: true,
      color: 'var(--antd-blue)',
    },
  };

export const DEV_TOOLS = {
  MAP_INFO_BTN: 'информация карты',
  STATIC_DATA_LOG: 'лог статических данных',
  ZOD_LOG: 'лог ошибок зод',
  VIDEO_DT_LINKS_LOG: 'лог битых ссылок видеодетект.',
  INCORRECT_COORDINATES: 'лог некорректных координат',
  ACTIVE_DEVICE: 'лог выбранного устройства',
  QUERY_TOOLS: 'отладка кэширования запросов',
  QUERY_TOOLS_BTN_POSITION: 'расположение кнопки кэширования',
} as const;

export const CLUSTERS_INFO = {
  CLUSTERIZATION: 'кластеризация',
  OPENED: 'раскрытие кластера',
} as const;

export const DISPLAY_INFO = {
  CENTER: 'центрирование',
  INFO_PANEL: 'открывать инфо панель',
  INFO_BTN: 'блок с информацией',
  ALARM_ALERT: 'мигающие уведомления',
  MINIFIC_MAP: 'минифицированная карта',
  SCALE_LABEL: 'масштаб карты',
  SLIDER: 'размер иконок карты',
} as const;

export const GIS_BUTTON_INFO = {
  SEARCH: 'поиск',
  LAYERS: 'слои',
  MEASUREMENT: 'картографические измерения',
  BASEMAP: 'картографические подложки',
  METEODATA: 'погода',
  TRAFFIC: 'дорожный затор',
  TRAFFIC_GRAPH: 'график потоков ТС',
};

type HintsTypeVal =
  | 'INTERFACE'
  | 'MAP'
  | 'BTNS_MAP'
  | 'PROFILE'
  | 'GIT_TOOLTIPS';

export const HINTS: Record<HintsTypeVal, string> = {
  INTERFACE: 'интерфейс',
  MAP: 'карта',
  BTNS_MAP: 'кнопки карты',
  PROFILE: 'профиль',
  GIT_TOOLTIPS: 'тултипы объектов',
};

export const PARAMS = {
  SUMMARY_INFO: 'сводная информация',
};

export const BREAK_POINTS = {
  XL: 1600,
  L: 1280,
  M: 850,
  S: 650,
};

export const ROLE_RUSSIAN = {
  [Role.admin]: 'Администратор',
  [Role.dispatcher]: 'Диспетчер',
  [Role.operator]: 'Оператор',
  [Role.engineer]: 'Инженер',
  [Role.admin_root]: 'Суперпользователь',
};

export const ROLE_TABS = {
  [Role.admin]: 'Администратор - пользователь с полным функционалом',
  [Role.dispatcher]: 'Диспетчер - пользователь с расширенным функционалом',
  [Role.operator]: 'Оператор - пользователь со стандартным функционалом',
};

export const CACHED_IMAGES = {
  MAX_AMOUNT: 100,
  TO_REMOVE_AMOUNT: 30,
};

export const PHASES_TEMPLATE = {
  FILLED: 'FILLED',
  LIST: 'LIST',
  GRID: 'GRID',
};

export const SIGNAL_GRAPH_COLORS = {
  RED: 'rgb(204 0 0)',
  GREEN: 'rgb(89 179 0)',
  YELLOW: 'rgb(230 230 0)',
  ORANGE: 'rgb(255 128 0)',
};

export const LOGO_NAMES = {
  sber: 'Sber',
  osut: 'Osut',
};

export const LOGO_URLS = {
  SBER: 'assets/ic_logo_sber.ico',
  OSUT: 'assets/ic_logo_osut.ico',
};

export const ADMIN_USERS_COLLOBORATION: ITableColumn = {
  title: 'Взаимодействие',
  dataIndex: 'collaboration',
  visible: true,
  type: ColumnTypes.collaboration,
  filterSettings: {
    filters: null,
    searched: false,
    sorted: false,
  },
  readonly: false,
  referens: 'collaboration',
};

export const LOGIN_METHODS = [
  {
    type: 'isFace',
    isActive: false,
    title: 'Распознавание лиц',
    info: 'Вход с помощью камеры',
  },
  {
    type: 'isFingerprint',
    isActive: false,
    title: 'Распознавание отпечатков пальцев',
    info: 'Вход с помощью сканера отпечатков пальцев',
  },
  {
    type: 'isKey',
    isActive: false,
    title: 'Ключ безопасности',
    info: 'Вход с помощью физического ключа безопасности',
  },
  {
    type: 'isPassword',
    isActive: true,
    title: 'Пароль',
    info: 'Вход с помощью пароля учетной записи',
  },
];

export enum WS_SEND_EVENTS {
  checkTimestamp = 'CHECK_TIMESTAMP',
  subReq = 'SUB_REQ',
  subExit = 'SUB_EXIT',
  ping = 'PING',
}

export enum WS_RECEIVE_EVENTS {
  changeField = 'CHANGE_FIELD',
  addItem = 'ADD_ITEM',
  deleteItem = 'DELETE_ITEM',
  unauthorized = 'UNAUTHORIZED',
  error = 'ERROR',
  subRes = 'SUB_RES',
  subData = 'SUB_DATA',
  updateEntity = 'UPDATE_ENTITY',
  pong = 'PONG',
  sessionQuit = 'SESSION_QUIT',
  userDataUpdate = 'USER_DATA_UPDATE',
  refreshMapObjectsData = 'REFRESH_MAP_OBJECTS_DATA',
}

export const MONTHS = [
  'янв',
  'фев',
  'мар',
  'апр',
  'май',
  'июн',
  'июл',
  'авг',
  'сен',
  'окт',
  'ноя',
  'дек',
];

export const WIDTH = {
  SIDEBAR: 80,
  PANEL_MAIN: 312,
  PANEL_INFO: 500,
};

export const IS_DEV = process.env.NODE_ENV === 'development';
export const APP_VERSION = packageJson.version;

export const WKID = 102100;
export const OUTSR = 3857;
export const DEFAULT_NAME_FIELD_OBJECTID = 'OBJECTID';

export const UNEXPECTED_ERROR =
  'Произошла непредвиденная ошибка, наши разработчики уже решают этy проблему.';

export const POINT_UDS_ID_OFFSET = 1000000;

export enum KEY_COMBINATIONS {
  ENTER = 'Enter',
  SHIFT_ENTER = 'Shift+Enter',
  SHIFT_F5 = 'Shift+F5',
  ESCAPE = 'Escape',
}

export const KEY_EVENT_COMPONENT = {
  ENTER: {
    registerUserFormDescriptionTextarea: {
      keyCombination: KEY_COMBINATIONS.ENTER,
      index: 1,
      stopChainingIteration: false,
      options: {
        preventDefault: true,
        stopPropagation: true,
      },
    },
  },
  SHIFT_ENTER: {
    registerUserFormDescriptionTextarea: {
      keyCombination: KEY_COMBINATIONS.SHIFT_ENTER,
      index: 1,
      stopChainingIteration: true,
      options: {
        preventDefault: true,
        stopPropagation: true,
      },
    },
  },
  SHIFT_F5: {
    app: {
      keyCombination: KEY_COMBINATIONS.SHIFT_F5,
      index: 1,
      stopChainingIteration: true,
      options: {
        preventDefault: false,
        stopPropagation: false,
      },
    },
  },
  ESCAPE: {
    panel: {
      keyCombination: KEY_COMBINATIONS.ESCAPE,
      index: 3,
      stopChainingIteration: true,
      options: {
        preventDefault: true,
        stopPropagation: true,
      },
    },
    detailed: {
      keyCombination: KEY_COMBINATIONS.ESCAPE,
      index: 2,
      stopChainingIteration: true,
      options: {
        preventDefault: true,
        stopPropagation: true,
      },
    },
    infoPanel: {
      keyCombination: KEY_COMBINATIONS.ESCAPE,
      index: 1,
      stopChainingIteration: true,
      options: {
        preventDefault: true,
        stopPropagation: true,
      },
    },
  },
};

export const ModulesTypes = {
  CoordControl: 'CoordControl',
  DispatchControl: 'DispatchControl',
  External: 'External',
} as const;

export const MEDIA = '/media';
export const PHASE_IMG = '/tlPhaseImages/';
export const SIGN1 = '/sign1/';
export const REGION = 'region';
export const PHASE_IMG_FOLDER = MEDIA + PHASE_IMG;
export const DEFAULT_PHASE_IMG_PATH = `${PHASE_IMG_FOLDER}no_phase_image.png`;

export const API_PATH = CONFIG.EPUTS_URL + VERSION;
