import {
  StyleProvider,
  legacyLogicalPropertiesTransformer,
} from '@ant-design/cssinjs';
import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';

import App from './components/App/App';
import AppError from './components/AppError/AppError';
import './assets/styles/index.scss';
import QueryClientProvider from './components/ReactQuery/QueryClientProvider';

const container = document.getElementById('root');
const root = createRoot(container as any); // CreateRoot(container!) if you use TypeScript

root.render(
  <ErrorBoundary fallback={<AppError />}>
    <QueryClientProvider>
      <StyleProvider
        transformers={[legacyLogicalPropertiesTransformer]}
        hashPriority="high"
      >
        <App />
      </StyleProvider>
    </QueryClientProvider>
  </ErrorBoundary>
);
