import { MINUTE, SECOND } from './constants';

export const DEFAULT_UPDATE_TIME = 5 * MINUTE;
export const AUTO_UPDATE_STATIC = 5 * SECOND;

export const QUERY_GROUP_KEY = {
  POINT_UDS: ['pointUds'],
};

export const QUERY_KEY = {
  VEHICLES_AMOUNT: [...QUERY_GROUP_KEY.POINT_UDS, 'vehicles', 'amount'],
  STATISTIC_MENU: 'statisticMenu',
  DETECTORS_DATA: 'detectorsData',
  STATISTIC_ADAPTERS: 'statistic',
  DEVICE_INFO: 'deviceInfo',
};
