import { TrafficLane } from '../../../ts/models/constructor.model';

export interface TLaneItem {
  item: TrafficLane;
}

export interface TLaneDirectionProps extends TLaneItem {
  directionKey: LaneDirectionKey;
}

export interface TLanesBlockProps extends TLaneDirectionProps {
  isTLanesBlock: boolean;
}

export enum DetectorSegment {
  Icon = 'Иконка',
  Lanes = 'Полосы',
}

export enum DirectionSegment {
  ToCamera = 'На камеру',
  FromCamera = 'От камеры',
}

export enum LaneDirectionKey {
  ToCamera = 'toCamera',
  FromCamera = 'fromCamera',
}
