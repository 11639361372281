import { Card } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import CircleChart from '../../../ui-kit/CircleChart/CircleChart';
import NoData from '../../../ui-kit/Errors/NoData/NoData';

import useMonitoringCard from './hooks/useMonitoringCard';
import { TWidgetsOptionalData } from './models/monitoring.model';

import styles from './MonitoringCard.module.scss';

interface IMonitoringCardProps {
  statusData: U<TWidgetsOptionalData>;
  title: string;
}

const MonitoringCard: FC<IMonitoringCardProps> = ({ statusData, title }) => {
  const { dataPieChart, activeSectors, handleClickSector } =
    useMonitoringCard(statusData);

  return (
    <Card className={styles.container}>
      <h3 className={styles.title}>{title}:</h3>
      {dataPieChart ? (
        <>
          <div className={styles.chartContainer}>
            <CircleChart
              data={dataPieChart.data}
              colors={dataPieChart.colors}
              isLabel={false}
              handleClickSector={handleClickSector}
              activeSectors={activeSectors}
            />
          </div>
          <p className={styles.footer}>
            Кол-во оборудования: {dataPieChart.total}
          </p>
        </>
      ) : (
        <NoData />
      )}
    </Card>
  );
};

export default observer(MonitoringCard);
