import { Spin } from 'antd';
import { SpinProps } from 'antd/lib';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';

import api from '../../../api';
import rootStore from '../../../stores/rootStore/rootStore';
import useChannel from '../../Socket/useChannel';

import { convertTimeToColor } from './helpers';
import { IServerData } from './NJSInfo.model';
import DBInfo from './ui/DBInfo/DBInfo';
import IntegrationApiInfo from './ui/IntegrationApiInfo/IntegrationApiInfo';
import MainNJSInfo from './ui/MainNJSInfo/MainNJSInfo';
import NotifyChannelsInfo from './ui/NotifyChannelsInfo/NotifyChannelsInfo';
import OsInfo from './ui/OsInfo/OsInfo';
import PingContainer from './ui/PingContainer/PingContainer';
import TableValidationStatus from './ui/TableValidationStatus/TableValidationStatus';
import TimeRefresh from './ui/TimeRefresh/TimeRefresh';

import styles from './NJSInfo.module.scss';

const DEFAULT_COLOR = 'green';
const DEFAULT_PING = 40;
const APP_PATH = 'Admin.NJSInfo';

const NJInfo = () => {
  const { categories } = rootStore.channelsDataStore;
  const serverData = useChannel(categories, 'server', 'info', true);
  const [data, setData] = useState<N<IServerData>>(null);
  const [isLoading, setIsLoading] = useState({
    dataTime: false,
    ping: false,
  });
  const [pingInfo, setPingInfo] = useState({
    color: DEFAULT_COLOR,
    time: DEFAULT_PING,
  });
  const [updateTime, setUpdateTime] = useState<N<number>>();

  const handleResetDataTime = () => {
    setIsLoading({
      ...isLoading,
      dataTime: true,
    });

    api.server.fetchRefreshServerData(APP_PATH).finally(() => {
      setIsLoading({
        ...isLoading,
        dataTime: false,
      });
    });
  };

  const handleResetPing = useCallback(async () => {
    const startTime = Date.now();

    try {
      setIsLoading({
        ...isLoading,
        ping: true,
      });

      await api.server.fetchPing(APP_PATH);

      const endTime = Date.now();
      const time = endTime - startTime;

      setPingInfo({
        color: convertTimeToColor(time),
        time,
      });
    } catch (e: unknown) {
      const endTime = Date.now();
      const time = endTime - startTime;

      setPingInfo({
        color: convertTimeToColor(time),
        time,
      });
      console.log(e);
    } finally {
      setIsLoading({
        ...isLoading,
        ping: false,
      });
    }
  }, [isLoading]);

  useEffect(() => {
    handleResetPing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (serverData) {
      setData({ ...serverData });
      setUpdateTime(Date.now() - serverData.uptime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serverData]);

  const spinProps: SpinProps = {
    size: 'small',
    className: styles.spin,
  };

  if (!data)
    return (
      <section className={styles.wrapper}>
        <div>
          <h2>Дождитесь загрузки данных</h2>
          <Spin {...spinProps} />
        </div>
      </section>
    );

  return (
    <section className={styles.wrapper}>
      <div className={styles.headInfo}>
        {updateTime ? (
          <MainNJSInfo
            data={data}
            timer={updateTime}
            setTimer={setUpdateTime}
          />
        ) : null}
        <PingContainer
          isLoading={isLoading.ping}
          pingInfo={pingInfo}
          handleReset={handleResetPing}
        />
      </div>
      <div className={styles.infoBoxes}>
        <TimeRefresh
          data={data}
          type="fullData"
          handleRefresh={handleResetDataTime}
          isLoading={isLoading.dataTime}
        />
        <TimeRefresh
          data={data}
          type="partialData"
          handleRefresh={handleResetDataTime}
          isLoading={isLoading.dataTime}
        />
        <DBInfo data={data} />
        <NotifyChannelsInfo data={data} />
        <OsInfo data={data} />
        <IntegrationApiInfo data={data} />
        <TableValidationStatus status={data.tableValidationStatus} />
      </div>
    </section>
  );
};

export default observer(NJInfo);
