import api from '../../../api';
import { IGroup } from '../../../api/adminObjects/groups/groups.zod';
import rootStore from '../../../stores/rootStore/rootStore';

export interface ISeparateGroups {
  web: IGroup[];
  njs: IGroup[];
}

export const getAllGroupsObj = async () => {
  const { webSystemId, serverSystemId } = rootStore.userDataStore;
  const systemsWithGroups = await api.adminObjects.groups.fetchGroups(
    'admin.getAllGroupsObj'
  );

  const groups: ISeparateGroups = {
    njs: [],
    web: [],
  };

  systemsWithGroups?.forEach((item) => {
    if (item.systemId === webSystemId) {
      groups.web = item.groups;
    }

    if (item.systemId === serverSystemId) {
      groups.njs = item.groups;
    }
  });

  return groups;
};
