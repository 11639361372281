import dayjs, { type Dayjs } from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import { getTimeWithTimeZone } from '../../../TlDetectors/IntancyGraph/TitleBtns/TimeLocalCard/helpers/getTimeWithTimeZone';
import { DEFAULT_DISABLED_TIME_DURATION } from '../../constants/constants';

import { setRange } from './setRange';

dayjs.extend(relativeTime);

export const disabledDateTime = (
  date: N<Dayjs>,
  disabledMinutes = DEFAULT_DISABLED_TIME_DURATION
) => {
  const newDate = getTimeWithTimeZone().dateFormattedJS;

  const hourNow = newDate.get('hour');
  const minutesNow = newDate.get('minute');

  const disabledDifference = minutesNow + disabledMinutes;
  const isNextHour = date && date.get('hour') - hourNow === 1;

  const disabledNextHour =
    disabledDifference < 60 ? 0 : disabledDifference - 60;

  if (date?.format('dddd') !== newDate.add(1, 'days').format('dddd')) {
    return {
      disabledHours: () => setRange(0, 24).splice(0, hourNow),
      disabledMinutes: () => {
        return !(
          newDate.startOf('day').from(date) >
          newDate.startOf('day').from(newDate)
        )
          ? setRange(0, 60).splice(
              0,
              !isNextHour ? minutesNow + disabledMinutes : disabledNextHour
            )
          : [];
      },
    };
  }

  return {
    disabledHours: () => setRange(0, 24).splice(hourNow + 1, 24 - hourNow),
  };
};
