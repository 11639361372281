import classNames from 'classnames';
import { FC } from 'react';

import useCounter from '../../../../../hooks/useCount';

import styles from './PercentCircle.module.scss';

const OFFSET = 20;

interface IPercentCircle {
  cx: number;
  cy: number;
  percent: number;
  value: number;
  isSmall?: boolean;
}

const PercentCircle: FC<IPercentCircle> = ({
  cx,
  cy,
  percent,
  value,
  isSmall = false,
}) => {
  const roundedPercent = Math.round(percent);

  const [percentsCounter] = useCounter(roundedPercent, 10, false);

  const textStyle = classNames({
    [styles.text]: true,
    [styles.large]: !isSmall,
  });

  const isEnd = percentsCounter === roundedPercent;

  return (
    <g x={cx} y={cy}>
      {!isSmall && (
        <text
          x={cx}
          y={cy - OFFSET}
          dy={8}
          textAnchor="middle"
          className={textStyle}
        >
          {value}
        </text>
      )}
      <text
        x={cx}
        y={cy + (!isSmall ? OFFSET : 0)}
        dy={8}
        textAnchor="middle"
        className={classNames(textStyle, { [styles.percent]: !isSmall })}
      >
        {isEnd ? percent : percentsCounter}%
      </text>
    </g>
  );
};

export default PercentCircle;
