import { observer } from 'mobx-react-lite';
import { MapBrowserEvent, Map as OLMap, View } from 'ol';
import type { Extent } from 'ol/extent';
import { fromLonLat } from 'ol/proj';
import { FC, useEffect, useRef } from 'react';

import {
  CURSOR_MAP,
  MAP_INITIAL_OPTIONS,
  ZOOM,
} from '../../../constants/mapConstants';
import { TMap } from '../../../stores/mapStore/mapStore.model';
import rootStore from '../../../stores/rootStore/rootStore';
import { normalizeExtent } from '../../Map/helpers/normalizeExtent';
import { MapChild } from '../../Mapper/Mapper.model';

import { getViewProps, getZoomProps } from './helpers/helpers';
import ZoomBtnsWrapper from './ZoomBtnsWrapper/ZoomBtnsWrapper';

import styles from './UiMap.module.scss';

const { GRAB, GRABBING } = CURSOR_MAP;

export type ZoomProps = Partial<{
  initZoom: number;
  maxZoom: number;
  minZoom: number;
}>;

export interface UIMapProps {
  map: TMap;
  setMap: (mapOL: TMap) => void;
  children: MapChild;
  centerCoord?: XY;
  extent?: Extent;
  zoomProps?: ZoomProps;
  handleZoom?: (zoom: number, resolution: number) => void;
  handleMapClick?: (event: MapBrowserEvent<any>) => void;
  handleLoadView: () => void;
}

const UiMap: FC<UIMapProps> = ({
  map,
  setMap,
  centerCoord,
  extent,
  zoomProps,
  children,
  handleZoom,
  handleMapClick,
  handleLoadView,
}) => {
  const { regionData } = rootStore.uiStore;
  const mapRef = useRef<HTMLDivElement>(null);

  // set initial map
  useEffect(() => {
    if (!mapRef.current) return;

    const mapObject = new OLMap({
      ...MAP_INITIAL_OPTIONS,
      view: new View({}),
    });

    mapObject.setTarget(mapRef.current);

    setMap(mapObject);

    return () => {
      mapObject.setTarget(undefined);
      setMap(null);
    };
  }, [setMap]);

  //set map props
  useEffect(() => {
    if (!map || !regionData?.extent) return;

    const zoomProperties = getZoomProps(regionData.initialZoom, zoomProps);

    const extentInitial = normalizeExtent(extent ?? regionData.extent);
    const centerCoordInitial = centerCoord ?? regionData.mapCenterCoords;

    const view = new View({
      ...zoomProperties,
      extent: extentInitial,
    });

    view.setCenter(fromLonLat(centerCoordInitial));

    map.setView(view);
  }, [centerCoord, extent, map, regionData, zoomProps]);

  //set map event handlers
  useEffect(() => {
    if (!map) return;

    const { currZoom, resolution } = getViewProps(map);

    currZoom && handleZoom?.(currZoom, resolution ?? 1);

    const handleZoomChange = () => {
      const newZoom = map.getView().getZoom();

      map.getTargetElement().style.cursor = GRAB;

      if (currZoom !== newZoom) {
        const { currZoom, resolution } = getViewProps(map);

        currZoom && handleZoom?.(currZoom, resolution ?? 1);
      }
    };

    const onPointerDrag = () => {
      map.getTargetElement().style.cursor = GRABBING;
    };

    handleMapClick && map.on('singleclick', handleMapClick);
    handleZoom && map.on('moveend', handleZoomChange);
    handleZoom && map.on('pointerdrag', onPointerDrag);
    map.on('loadend', handleLoadView);

    return () => {
      handleMapClick && map.un('singleclick', handleMapClick);
      handleZoom && map.un('moveend', handleZoomChange);
      handleZoom && map.un('pointerdrag', onPointerDrag);
      map.un('loadend', handleLoadView);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  return (
    <div className={styles.olMap} ref={mapRef} role="presentation">
      {children}
      <ZoomBtnsWrapper
        handleZoomProps={handleZoom}
        map={map}
        zoomProps={getZoomProps(
          regionData?.initialZoom ?? ZOOM.INITIAL,
          zoomProps
        )}
      />
    </div>
  );
};

export default observer(UiMap);
