import { isNumber } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

import checkTL from '../../../helpers/checkIsTLValid';
import { findById } from '../../../helpers/findBy';
import { getTLModeName } from '../../../helpers/statusHelpers';
import rootStore from '../../../stores/rootStore/rootStore';
import { isTLObject } from '../../../ts/models/tl.model';
import UnexpectedError from '../../ui-kit/Errors/UnexpectedError/UnexpectedError';
import usePanelLogic from '../hooks/usePanelLogic';
import IdentificationInfo from '../IdentificationInfo/IdentificationInfo';

import InfoBtns from './InfoBtns/InfoBtns';
import InfoMain from './InfoMain/InfoMain';
import InfoRow from './InfoRow/InfoRow';
import NoCurrentObj from './NoCurrentObj/NoCurrentObj';

import styles from './SystemsInfo.module.scss';

const UNAVAILABLE =
  "Статус объекта 'Недоступен' или 'Удален'. Отображение данных невозможно.";

interface IStatusMode {
  modeName: string;
  modeNumber: N<number>;
}

const SystemsInfo = () => {
  const {
    isDetailedForm,
    infoData: infoDataMap,
    identifiedItems,
  } = rootStore.uiStore;

  const { systemsInfo, tls } = rootStore.mapDataStore;

  const [statusMode, setStatusMode] = useState<IStatusMode>({
    modeName: '',
    modeNumber: null,
  });
  const [currentId, setCurrentId] = useState<number>(0);

  const { panelContentContext: infoData } = usePanelLogic(infoDataMap);

  useEffect(() => {
    if (!infoData) return;

    const { id } = infoData;
    const currentObject = findById(tls, id);
    const mode = currentObject?.mode;

    if (mode !== undefined) {
      const name = getTLModeName(mode);

      setStatusMode({ modeName: name, modeNumber: mode });
    }

    setCurrentId(currentObject?.deviceInfo?.externalDeviceId ?? id);
  }, [tls, infoData]);

  if (!infoData || isDetailedForm) return null;

  const { id, system } = infoData;

  const currentObject =
    isNumber(id) && systemsInfo[system]
      ? findById(systemsInfo[system].data, id)
      : null;

  const isNotCurrentObject = !currentObject;
  const hasIdentifiedItems = identifiedItems.length > 0;
  const isNotNumberId = !isNumber(id);

  if (isNotCurrentObject || (hasIdentifiedItems && isNotNumberId)) {
    return <IdentificationInfo />;
  }

  if (isNotCurrentObject || isNotNumberId) {
    return <NoCurrentObj />;
  }

  const { controlAccess, deviceStatusId } = currentObject.deviceInfo;
  const { scSystemTypeId } = currentObject.eputsAdapter;

  const isTL = isTLObject(currentObject, system);
  const isTLValid = checkTL(currentObject, system);

  return (
    <UnexpectedError resetKeys={[currentId]}>
      <div className={styles.info}>
        <hr className={styles.line} />

        <InfoRow
          modeName={statusMode.modeName}
          currentId={currentId}
          deviceStatusId={deviceStatusId}
          isLights={isTL}
          scSystemTypeId={scSystemTypeId}
        />
        <hr className={styles.line} />

        {isTLValid ? (
          <InfoMain currentObject={currentObject} system={system} id={id} />
        ) : (
          <p className={styles.unavailable}>{UNAVAILABLE}</p>
        )}

        <InfoBtns
          isLights={isTL}
          infoData={infoData}
          controlAccess={controlAccess}
          isDisabled={!isTLValid}
        />
      </div>
    </UnexpectedError>
  );
};

export default observer(SystemsInfo);
