import { Tag } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { COLORS } from '../../../constants/colorsConstants';
import { TL_DETAILED } from '../../../constants/tlDetailedConstants';
import { findById } from '../../../helpers/findBy';
import rootStore from '../../../stores/rootStore/rootStore';

import styles from './Title.module.scss';

interface ITitle {
  id: number;
  isStatic: boolean;
  phaseGroupNum?: N<number>;
  title?: string;
}

const Title: FC<ITitle> = ({ id, isStatic, title, phaseGroupNum }) => {
  const { tls } = rootStore.mapDataStore;
  const { activeTLPlanNumber } = rootStore.detailedStore;

  const tl = findById(tls, id);

  const isCurrentPlan =
    activeTLPlanNumber === phaseGroupNum &&
    tl?.tlStatus?.mode === TL_DETAILED.MODE.Work.com;

  if (isStatic || !tl)
    return (
      <div className={styles.titleWrapper}>
        <h3>{title}</h3>
        {isCurrentPlan && <Tag color={COLORS.SUCCESS}>Текущий</Tag>}
      </div>
    );

  const {
    tlGeneral: { progViewName },
  } = tl;

  return (
    <h3 className={styles.title}>
      {progViewName || 'Неизвестная сигнальная программа'}
    </h3>
  );
};

export default observer(Title);
