import { UrlConfigurations } from '../../../../api/server/web/web.zod';
import rootStore from '../../../../stores/rootStore/rootStore';

type VideoStreamsProxy = UrlConfigurations['videoStreamsProxyList'][0];

const proxyListIterationsMap = new Map<string, number>();

function getCurrentProxyHost(
  sourceHost: VideoStreamsProxy['sourceHost'],
  proxyHosts: VideoStreamsProxy['proxyHosts']
) {
  let proxyHostsIndex = (proxyListIterationsMap.get(sourceHost) ?? -1) + 1;

  if (proxyHostsIndex === proxyHosts.length) {
    proxyListIterationsMap.set(sourceHost, 0);
    proxyHostsIndex = 0;
  } else {
    proxyListIterationsMap.set(sourceHost, proxyHostsIndex);
  }

  return proxyHosts.at(proxyHostsIndex);
}

export const getPreparedActiveStreamUrl = (activeSteamUrl: string): string => {
  const urlConfigurations = rootStore.userDataStore.urlConfigurations;

  if (!urlConfigurations) return activeSteamUrl;

  const videoStreamsProxyList = urlConfigurations.videoStreamsProxyList;

  const matchingProxyItem = videoStreamsProxyList.find((item) =>
    activeSteamUrl.includes(item.sourceHost)
  );

  if (!matchingProxyItem) return activeSteamUrl;

  const { sourceHost, proxyHosts } = matchingProxyItem;

  const currentProxyHost = getCurrentProxyHost(sourceHost, proxyHosts);

  if (!currentProxyHost) return activeSteamUrl;

  const preparingActiveSteamUrl = activeSteamUrl.replace(
    sourceHost,
    currentProxyHost
  );

  return preparingActiveSteamUrl;
};
