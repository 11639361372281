import {
  RotateLeftOutlined,
  RotateRightOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import { Empty, Image as ImageANT, Space, Spin } from 'antd';
import classNames from 'classnames';
import type { ImagePreviewType } from 'rc-image';
import { FC, useEffect, useState } from 'react';

import { CONFIG } from '../../../constants/configConstants';
import { VERSION } from '../../../constants/versions';

import MaskPreview from './MaskPreview/MaskPreview';

import styles from './Img.module.scss';

interface IImg {
  src: N<string>;
  alt: string;
  className?: string;
  classNameImg?: string;
  spinClassName?: string;
  isViewGallery?: boolean;
  isShowIcons?: boolean;
  isNotCustom?: boolean;
  width?: SN;
  height?: SN;
  handleError?: () => void;
}

const Img: FC<IImg> = ({
  src,
  alt,
  className = '',
  classNameImg,
  isViewGallery = true,
  isShowIcons,
  isNotCustom = false,
  width,
  height,
  handleError,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorImg, setErrorImg] = useState<boolean>(false);
  const [rotate, setRotate] = useState<number>(0);

  const customSrc = CONFIG.EPUTS_URL + VERSION + src;

  const containerStyle = classNames({
    [styles.container]: true,
    [className]: className,
  });

  useEffect(() => {
    setErrorImg(false);
  }, [src]);

  const preview: ImagePreviewType = {
    mask: (
      <MaskPreview
        setRotate={(value: number) =>
          setRotate((prev: number) =>
            value !== 0 ? prev + value : Math.trunc(rotate / 360) * 360
          )
        }
        isShowIcons={isShowIcons}
        rotate={rotate}
      />
    ),
    toolbarRender: (
      _,
      {
        transform: { scale },
        actions: { onRotateLeft, onRotateRight, onZoomOut, onZoomIn },
      }
    ) => (
      <Space size={12} className="toolbar-wrapper">
        <RotateLeftOutlined
          onClick={onRotateLeft}
          title="Повернуть против часовой"
        />
        <RotateRightOutlined
          onClick={onRotateRight}
          title="Повернуть по часовой"
        />
        <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
        <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
      </Space>
    ),
  };

  if (errorImg || typeof src !== 'string') {
    handleError?.();

    return (
      <div className={containerStyle}>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </div>
    );
  }

  if (isLoading || !src) {
    return (
      <div className={containerStyle}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className={containerStyle}>
      <ImageANT
        preview={isViewGallery && preview}
        src={isNotCustom ? src : customSrc}
        onError={() => {
          setErrorImg(true);
          setIsLoading(false);
        }}
        onLoad={() => setIsLoading(false)}
        onLoadStart={() => setIsLoading(true)}
        rootClassName={classNameImg || styles.img}
        style={{
          transition: 'transform 1s',
          transform: `rotate(${rotate}deg)`,
          fontSize: '10px',
        }}
        alt={alt}
        width={width}
        height={height}
      />
    </div>
  );
};

export default Img;
