import { TDevicesIds } from '../../../constants/constants';
import { TDeviceInfo } from '../../../ts/models/mapObject.model';
import { PointUds } from '../../../ts/models/pointUds.model';

import { DEVICE_TYPE_IDS } from './constants/constants';

export type DeviceTypeId = 2 | 3 | 4 | 6;

export interface Device {
  deviceInfo: TDeviceInfo;
  isCorrectCoordinate?: boolean;
  longitude: number;
  latitude: number;
}

export interface CorrectCoordArgs {
  device: Device;
  idx: number;
  pointsUds: PointUds[];
}

export const isActualDeviceType = (
  type: TDevicesIds | DeviceTypeId
): type is DeviceTypeId => DEVICE_TYPE_IDS.includes(type);

export interface LogTableData {
  type: U<string>;
  deviceId: number;
  caption: string;
  linkedId?: number;
  pointUdsUid: N<string>;
  pointUdsName: N<string>;
}

type DeviceKeys = 'tlsList' | 'cmsList' | 'dtsList' | 'mtsList';

export type DevicesList = Record<DeviceKeys, Device[]>;

export interface DeviceInExtent {
  longitude: number;
  latitude: number;
  id: number;
}
