import { FC } from 'react';

import TitleBtns from '../../../TrafficLightDetailed/TlDetectors/IntancyGraph/TitleBtns/TitleBtns';
import { IDomain } from '../constants/constants';
import { CalcData, GraphInfo, RadioProps } from '../models/chart.model';

import ExportButton from './ExportButton/ExportButton';
import InfoButton from './InfoButton/InfoButton';
import UpdateButton from './UpdateButton/UpdateButton';
import ZoomOutButton from './ZoomOutButton/ZoomOutButton';

import styles from './ButtonsPanel.module.scss';

export interface IButtonsPanelProps {
  domain: IDomain;
  timeUpdate: U<string>;
  isLoading: boolean;
  isError: boolean;
  graphInfo: GraphInfo;
  data: CalcData[];
  radioProps: RadioProps;
  title?: string;
  handleZoomOut: () => void;
  handleUpdateGraph: () => void;
}

const ButtonsPanel: FC<IButtonsPanelProps> = ({
  timeUpdate,
  domain,
  isLoading,
  isError,
  graphInfo,
  data,
  title = 'Отчет',
  radioProps,
  handleZoomOut,
  handleUpdateGraph,
}) => {
  return (
    <div className={styles.container}>
      <TitleBtns {...radioProps} isError={isError} />
      <div className={styles.buttons}>
        <ZoomOutButton domain={domain} handleZoomOut={handleZoomOut} />
        <UpdateButton
          isLoading={isLoading}
          handleUpdateGraph={handleUpdateGraph}
        />
        <ExportButton data={data} title={title} />
        <InfoButton timeUpdate={timeUpdate} graphInfo={graphInfo} />
      </div>
    </div>
  );
};

export default ButtonsPanel;
