import { Dayjs } from 'dayjs';
import { z } from 'zod';

import { HeaderSchema } from '../../api/mapObjects/mapObjects.zod';
import {
  GeometrySchema,
  PropertiesSchema,
  FeatureOGSSchema,
  TLDetailsImageSchema,
  TLTactsImageSchema,
  TLTactSchema,
  TLZoomsTactSchema,
  DashSchema,
  TLDetailsSchema,
  TdkSchema,
  TLGeneralSchema,
  TLSignalProgramItemSchema,
  TLSignalProgramItemSchema2,
  CommandSchema,
  TLStatusSchema,
  TLSchema,
  TLCrossroadSchema,
  TLDaiJdt83Schema,
} from '../../api/mapObjects/trafficLights/zod/tls.zod';
import { MODES_ARR, SOURCE_ARR, STATES_ARR } from '../../constants/commands';
import { SYSTEM } from '../../constants/constants';
import { TactsVal, TLTactTypesCode } from '../../constants/signalsConstants';
import { System } from '../enums/enums';
import { CircleElEnum } from '../enums/tl';

import { IBasicMapData, TDeviceInfo } from './mapObject.model';
import { DtFinalFromProfiles } from './MapObjects/detectors.model';

export type TlState = typeof STATES_ARR[0];
export type TlMode = typeof MODES_ARR[0];
export type TlSource = N<typeof SOURCE_ARR[0]>;

export type Header = z.infer<typeof HeaderSchema>;
export type Geometry = z.infer<typeof GeometrySchema>;
export type FeatureOGSProperties = z.infer<typeof PropertiesSchema>;
export type FeatureOGS = z.infer<typeof FeatureOGSSchema>;
export type TLFinalArrayFromProfiles = N<DtFinalFromProfiles[]>;
export type TLDetailsImage = z.infer<typeof TLDetailsImageSchema>;
export type TLZoomsTact = z.infer<typeof TLZoomsTactSchema>;
export type Dash = z.infer<typeof DashSchema>;
export type TLTactsImage = z.infer<typeof TLTactsImageSchema>;
export type TLCrossroad = z.infer<typeof TLCrossroadSchema>;
export type Tdk = z.infer<typeof TdkSchema>;
export type TLGeneral = z.infer<typeof TLGeneralSchema>;
export type TLSignalProgramData = z.infer<typeof TLSignalProgramItemSchema>;
export type TLSignalProgramData2 = z.infer<typeof TLSignalProgramItemSchema2>;
export type Command = z.infer<typeof CommandSchema>;

type TLTactItem = z.infer<typeof TLTactSchema>;

export interface TLTactItemDetails extends TLTactItem {
  tactType: N<TLTactTypesCode>;
}

type TLDetailsZod = z.infer<typeof TLDetailsSchema>;
export type DaiJdt83 = z.infer<typeof TLDaiJdt83Schema>;

export interface TLDetails extends TLDetailsZod {
  tlTacts: N<TLTactItemDetails[]>;
}

type TLStatusZod = z.infer<typeof TLStatusSchema>;

export interface TLStatus extends TLStatusZod {
  mode: TlMode;
  source: TlSource;
  state: TlState;
}

type TLZod = z.infer<typeof TLSchema>;

export interface TLData extends TLZod {
  tlStatus: TLStatus;
  tlDetails: TLDetails;
  deviceInfo: TDeviceInfo;
  tlTacts: TLDetails['tlTacts'];
}
export interface TLSignalProgram extends TLSignalProgramData {
  type: TactsVal;
}
export interface TLSignalProgram2 extends TLSignalProgramData2 {
  data: TLSignalProgram[];
}

interface TLInfo extends TLData {
  extId: N<number>;
  mode: TlMode;
}

export type TL = IBasicMapData & TLInfo;

export type TLAnimation = N<Record<string, unknown>>;

export interface TLTact {
  color: string;
  main: number;
  tact: number;
  time: number;
  tactImages: U<TactsImagesEl[]>;
  tactType: N<IntRange<1, 4>>;
  animation: TLAnimation;
  direction: N<number[]>;
  direction_green_in_phase: N<number[]>;
}

export interface TactsImagesEl {
  type: CircleElEnum;
  angle: number;
  positionXY: XY;
  size: number;
  color: string;
  strokeWidth: number;
  sizeRate: number;
  dash: N<Dash>;
  animation: TLAnimation;
  direction?: number;
  hue?: number;
}

export interface TLZoomsTacts {
  range: XY;
  diameter: number;
  borderWidth: number;
  hue: number;
  other: N<string>;
  tlTactsImagesIndex: N<number>;
}

export interface INearestPhases {
  startTime: N<Dayjs>;
  endTime: N<Dayjs>;
  phaseNum: number;
}

export interface ITlCurrentMode {
  id?: number;
  name: string;
  timeRcvNormalize: any;
}

export interface IPrograms {
  addInfo?: string;
  cycleTime?: number;
  execTime?: string;
  phaseGroupNum?: number;
  tlSignalProgram2?: TLSignalProgram2[][];
  tlTacts?: TLTact[];
  viewItem?: string;
}

export const isTLObject = (object: unknown, system: System): object is TL =>
  system === SYSTEM.LIGHTS;
