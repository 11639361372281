import Feature from 'ol/Feature';
import { Style } from 'ol/style';

import { ClassBreaksRenderer } from './renderer.model';
import { Symbol } from './Symbol';

function ClassBreaks(
  renderer: ClassBreaksRenderer,
  feature: Feature,
  resolution?: number
): Style | undefined {
  const { classBreakInfos, defaultSymbol, field, minValue } = renderer;

  let defaultStyle;
  let style;
  let defaultMinValue = 0;
  const properties = feature.getProperties();

  if (!properties) {
    console.error('Not properties');

    return;
  }

  if (Object.prototype.hasOwnProperty.call(renderer, 'minValue')) {
    defaultMinValue = minValue;
  }

  if (defaultSymbol) {
    defaultStyle = Symbol(defaultSymbol);
  }

  if (!field || !Object.prototype.hasOwnProperty.call(properties, field)) {
    return defaultStyle;
  }

  classBreakInfos.forEach((classBreakInfo) => {
    const { classMaxValue, classMinValue, symbol } = classBreakInfo;

    if (Object.prototype.hasOwnProperty.call(classBreakInfo, 'classMinValue')) {
      defaultMinValue = classMinValue ?? defaultMinValue;
    }

    if (
      properties[field] > defaultMinValue &&
      properties[field] <= classMaxValue
    ) {
      style = Symbol(symbol);
    }

    defaultMinValue = classMaxValue;
  });

  if (!style) {
    return defaultStyle;
  }

  return style;
}

export { ClassBreaks };
