/* eslint-disable camelcase */
import { IS_DEV } from '../../../../constants/constants';
import NOTICE from '../../../../constants/notificationConstants';
import { sendError } from '../../../../helpers/sendError';
import { getAppPathHeaders } from '../../../helpers/getAppPathHeaders';
import http from '../../../http';
import tryCatchWrapper from '../../../tryCatchWrapper';
import { SYSTEMS } from '../../constants/constants';

import { StatisticAdapter, StatisticAdapterSchema } from './adapter.zod';
import {
  StatisticProps,
  useStatisticQueryOptions,
} from './model/adapter.model';
import useStatisticQuery from './query/useStatisticQuery';

const ADAPTER = '/adapter';
const STATISTIC = SYSTEMS + ADAPTER + '/statistics';

class AdapterApi {
  fetchStatisticBySystemId = ({
    appPath,
    systemIds,
    systemTypes,
    regionIds,
    subSystemTypes,
    isBlockedNotify = false,
  }: StatisticProps) =>
    tryCatchWrapper(
      async () => {
        const res: U<{ data: StatisticAdapter[] }> = await http.get(STATISTIC, {
          headers: getAppPathHeaders(appPath),
          params: {
            systemIds: JSON.stringify(systemIds),
            systemTypes: JSON.stringify(systemTypes),
            subSystemFilter:
              regionIds?.length &&
              JSON.stringify({
                sc_system_regions_id: regionIds,
                sub_system_type_arr: subSystemTypes,
              }),
          },
        });

        const errors: { dsc: string; issues: unknown }[] = [];

        const data = res?.data.reduce((acc: StatisticAdapter[], adapter) => {
          const validation = StatisticAdapterSchema.safeParse(adapter);

          if (!validation.success) {
            errors.push({
              dsc: `Ошибка получения статистики по адаптеру ${adapter?.sc_system_id}`,
              issues: validation.error.issues,
            });
          }

          validation.success && acc.push(adapter);

          return acc;
        }, []);

        if (errors.length) {
          console.error('Ошибка валидации статистики адаптеров', errors);
          !IS_DEV &&
            sendError(
              NOTICE.INCORRECT_TYPES_FLEX.description,
              {
                dsc: errors,
              },
              appPath
            );
        }

        return data;
      },
      {
        isBlockedNotify,
      }
    );
  useStatistic = (statisticProps: useStatisticQueryOptions) =>
    useStatisticQuery({
      ...statisticProps,
      fetchStatistic: this.fetchStatisticBySystemId,
    });
}

export const adapter = new AdapterApi();
