import { isEqual } from 'lodash';
import { FC } from 'react';
import { TbZoomReset } from 'react-icons/tb';

import ButtonOnlyIcon from '../../../ButtonOnlyIcon/ButtonOnlyIcon';
import { INITIAL_X_DOMAIN } from '../../constants/constants';
import { IButtonsPanelProps } from '../ButtonsPanel';

type TZoomOutButtonProps = Pick<IButtonsPanelProps, 'domain' | 'handleZoomOut'>;

const ZoomOutButton: FC<TZoomOutButtonProps> = ({ domain, handleZoomOut }) => {
  const isDisabled = isEqual(domain.x, INITIAL_X_DOMAIN);

  return (
    <ButtonOnlyIcon
      isDisabled={isDisabled}
      onClick={handleZoomOut}
      tip="К начальному масштабу"
      isSmall
    >
      <TbZoomReset />
    </ButtonOnlyIcon>
  );
};

export default ZoomOutButton;
