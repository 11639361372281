import { COLORS } from '../../../constants/colorsConstants';

export const STYLE_CONSTANTS = {
  WIDTH: {
    DEFAULT: 1,
  },
  COLOR: {
    DEFAULT: COLORS.MAGENTA,
    FILL: COLORS.GREY_TRANSPARENT,
  },
  RADIUS: {
    DEFAULT: 12,
    SCALE: 64,
  },
  ANGLE: {
    DEFAULT: 0,
  },
  POINTS: {
    DEFAULT: 12,
  },
  PLACEMENTS: {
    LINE: 'line',
    POINT: 'point',
  },
  TEXT_ALIGN: {
    CENTER: 'center',
    LEFT: 'left',
    TOP: 'top',
    BOTTOM: 'bottom',
  },
} as const;

export const DEFAULT_IMAGE_SCALE = 2;
export const DEFAULT_IMAGE_ANCHOR = [0.5, 0.5];

export const DIRECTION_CONSTANTS = {
  WIDTH_MAIN_LINE: 8,
  WIDTH_SECONDARY_LINE: 5,
  WIDTH_OVERPASS_LINE: 4,
  WIDTH_OVERPASS_SECONDARY_LINE: 2,
  LINE_DASH_OVERPASS_LINE: [0.2, 5],
};

export const TEMPORARY_GRAPHICS_STYLE = {
  STROKE_COLOR: COLORS.MAGENTA,
  STROKE_WIDTH: 12,
  ICON_SIZE: 15,
  FILL_COLOR: COLORS.BLUE0,
  ICON_SCALE: 0.1,
  TEXT_STROKE_COLOR: COLORS.WHITE,
  TEXT_STROKE_WIDTH: 0.1,
};

export const DEFAULT_TEXT_STYLE_PROPS = {
  FONT: 'bold 12px arial',
  FONT_SIZE: '12px',
  FONT_FAMILY: 'arial',
  FILL: {
    COLOR: COLORS.BLACK,
  },
  STROKE: {
    WIDTH: 1,
    COLOR: COLORS.WHITE,
  },
};
