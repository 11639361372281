import { Popover as AntdPopover } from 'antd';
import { type TooltipPlacement } from 'antd/lib/tooltip';
import classNames from 'classnames';
import { ReactNode, FC, RefObject } from 'react';

import { TipType } from '../../../stores/uiStore/uiStore.model';

import formatContent from './helpers/formatContent';
import { getPopupContainer } from './helpers/getPopupContainer/getPopupContainer';

import styles from './Popover.module.scss';

export type ITrigger = 'hover' | 'focus' | 'click' | 'contextMenu';

type ITriggerType = ITrigger | Array<ITrigger> | undefined;
type ContentJSX = () => JSX.Element;
export type Content = ReactNode | ContentJSX;

export type PopoverWithoutChildren = Omit<PopoverProps, 'children'>;

export interface PopoverProps {
  children: ReactNode;
  content: Content;
  placement?: TooltipPlacement;
  mouseEnterDelay?: N<number>;
  tipsDelay?: TipType;
  overlayClassName?: string;
  isClearParent?: boolean;
  trigger?: ITriggerType;
  zIndex?: number;
  open?: boolean;
  fresh?: boolean;
  destroyTooltipOnHide?: boolean;
  containerRef?: RefObject<HTMLElement>;
}

const Popover: FC<PopoverProps> = ({
  children,
  content,
  placement,
  tipsDelay,
  trigger = 'hover',
  overlayClassName = '',
  isClearParent = false,
  zIndex,
  open,
  fresh = false,
  destroyTooltipOnHide = false,
  containerRef,
}) => {
  if (tipsDelay === null) {
    return <>{children}</>;
  }

  const overlayClassNameStyles = classNames({
    [overlayClassName]: true,
    [styles.popoverClear]: Boolean(isClearParent),
  });

  const popupContainer = getPopupContainer(containerRef)?.func;

  return (
    <AntdPopover
      placement={placement}
      content={formatContent(content)}
      trigger={trigger}
      mouseEnterDelay={tipsDelay}
      fresh={fresh}
      overlayClassName={overlayClassNameStyles}
      zIndex={zIndex}
      destroyTooltipOnHide={destroyTooltipOnHide}
      open={open}
      getPopupContainer={popupContainer}
    >
      {children}
    </AntdPopover>
  );
};

export default Popover;
