import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import rootStore from '../../../stores/rootStore/rootStore';
import { secondsToTime } from '../../TrafficLightDetailed/TlManagement/PhaseHold/helpers/secondsToTime';

import styles from './PhaseTitle.module.scss';

export interface IPhaseTitle {
  isStatic: boolean;
}

const TITLE = 'Фазы:';
const TEXT = 'Текущее время: ';

const PhaseTitle: FC<IPhaseTitle> = ({ isStatic }) => {
  const { secTL } = rootStore.uiStore;

  return (
    <div className={styles.title}>
      <h3>{TITLE}</h3>
      {!isStatic && (
        <p>
          {TEXT}
          {secondsToTime(secTL, { H: false, M: true, S: true })}
        </p>
      )}
    </div>
  );
};

export default observer(PhaseTitle);
