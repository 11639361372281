import { FC } from 'react';

import { RadioValue } from '../../../../../TrafficLightDetailed/TlDetectors/model/graph.model';
import { ChartBDKeys } from '../../Chart/constants/constants';

import styles from './TitleInfo.module.scss';

interface ITitleInfo {
  dateInterval: string[];
  timeUpdate: U<string>;
  radioValue: RadioValue;
}

const TitleInfo: FC<ITitleInfo> = ({
  dateInterval,
  timeUpdate,
  radioValue,
}) => {
  const title =
    radioValue === ChartBDKeys.PassageCount
      ? 'Интенсивность ТС'
      : 'Средняя скорость ТС';

  return (
    <div className={styles.title}>
      <h2>{title}</h2>
      <p>
        Период: {dateInterval[0]} по {dateInterval[1]}
      </p>
      {timeUpdate ? <p>Обновлено: {timeUpdate}</p> : null}
    </div>
  );
};

export default TitleInfo;
