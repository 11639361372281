import { useEffect } from 'react';

import {
  changeCrossroadEls,
  changeCrosswalkEls,
} from '../helpers/changeCrossroadEls';
import { CrossroadElsArgs } from '../models/crossroad.model';

const useCrossroadEls = ({
  data,
  id,
  color = '',
  crosswalkColor = 'blue',
  strokeWidth = 1,
  isCrossroad = true,
}: CrossroadElsArgs) => {
  useEffect(() => {
    if (!data || !id || !isCrossroad) return;

    const dirs = document.querySelectorAll(`.st${id}-ph`);
    const crosswalkLines = document.querySelectorAll(`.st${id}-crosswalk-line`);
    const crosswalkPolygons = document.querySelectorAll(
      `.st${id}-crosswalk-polygon`
    );

    changeCrossroadEls({ dirs, color, strokeWidth });
    changeCrosswalkEls(crosswalkLines, crosswalkColor);
    changeCrosswalkEls(crosswalkPolygons, crosswalkColor);
  }, [color, data, id, strokeWidth, crosswalkColor, isCrossroad]);
};

export default useCrossroadEls;
