import ContentCard from './ContentCard/ContentCard';

import styles from './DeviceCard.module.scss';

const DeviceCard = () => {
  return (
    <div className={styles.container}>
      <h3> Карточка устройства: </h3>
      <ContentCard />
    </div>
  );
};

export default DeviceCard;
