import { useMemo } from 'react';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import rootStore from '../../../../../stores/rootStore/rootStore';
import { TL } from '../../../../../ts/models/tl.model';
import getSliderSettings from '../../../../WidgetSlider/sliderSettings';
import { getTypeTactObj } from '../../../TlMainInfo/PhaseCardsList/helpers/getTypeTactObj';
import PhaseCard from '../../../TlMainInfo/PhaseCardsList/PhaseCard/PhaseCard';
import ArrowCustom from '../Arrows/ArrowCustom';
import { BREAK_POINTS_PHASES } from '../constants/constants';
import DotsCustom from '../DotsCustom/DotsCustom';
import useSgnProgram from '../hooks/useSgnProgram';

import styles from './SliderPhase.module.scss';

interface SliderPhaseProps {
  activeTL: TL;
}

const SliderPhase = ({ activeTL }: SliderPhaseProps) => {
  const { phasesArray } = rootStore.uiStore;
  const { isActiveControlBtn, setSelectedPhase } = rootStore.detailedStore;

  const { phasesSet, isError } = useSgnProgram(activeTL.id);

  const { tlStatus, tlDetails, dai_jdt83: daiJDT83 } = activeTL;

  const images = useMemo(
    () => daiJDT83?.images?.filter((el) => el.phase) ?? [],
    [daiJDT83?.images]
  );

  const imgProps = {
    phasesSet,
    tlStatus,
    id: activeTL.id,
    isError,
    tlDetails,
    images,
  };

  const settings = {
    ...getSliderSettings(0, BREAK_POINTS_PHASES),
    infinite: false,
    swipeToSlide: true,
    dots: true,
    nextArrow: <ArrowCustom />,
    prevArrow: <ArrowCustom />,
    customPaging: () => <DotsCustom />,
  };

  return (
    <Slider {...settings} className={styles.slider}>
      {phasesArray.map((phase) => (
        <div key={phase.phase} className={styles.phaseCardWrapper}>
          <PhaseCard
            phase={phase}
            tactTypeObj={getTypeTactObj(phase.tactType)}
            {...imgProps}
            onSelectPhase={(value) => setSelectedPhase(value)}
            isSliderCard
            isDisabled={!isActiveControlBtn}
          />
        </div>
      ))}
    </Slider>
  );
};

export default SliderPhase;
