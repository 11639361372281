export type SymbolType =
  | 'esriSLS'
  | 'esriPFS'
  | 'esriPMS'
  | 'esriSFS'
  | 'esriSMS'
  | 'esriTS'
  | 'esriCLS';

export type SymbolStyle =
  | 'esriSLSDash'
  | 'esriSLSDashDot'
  | 'esriSLSDashDotDot'
  | 'esriSLSDot'
  | 'esriSLSLongDash'
  | 'esriSLSLongDashDot'
  | 'esriSLSNull'
  | 'esriSLSShortDash'
  | 'esriSLSShortDashDot'
  | 'esriSLSShortDashDotDot'
  | 'esriSLSShortDot'
  | 'esriSLSSolid';

export type RendererSymbol =
  | PictureFillSymbols
  | PictureMarkerSymbol
  | SimpleFillSymbol
  | SimpleLineSymbol
  | SimpleMarkerSymbol
  | CartographicLineSymbol
  | TextSymbol;

export interface DrawingInfo {
  showLabels: boolean;
  renderer: DrawingInfoRenderer;
  labelingInfo: LabelingInfo[];
}

export type DrawingInfoRenderer =
  | SimpleRenderer
  | ClassBreaksRenderer
  | Heatmap;

export interface Renderer {
  type:
    | 'simple'
    | 'classBreaks'
    | 'unique-value'
    | 'dictionary'
    | 'dot-densit'
    | 'heatmap'
    | 'pie-chart';
}

export interface SimpleRenderer extends Renderer {
  symbol: RendererSymbol;
}

export interface ClassBreaksRenderer extends Renderer {
  classBreakInfos: ClassBreakInfo[];
  classificationMethod:
    | 'esriClassifyDefinedInterval'
    | 'esriClassifyEqualInterval'
    | 'esriClassifyGeometricalInterval'
    | 'esriClassifyManual'
    | 'esriClassifyNaturalBreaks'
    | 'esriClassifyQuantile'
    | 'esriClassifyStandardDeviation';
  defaultLabel: string;
  defaultSymbol: RendererSymbol;
  field: string;
  minValue: number;
  normalizationField: string;
  normalizationTotal: string;
  normalizationType:
    | 'esriNormalizeByField'
    | 'esriNormalizeByLog'
    | 'esriNormalizeByPercentOfTotal';
  rotationExpression: string;
  rotationType: 'arithmetic' | 'geographic';
  valueExpression: unknown;
  valueExpressionTitle: string;
  visualVariables: unknown[];
}

export interface Heatmap extends Renderer {
  blurRadius: number;
  colorStops: {
    ratio: number;
    color: number[];
  }[];
  field: string;
  legendOptions: {
    maxLabel: string;
    minLabel: string;
    tile: string;
  };
  maxDensity: number;
  maxPixelIntensity: number;
  minDensity: number;
  minPixelIntensity: number;
  radius: number;
  referenceScale: number;
}

export interface ISymbol {
  type: SymbolType;
}

export interface Outline {
  type: SymbolType;
  style: SymbolStyle;
  color: number[];
  width: number;
}

export interface PictureFillSymbols extends ISymbol {
  width: number;
  height: number;
  angle: number;
  xoffset: number;
  yoffset: number;
  xscale: number;
  yscale: number;
  contentType: string;
  imageData: string;
  url: string;
  outline: Outline;
}

export interface PictureMarkerSymbol extends ISymbol {
  url: string;
  imageData: string;
  contentType: string;
  width?: number;
  height?: number;
  angle: number;
  xoffset: number;
  yoffset: number;
}

export interface SimpleFillSymbol extends ISymbol {
  style:
    | 'esriSFSBackwardDiagonal'
    | 'esriSFSCross'
    | 'esriSFSDiagonalCross'
    | 'esriSFSForwardDiagonal'
    | 'esriSFSHorizontal'
    | 'esriSFSNull'
    | 'esriSFSSolid'
    | 'esriSFSVertical';
  color: number[];
  outline: Outline;
}

export interface SimpleLineSymbol extends ISymbol {
  style: SymbolStyle;
  color: number[];
  width: number;
}

export interface SimpleMarkerSymbol extends ISymbol {
  style:
    | 'esriSMSCircle'
    | 'esriSMSCross'
    | 'esriSMSDiamond'
    | 'esriSMSSquare'
    | 'esriSMSTriangle'
    | 'esriSMSX';
  color: number[];
  size: number;
  angle: number;
  xoffset: number;
  yoffset: number;
  outline: Outline;
}

export interface CartographicLineSymbol extends ISymbol {
  color: number[];
  width: number;
  style: SymbolStyle;
  cap: keyof typeof DIC_CLS_CAP;
  join: 'esriLJSMiter' | 'esriLJSBevel' | 'esriLJSRound';
  miterLimit: number;
}

export interface TextSymbol extends ISymbol {
  color: number[];
  text?: string;
  backgroundColor: number[];
  borderLineSize: number;
  borderLineColor: number[];
  haloSize: number;
  haloColor: number[];
  verticalAlignment: CanvasTextBaseline;
  horizontalAlignment: CanvasTextAlign;
  rightToLeft: boolean;
  angle: number;
  xoffset: number;
  yoffset: number;
  kerning: boolean;
  font: {
    family: string;
    size: number;
    style: 'normal' | 'italic' | 'oblique';
    weight: 'bold' | 'bolder' | 'lighter' | 'normal';
    decoration: 'none' | 'line-through' | 'underline';
  };
}

export interface ClassBreakInfo {
  classMaxValue: number;
  classMinValue: number;
  label: string;
  description: string;
  symbol: RendererSymbol;
}

export interface LabelingInfo {
  allowOverlapOfFeatureBoundary: 'allow' | 'avoid' | 'exclude';
  allowOverlapOfFeatureInterior: 'allow' | 'avoid' | 'exclude';
  allowOverlapOfLabel: 'allow' | 'avoid' | 'exclude';
  allowOverrun: boolean;

  deconflictionStrategy: 'dynamic' | 'dynamicNeverRemove' | 'none' | 'static';

  fieldInfos: FieldInfos[];

  labelAngleInfo: LabelAngleInfo;
  labelExpression: string;
  labelExpressionInfo: string;
  labelPlacement:
    | 'esriServerLinePlacementAboveAfter'
    | 'esriServerLinePlacementAboveAlong'
    | 'esriServerLinePlacementAboveBefore'
    | 'esriServerLinePlacementAboveEnd'
    | 'esriServerLinePlacementAboveStart'
    | 'esriServerLinePlacementBelowAfter'
    | 'esriServerLinePlacementBelowAlong'
    | 'esriServerLinePlacementBelowBefore'
    | 'esriServerLinePlacementBelowEnd'
    | 'esriServerLinePlacementBelowStart'
    | 'esriServerLinePlacementCenterAfter'
    | 'esriServerLinePlacementCenterAlong'
    | 'esriServerLinePlacementCenterBefore'
    | 'esriServerLinePlacementCenterEnd'
    | 'esriServerLinePlacementCenterStart'
    | 'esriServerPointLabelPlacementAboveCenter'
    | 'esriServerPointLabelPlacementAboveLeft'
    | 'esriServerPointLabelPlacementAboveRight'
    | 'esriServerPointLabelPlacementBelowCenter'
    | 'esriServerPointLabelPlacementBelowLeft'
    | 'esriServerPointLabelPlacementBelowRight'
    | 'esriServerPointLabelPlacementCenterCenter'
    | 'esriServerPointLabelPlacementCenterLeft'
    | 'esriServerPointLabelPlacementCenterRight'
    | 'esriServerPolygonPlacementAlwaysHorizontal';
  lineConnection: 'minimizeLabels' | 'none' | 'unambiguousLabels';
  lineOrientation: 'direction' | 'page' | 'unconstrained';
  maxScale: number;
  minScale: number;
  multiPart:
    | 'labelLargest'
    | 'labelPerFeature'
    | 'labelPerPart'
    | 'labelPerSegment';
  name: string;
  offsetDistance: number;
  priority: number;
  removeDuplicates: 'all' | 'featureType' | 'labelClass' | 'none';
  removeDuplicatesDistance: number;
  repeatLabel: boolean;
  repeatLabelDistance: number;
  stackAlignment: 'dynamic' | 'textSymbol';
  stackBreakPosition: 'after' | 'before';
  stackLabel: boolean;
  stackRowLength: number;
  stackSeparators: StackSeparators[];
  symbol: TextSymbol;
  textLayout: 'followFeature' | 'horizontal' | 'perpendicular' | 'straight';
  useClippedGeometry: boolean;
  useCodedValues: boolean;
  where: string;
}

export interface FieldInfos {
  fieldName: string;
  format: {
    places: number;
    digitSeparator: boolean;
  };
}

export interface LabelAngleInfo {
  angleExpressionInfo: {
    title: string;
    expression: string;
    returnType: 'number' | 'sting';
  };
  rotationType: 'arithmetic' | 'geographic';
}

export interface StackSeparators {
  separator: string;
  visible: boolean;
  forced: boolean;
  breakPosition: 'after' | 'automatic' | 'before';
}

export enum DIC_CLS_CAP {
  'esriLCSButt' = 'butt',
  'esriLCSRound' = 'round',
  'esriLCSSquare' = 'square',
}

export enum DIC_LINE_CAP {
  esriSMSCircle = 'round',
  esriSMSCross = 'round',
  esriSMSDiamond = 'square',
  esriSMSSquare = 'square',
  esriSMSTriangle = 'butt',
  esriSMSX = 'butt',
}
