import mapObjectsApi from '../../api/mapObjects/mapObjectsApi';
import { MapObject } from '../../ts/enums/enums';
import { BasicMapDataZod } from '../../ts/models/mapObject.model';

const service = {
  async getObjects<T extends BasicMapDataZod>(
    type: MapObject,
    regionId: number
  ) {
    const data = await mapObjectsApi.fetchFullObject<T>(type, regionId);

    return { data: data ?? [], isOk: Boolean(data?.length) };
  },
};

export default service;
