import { Button } from 'antd';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useRef, useState } from 'react';
import { BsCheck2 } from 'react-icons/bs';

import rootStore from '../../../../../../stores/rootStore/rootStore';
import ConfirmBlock from '../../../../../Panel/ScriptsControl/SelectModule/DispatchGroupControl/DipatchGroupDetailed/ControlCard/ConrolPanel/ControlBtn/ConfirmBlock/ConfirmBlock';
import Confirm, { ConfirmProps } from '../../../../../ui-kit/Confirm/Confirm';
import Popover from '../../../../../ui-kit/Popover/Popover';

import styles from './SelectButton.module.scss';

interface SelectButtonProps {
  className?: string;
  phase: number;
  isDisabled: boolean;
  confirmProps?: ConfirmProps;
  tipPopover: U<string>;
  onClick: () => void;
}

const SelectButton: FC<SelectButtonProps> = ({
  phase,
  onClick,
  isDisabled,
  tipPopover,
  confirmProps,
}) => {
  const { interfaceTipsDelay } = rootStore.uiStore;
  const { selectedPhase } = rootStore.detailedStore;

  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const cardRef = useRef<HTMLButtonElement>(null);

  const isSelectedPhase = selectedPhase === phase;
  const title = !isSelectedPhase ? 'Удержать фазу' : <BsCheck2 />;

  const handleClick = () => {
    onClick();
    setIsOpenConfirm(true);
  };

  const handleCancel = () => setIsOpenConfirm(false);

  useEffect(() => {
    setIsOpenConfirm(false);
  }, [confirmProps?.isOpen]);

  const btnStyle = classNames({
    [styles.btnSelect]: true,
    [styles.active]: isSelectedPhase,
  });

  const popoverContent = tipPopover ?? `Выбрать фазу №${phase} для удержания?`;

  const popOverProps = {
    content: popoverContent,
    placement: 'bottom',
    tipsDelay: interfaceTipsDelay,
    open: isOpenConfirm ? false : undefined,
  } as const;

  const MainBlock = (
    <Popover {...popOverProps}>
      <Button
        className={btnStyle}
        type="primary"
        id={`${phase}`}
        onClick={handleClick}
        size="small"
        disabled={isDisabled}
        ref={cardRef}
      >
        {title}
      </Button>
    </Popover>
  );

  if (!confirmProps) return MainBlock;

  return (
    <Confirm
      {...confirmProps}
      title={
        <ConfirmBlock
          title={confirmProps.title}
          setIsOpenConfirm={setIsOpenConfirm}
          cardRef={cardRef}
        />
      }
      onCancel={handleCancel}
      isOpen={isOpenConfirm}
    >
      {MainBlock}
    </Confirm>
  );
};

export default observer(SelectButton);
