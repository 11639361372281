import { FC } from 'react';

import { Events, System } from '../../../../../ts/enums/enums';
import { isMapObj, isSystem } from '../../../../../ts/models/mapObject.model';
import ModeInfo from '../../../../Mapper/TooltipContent/MapIconContent/ModeInfo/ModeInfo';
import {
  DISABLED_POPOVER_TEXT,
  DisabledTypes,
} from '../../../../Panel/MainPanelTabs/AllSystemsTab/constants/constants';
import { DataType } from '../../Accordion';

import styles from './PopoverContent.module.scss';

interface PopoverContentProps {
  mapObject: DataType;
  system: System | Events;
  disabledType: Nullish<DisabledTypes>;
}

const PopoverContent: FC<PopoverContentProps> = ({
  mapObject,
  system,
  disabledType,
}) => {
  const isStatus = isSystem(system) && isMapObj(mapObject);

  const {
    deviceInfo: { caption },
  } = mapObject;

  const disabledText = disabledType
    ? DISABLED_POPOVER_TEXT[disabledType]
    : null;

  return (
    <div className={styles.container}>
      <h4>{caption}</h4>
      {disabledText && <p className={styles.disabledText}>{disabledText}</p>}
      {isStatus && (
        <ModeInfo
          activeItem={mapObject}
          system={system}
          isLine={false}
          isExtId={false}
        />
      )}
    </div>
  );
};

export default PopoverContent;
