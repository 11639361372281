import { uniqueId } from 'lodash';

import { getIntervalFormatted } from '../helpers/getIntervalFormatted';
import { CalcData } from '../models/chart.model';

import styles from './CustomTooltip.module.scss';
interface ICustomTooltip {
  active?: boolean;
  label?: number;
  payload?: any;
  calcData: CalcData[];
  keyValue: string;
  interval: number;
}

const CustomTooltip = ({
  active,
  payload,
  label,
  calcData,
  keyValue,
  interval,
}: ICustomTooltip) => {
  if (active && payload && payload.length && label !== undefined) {
    const intervalDuration = getIntervalFormatted(interval);

    return (
      <div className={styles.container}>
        <h4>Диапазон:</h4>
        <p>{calcData[label][keyValue]}</p>
        <p>Интервал: {intervalDuration}</p>
        {payload.map((el: any) => (
          <p
            key={uniqueId()}
            className={styles.payload}
            style={{ color: el.color }}
          >{`${el.dataKey}: ${el.value}`}</p>
        ))}
      </div>
    );
  }

  return null;
};

export default CustomTooltip;
