import {
  LEFT_OFFSET,
  LEFT_PANEL_WIDTH,
  MENU_WIDTH,
  MIN_OFFSET,
  MINIMAP_WIDTH,
  RIGHT_OFFSET,
  RIGHT_PANEL_WIDTH,
  SCALE_LINE_WIDTH,
} from '../constants';

export const getOffsets = (
  isLeft: boolean,
  isRight: boolean,
  isMiniMap: boolean,
  isScaleLine: boolean
) => {
  let leftOffset = LEFT_OFFSET + MIN_OFFSET + MENU_WIDTH;
  let rightOffset = RIGHT_OFFSET + MIN_OFFSET + RIGHT_OFFSET;

  if (isLeft) {
    leftOffset += LEFT_PANEL_WIDTH;
  }

  if (isMiniMap) {
    leftOffset += MINIMAP_WIDTH;
  }

  if (isScaleLine) {
    leftOffset += SCALE_LINE_WIDTH;
  }

  if (isRight) {
    rightOffset += RIGHT_PANEL_WIDTH;
  }

  return {
    left: leftOffset,
    right: rightOffset,
  };
};
