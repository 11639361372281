import { Select } from 'antd';
import { SelectProps } from 'antd/lib';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useMemo, useState } from 'react';

import rootStore from '../../../../../stores/rootStore/rootStore';
import Popover, {
  PopoverWithoutChildren,
} from '../../../../ui-kit/Popover/Popover';
import {
  ProgramsInfo,
  SelectedPlan,
} from '../../PlanSelect/models/plansControl.model';
import { getSelectLabels } from '../../TemporaryPlan/helpers/getSelectLabels';

import styles from './SelectPlanNumber.module.scss';

interface SelectPlanNumberProps {
  id: number;
  programInfo: ProgramsInfo;
  selectedPlan: SelectedPlan;
  setSelectedPlan: SetState<SelectedPlan>;
}

const SelectPlanNumber: FC<SelectPlanNumberProps> = ({
  id,
  programInfo,
  selectedPlan,
  setSelectedPlan,
}) => {
  const { interfaceTipsDelay } = rootStore.uiStore;
  const { isActiveControlBtn } = rootStore.detailedStore;

  const [widthWindow, setWidthWindow] = useState(0);

  const { isPlansLoading, programs, sgnProgramValues } = programInfo;

  const options = useMemo(
    () =>
      getSelectLabels(
        {
          programs,
          sgnProgramValues,
          id,
        },
        widthWindow
      ),
    [id, programs, sgnProgramValues, widthWindow]
  );

  const onChangeSelect = (value: string, option: any) => {
    const phaseGroupNum = Number(option.label.props.content.key);

    setSelectedPlan({
      label: `Выбранный план: ${value}`,
      planId: phaseGroupNum,
    });
  };

  useEffect(() => {
    const changeSize = () => {
      setWidthWindow(window.innerWidth);
    };

    window.addEventListener('resize', changeSize);

    return () => {
      window.removeEventListener('resize', changeSize);
    };
  }, []);

  const selectProps: SelectProps = {
    className: styles.select,
    showSearch: true,
    placeholder: 'Выберите базовый план',
    optionFilterProp: 'children',
    onChange: onChangeSelect,
    filterOption: (input: string, option: any) =>
      (option?.value ?? '').toLowerCase().includes(input.toLowerCase()),
    options,
    value: selectedPlan.label,
    disabled: !isActiveControlBtn,
    loading: isPlansLoading,
  };

  const popoverProps: PopoverWithoutChildren = {
    content: 'Обязательное поле для заполнения',
    placement: 'right',
    tipsDelay: interfaceTipsDelay,
  } as const;

  return (
    <Popover {...popoverProps}>
      <div className={styles.spaceHorizontal}>
        <h4>Выберите план: * </h4>
        <Select {...selectProps} />
      </div>
    </Popover>
  );
};

export default observer(SelectPlanNumber);
