export enum DisabledTypes {
  UnknownCoordinates = 'UnknownCoordinates',
  NoDataDetailedTL = 'NoDataDetailedTL',
  IncorrectControlStatus = 'IncorrectControlStatus',
}

export const DISABLED_POPOVER_TEXT: Record<DisabledTypes, string> = {
  UnknownCoordinates: 'Данный объект находится за пределами региона',
  NoDataDetailedTL: 'Недостаточно данных для перехода в подробнее',
  IncorrectControlStatus: 'В данном статусе СО невозможно управлять',
};
