import { Style } from 'ol/style';

import { SimpleRenderer } from './renderer.model';
import { Symbol } from './Symbol';

/**
 * {
 *   "type": "simple",
 *   "symbol": {
 *     "color": [
 *       0,
 *       0,
 *       128,
 *       128
 *     ],
 *     "size": 15,
 *     "angle": 0,
 *     "xoffset": 0,
 *     "yoffset": 0,
 *     "type": "esriSMS",
 *     "style": "esriSMSCircle",
 *     "outline": {
 *       "color": [
 *         0,
 *         0,
 *         128,
 *         255
 *       ],
 *       "width": 0.99975,
 *       "type": "esriSLS",
 *       "style": "esriSLSSolid"
 *     }
 *   }
 * }
 * @param renderer
 */
function Simply(renderer: SimpleRenderer): Style | undefined {
  const { symbol } = renderer;
  const res = Symbol(symbol);

  return res;
}

export { Simply };
