import { useCallback, useMemo, useState } from 'react';

import { IPieChartData } from '../models/circleChart.model';

interface UseCircleChartProps {
  activeSectors: U<string[]>;
  data: IPieChartData[];
  isHalfPie: boolean;
}

const useCircleChart = ({
  activeSectors,
  data,
  isHalfPie,
}: UseCircleChartProps) => {
  const [activeIndex, setActiveIndex] = useState<number[] | number>(0);

  const handlerMouseEnter = useCallback(
    (data: any, i: number) => !isHalfPie && setActiveIndex(i),
    [isHalfPie]
  );
  const handleMouseLeave = useCallback(() => {
    if (!activeSectors?.length || isHalfPie) return;

    const indexes = data.reduce((acc: number[], { name }, index) => {
      const isActive = !!activeSectors?.find((el) => el === name);

      if (isActive) acc.push(index);

      return acc;
    }, []);

    indexes.length && setActiveIndex(indexes);
  }, [activeSectors, data, isHalfPie]);

  const total = useMemo(
    () => data.reduce((acc, { value }) => acc + value, 0),
    [data]
  );

  return { activeIndex, total, handlerMouseEnter, handleMouseLeave };
};

export default useCircleChart;
