import { CiImageOff } from 'react-icons/ci';

import { Geocode } from '../../../../../api/integration/gis/geocode/model/geocode.model';
import { ReactComponent as AllSystem } from '../../../../../assets/icons/map/group_ico.svg';
import { ReactComponent as CameraIco } from '../../../../../assets/icons/signs/camera.svg';
import { ReactComponent as DetectorIco } from '../../../../../assets/icons/signs/detector.svg';
import { ReactComponent as MeteoIco } from '../../../../../assets/icons/signs/meteo.svg';
import { ReactComponent as LightsIco } from '../../../../../assets/icons/signs/tr_lights.svg';
import { findBy, findById } from '../../../../../helpers/findBy';
import { getSystemTitle } from '../../../../../helpers/getSystemTitle';
import {
  ISystemsInfo,
  TObjMap,
} from '../../../../../stores/mapDataStore/mapDataStore.model';
import {
  StatusFilteredMonitoring,
  TActiveSystemMonitoring,
} from '../../../../../stores/monitoringPanelStore/monitoringPanelStore.model';
import { System } from '../../../../../ts/enums/enums';
import { isSearchedDevice } from '../../../../Panel/MainPanelTabs/AllSystemsTab/helpers';
import { ALL_SYSTEM_TITLE } from '../../../constants/constants';
import { IDataList } from '../../model/monitoring.model';

export const getAvatarIcon = (system: TActiveSystemMonitoring) => {
  switch (system) {
    case System.Lights:
      return <LightsIco />;
    case System.Cameras:
      return <CameraIco />;
    case System.Detectors:
      return <DetectorIco />;
    case System.Meteo:
      return <MeteoIco />;
    case ALL_SYSTEM_TITLE:
      return <AllSystem />;
    default:
      return <CiImageOff />;
  }
};

export const getTitleDevice = (id: number, caption: string) =>
  `id: ${id} | ${caption}`;

const getSystemDeviceData = (
  systemsData: ISystemsInfo[],
  systemsList: System[]
) => {
  return systemsList.map((system) => ({
    system,
    data: findBy(systemsData, system, 'title')?.data ?? [],
  }));
};

const isSearchedItem = (device: TObjMap, value: N<string>) => {
  if (!value) return true;

  const isSearchedItem = isSearchedDevice(device, value);

  const isDscFits = device.deviceInfo.dsc
    .toString()
    .trim()
    .toLowerCase()
    .includes(value.toLowerCase());

  return isSearchedItem || isDscFits;
};

interface FormattedListProps {
  systemsData: ISystemsInfo[];
  systemsList: System[];
  statusArr: StatusFilteredMonitoring[];
  searchMonitoring: N<string>;
  addressInfo: Geocode[];
}

export const getFormattedListDevices = ({
  systemsData,
  systemsList,
  statusArr,
  searchMonitoring,
  addressInfo,
}: FormattedListProps) => {
  const deviceData = getSystemDeviceData(systemsData, systemsList);
  const isEmptyFilter = !statusArr.length;
  const isEmptySearch = !searchMonitoring;

  return deviceData
    .map((systemObj) => {
      return systemObj.data.reduce((acc: IDataList[], el) => {
        const { deviceStatusId, caption, dsc } = el.deviceInfo;

        const address = findById(addressInfo, el.id)?.address ?? null;

        const isEmpty = isEmptyFilter && isEmptySearch;
        const isCurrentStatus = !!findBy(statusArr, deviceStatusId, 'status');
        const isSearched = isSearchedItem(el, searchMonitoring);

        const title = getSystemTitle(systemObj.system, el);

        if (
          (isCurrentStatus && isSearched) ||
          isEmpty ||
          (isSearched && isEmptyFilter)
        ) {
          acc.push({
            avatar: getAvatarIcon(systemObj.system),
            status: deviceStatusId,
            title: `${title} ${caption}`,
            description: dsc || 'Описание отсутствует',
            id: el.id,
            address,
            system: systemObj.system,
          });
        }

        return acc;
      }, []);
    })
    .flat();
};
