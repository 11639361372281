import { Card, List, Input } from 'antd';
import React, { useLayoutEffect, useState } from 'react';
import { MdSearch, MdSettings } from 'react-icons/md';

import {
  DEFAULT_PAGE_SETTINGS,
  MIN_SEARCH_LENGTH,
  SEARCH_CONTENT,
  SEARCH_PAGE_SETTINGS,
  SEARCH_PANEL_CONTAINER_ID,
  SEARCH_RESULT_ICON_SIZE,
  SEARCH_STYLES,
} from '../constants';
import { getMapCenterText, getSearchPageSize } from '../helpers';
import { ISearchPanelProps } from '../search.model';

import styles from '../SearchButton.module.scss';

const { Search } = Input;

const SearchPanel = (props: ISearchPanelProps) => {
  const [searchPageSize, setSearchPageSize] = useState(
    getSearchPageSize(SEARCH_PANEL_CONTAINER_ID)
  );

  const searchPlaceholder = `Название / широта, долгота' (${getMapCenterText(
    props.map
  )})`;

  useLayoutEffect(() => {
    const updateSize = () => {
      setSearchPageSize(getSearchPageSize(SEARCH_PANEL_CONTAINER_ID));
    };

    window.addEventListener('resize', updateSize);

    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const paginationSettings = () => {
    if (props.dataSource.length < searchPageSize) {
      return DEFAULT_PAGE_SETTINGS;
    }

    return {
      ...SEARCH_PAGE_SETTINGS,
      pageSize: searchPageSize,
    };
  };

  return (
    <Card
      bordered={false}
      className={styles.searchPanel}
      styles={{ body: SEARCH_STYLES.panelBodyStyle }}
    >
      <div className={styles.searchPanelTitle}>
        <MdSearch size={SEARCH_RESULT_ICON_SIZE} style={{ marginRight: 5 }} />
        {SEARCH_CONTENT.searchTitle}
      </div>

      <div className={styles.searchContainer}>
        <Search
          value={props.searchText}
          placeholder={searchPlaceholder}
          onChange={(evt) => {
            props.onChange(evt.target.value);
          }}
          onSearch={props.onSearch}
          loading={props.loading}
          allowClear
          addonBefore={<MdSettings onClick={props.onSettings} />}
          minLength={MIN_SEARCH_LENGTH}
        />
      </div>

      <List
        id={SEARCH_PANEL_CONTAINER_ID}
        className={styles.listContainer}
        dataSource={props.dataSource}
        pagination={paginationSettings() || props.pagination}
        renderItem={props.renderItem}
      />
    </Card>
  );
};

export default SearchPanel;
