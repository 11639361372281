import { Dayjs } from 'dayjs';
import { useCallback, useEffect, useState } from 'react';

import { SECOND } from '../../../../../constants/constants';
import rootStore from '../../../../../stores/rootStore/rootStore';
import { getTimeWithTimeZone } from '../../../TlDetectors/IntancyGraph/TitleBtns/TimeLocalCard/helpers/getTimeWithTimeZone';

let timerId: NodeJS.Timer;

const useTimer = (startTime: Dayjs, endTime: Dayjs) => {
  const { setStopTimerTrigger } = rootStore.detailedStore;

  const [sec, setSec] = useState<N<number>>(null);
  const [isPhaseHold, setIsPhaseHold] = useState<boolean>(false);

  const tick = useCallback(() => {
    const dateNow = getTimeWithTimeZone().dateFormattedJS;

    const isHold = startTime.diff(dateNow) < 0 && endTime.diff(dateNow) > 0;

    const diff = (!isHold ? startTime : endTime).diff(dateNow, 's');

    setIsPhaseHold(startTime.diff(dateNow) < 0 && endTime.diff(dateNow) > 0);
    setSec(diff);
  }, [startTime, endTime]);

  useEffect(() => {
    if (!sec || sec >= 0) return;

    clearInterval(timerId);
    setSec(0);
    setStopTimerTrigger();
  }, [sec, setStopTimerTrigger]);

  useEffect(() => {
    clearInterval(timerId);
    tick();
    timerId = setInterval(() => tick(), SECOND);

    return () => clearInterval(timerId);
  }, [tick]);

  return { sec, isPhaseHold };
};

export default useTimer;
