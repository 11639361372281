import { Button, Popconfirm } from 'antd';
import { FC, useState } from 'react';
import { BsDownload } from 'react-icons/bs';

import {
  exportFile,
  exportFileService,
} from '../../../../helpers/export/helpers';
import { sanitizeAndCrop, sanitizeText } from '../../../../helpers/sanitize';
import { FileExtensions } from '../../../../ts/models/table.model';
import useExport from '../hooks/useCsvExport';

import ChangeExportType from './ChangeExportType/ChangeExportType';
import {
  EXPORT_DATA_TEXT,
  OK_TEXT,
  CANCEL_TEXT,
  CONFIRM_PLACEMENT,
} from './constants';
import { IExport } from './model';

import styles from './Export.module.scss';

const Export: FC<IExport> = ({ data, headers, title }) => {
  const { exportData } = useExport({
    dataRows: data,
    headerItems: headers,
  });

  const [type, setType] = useState(FileExtensions.xlsx);

  const handleExport = () => {
    type === FileExtensions.xlsx &&
      exportFile(data, type, sanitizeAndCrop(title), headers);
    type === FileExtensions.xlsxService &&
      exportFileService(data, type, sanitizeText(title), headers);
    type === FileExtensions.csv && exportData();
  };

  return (
    <Popconfirm
      placement={CONFIRM_PLACEMENT}
      title={<ChangeExportType onChange={setType} value={type} />}
      onConfirm={handleExport}
      okText={OK_TEXT}
      cancelText={CANCEL_TEXT}
      icon={<BsDownload className={styles.downloadIcon} />}
    >
      <Button>{EXPORT_DATA_TEXT}</Button>
    </Popconfirm>
  );
};

export default Export;
