import { DatePicker } from 'antd';
import type { DatePickerProps } from 'antd/lib';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import rootStore from '../../../../../../stores/rootStore/rootStore';
import TimePicker, {
  TimePickerBasicProps,
} from '../../../../../ui-kit/Picker/TimePicker/TimePicker';
import Popover from '../../../../../ui-kit/Popover/Popover';

import styles from './TimeCardTemporaryPlan.module.scss';

interface TimeCardTemporaryPlanProps {
  timePickerProps: TimePickerBasicProps;
  datePickerProps: DatePickerProps;
}

const TimeCardTemporaryPlan: FC<TimeCardTemporaryPlanProps> = ({
  timePickerProps,
  datePickerProps,
}) => {
  const { tipsValues } = rootStore.uiStore;

  const obligatoryFieldProps = {
    content: 'Обязательное поле для заполнения',
    placement: 'right',
    tipsDelay: tipsValues.profileTipsDelay,
  } as const;

  return (
    <div className={styles.timePickerCard}>
      <div className={styles.spaceVertical}>
        <Popover {...obligatoryFieldProps}>
          <div className={styles.spaceHorizontalDuration}>
            <h4>Дата: *</h4>
            <DatePicker {...datePickerProps} />
          </div>
        </Popover>

        <Popover {...obligatoryFieldProps}>
          <div className={styles.spaceHorizontalDuration}>
            <h4>Длительность: *</h4>
            <TimePicker {...timePickerProps} />
          </div>
        </Popover>
      </div>
    </div>
  );
};

export default observer(TimeCardTemporaryPlan);
