import { FC } from 'react';

import { MANAGE_PANEL } from './constants/constants';
import ControlBtn from './ControlBtn/ControlBtn';

import styles from './ControlPanel.module.scss';

interface ControlPanelProps {
  tlId: number;
  isActiveCoordTL: boolean;
}

const ControlPanel: FC<ControlPanelProps> = ({ tlId, isActiveCoordTL }) => {
  return (
    <div className={styles.container}>
      {MANAGE_PANEL.map((el) => (
        <ControlBtn
          key={el.com}
          mode={el.com}
          tlId={tlId}
          isActiveCoordTL={isActiveCoordTL}
        />
      ))}
    </div>
  );
};

export default ControlPanel;
