import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import NOTICE from '../../../../constants/notificationConstants';
import rootStore from '../../../../stores/rootStore/rootStore';
import DefaultChecked from '../../../ui-kit/DefaultChecked/DefaultChecked';
import { StatusInfoBtn } from '../../../ui-kit/InfoBtn/constants/constants';
import InfoBtn from '../../../ui-kit/InfoBtn/InfoBtn';
import useTimerPlannedComand from '../../TlManagement/hooks/useTimerPlannedComand';
import Timer from '../../TlManagement/Timer/Timer';

import styles from './PhasesTimer.module.scss';

interface PhasesTimerProps {
  id: number;
}

const PhasesTimer: FC<PhasesTimerProps> = ({ id }) => {
  const { stopTimerTrigger } = rootStore.detailedStore;

  const { timerInfo, isLoading, isError } = useTimerPlannedComand({
    id,
    stopTimerTrigger,
  });

  return (
    <div className={styles.container}>
      <DefaultChecked isLoading={isLoading}>
        {timerInfo ? (
          <Timer {...timerInfo} className={styles.timer} />
        ) : (
          <p className={styles.title}>Нет запланированных команд</p>
        )}
      </DefaultChecked>
      <InfoBtn
        isShow={isError}
        status={StatusInfoBtn.Error}
        popoverContent={NOTICE.PLANNED_CMD_ERROR}
      />
    </div>
  );
};

export default observer(PhasesTimer);
