import { useCallback, useEffect, useRef, useState } from 'react';

import { StreamType } from '../../../../api/mapObjects/cameras/cameras.zod';
import { AUTO_UPDATE_STATIC_COMPONENTS_TIME } from '../../../../constants/constants';
import eventBus from '../../../../eventBus';
import { findBy } from '../../../../helpers/findBy';
import {
  ERROR_CAMERA,
  ERROR_TEXT,
  ERROR_URL,
  INITIAL_PROPERTIES,
} from '../../constants/constants';
import { getPreparedActiveStreamUrl } from '../../helpers/getPreparedActiveStreamUrl/getPreparedActiveStreamUrl';
import {
  ActiveStream,
  LoadProperties,
  VideoCameraProps,
} from '../../models/VideoCamera.model';

interface useVideoCameraProps {
  cameraData: U<VideoCameraProps>;
}

const useVideoCamera = ({ cameraData }: useVideoCameraProps) => {
  const [loadProperties, setLoadProperties] =
    useState<LoadProperties>(INITIAL_PROPERTIES);
  const [activeStream, setActiveStream] = useState<N<ActiveStream>>(null);
  const cameraRef = useRef<HTMLIFrameElement | HTMLImageElement>(null);

  const handleUpdate = useCallback(
    (isLoad = true) => {
      activeStream?.url &&
        cameraRef.current?.setAttribute('src', activeStream.url);
      isLoad && setLoadProperties(INITIAL_PROPERTIES);
    },
    [activeStream?.url]
  );

  useEffect(() => {
    let intervalId: U<NodeJS.Timer>;

    handleUpdate();

    if (activeStream?.streamType === StreamType.Jpeg) {
      intervalId = setInterval(() => {
        handleUpdate(false);
      }, AUTO_UPDATE_STATIC_COMPONENTS_TIME);
    }

    return () => clearInterval(intervalId);
  }, [activeStream, handleUpdate]);

  useEffect(() => {
    const unsubscribe = eventBus.videoListUpdate.subscribe(handleUpdate);

    return () => {
      unsubscribe();
    };
  }, [handleUpdate]);

  useEffect(() => {
    setLoadProperties(INITIAL_PROPERTIES);
    cameraRef.current?.removeAttribute('src');

    const defaultPlayer = findBy(
      cameraData?.videoSources ?? [],
      true,
      'defaultView'
    );

    if (!cameraData || !defaultPlayer)
      return onError(cameraData ? ERROR_URL : ERROR_CAMERA);

    const activeStreamUrl = getPreparedActiveStreamUrl(defaultPlayer.url);

    const tread: ActiveStream = {
      name: defaultPlayer.name,
      streamType: defaultPlayer.streamType,
      url: activeStreamUrl,
      id: defaultPlayer.id,
    };

    setActiveStream(tread);
  }, [cameraData]);

  const onLoad = () =>
    setLoadProperties((prev) => ({ ...prev, isLoad: false }));

  const onError = (errorText = ERROR_TEXT) =>
    setLoadProperties({
      textError: errorText,
      isLoad: false,
    });

  return {
    activeStream,
    setActiveStream,
    loadProperties,
    onLoad,
    onError,
    handleUpdate,
  };
};

export default useVideoCamera;
