import { LibraryKeys } from '../../../api/libraries/enums/enums';
import { Dictionaries } from '../uiStore.model';

const {
  AdapterStatus,
  RegionId,
  SubSystemId,
  SystemId,
  SystemStatusId,
  SystemTypeId,
} = LibraryKeys;

export const DICTIONARY_DEFAULT: Dictionaries = {
  [AdapterStatus]: [],
  [RegionId]: [],
  [SubSystemId]: [],
  [SystemId]: [],
  [SystemStatusId]: [],
  [SystemTypeId]: [],
};
