import { FC } from 'react';
import { Joystick } from 'react-joystick-component';
import { IJoystickUpdateEvent } from 'react-joystick-component/build/lib/Joystick';

import { COLORS } from '../../../../constants/colorsConstants';
import { Id } from '../../../../ts/models/constructor.model';
import Item from '../Item/Item';
import Slider from '../Slider/Slider';

import styles from './Offset.module.scss';

export enum OffsetKey {
  X = 'offsetX',
  Y = 'offsetY',
}

const { X, Y } = OffsetKey;

export type OnChange = (key: OffsetKey, val: number, id: Id) => void;

interface OffsetProps {
  onChange: OnChange;
  id?: Id;
  offsetX: number;
  offsetY: number;
  rate?: 1 | 2;
  isVisible?: boolean;
  label?: string;
}

const Offset: FC<OffsetProps> = ({
  offsetX,
  offsetY,
  onChange,
  id = -1,
  rate = 2,
  isVisible = true,
  label = 'Смещение элемента',
}) => {
  if (!isVisible) return null;

  const range = 100 * rate;
  const jRate = 2 * rate;

  const handleJoystick = (e: IJoystickUpdateEvent) => {
    onChange(X, Math.round(e.x ?? 0) * jRate, id);
    onChange(Y, -Math.round(e.y ?? 0) * jRate, id);
  };
  const handleOffsetX = (val: number) => onChange(X, val, id);
  const handleOffsetY = (val: number) => onChange(Y, -val, id);

  return (
    <>
      <Item label={`${label} XY:`}>
        <div className={styles.joystick}>
          <Joystick
            size={100}
            sticky
            baseColor={COLORS.PRIMARY}
            stickColor={COLORS.TURQUOISE}
            move={handleJoystick}
          />
        </div>
      </Item>

      <Slider
        label={`${label} (ось X):`}
        value={offsetX}
        min={-range}
        max={range}
        onChange={handleOffsetX}
      />

      <Slider
        label={`${label} (ось Y):`}
        value={-offsetY}
        min={-range}
        max={range}
        onChange={handleOffsetY}
      />
    </>
  );
};

export default Offset;
