import { ConfigProvider, App as AntdApp, Spin } from 'antd';
import ruRu from 'antd/lib/locale/ru_RU';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { getAntdTheme } from '../../helpers/theme/getAntdTheme';
import useAuth from '../../hooks/useAuth';
import useExtraReload from '../../hooks/useExtraReload';
import useLibraries from '../../hooks/useLibraries/useLibraries';
import useNeedReload from '../../hooks/useNeedReload';
import rootStore from '../../stores/rootStore/rootStore';
import DetailedWrapper from '../DetailedMain/DetailedWrapper/DetailedWrapper';
import InfoPanelWithContext from '../InfoPanel/InfoPanelWithContext';
import Login from '../Login/Login';
import Mapper from '../Mapper/Mapper';
import Panel from '../Panel/Panel';
import QueryDevTools from '../ReactQuery/QueryDevTools';
import RegionSwitcher from '../RegionSwitcher/RegionSwitcher';
import Sidebar from '../Sidebar/Sidebar';
import useSocket from '../Socket/useSocket';

function App() {
  const { regionData, isDark } = rootStore.uiStore;
  const { isAuth, isAppReady, isNeedPageReload } = rootStore.userDataStore;
  const { fetchDataByRegion } = rootStore.mapDataStore;

  useAuth();
  useExtraReload();
  useNeedReload(isNeedPageReload);
  useSocket();
  useLibraries();

  useEffect(() => {
    isAuth && regionData && fetchDataByRegion();
  }, [fetchDataByRegion, regionData, isAuth]);

  if (!isAppReady) {
    return <Spin size="large" fullscreen />;
  }
  if (!isAuth) {
    return <Login />;
  }
  if (!regionData) {
    return <RegionSwitcher />;
  }

  return (
    <ConfigProvider locale={ruRu} theme={getAntdTheme(isDark)}>
      <AntdApp>
        <DndProvider backend={HTML5Backend}>
          <Sidebar />
          <Panel />
          <Mapper />
          <InfoPanelWithContext />
          <DetailedWrapper />
          <QueryDevTools />
        </DndProvider>
      </AntdApp>
    </ConfigProvider>
  );
}

export default observer(App);
