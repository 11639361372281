import type { Feature } from 'ol';
import type { FeatureLike } from 'ol/Feature';
import Point from 'ol/geom/Point';
import DoubleClickZoom from 'ol/interaction/DoubleClickZoom';
import Interaction from 'ol/interaction/Interaction';
import MapBrowserEvent from 'ol/MapBrowserEvent';
import { toLonLat } from 'ol/proj';

import { executeIdentify } from '../../../apiGIS/tasks/identify';
import { CLUSTERS_INFO } from '../../../constants/mapClusterConstants';
import { CURSOR_MAP } from '../../../constants/mapConstants';
import setDisplayStyle from '../../../helpers/setDisplayStyle';
import { TMap } from '../../../stores/mapStore/mapStore.model';
import rootStore from '../../../stores/rootStore/rootStore';
import { IInfoPanelData } from '../../../stores/uiStore/uiStore.model';
import {
  FeatureDeviceProps,
  FeaturesTypes,
  RightPanelType,
} from '../../../ts/enums/enums';
import { IRefs } from '../../Mapper/Mapper.model';
import { getSelectInteraction } from '../../ui-kit/MapLayers/ClusterLayers/helpers/getSelectInteraction';

import { getHit, handleInfoDataByClick, handlePointer } from './handlers';

const { GRAB } = CURSOR_MAP;
const ANIMATION_CORRECTION_CLOSE = 300;

let timeoutAnimationCluster: NodeJS.Timeout;
let timeoutCloseId: N<NodeJS.Timeout>;

type Map = NonNullable<TMap>;

interface HandleClickFeatureProps {
  e: MapBrowserEvent<any>;
  map: Map;
  idSelectFeature: U<IInfoPanelData['id']>;
  refs: IRefs;
}

const getFeatureInfo = (
  features: FeatureLike[],
  idSelectFeature: HandleClickFeatureProps['idSelectFeature']
) => {
  const featureObj = features.at(0);
  const featureArr: N<Feature<Point>[]> =
    featureObj?.get(FeaturesTypes.Features) ?? null;
  const feature = featureArr?.at(0);

  const featureId: N<number> = feature
    ? feature.get(FeatureDeviceProps.Id)
    : null;

  const isFeatureDisabled = !!feature?.get(FeatureDeviceProps.IsDisabled);

  const isCluster = !!(featureArr && featureArr?.length > 1);
  const isFeatureOnCluster = !!featureObj?.get(FeaturesTypes.FeatureOnCluster);
  const isClickedOnSelectedFeatureInCluster =
    isFeatureOnCluster && idSelectFeature === featureId;

  const isClusterLine = featureObj?.get(FeaturesTypes.ClusterLine);
  const isDisabled = !isCluster && feature?.get(FeatureDeviceProps.IsDisabled);

  const isCloseTooltip = isDisabled && isFeatureOnCluster;

  return {
    isCluster,
    isCloseTooltip,
    isClusterLine,
    isClickedOnSelectedFeatureInCluster,
    isFeatureDisabled,
  };
};

const checkCluster = (map: Map) => {
  const { setSelectedClusters } = rootStore.clustersStore;

  const featuresOnCluster = getSelectInteraction(map)
    ?.getLayer()
    ?.getSource()
    ?.getFeatures();

  timeoutCloseId && clearTimeout(timeoutCloseId);

  timeoutCloseId = setTimeout(() => {
    const featuresOnClusterTimeout = getSelectInteraction(map)
      ?.getLayer()
      ?.getSource()
      ?.getFeatures();

    if (!featuresOnClusterTimeout?.length && featuresOnCluster?.length) {
      setSelectedClusters(null);
    }
  }, ANIMATION_CORRECTION_CLOSE);
};

const setDoubleClick = (
  map: Map,
  isCluster: boolean,
  newIsInfoPanel: boolean
) => {
  const doubleClick = map
    .getInteractions()
    .getArray()
    .find((interaction: Interaction) => {
      if (interaction instanceof DoubleClickZoom) return interaction;

      return null;
    });

  doubleClick?.setActive(!newIsInfoPanel || isCluster);
};

export const handleClickFeature = ({
  e,
  map,
  idSelectFeature,
  refs,
}: HandleClickFeatureProps) => {
  const { rightPanelType, setRightPanel, setKeyValue } = rootStore.uiStore;
  const { setMapData } = rootStore.mapDataStore;
  const { setSelectedClusters, setIsClusterClicked } = rootStore.clustersStore;
  const { isMultipleSelect } = rootStore.scriptsControlStore;

  clearTimeout(timeoutAnimationCluster);
  setMapData('coordinates', toLonLat(e.coordinate));

  const features: FeatureLike[] = [];

  map.forEachFeatureAtPixel(e.pixel, (feature: FeatureLike) =>
    features.push(feature)
  );

  const mapItems = executeIdentify(map, e);

  if (!features.length) {
    setMapData('selectedFeature', null);
    setSelectedClusters(null);
    setIsClusterClicked(false);

    timeoutAnimationCluster = setTimeout(() => {
      getSelectInteraction(map)?.clear();
    }, CLUSTERS_INFO.ANIMATION_DURATION);
  }

  const {
    isCluster,
    isCloseTooltip,
    isClusterLine,
    isClickedOnSelectedFeatureInCluster,
    isFeatureDisabled,
  } = getFeatureInfo(features, idSelectFeature);

  if (!isCluster && isFeatureDisabled) return;

  if (isCloseTooltip || isClusterLine || isClickedOnSelectedFeatureInCluster) {
    setKeyValue('isTooltip', false);

    return (map.getTargetElement().style.cursor = GRAB);
  }

  setIsClusterClicked(isCluster);

  checkCluster(map);

  const newRightPanelType = handleInfoDataByClick(e, map, isMultipleSelect);

  const hit = getHit(e, map);

  if (!hit && mapItems.length === 0 && !isMultipleSelect) {
    setDisplayStyle(Object.values(refs).flat());

    return setRightPanel(null);
  }

  setKeyValue('isTooltip', false);
  handlePointer(map, { isPoint: false });

  setDoubleClick(map, isCluster, !!newRightPanelType);

  if (isMultipleSelect) return;

  setKeyValue('mapIdentifiedItems', mapItems);

  const isGisFeatures = mapItems.length > 0;

  if (isGisFeatures) {
    return setRightPanel(newRightPanelType ?? RightPanelType.IdentificationGis);
  }

  return (
    rightPanelType !== newRightPanelType && setRightPanel(newRightPanelType)
  );
};
