import dayjs from 'dayjs';
import { isNumber } from 'lodash';

import { DATE_FORMAT, TIME_FORMAT } from '../../../../../constants/constants';

import { IData } from './getAverage/getAverage';
import { getArrSumInfo } from './getComparableValue';
import { getDateRangeStr } from './getDateRangeStr';
import { ICalcData } from './getFormatDataDt';

export const initialDates = {
  comparableValue: 0,
  date: dayjs().format(DATE_FORMAT),
  timeFrom: dayjs().format(TIME_FORMAT),
  interval: [],
};

export const getGeneral = (data: IData[], format?: string): ICalcData[] => {
  const calcData = data.map((item: IData) => {
    const { sum } = getArrSumInfo(Object.values(item));

    const { dateFrom, dateTo } = item;

    if (isNumber(dateFrom) || isNumber(dateTo)) return initialDates;

    const {
      normalDateTime,
      timeFrom,
      normalDate: interval,
    } = getDateRangeStr(dateFrom, dateTo, format);

    return { date: normalDateTime, comparableValue: sum, timeFrom, interval };
  });

  return calcData;
};
