import { isNull, isNumber } from 'lodash';
import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';

import rootStore from '../../../../../stores/rootStore/rootStore';
import { SelectedTime } from '../../PlanSelect/models/plansControl.model';
import Confirm from '../Modal/Confirm/Confirm';
import EditTable from '../Modal/EditTable/EditTable';
import { ProgramInfo, UseConfirmPlanProps } from '../model/temporaryPlan.model';

import EditPlanButton from './EditPlanButton/EditPlanButton';
import useEditProgram from './hooks/useEditProgram';

interface EditProgramProps {
  selectedPlanId: N<number>;
  id: number;
  confirmProps: {
    programInfo: ProgramInfo;
    selectedTime: SelectedTime;
  };
  clearPlan: () => void;
}

const EditProgram: FC<EditProgramProps> = ({
  selectedPlanId,
  id,
  confirmProps,
  clearPlan,
}) => {
  const { isActiveControlBtn } = rootStore.detailedStore;

  const {
    programInfo: { sgnProgramValues },
  } = confirmProps;

  const [openModal, setOpenModal] = useState<boolean>(false);

  const {
    isEditTable,
    dataTable,
    editTableProps,
    clearDataTable,
    setDefaultDataTable,
  } = useEditProgram({
    selectedPlanId,
    programValues: sgnProgramValues,
  });

  const isEdit = isNumber(selectedPlanId);

  const isError = Boolean(
    isNumber(selectedPlanId) && sgnProgramValues.at(selectedPlanId - 1)?.isError
  );

  const confirmBasicProps: UseConfirmPlanProps = {
    ...confirmProps,
    id,
    readyDataPhases: dataTable,
    selectedPlan: selectedPlanId,
    clearPlanSelect: () => {
      clearPlan();
      clearDataTable();
    },
  };

  const modalEditProps = {
    width: '1400px',
    modalTitle: `Режим редактирования временного плана`,
    openModal,
    onOkConfirm: () => setOpenModal(false),
    setOpenModal,
    handleCancel: setDefaultDataTable,
    okText: 'Применить',
    okButtonProps: { disabled: !isEditTable },
  };

  const isDisabled =
    !isEdit || !isActiveControlBtn || isNull(selectedPlanId) || isError;

  return (
    <>
      <EditPlanButton
        isDisabled={isDisabled}
        isEdit={isEdit}
        isError={isError}
        handleClickEdit={() => setOpenModal(true)}
      />
      <EditTable
        {...editTableProps}
        id={id}
        programInfo={confirmProps.programInfo}
        openModal={openModal}
        editProps={modalEditProps}
      />
      <Confirm {...confirmBasicProps} />
    </>
  );
};

export default observer(EditProgram);
