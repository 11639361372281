import Feature from 'ol/Feature';
import { Style } from 'ol/style';

import { ClassBreaks } from './ClassBreaks';
import {
  ClassBreaksRenderer,
  DrawingInfoRenderer,
  SimpleRenderer,
} from './renderer.model';
import { Simply } from './Simply';

function createStyleFunction(renderer: DrawingInfoRenderer) {
  return function (feature: Feature, resolution: number) {
    return fromRenderer(renderer, feature, resolution);
  };
}

function fromRenderer(
  renderer: DrawingInfoRenderer,
  feature: Feature,
  resolution: number
): Style | undefined {
  let style = undefined;

  if (!renderer) return style;

  switch (renderer.type) {
    case 'simple': {
      style = Simply(renderer as SimpleRenderer);
      break;
    }
    case 'classBreaks': {
      style = ClassBreaks(renderer as ClassBreaksRenderer, feature, resolution);
      break;
    }
    case 'unique-value': {
      // style = UniqueValue(renderer, feature, resolution);
      break;
    }
    case 'dictionary': {
      break;
    }
    case 'dot-densit': {
      break;
    }
    case 'heatmap': {
      break;
    }
    case 'pie-chart': {
      break;
    }
  }

  return style;
}

export { createStyleFunction };
