import { useEffect, useState } from 'react';

import rootStore from '../../../../../stores/rootStore/rootStore';
import { System } from '../../../../../ts/enums/enums';
import { IDataList } from '../../model/monitoring.model';
import { getFormattedListDevices } from '../helpers/helpers';

import useGeocode from './useGeocode';

interface UseSystemMonitoringProps {
  systemsList: System[];
}

const useSystemMonitoring = ({ systemsList }: UseSystemMonitoringProps) => {
  const { systems } = rootStore.mapDataStore;
  const {
    statusFilteredMonitoring,
    searchMonitoring,
    deviceInfo,
    activeSystemMonitoring,
    setMonitoringValue,
  } = rootStore.monitoringPanelStore;

  const [dataList, setDataList] = useState<IDataList[]>([]);

  const { addressInfo, isLoading } = useGeocode();

  useEffect(() => {
    setDataList([]);
  }, [activeSystemMonitoring, setMonitoringValue]);

  useEffect(() => {
    setDataList(
      getFormattedListDevices({
        systemsData: systems,
        systemsList,
        statusArr: statusFilteredMonitoring,
        searchMonitoring,
        addressInfo,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    systems,
    systemsList,
    statusFilteredMonitoring.length,
    searchMonitoring,
    addressInfo,
  ]);

  useEffect(() => {
    if (deviceInfo || !dataList.length) return;

    const { id, system } = dataList[0];

    setMonitoringValue('deviceInfo', { id, system });
  }, [dataList, deviceInfo, setMonitoringValue]);

  //set address to store
  useEffect(() => {
    setMonitoringValue('addresses', {
      loading: isLoading,
      data: addressInfo,
    });
  }, [setMonitoringValue, isLoading, addressInfo]);

  useEffect(() => {
    return () => setMonitoringValue('addresses', null);
  }, [setMonitoringValue]);

  return { dataList, addressLoading: isLoading };
};

export default useSystemMonitoring;
