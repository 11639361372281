import { StatisticAdapter } from '../../../../../api/adminObjects/systems/adapter/adapter.zod';
import { findById } from '../../../../../helpers/findBy';
import { LibraryValues } from '../../../../../stores/uiStore/uiStore.model';

export interface FormattedStatistic extends StatisticAdapter {
  systemName: N<string>;
  regions: {
    id: number;
    name: string;
  }[];
}

const getRegionNames = (
  regionIds: N<number[]>,
  regionsDict: LibraryValues[]
) => {
  return (
    regionIds?.reduce((acc: FormattedStatistic['regions'], id) => {
      const regionName = findById(regionsDict, id)?.name;

      regionName &&
        acc.push({
          id,
          name: regionName,
        });

      return acc;
    }, []) ?? []
  );
};

export const getSystemNames = (
  statisticAdapter: StatisticAdapter[],
  systemDict: LibraryValues[],
  regionsDict: LibraryValues[]
): FormattedStatistic[] => {
  if (!statisticAdapter.length) return [];

  const statistic = statisticAdapter.map((el) => {
    const systemName = findById(systemDict, el.sc_system_id)?.name;
    const regionNames = getRegionNames(el.sc_system_regions_id, regionsDict);

    return {
      ...el,
      systemName: systemName ?? null,
      regions: regionNames,
    };
  });

  return statistic;
};
