import { FC } from 'react';

import { getIntervalFormatted } from '../../../helpers/getIntervalFormatted';
import { InfoButtonProps } from '../InfoButton';

import getSelectedPeriod from './helpers/getSelectedPeriod';

const PopoverInfoContent: FC<InfoButtonProps> = ({
  graphInfo: { interval, selectedDate, isNeedUtcFormatted },
  timeUpdate,
}) => {
  const dates = getSelectedPeriod(selectedDate, isNeedUtcFormatted);

  const intervalText = getIntervalFormatted(interval);

  return (
    <div>
      {timeUpdate && <p>Обновлено: {timeUpdate}</p>}{' '}
      <p>Выбранный период: {dates}</p>
      <p>Выбранный интервал: {intervalText}</p>
    </div>
  );
};

export default PopoverInfoContent;
