import { FC } from 'react';

import rootStore from '../../../../../../../stores/rootStore/rootStore';
import { FilteredTypes } from '../../../../../../TrafficLightDetailed/TlDetectors/constants/constants';
import ButtonIco from '../../../../../ButtonIco/ButtonIco';
import { FilterItem as FilterItemType } from '../../../../models/chart.model';
import { checkIsLaneActive } from '../helpers/checkIsLaneActive';
import { WARNING_TEXT } from '../helpers/helpers';

export interface FilterItemData {
  isDisabledItems: boolean;
  filterType: FilteredTypes;
  isLoading: boolean;
  isLast: boolean;
  parentFilter?: FilteredTypes;
  parentType?: number;
  isFirst?: boolean;
}

type FilterItemProps = FilterItemData & FilterItemType;

const FilterItem: FC<FilterItemProps> = ({
  name,
  type,
  value,
  isDisabledItems,
  filterType,
  isLoading,
  isLast,
  parentFilter,
  parentType,
  isFirst = false,
}) => {
  const { filterDtData, setFilteredDtData } = rootStore.detailedStore;

  const isActiveChild = !!filterDtData[filterType]?.find((el) => el === type);

  const isActive = parentFilter
    ? checkIsLaneActive(filterType, type, parentFilter, parentType)
    : isActiveChild;

  const isLaneFilter = filterType === FilteredTypes.Lanes;

  const isDisabledDir = isDisabledItems && !isLaneFilter && isActive;
  const content = isDisabledDir ? `${name} (${WARNING_TEXT})` : name;

  return (
    <ButtonIco
      key={type}
      onClick={() =>
        setFilteredDtData(filterType, type, parentFilter, parentType)
      }
      popoverProps={{ content }}
      isActive={isActive}
      disabled={isDisabledDir || isLoading}
      isRoundRight={isLast}
      isRoundLeft={isFirst}
    >
      {value}
    </ButtonIco>
  );
};

export default FilterItem;
