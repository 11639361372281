import { type Rule } from 'antd/es/form';
import { isNumber } from 'lodash';

import {
  QueryParamItem,
  VALUE_TYPES,
} from '../../../../../../ts/models/table.model';
import { getInputNumberRules } from '../helpers';

interface DateValues {
  from: string;
  to: string;
}

const getIsRequiredValue = (
  value: QueryParamItem['defaultValue'],
  type: QueryParamItem['jvalueType']
) => {
  if (isNumber(value)) return true;

  if (type === VALUE_TYPES.dateTime) {
    const dateObj = value as N<DateValues>;

    if (!dateObj) return false;

    return dateObj?.from !== null && dateObj?.to !== null;
  }

  return Boolean(Array.isArray(value) ? value.length : value);
};

const getRulesFiltration = (param: QueryParamItem): U<Rule[]> => {
  const { minValue, maxValue } = param;

  const isRequiredDefault = getIsRequiredValue(
    param.defaultValue,
    param.jvalueType
  );

  const rules: Rule[] = [];

  if (param.jvalueType === VALUE_TYPES.number)
    rules.push(...getInputNumberRules({ minValue, maxValue }));

  if (isRequiredDefault)
    rules.push({ required: true, message: 'Обязательное поле' });

  return rules.length ? rules : undefined;
};

export default getRulesFiltration;
