import { Radio } from 'antd';
import { FC } from 'react';

import { ChartBDKeys } from '../Chart/constants/constants';

import GraphButtons, { GraphButtonsProps } from './GraphButtons/GraphButtons';

import styles from './RadioButtons.module.scss';

interface RadioButtonsProps {
  radioValue: ChartBDKeys;
  setRadioValue: SetState<ChartBDKeys>;
  labelProps: GraphButtonsProps;
}

const RadioButtons: FC<RadioButtonsProps> = ({
  radioValue,
  setRadioValue,
  labelProps,
}) => {
  return (
    <div className={styles.container}>
      <Radio.Group
        onChange={(e) => setRadioValue(e.target.value)}
        value={radioValue}
        className={styles.radioGroup}
        buttonStyle="solid"
      >
        <Radio.Button value={ChartBDKeys.PassageCount}>
          Интенсивность
        </Radio.Button>
        <Radio.Button value={ChartBDKeys.SpeedAvg}>Ср. скорость</Radio.Button>
      </Radio.Group>
      <GraphButtons {...labelProps} />
    </div>
  );
};

export default RadioButtons;
