import rootStore from '../../../../../stores/rootStore/rootStore';
import { TLDetailsImage } from '../../../../../ts/models/tl.model';
import Img from '../../../../ui-kit/Img/Img';
import Popover from '../../../../ui-kit/Popover/Popover';
import { ISelectLabels } from '../PhaseHold.model';

import styles from '../PhaseHold.module.scss';

export const Z_INDEX_FOR_SELECT_DROPDAWN = 1051;

export const getSelectLabelsPhase = (
  images: TLDetailsImage[],
  title: string
) => {
  const { tipsValues } = rootStore.uiStore;

  const signalProgramShow = {
    content: 'Нет данных',
    placement: 'right',
    tipsDelay: tipsValues.profileTipsDelay,
    zIndex: Z_INDEX_FOR_SELECT_DROPDAWN,
  } as const;

  const selectLabels: ISelectLabels[] = images.map((item, i) => {
    const { phase } = item;

    const phaseTitle = `Фаза №${phase}`;

    return {
      value: phaseTitle,
      label: (
        <Popover
          {...signalProgramShow}
          content={
            <div className={styles.tooltipContainer} key={phase}>
              <p>
                Сигнальная программа: {title}. Фаза №{phase}
              </p>
              <Img
                src={'/media' + (images && images[i]?.image)}
                alt={`Фаза №${phase}`}
                className={styles.imgInfo}
                isShowIcons
              />
            </div>
          }
        >
          <p className={styles.selectText}>{phaseTitle}</p>
        </Popover>
      ),
    };
  });

  return selectLabels;
};
