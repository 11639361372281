import api from '../../../api';
import { LibraryKeys } from '../../../api/libraries/enums/enums';
import rootStore from '../../../stores/rootStore/rootStore';

const { getLibrariesByKey } = api.libraries;

const APP_PATH = 'hooks.useLibraries.fetchDictValue';

const fetchDictValue = async (dictKey: LibraryKeys) => {
  const { setDictionaryValue } = rootStore.uiStore;

  const subSystemDict = (await getLibrariesByKey(dictKey, APP_PATH)) ?? [];

  setDictionaryValue(dictKey, subSystemDict);
};

export default fetchDictValue;
