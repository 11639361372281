import { RightPanelType } from '../../../ts/enums/enums';
import DetailedConstructor from '../../Constructor/DetailedConstructor/DetailedConstructor';
import ScriptsControlDetailed from '../../Panel/ScriptsControl/ScriptsControlDetailed/ScriptsControlDetailed';
import DispatchGroupDetailed from '../../Panel/ScriptsControl/SelectModule/DispatchGroupControl/DipatchGroupDetailed/DispatchGroupDetailed';
import ClusterHeader from '../ClusterInfo/ClusterHeader/ClusterHeader';
import ClusterInfo from '../ClusterInfo/ClusterInfo';
import IdentificationInfo from '../IdentificationInfo/IdentificationInfo';
import InfoHeader from '../SystemsInfo/InfoHeader/InfoHeader';
import SystemsInfo from '../SystemsInfo/SystemsInfo';

interface RightPanelInfo {
  content: JSX.Element;
  title: string | JSX.Element;
}

export const RIGHT_PANEL_CONTENT: Record<RightPanelType, RightPanelInfo> = {
  constructor: {
    content: <DetailedConstructor />,
    title: 'Настройки конструктора',
  },
  coordControl: {
    content: <ScriptsControlDetailed />,
    title: 'Создание группы',
  },
  groupDispatch: {
    content: <DispatchGroupDetailed />,
    title: 'Группа светофорных объектов',
  },
  cluster: {
    content: <ClusterInfo />,
    title: <ClusterHeader />,
  },
  infoPanel: {
    content: <SystemsInfo />,
    title: <InfoHeader />,
  },
  identificationGis: {
    content: <IdentificationInfo />,
    title: 'Ближайшие объекты',
  },
};
