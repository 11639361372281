import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { ReactComponent as InfoIcon } from '../../../../../../assets/icons/confirm_icon.svg';
import ModalBody from '../../../PhaseHold/ModalBody/ModalBody';
import ModalConfirm from '../../../PhaseHold/ModalConfirm/ModalConfirm';
import { UseConfirmPlanProps } from '../../model/temporaryPlan.model';

import useConfirmPlan from './hooks/useConfirmPlan';

import styles from './Confirm.module.scss';

const Confirm: FC<UseConfirmPlanProps> = (props) => {
  const { modalBodyProps, modalConfirmProps } = useConfirmPlan(props);

  return (
    <ModalConfirm
      {...modalConfirmProps}
      icon={<InfoIcon className={styles.iconInfo} />}
    >
      <ModalBody {...modalBodyProps} />
    </ModalConfirm>
  );
};

export default observer(Confirm);
