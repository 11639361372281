import Feature, { type FeatureLike } from 'ol/Feature';
import Point from 'ol/geom/Point';

import { FeatureDeviceProps, FeaturesTypes } from '../../../ts/enums/enums';
import rootStore from '../../rootStore/rootStore';

const { Features } = FeaturesTypes;

export const getIsFeatureOnClusters = (cluster: U<FeatureLike>, id: number) => {
  return Boolean(
    cluster
      ?.get(Features)
      ?.some(
        (feature: Feature<Point>) => feature.get(FeatureDeviceProps.Id) === id
      )
  );
};

export const closeClusterWithoutFeatures = (
  ids: number[],
  cluster: U<FeatureLike>
) => {
  const { setSelectedClusters } = rootStore.clustersStore;
  const isNotEmptyCluster = ids.some((id) =>
    getIsFeatureOnClusters(cluster, id)
  );

  !isNotEmptyCluster && setSelectedClusters(null);
};

export const getClusterById = (clusters: Feature<Point>[], id: number) => {
  const cluster = clusters.find((cl) => getIsFeatureOnClusters(cl, id));

  return cluster;
};
