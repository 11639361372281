import classNames from 'classnames';
import { uniqueId } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useState, FC, useEffect } from 'react';

import rootStore from '../../../../stores/rootStore/rootStore';
import { ICamera } from '../../../../ts/models/camera.model';
import Popover from '../../../ui-kit/Popover/Popover';
import { getCameraInfo } from '../../../VideoCamera/helpers/getCameraInfo/getCameraInfo';
import { VideoCameraProps } from '../../../VideoCamera/models/VideoCamera.model';
import VideoCamera from '../../../VideoCamera/VideoCamera';

import styles from './CamerasOneToMany.module.scss';

interface CamerasOneToManyProps {
  cameras: ICamera[];
}

const CamerasOneToMany: FC<CamerasOneToManyProps> = ({ cameras }) => {
  const { interfaceTipsDelay } = rootStore.uiStore;

  const [cameraItems, setCameraItems] = useState<VideoCameraProps[]>([]);

  useEffect(() => {
    const cameraItems = cameras
      .map((camera) => getCameraInfo(camera))
      .filter(Boolean);

    setCameraItems(cameraItems);
  }, [cameras]);

  const onClickHandler = (camera: VideoCameraProps) => {
    const camerasWithoutMain = cameraItems.filter(
      (el) => el.deviceId !== camera.deviceId
    );

    setCameraItems([camera, ...camerasWithoutMain]);
  };

  return (
    <>
      <div className={styles.container}>
        {cameraItems.map((camera, idx) => {
          const isMainCamera = !idx;
          const divStyle = isMainCamera ? styles.mainCamera : styles.subCamera;
          const divCameraStyle = classNames({
            [styles.cameraCaption]: true,
            [styles.small]: !isMainCamera,
          });

          return (
            <div
              className={divStyle}
              key={`camera_${camera?.deviceId ?? uniqueId()}`}
            >
              <div className={styles.cameraWrap}>
                <VideoCamera cameraData={camera} />
                <div className={divCameraStyle}>
                  <p
                    className={styles.cameraID}
                  >{`Камера ${camera?.deviceId}`}</p>
                  <p>{camera?.caption}</p>
                  {!isMainCamera ? (
                    <Popover
                      tipsDelay={interfaceTipsDelay}
                      placement="left"
                      content={'сделать основной камерой для отображения'}
                    >
                      <input
                        type="button"
                        value=""
                        onClick={() => {
                          onClickHandler(camera);
                        }}
                      />
                    </Popover>
                  ) : null}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default observer(CamerasOneToMany);
