import { RefObject } from 'react';

import {
  COMMANDS,
  COMMANDS_CONTENT,
} from '../../../../../../../../../../constants/commands';
import { CONTROL_ACCESS_DEVICES } from '../../../../../../../../../../constants/constants';
import { findById } from '../../../../../../../../../../helpers/findBy';
import parseTLMode from '../../../../../../../../../../helpers/parseTLMode';
import { getTLModeName } from '../../../../../../../../../../helpers/statusHelpers';
import { TL, TlMode } from '../../../../../../../../../../ts/models/tl.model';
import { IPostCommandProps } from '../../../../../../../../../TrafficLightDetailed/TlManagement/model/postCommands.model';
import ConfirmBlock from '../ConfirmBlock/ConfirmBlock';

const { SET_OBJECT_MODE } = COMMANDS;
const { ReadAndControl } = CONTROL_ACCESS_DEVICES;

const TITLE_CONFIRM = `СО находится в координации.
Это может привести к нарушению работы запущенной группы координации.`;

export const getCommandInfo = (
  tlsMode: TlMode,
  tls: TL[],
  tlId: number,
  isActiveCoordTL: boolean,
  setIsOpenConfirm: SetState<boolean>,
  cardRef: RefObject<HTMLDivElement>
) => {
  const color = parseTLMode(tlsMode).color;

  const activeTL = findById(tls, tlId);

  const modeLabel = getTLModeName(tlsMode);
  const commandInfo = findById(COMMANDS_CONTENT, tlsMode);

  if (!activeTL || !modeLabel || !commandInfo) return null;

  const {
    deviceInfo: { caption, controlAccess },
    tlStatus: { mode },
    id,
  } = activeTL;

  const isCurrentMode = mode === tlsMode;

  const isAccessMode = controlAccess === ReadAndControl;

  const isDisabled = !isAccessMode || isCurrentMode;

  const cmdInfo = `${tlsMode} ${commandInfo.title}`;

  const propsPostCommand: IPostCommandProps = {
    id: tlId,
    command: tlsMode,
    cmdTitle: SET_OBJECT_MODE,
    cmdInfo,
    disabledTime: 0,
  };

  const tlName = `СО ${id}. ${caption}`;

  const title = `${
    isActiveCoordTL ? TITLE_CONFIRM : ''
  } Вы уверены, что хотите включить режим "${modeLabel}" для "${tlName}"?`;

  return {
    color,
    title: (
      <ConfirmBlock
        title={title}
        setIsOpenConfirm={setIsOpenConfirm}
        cardRef={cardRef}
      />
    ),
    isCurrentMode,
    isDisabled,
    modeLabel,
    infoTooltip: commandInfo.tip,
    propsPostCommand,
  };
};
