/* eslint-disable jsx-a11y/no-static-element-interactions */
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC, useRef, useState } from 'react';

import rootStore from '../../../../../../../../../stores/rootStore/rootStore';
import { System } from '../../../../../../../../../ts/enums/enums';
import { TlMode } from '../../../../../../../../../ts/models/tl.model';
import { getAvatarIcon } from '../../../../../../../../DetailedStatistics/Monitoring/SystemMonitoring/helpers/helpers';
import { postCommand } from '../../../../../../../../TrafficLightDetailed/TlManagement/helpers/postCommand';
import TooltipCommandContent from '../../../../../../../../TrafficLightDetailed/TlManagement/ManagementCommands/CommandCard/TooltipCommandContent/TooltipCommandContent';
import Confirm from '../../../../../../../../ui-kit/Confirm/Confirm';
import Popover from '../../../../../../../../ui-kit/Popover/Popover';

import { getCommandInfo } from './helpers/getCommandInfo';

import styles from './ControlBtn.module.scss';

const APP_PATH =
  '.Panel.ScriptsControl.SelectModule.DispatchGroupControl.ControlBtn';

interface ControlBtnProps {
  mode: TlMode;
  tlId: number;
  isActiveCoordTL: boolean;
}

const ControlBtn: FC<ControlBtnProps> = ({ mode, tlId, isActiveCoordTL }) => {
  const { interfaceTipsDelay } = rootStore.uiStore;
  const { tls } = rootStore.mapDataStore;

  const [isLoadingRequest, setIsLoadingRequest] = useState<boolean>(false);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const cardRef = useRef<HTMLDivElement>(null);

  const commandInfo = getCommandInfo(
    mode,
    tls,
    tlId,
    isActiveCoordTL,
    setIsOpenConfirm,
    cardRef
  );

  if (!commandInfo) return null;

  const {
    color,
    infoTooltip,
    isCurrentMode,
    isDisabled,
    modeLabel,
    propsPostCommand,
    title,
  } = commandInfo;

  const handlePostCommand = async () => {
    setIsLoadingRequest(true);

    await postCommand(propsPostCommand, APP_PATH);

    setIsLoadingRequest(false);
    setIsOpenConfirm(false);
  };

  const blockContainer = classNames({
    [styles.blockCard]: true,
    [styles.disabled]: isDisabled,
  });

  return (
    <Confirm
      title={title}
      okButtonProps={{ danger: true, loading: isLoadingRequest }}
      disabled={isDisabled}
      overlayClassName={styles.confirm}
      onConfirm={handlePostCommand}
      isOpen={isOpenConfirm}
      onCancel={() => setIsOpenConfirm(false)}
    >
      <Popover
        tipsDelay={interfaceTipsDelay}
        open={isOpenConfirm ? false : undefined}
        content={
          <TooltipCommandContent
            title={modeLabel}
            infoTooltip={infoTooltip}
            mode={mode}
            isCurrentMode={isCurrentMode}
            isStatus={false}
          />
        }
      >
        <div
          ref={cardRef}
          className={blockContainer}
          onClick={() => !isDisabled && setIsOpenConfirm((prev) => !prev)}
        >
          <span style={{ color }}>{getAvatarIcon(System.Lights)}</span>
        </div>
      </Popover>
    </Confirm>
  );
};

export default observer(ControlBtn);
