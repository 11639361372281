import { FC } from 'react';

import Popover, { PopoverProps } from '../../../../../ui-kit/Popover/Popover';
import useUserInfo from '../../../../../UserCard/hooks/useUserInfo';
import UserCard from '../../../../../UserCard/UserCard';

const SYSTEM_MESSAGE = 'Системное сообщение';

interface PopoverMessageProps {
  id: U<number>;
  children: JSX.Element;
}

const PopoverMessage: FC<PopoverMessageProps> = ({ id, children }) => {
  const { error } = useUserInfo(id);

  const content = id ? error : SYSTEM_MESSAGE;

  const popoverProps: PopoverProps = {
    placement: 'left',
    content: id && !error ? <UserCard userId={id} /> : <p>{content}</p>,
    children: null,
  };

  return (
    <Popover isClearParent={Boolean(id && !error)} {...popoverProps}>
      {children}
    </Popover>
  );
};

export default PopoverMessage;
