import { Card, Switch } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { TiWeatherCloudy } from 'react-icons/ti';
import Slider from 'react-slick';

import {
  PROPERTY_NAMES,
  WEATHER_ITEMS,
} from '../../../apiGIS/constants/weather';
import { MAP_BUTTONS_NAMES } from '../../../constants/uiComponentConstants';
import rootStore from '../../../stores/rootStore/rootStore';
import { MapButtonsCode } from '../../../ts/enums/userData';
import {
  IWeatherItem,
  IWeatherLayerItem,
  IWeatherTimeModel,
} from '../../Map/IWeatherTimeModel';
import ButtonIco from '../../ui-kit/ButtonIco/ButtonIco';
import Popover from '../../ui-kit/Popover/Popover';

import {
  PLACEMENT,
  TIME_INTERVAL_TTL,
  WEATHER_CONTENT,
  WEATHER_STYLES,
} from './constants';
import { SliderNextArrow } from './SliderArrows/SliderNextArrow';
import { SliderPrevArrow } from './SliderArrows/SliderPrevArrow';
import {
  formatDate,
  formatVisualDate,
  getCurrentDate,
  getTimes,
} from './utils/dates';
import { getGradient } from './utils/gradient';
import { getLabels } from './utils/labels';
import { getOffsets } from './utils/offsets';
import { getPanelsCount, getTimeSliderStyle } from './utils/styles';
import { WeatherPlate } from './WeatherPlate';
import { WeatherTimeItem } from './WeatherTimeItem';

import styles from './WeatherButton.module.scss';

export const timeSliderProps0 = {
  centerMode: false,
  dots: false,
  infinite: false,
  slidesToScroll: 1,
  slidesToShow: 12,
  initialSlide: 0,
  speed: 0,
  responsive: [
    {
      breakpoint: 2500,
      settings: {
        slidesToShow: 15,
        slidesToScroll: 1,
        infinite: false,
        dots: false,
        initialSlide: 0,
        speed: 0,
      },
    },
    {
      breakpoint: 1900,
      settings: {
        slidesToShow: 10,
        slidesToScroll: 1,
        infinite: false,
        dots: false,
        initialSlide: 0,
      },
    },
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 10,
        slidesToScroll: 1,
        infinite: false,
        dots: false,
        initialSlide: 0,
        speed: 0,
      },
    },
    {
      breakpoint: 1440,
      settings: {
        slidesToShow: 8,
        slidesToScroll: 1,
        infinite: false,
        dots: false,
        initialSlide: 0,
        speed: 0,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 1,
        infinite: false,
        dots: false,
        initialSlide: 0,
        speed: 0,
      },
    },
  ],
  prevArrow: <SliderPrevArrow />,
  nextArrow: <SliderNextArrow />,
};

export const timeSliderProps1 = {
  centerMode: false,
  dots: false,
  infinite: false,
  slidesToScroll: 1,
  slidesToShow: 10,
  initialSlide: 0,
  speed: 10,
  responsive: [
    {
      breakpoint: 2500,
      settings: {
        slidesToShow: 10,
        slidesToScroll: 1,
        infinite: false,
        dots: false,
        initialSlide: 0,
        speed: 0,
      },
    },
    {
      breakpoint: 1900,
      settings: {
        slidesToShow: 7,
        slidesToScroll: 1,
        infinite: false,
        dots: false,
        initialSlide: 0,
        speed: 0,
      },
    },
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 1,
        infinite: false,
        dots: false,
        initialSlide: 0,
        speed: 0,
      },
    },
    {
      breakpoint: 1440,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
        infinite: false,
        dots: false,
        initialSlide: 0,
        speed: 0,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: false,
        dots: false,
        initialSlide: 0,
        speed: 0,
      },
    },
  ],
  prevArrow: <SliderPrevArrow />,
  nextArrow: <SliderNextArrow />,
};

export const timeSliderProps2 = {
  centerMode: false,
  dots: false,
  infinite: false,
  slidesToScroll: 1,
  slidesToShow: 8,
  initialSlide: 0,
  speed: 10,
  responsive: [
    {
      breakpoint: 2500,
      settings: {
        slidesToShow: 7,
        slidesToScroll: 1,
        infinite: false,
        dots: false,
        initialSlide: 0,
        speed: 0,
      },
    },
    {
      breakpoint: 1900,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
        infinite: false,
        dots: false,
        initialSlide: 0,
        speed: 0,
      },
    },
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: false,
        dots: false,
        initialSlide: 0,
        speed: 0,
      },
    },
    {
      breakpoint: 1440,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
        dots: false,
        initialSlide: 0,
        speed: 0,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
        dots: false,
        initialSlide: 0,
        speed: 0,
      },
    },
  ],
  prevArrow: <SliderPrevArrow />,
  nextArrow: <SliderNextArrow />,
};

const WeatherButton: FC = () => {
  const {
    activeMapButton,
    mapButtonsTipsDelay,
    setKeyValue,
    isDark,
    isRightPanel,
    isPanel,
    regionData,
    isMinificMap,
    isScaleLine,
    isMeteoDataBtnGis,
  } = rootStore.uiStore;

  const { weatherDatasets, hasIntegrationWeatherGIS, setGisValue } =
    rootStore.gisDataStore;

  const { hasAccess } = rootStore.userDataStore;

  const [isShowPanel, setShowPanel] = useState(false);

  const [animationVisibility, setAnimationVisibility] = useState(true);
  const [leftOffset, setLeftOffset] = useState(0);
  const [rightOffset, setRightOffset] = useState(0);

  const [panelsCount, setPanelsCount] = useState(
    getPanelsCount(isPanel, isRightPanel)
  );

  const initialWeatherItem = {
    propertyName: PROPERTY_NAMES.TEMPERATURE || WEATHER_ITEMS[0].propertyName,
    imageSrc: WEATHER_ITEMS[0].imageSrc,
    gradient: WEATHER_ITEMS[0].gradient,
    timeInfo: {
      text: '0',
      time: formatDate(getCurrentDate(regionData).getTime()),
      label: formatVisualDate(getCurrentDate(regionData).getTime()),
    },
  } as IWeatherLayerItem;

  const [weatherItems, setWeatherItems] = useState(WEATHER_ITEMS);
  const [currentWeatherLayer, setCurrentWeatherLayer] =
    useState(initialWeatherItem);
  const [currentWeatherTime, setCurrentWeatherTime] = useState(
    initialWeatherItem.timeInfo as IWeatherTimeModel
  );
  const [times, setTimes] = useState(getTimes(weatherDatasets, regionData));

  const [timeInterval, setTimeInterval] = useState<U<NodeJS.Timer>>();
  const [bodyStyle, setBodyStyle] = useState<Record<string, any>>(
    getTimeSliderStyle(leftOffset, rightOffset)
  );

  const populateWeatherItems = () => {
    const times: Array<IWeatherTimeModel> = getTimes(
      weatherDatasets,
      regionData
    );

    setTimes(times);

    const weathers = weatherItems.map((item, index) => {
      item.timeInfo = times[0];

      return item;
    });

    setWeatherItems(weathers);
  };

  const togglePanel = () => {
    populateWeatherItems();
    setShowPanel(!isShowPanel);
  };

  useEffect(() => {
    const offsets = getOffsets(
      isPanel,
      isRightPanel,
      isMinificMap,
      isScaleLine
    );

    setLeftOffset(offsets.left);
    setRightOffset(offsets.right);

    setBodyStyle(getTimeSliderStyle(offsets.left, offsets.right));

    setPanelsCount(getPanelsCount(isPanel, isRightPanel));
  }, [isPanel, isRightPanel, isMinificMap, isScaleLine]);

  useEffect(() => {
    populateWeatherItems();
    // eslint-disable-next-line
  }, [weatherDatasets]);

  useEffect(() => {
    if (activeMapButton !== MAP_BUTTONS_NAMES.WEATHER) {
      setShowPanel(false);
    }
  }, [activeMapButton]);

  useEffect(() => {
    if (isShowPanel) {
      const propertyName =
        currentWeatherLayer.propertyName || PROPERTY_NAMES.TEMPERATURE;
      const time = currentWeatherTime.time || '';

      const element: IWeatherItem = {
        propertyName,
        time,
        showAnimation: animationVisibility,
      };

      setGisValue('weatherLayer', element);
      setKeyValue('activeMapButton', MAP_BUTTONS_NAMES.WEATHER);

      clearInterval(timeInterval);

      const interval = setInterval(() => {
        const timeItem: IWeatherTimeModel = {
          time: formatDate(getCurrentDate(regionData).getTime()),
        };

        setCurrentWeatherTime(timeItem);
      }, TIME_INTERVAL_TTL);

      setTimeInterval(interval);
    } else {
      setGisValue('weatherLayer', {
        propertyName: PROPERTY_NAMES.NONE,
        time: 'none',
      });

      setKeyValue('activeMapButton', MAP_BUTTONS_NAMES.NONE);

      clearInterval(timeInterval);
    } // eslint-disable-next-line
  }, [isShowPanel]);

  const popOverProps = {
    placement: PLACEMENT,
    tipsDelay: mapButtonsTipsDelay,
  } as const;

  const selectWeatherProperty = (item: IWeatherLayerItem) => {
    const propertyName = item.propertyName || PROPERTY_NAMES.TEMPERATURE;
    const time = currentWeatherTime.time || '';
    const showAnimation = animationVisibility;

    const element: IWeatherItem = { propertyName, time, showAnimation };

    setGisValue('weatherLayer', element);

    setCurrentWeatherLayer(item);
  };

  const setWeatherItemTime = (item: IWeatherTimeModel) => {
    const propertyName =
      currentWeatherLayer.propertyName || PROPERTY_NAMES.TEMPERATURE;
    const time = item.time || '';
    const showAnimation = animationVisibility;

    const element: IWeatherItem = { propertyName, time, showAnimation };

    setGisValue('weatherLayer', element);

    setCurrentWeatherTime(item);
  };

  const changeWindVisibility = () => {
    const propertyName =
      currentWeatherLayer.propertyName || PROPERTY_NAMES.TEMPERATURE;
    const time = currentWeatherTime.time || '';

    const showAnimation = !animationVisibility;

    const element: IWeatherItem = { propertyName, time, showAnimation };

    setGisValue('weatherLayer', element);

    setCurrentWeatherTime(element);

    setAnimationVisibility(!animationVisibility);
  };

  const getSliderSettings = (value?: number) => {
    if (!value) {
      return timeSliderProps0;
    }

    if (value === 1) {
      return timeSliderProps1;
    }

    return timeSliderProps2;
  };

  if (!hasIntegrationWeatherGIS) {
    WEATHER_CONTENT.content =
      'Для выбранного региона интеграция с ГИС недоступна';
  }

  const isAccess = hasAccess(MapButtonsCode.MeteoDataButton);

  if (!hasIntegrationWeatherGIS || !isMeteoDataBtnGis || !isAccess) return null;

  return (
    <>
      <ButtonIco
        isActive={isShowPanel}
        disabled={!hasIntegrationWeatherGIS}
        onClick={togglePanel}
        popoverProps={{ ...popOverProps, content: WEATHER_CONTENT.content }}
      >
        <TiWeatherCloudy />
      </ButtonIco>
      {isShowPanel ? (
        <>
          <Card
            bordered={false}
            className={styles.weathersPanel}
            styles={{ body: WEATHER_STYLES.panelBodyStyle }}
          >
            <div className={styles.weathersPanelTitle}>
              {WEATHER_CONTENT.title}
            </div>
            <div className={styles.weathersContainer}>
              {weatherItems.map((item, num) => {
                return (
                  <Popover
                    content={item.text}
                    key={item.text}
                    {...popOverProps}
                  >
                    <WeatherPlate
                      onClick={() => {
                        selectWeatherProperty(item);
                      }}
                      item={item}
                      currentWeatherLayer={currentWeatherLayer}
                      dark={isDark}
                      count={weatherItems.length}
                      num={num}
                    />
                  </Popover>
                );
              })}
            </div>
            <div className={styles.weatherItemContainerRightTriangle}></div>
          </Card>

          {
            <div
              className={styles.weatherTimeButtonsContainer0}
              style={{ ...bodyStyle }}
            >
              <div className={styles.timeSlideContainer}>
                <Slider
                  className={styles.timeSlider}
                  {...getSliderSettings(panelsCount)}
                >
                  {times.map((item) => {
                    return (
                      <WeatherTimeItem
                        key={item.time}
                        onClick={() => {
                          setWeatherItemTime(item);
                        }}
                        datasets={weatherDatasets}
                        item={item}
                        currentWeatherTime={currentWeatherTime}
                        currentWeatherLayer={currentWeatherLayer}
                        dark={isDark}
                      />
                    );
                  })}
                </Slider>
              </div>
            </div>
          }

          <div className={styles.weatherLegendContainer}>
            <Card bodyStyle={WEATHER_STYLES.panelBodyStyle}>
              <div className={styles.weathersPanelTitle}>
                {currentWeatherLayer.text}
              </div>
              <div className={styles.labelsContainer}>
                {getLabels(currentWeatherLayer).map((label) => {
                  return <div key={label}>{label}</div>;
                })}
              </div>
              <div
                style={{ background: getGradient(currentWeatherLayer) }}
                className={styles.weatherLegendMeasurementScale}
              ></div>

              {currentWeatherLayer.id ===
              PROPERTY_NAMES.PRECIPITATION ? null : (
                <div className={styles.windAnimationContainer}>
                  <Switch
                    defaultChecked
                    onChange={changeWindVisibility}
                    size={'small'}
                  />
                  <div className={styles.legendWindLabel}>
                    показывать анимацию ветра
                  </div>
                </div>
              )}
            </Card>
          </div>
        </>
      ) : null}{' '}
    </>
  );
};

export default observer(WeatherButton);
