import locale from 'antd/es/date-picker/locale/ru_RU';
import type { DatePickerProps } from 'antd/lib';
import dayjs from 'dayjs';
import { FC } from 'react';

import { COMMANDS } from '../../../../../constants/commands';
import {
  DATE_FORMAT_TO_SHOW,
  TIME_FORMAT,
} from '../../../../../constants/constants';
import rootStore from '../../../../../stores/rootStore/rootStore';
import { TimePickerBasicProps } from '../../../../ui-kit/Picker/TimePicker/TimePicker';
import {
  DEFAULT_DISABLED_TIME_DURATION,
  INITIAL_TIME,
} from '../../constants/constants';
import { disabledDate } from '../../helpers/disabledDate';
import { disabledDateTime } from '../../PhaseHold/helpers/disabledDateTime';

import { getFormattedDayJSToSec } from './helpers/getFormattedDayJSToSec';
import NecessarilyDuration from './NecessarilyDuration/NecessarilyDuration';
import TimeCardPhaseHold from './TimeCardPhaseHold/TimeCardPhaseHold';
import TimeCardTemporaryPlan from './TimeCardTemporaryPlan/TimeCardTemporaryPlan';

import styles from './TimeCard.module.scss';

const { SET_OBJECT_SYNC_PLAN } = COMMANDS;

export interface TimeCardProps {
  checked?: boolean;
  setChecked?: SetState<boolean>;
  statusDate: 'error' | 'warning' | undefined;
  valueDate: dayjs.Dayjs | null | undefined;
  duration: N<number>;
  setDurationTime: (value: number) => void;
  handlerChangeDate: (value: dayjs.Dayjs | null) => void;
  isDuration?: boolean;
  cmd?: keyof typeof COMMANDS;
  disabledTime?: number;
}

export const TIME_TO_POST_COMMAND = 2;

const TimeCard: FC<TimeCardProps> = ({
  checked,
  setChecked,
  statusDate,
  valueDate,
  duration,
  setDurationTime,
  handlerChangeDate,
  isDuration = false,
  cmd,
  disabledTime = DEFAULT_DISABLED_TIME_DURATION,
}) => {
  const { isActiveControlBtn } = rootStore.detailedStore;

  const isDisabled = !isActiveControlBtn;

  const datePickerProps: DatePickerProps = {
    className: styles.picker,
    showTime: {
      format: TIME_FORMAT,
    },
    status: statusDate,
    value: valueDate,
    showNow: false,
    format: DATE_FORMAT_TO_SHOW,
    locale: locale,
    onChange: handlerChangeDate,
    placeholder: 'Выберите дату',
    placement: 'bottomRight',
    disabledDate,
    disabledTime: (value: N<dayjs.Dayjs>) =>
      disabledDateTime(value, disabledTime + TIME_TO_POST_COMMAND),
    disabled: isDisabled,
  };

  const timePickerProps: TimePickerBasicProps = {
    pickerStyle: styles.picker,
    onChange: (time) => setDurationTime(getFormattedDayJSToSec(time)),
    placeholder: 'Укажите длительность',
    disabledTimeProps: INITIAL_TIME,
    value: duration ? dayjs().startOf('day').add(duration, 'seconds') : null,
    disabled: isDisabled,
  };

  const necessarilyDurationProps = {
    checked,
    isActiveBtn: isActiveControlBtn,
    setChecked,
    timePickerProps,
    datePickerProps,
  };

  if (isDuration) return <NecessarilyDuration {...necessarilyDurationProps} />;

  if (cmd === SET_OBJECT_SYNC_PLAN)
    return (
      <TimeCardTemporaryPlan
        timePickerProps={timePickerProps}
        datePickerProps={datePickerProps}
      />
    );

  return <TimeCardPhaseHold {...necessarilyDurationProps} />;
};

export default TimeCard;
