import { Card } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC, useCallback, useEffect, useState } from 'react';

import eventBus from '../../../eventBus';
import { TableData } from '../../../ts/models/table.model';
import { DetailedItemProps } from '../../DetailedMain/DetailedWrapper/constants/constants';
import Table from '../../ui-kit/Table/Table';

import { getPlannedCommandsArray } from './helpers/getPlannedCommandsArray';

import styles from './TlPlanning.module.scss';

const APP_PATH = '.TrafficLightDetailed.TlPlanning';

const TlPlanning: FC<DetailedItemProps> = ({ id }) => {
  const [plannedCommandsTableData, setPlannedCommandsTableData] =
    useState<N<TableData>>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getPlannedCommands = useCallback(async () => {
    setIsLoading(true);
    const commands = await getPlannedCommandsArray({
      id,
      dateIndexFrom: 0,
      appPath: APP_PATH,
    });

    setIsLoading(false);

    if (!commands) {
      return;
    }

    setPlannedCommandsTableData(commands);
  }, [id]);

  useEffect(() => {
    const unsubscribe = eventBus.phaseHoldChange.subscribe(getPlannedCommands);

    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getPlannedCommands();
  }, [getPlannedCommands]);

  return (
    <Card>
      <h3 className={styles.title}>Запланированные команды</h3>
      {plannedCommandsTableData ? (
        <Table
          tableData={plannedCommandsTableData}
          tableConfigType="users"
          isLoading={isLoading}
          refreshData={getPlannedCommands}
        />
      ) : null}
    </Card>
  );
};

export default observer(TlPlanning);
