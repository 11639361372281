type Id = N<SN>;

interface ElWithIdx<T> {
  el: N<T>;
  idx: N<number>;
}

interface ItemWithId {
  id: Id;
}

const findElementWithIdx = <T extends ItemWithId>(array: T[], id: Id) =>
  array.reduce(
    (res: ElWithIdx<T>, el, idx, arr) => {
      if (!id || !el.id || el.id !== id) return res;
      arr.slice(0);

      return { el, idx };
    },
    { el: null, idx: null }
  );

export default findElementWithIdx;
