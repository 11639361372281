import { Button } from 'antd';
import { observer } from 'mobx-react-lite';

import { IRegionData } from '../../api/server/web/web.zod';
import { COLORS } from '../../constants/colorsConstants';
import rootStore from '../../stores/rootStore/rootStore';
import CloseBtn from '../Svg/CloseBtn/CloseBtn';
import ModalLoading from '../ui-kit/ModalLoading/ModalLoading';

import useRegions from './hooks/useRegions';

import styles from './RegionSwitcher.module.scss';

function RegionSwitcher() {
  const { setKeyValue, prevRegionData } = rootStore.uiStore;
  const { setMapData } = rootStore.mapDataStore;
  const { user, resetUserData } = rootStore.userDataStore;

  const handlePickRegion = (region: IRegionData) => {
    setKeyValue('regionData', region);
    setMapData('percentLoad', 0);
    setKeyValue('prevRegionData', null);
    localStorage.regionId = region.id;
  };

  const { availableRegions, erroredRegionsId } = useRegions({
    user,
    resetUserData,
    handlePickRegion,
  });

  if (!availableRegions) {
    return <ModalLoading />;
  }

  return (
    <div className={styles.container}>
      <h2>Выберите регион</h2>

      <div className={styles.regionsButtons}>
        {Object.values(availableRegions).map((region) => {
          const { id, name } = region;

          return (
            <Button
              key={id}
              className={styles.btn}
              onClick={() => handlePickRegion(region)}
              type="primary"
            >
              {name}
            </Button>
          );
        })}
        {erroredRegionsId.length
          ? erroredRegionsId.map((regionsId) => (
              <Button
                key={regionsId}
                className={styles.btn}
                disabled={true}
                type="primary"
              >
                <p>Region ID: {regionsId}</p>
                <p>Ошибка загрузки</p>
              </Button>
            ))
          : null}
      </div>

      {prevRegionData && (
        <CloseBtn
          bgColor={COLORS.BG_TINT}
          onClick={() => handlePickRegion(prevRegionData)}
          size={60}
        />
      )}
    </div>
  );
}

export default observer(RegionSwitcher);
