import dayjs from 'dayjs';
import { cloneDeep } from 'lodash';
import { flow, makeAutoObservable } from 'mobx';
import type { Coordinate } from 'ol/coordinate';

import { trafficObjectsApi } from '../../api/integration/itslab/tl/trafficObjects';
import { getSelectedMultipleCluster } from '../../components/Map/helpers/getSelectedMultipleCluster';
import handleCenter from '../../components/Map/helpers/zoomHandlers/handleCenter';
import {
  addMarker,
  getMarker,
} from '../../components/Mapper/helpers/addMarkers';
import {
  CoordGroupStartValue,
  CoordStartGroupWithoutSec,
  CoordTls,
  GroupUid,
  IGroupsInfo,
} from '../../components/Panel/ScriptsControl/model/coordControl.model';
import { getSelectInteraction } from '../../components/ui-kit/MapLayers/ClusterLayers/helpers/getSelectInteraction';
import message from '../../components/ui-kit/Notification/Notification';
import { SECOND } from '../../constants/constants';
import { ANIMATION_DURATION_CLUSTER } from '../../constants/mapClusterConstants';
import { findBy, findById } from '../../helpers/findBy';
import {
  FeaturesTypes,
  PanelType,
  RightPanelType,
  System,
} from '../../ts/enums/enums';
import RootStore from '../rootStore/rootStore';
import { ManagementModules } from '../uiStore/uiStore.model';

import {
  closeClusterWithoutFeatures,
  getClusterById,
  getIsFeatureOnClusters,
} from './helpers/helpers';
import {
  CoordTlsItems,
  SelectedFeatures,
  TlsInfo,
} from './scriptsControlStore.model';

class ScriptsControlStore {
  rootStore;
  selectedFeatureSomeArray: SelectedFeatures[] = [];
  tlsInfo: TlsInfo[] = [];
  startGroupInfo: CoordGroupStartValue[] = [];
  selectedGroup: N<IGroupsInfo> = null;
  coordGroups: IGroupsInfo[] = [];

  constructor(rootStore: typeof RootStore) {
    makeAutoObservable(this, {
      rootStore: false,
      fetchCoordTls: flow.bound,
    });
    this.rootStore = rootStore;
  }

  setStartGroupInfo = (startGroupValue: N<CoordStartGroupWithoutSec>) => {
    if (!startGroupValue) {
      this.startGroupInfo.forEach(({ intervalId }) => {
        intervalId && clearInterval(intervalId);
      });

      return (this.startGroupInfo = []);
    }

    const isSame = this.startGroupInfo.some(
      ({ groupUid }) => groupUid === startGroupValue.groupUid
    );

    const sec = startGroupValue.startTime - dayjs().unix();

    !isSame &&
      this.startGroupInfo.push({
        ...startGroupValue,
        sec: sec < 0 ? 0 : sec,
        intervalId: null,
      });

    const newGroup = this.startGroupInfo.find(
      ({ groupUid }) => groupUid === startGroupValue.groupUid
    );

    if (!newGroup) return;

    if (isSame && sec > 0) {
      newGroup.sec = sec;
      newGroup.intervalId && clearInterval(newGroup.intervalId);
    }

    newGroup.intervalId = setInterval(() => {
      if (newGroup.sec) {
        return (newGroup.sec = startGroupValue.startTime - dayjs().unix());
      }

      if (newGroup.intervalId) {
        clearInterval(newGroup.intervalId);
        newGroup.intervalId = null;
      }
    }, SECOND);
  };

  setSelectedFeatureByArray = (ids: number[]) => {
    const { getCoordinatesById } = this.rootStore.mapDataStore;
    const { clickedCluster, setSelectedClusters } =
      this.rootStore.clustersStore;

    if (!ids.length) {
      setSelectedClusters(null);

      return (this.selectedFeatureSomeArray = []);
    }

    const isNewId = ids.length > this.selectedFeatureSomeArray.length;

    if (isNewId) {
      const newId = ids.at(-1);

      newId && this.setMoveToObject(newId, ids);

      return;
    }

    closeClusterWithoutFeatures(ids, clickedCluster?.cluster);

    this.selectedFeatureSomeArray = ids.map((id) => ({
      id,
      coordinate: getCoordinatesById(id) ?? [],
      system: System.Lights,
      coordFeatureOnCluster: undefined,
    }));
  };

  setMoveToObject = (featureId: number, ids: number[]) => {
    const { clusters, clickedCluster, setSelectedClusters } =
      this.rootStore.clustersStore;
    const { map } = this.rootStore.mapStore;
    const { getCoordinatesById } = this.rootStore.mapDataStore;

    const cluster = getClusterById(clusters, featureId);

    if (!map || !cluster) return console.error('no clusters');

    const clusterCoord = cluster.getGeometry()?.getFlatCoordinates();

    const isFeature = cluster.get(FeaturesTypes.Features)?.length === 1;
    const sourceSelect = getSelectInteraction(map);

    handleCenter(isFeature ? getCoordinatesById(featureId) : clusterCoord);

    const isSameCluster = getIsFeatureOnClusters(
      clickedCluster?.cluster,
      featureId
    );

    const duration =
      isSameCluster || isFeature ? 0 : ANIMATION_DURATION_CLUSTER;

    setTimeout(() => {
      this.selectedFeatureSomeArray = ids.map((id) => ({
        id,
        coordinate: getCoordinatesById(id) ?? [],
        system: System.Lights,
        coordFeatureOnCluster: undefined,
      }));
    }, duration);

    if (isFeature) {
      setSelectedClusters(null);
      sourceSelect?.clear();

      return;
    }

    if (!isSameCluster) {
      setSelectedClusters(getSelectedMultipleCluster(cluster));
      sourceSelect?.selectCluster(cluster);
    }
  };

  setSelectedFeatureSomeArray = (
    newId: N<number>,
    coordFeatureOnCluster?: SelectedFeatures['coordFeatureOnCluster']
  ) => {
    const { map } = this.rootStore.mapStore;
    const { getCoordinatesById } = this.rootStore.mapDataStore;
    const { clickedCluster } = this.rootStore.clustersStore;

    if (!newId) {
      this.selectedFeatureSomeArray = [];
      map && getSelectInteraction(map)?.clear();

      return;
    }

    const isDelete = this.selectedFeatureSomeArray.some(
      ({ id }) => id === newId
    );

    if (isDelete) {
      const selected = this.selectedFeatureSomeArray.filter(
        ({ id }) => id !== newId
      );

      this.selectedFeatureSomeArray = selected;

      closeClusterWithoutFeatures(
        selected.map(({ id }) => id),
        clickedCluster?.cluster
      );

      return;
    }

    this.selectedFeatureSomeArray.unshift({
      id: newId,
      coordinate: getCoordinatesById(newId) ?? [],
      system: System.Lights,
      coordFeatureOnCluster,
    });
  };

  setCoordGroups = (groups: IGroupsInfo[]) => {
    groups.forEach(({ startTime, endTime, groupUid, isStarted }) => {
      if (!isStarted) return;

      this.setStartGroupInfo({
        startTime: startTime ?? 0,
        endTime: endTime ?? 0,
        groupUid,
      });
    });

    this.coordGroups = cloneDeep(groups);
  };

  openGroup = (groupUid: GroupUid) => {
    const { isScriptsCreateDetailed, setSelectedFeatureSomeArray } = this;

    if (isScriptsCreateDetailed) return;

    setSelectedFeatureSomeArray(null);

    if (this.selectedGroup?.groupUid === groupUid) {
      return (this.selectedGroup = null);
    }

    const selectedGroup = findBy(this.coordGroups, groupUid, 'groupUid');

    this.selectedGroup = selectedGroup ? cloneDeep(selectedGroup) : null;
  };

  closeGroups = (isNeedClearFeatures = true) => {
    isNeedClearFeatures && this.setSelectedFeatureSomeArray(null);

    this.selectedGroup = null;
  };

  clearScriptsStore = (isExit = true) => {
    const { setRightPanel, setKeyValue } = this.rootStore.uiStore;
    const { setSelectedClusters } = this.rootStore.clustersStore;

    if (isExit) {
      setRightPanel(null);
    }
    this.setSelectedFeatureSomeArray(null);
    setSelectedClusters(null);
    this.tlsInfo = [];
    this.setStartGroupInfo(null);
    this.selectedGroup = null;
    setKeyValue('moduleManagementType', null);
  };

  *fetchCoordTls({ isNeedNotify = true, isNeedNotifyError = true } = {}) {
    const res: U<CoordTls[]> | U<{ status: number }> =
      yield trafficObjectsApi.getTLList(
        'coordControlDetailed',
        isNeedNotifyError
      );

    if (!res) return;

    if ('status' in res) {
      return;
    }

    isNeedNotify && message.success('SUCCESS_TLS_GROUPS_LIST');

    // eslint-disable-next-line camelcase
    this.tlsInfo = res.map(({ object_id, coordination }) => ({
      id: Number(object_id),
      coordination,
    }));
  }

  get coordTlsInfo() {
    const { isScriptsCreateDetailed, tlsInfo } = this;

    // eslint-disable-next-line camelcase
    return tlsInfo.map(({ id, coordination }) => ({
      id,
      isDisabled: isScriptsCreateDetailed ? !!coordination : !coordination,
    }));
  }

  get isManagement() {
    const { panelType, isPanel } = this.rootStore.uiStore;

    return isPanel && panelType === PanelType.CoordManagement;
  }

  get isMultipleSelect() {
    const { isManagement } = this;
    const { moduleManagementType } = this.rootStore.uiStore;

    return isManagement && !!moduleManagementType;
  }

  get isScriptsControl() {
    const { isMultipleSelect } = this;
    const { moduleManagementType } = this.rootStore.uiStore;

    return (
      isMultipleSelect &&
      moduleManagementType === ManagementModules.CoordControl
    );
  }

  get isGroupDispatch() {
    const { isMultipleSelect } = this;
    const { moduleManagementType } = this.rootStore.uiStore;

    return (
      isMultipleSelect &&
      moduleManagementType === ManagementModules.GroupDispatchManagement
    );
  }

  get selectedIds() {
    return this.selectedFeatureSomeArray.map(({ id }) => Number(id));
  }

  get regionTlsIds() {
    const { tls } = this.rootStore.mapDataStore;

    return tls.map((el) => el.id);
  }

  get coordTls() {
    const { tls } = this.rootStore.mapDataStore;

    return this.coordTlsInfo.reduce(
      (acc: CoordTlsItems[], { id, isDisabled }) => {
        const tl = findById(tls, id);

        if (tl?.id) {
          const tlScript = { ...tl, isDisabled };
          const formattedMarker = getMarker(tlScript);

          acc.push({
            tl,
            isDisabled,
            feature: addMarker(formattedMarker, System.Lights),
          });
        }

        return acc;
      },
      []
    );
  }

  get disabledTlsIds() {
    return this.coordTls.reduce((acc: number[], { tl: { id }, isDisabled }) => {
      if (isDisabled) acc.push(id);

      return acc;
    }, []);
  }

  get isScriptsCreateDetailed() {
    const { rightPanelType } = this.rootStore.uiStore;

    return rightPanelType === RightPanelType.CoordControl;
  }

  get coordGroupArray() {
    const { selectedGroup } = this;
    const { getCoordinatesById } = this.rootStore.mapDataStore;

    const coordsArray = selectedGroup?.tlsIds?.reduce(
      (acc: Coordinate[], id) => {
        const coord = getCoordinatesById(id);

        coord && acc.push(coord);

        return acc;
      },
      []
    );

    return coordsArray ?? [];
  }

  get isActiveCoordTL() {
    const { coordGroups } = this;
    const { infoData } = this.rootStore.uiStore;

    if (!infoData || infoData.system !== System.Lights || !coordGroups.length)
      return false;

    const isActive = !!coordGroups?.some(
      ({ tlsIds, isStarted }) =>
        isStarted && tlsIds.some((id) => id === infoData.id)
    );

    return isActive;
  }
}

export default ScriptsControlStore;
