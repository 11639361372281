import { useEffect } from 'react';

import { LibraryKeys } from '../../api/libraries/enums/enums';
import rootStore from '../../stores/rootStore/rootStore';

import fetchDictValue from './helpers/fetchDictValue';

const { SubSystemId, AdapterStatus, SystemId, RegionId, SystemTypeId } =
  LibraryKeys;

const useLibraries = () => {
  const { isAuth } = rootStore.userDataStore;

  useEffect(() => {
    if (!isAuth) return;

    fetchDictValue(SubSystemId);
    fetchDictValue(SystemId);
    fetchDictValue(AdapterStatus);
    fetchDictValue(RegionId);
    fetchDictValue(SystemTypeId);
  }, [isAuth]);
};

export default useLibraries;
