import { isNumber } from 'lodash';

import { findById } from '../../../../../helpers/findBy';
import findElementWithIdx from '../../../../../helpers/findElementWithIdx';
import rootStore from '../../../../../stores/rootStore/rootStore';

const setLaneEnabled = (id: N<number>) => {
  if (!id) return;

  const { linkedLanes, isTrafficLanes, setLinkedLanes, setPointsValue } =
    rootStore.pointsUdsStore;
  const { detectors } = rootStore.mapDataStore;

  const dt = findById(detectors, id);

  if (!dt) return;
  const parentId = dt.deviceInfo.pointUdsUid;
  const lanes = parentId && linkedLanes[parentId];

  if (!lanes) return;
  const { el: lane, idx } = findElementWithIdx(lanes, id);

  if (!lane || !isNumber(idx)) return;

  if (!isTrafficLanes) {
    const newLinkedLanes = lanes.map((lane, i) => ({
      ...lane,
      isEnabled: i === idx,
    }));

    setLinkedLanes(newLinkedLanes, parentId);
    setTimeout(() => setPointsValue('isTrafficLanes', true));

    return;
  }

  const isLanesEnabled = linkedLanes[parentId].some(({ isEnabled }, i) =>
    i === idx ? !lane?.isEnabled : isEnabled
  );

  if (!isLanesEnabled) {
    setPointsValue('isTrafficLanes', false);
  }

  lane.isEnabled = !lane.isEnabled;
};

export default setLaneEnabled;
