import { useEffect, useState } from 'react';

import { getWidthByPercent } from '../helpers/getWidthByPercent';

interface IGraphSize {
  width: number;
  height: number;
  radiusWeight: number;
}

interface IUseInitialCircle {
  containerRef: any;
}

const RADIUS_PERCENT_OF_WIDTH = 0.2;

const useInitialCircle = ({ containerRef }: IUseInitialCircle) => {
  const [graphSize, setGraphSize] = useState<IGraphSize>({
    width: 0,
    height: 0,
    radiusWeight: 0,
  });

  useEffect(() => {
    if (!containerRef.current) return;

    const newWidth = Math.round(
      containerRef.current.getBoundingClientRect().width
    );
    const newHeight = Math.round(
      containerRef.current.getBoundingClientRect().height
    );

    if (newWidth === graphSize.width && newHeight === graphSize.height) return;

    setGraphSize({
      width: newWidth,
      height: newHeight,
      radiusWeight: getWidthByPercent(newWidth, RADIUS_PERCENT_OF_WIDTH),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { graphSize };
};

export default useInitialCircle;
