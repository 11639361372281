import { makeAutoObservable } from 'mobx';

import { POINTS_INITIAL_SETTINGS as INIT } from '../../constants/initialSettingsConstants';
import { PHASE_CIRCLE } from '../../constants/mapConstants';
import { TrafficLane } from '../../ts/models/constructor.model';
import { PointUds } from '../../ts/models/pointUds.model';
import { TL } from '../../ts/models/tl.model';
import { getCircleDiameter, getRate } from '../helpers/schemaHelpers';
import RootStore from '../rootStore/rootStore';
import { StoreUtils } from '../storeUtils/storeUtils';

import { LinkedLanes } from './pointsUdsStore.model';

const { MIN_DIAMETER } = PHASE_CIRCLE;

class PointsUdsStore {
  rootStore;
  setPointsValue;
  setPointsValues;
  tlsInExtent: TL[] = [];
  isTrafficLanes = false;
  linkedLanes: LinkedLanes = {};
  pointsInExtent: PointUds[] = [];
  isShowPhaseCircle = INIT.isShowPhaseCircle;
  isUnderCircleFill = INIT.isUnderCircleFill;
  isPhaseCircleLabel = INIT.isPhaseCircleLabel;
  phaseCircleDiameterRate = INIT.phaseCircleDiameterRate;

  constructor(rootStore: typeof RootStore) {
    makeAutoObservable(this, {
      rootStore: false,
    });
    const utils = new StoreUtils(this);

    this.setPointsValue = utils.setKeyValue;
    this.setPointsValues = utils.setKeysValues;
    this.rootStore = rootStore;
  }

  setLinkedLanes = (lanes: TrafficLane[], id: SN) => {
    this.linkedLanes[id] = lanes;
  };

  get isPhaseCircle() {
    const { isShowPhaseCircle, rootStore } = this;
    const { isCrossroadBorder, isTlsDataFetched } = rootStore.mapDataStore;
    const { isDetailedForm } = rootStore.uiStore;

    return (
      isTlsDataFetched &&
      isShowPhaseCircle &&
      !isDetailedForm &&
      isCrossroadBorder
    );
  }

  get circleDiameter() {
    const { currentZoom, resolution } = this.rootStore.mapDataStore;

    const diameter = getCircleDiameter(currentZoom, resolution);

    return diameter < MIN_DIAMETER ? MIN_DIAMETER : diameter;
  }

  calcCircleVal = (val: number, rateFixed?: number) => {
    const rate = getRate(this.circleDiameter);

    return Math.round(val * (rateFixed ?? rate));
  };
}

export default PointsUdsStore;
