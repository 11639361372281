import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { CSSProperties, FC } from 'react';
import { BsClock } from 'react-icons/bs';

import { DEFAULT_CIRCLE } from '../../../../constants/constructorConstants';
import { getTimeFormat } from '../../../../helpers/formatDate';
import rootStore from '../../../../stores/rootStore/rootStore';
import { PhaseCircleHeaderProps } from '../PhaseCircleOverlay.model';

import styles from './PhaseCircleHeader.module.scss';

const PhaseCircleHeader: FC<PhaseCircleHeaderProps> = ({
  color,
  len = 0,
  phaseNumber = 0,
  restTime = 0,
  amount = 0,
  cmd = 'План не найден',
}) => {
  const { isPhaseCircleLabel } = rootStore.pointsUdsStore;

  const headerInlineStyles: CSSProperties = {
    filter: `hue-rotate(${DEFAULT_CIRCLE.HUE}deg)`,
    background: color,
  };

  const headerStyles = classNames({
    [styles.header]: true,
    [styles.headerOffset]: isPhaseCircleLabel,
  });

  return (
    <div className={headerStyles} style={headerInlineStyles}>
      <span className={styles.cycleLen}>
        <BsClock />
        {getTimeFormat(len)}
      </span>

      <div className={styles.line} />
      <div>
        <span>{phaseNumber} </span>
        <span className={styles.divider}> {`/ ${amount / 2}`} </span>
        <span>{getTimeFormat(restTime)}</span>
      </div>

      <div>{cmd}</div>
    </div>
  );
};

export default observer(PhaseCircleHeader);
