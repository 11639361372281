import { useEffect, useState } from 'react';
import { IoMdEye, IoMdEyeOff } from 'react-icons/io';

import rootStore from '../../../../stores/rootStore/rootStore';
import { DEFAULT_LAYERS_GROUP_TITLE } from '../constants';
import { getGroupVisibility, toggleGroupVisibility } from '../helpers';
import { ILayerDataNode } from '../layers.model';

import styles from '../LayersButton.module.scss';

const LayerGroupNode = (props: { data: ILayerDataNode }) => {
  const { layersVisibilityProxy } = rootStore.gisDataStore;

  const { map, children } = props.data;

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const value = getGroupVisibility(map, children);

    setIsVisible(value);
  }, [layersVisibilityProxy, children, map]);

  const changeGroupVisibility = () => {
    const value = !isVisible;

    toggleGroupVisibility(map, children, value);

    setIsVisible(value);
  };

  return (
    <div className={styles.nodeTitleContainer}>
      <div className={styles.groupContainer}>
        <div className={styles.groupTitle}>
          {props.data.item?.group ?? DEFAULT_LAYERS_GROUP_TITLE}
        </div>
        <div
          className={styles.groupTitleIcon}
          role={'presentation'}
          onClick={changeGroupVisibility}
        >
          {isVisible ? (
            <IoMdEye
              className={styles.iconActive}
              title={'Все слои группы видны'}
            />
          ) : (
            <IoMdEyeOff
              className={styles.iconPassive}
              title={'Все или часть слоёв группы не видны'}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default LayerGroupNode;
