import { isNumber } from 'lodash';

import { IData } from './getAverage/getAverage';

export type TArr = SN[];

export const getArrSumInfo = (arr: TArr) => {
  const numberArr = arr.filter((val) => isNumber(val)) as number[];

  return {
    sum: numberArr.reduce((acc: number, value) => acc + value, 0),
    len: numberArr.length,
  };
};

export const getComparableValue = (dataArr: IData) => {
  const { sum, len } = getArrSumInfo(Object.values(dataArr));

  const comparableValue = Math.ceil(sum / len);

  return comparableValue;
};
