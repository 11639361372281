import classNames from 'classnames';
import { uniqueId } from 'lodash';
import { FC } from 'react';

import { ICsdData, ITimePoints } from '../../../ts/models/signalPrograms.model';
import { getTactWidth } from '../helpers/getTactWidth';

import styles from './Tacts.module.scss';

const ZERO = 0;
const PX = 'px';

interface ITacts {
  phasesSet: ITimePoints[];
  csdData: ICsdData;
  width: number;
  height: number;
}

const Tacts: FC<ITacts> = ({ phasesSet, csdData, width, height }) => {
  if (!width && !height) return null;

  return (
    <div className={styles.tacts}>
      {phasesSet.map((tact, i) => {
        const isFirst = !i;

        const tactStyles = classNames({
          [styles.tact]: true,
          [styles.isNotMain]: Boolean(tact.main),
          [styles.isFirst]: isFirst,
        });

        return (
          <div
            className={tactStyles}
            key={uniqueId('*')}
            style={{
              width: getTactWidth(width, csdData.circle, tact.length) + PX,
              height: height + PX,
            }}
          >
            {isFirst ? <span className={styles.zero}>{ZERO}</span> : null}
            <span className={styles.tactStop}>{tact.stop}</span>
          </div>
        );
      })}
    </div>
  );
};

export default Tacts;
