import classNames from 'classnames';
import { observer } from 'mobx-react-lite';

import rootStore from '../../../stores/rootStore/rootStore';
import { PanelType } from '../../../ts/enums/enums';
import { SidebarCode } from '../../../ts/enums/userData';
import Popover from '../../ui-kit/Popover/Popover';

import { MAIN_BTNS, PANEL_TITLES } from './constants/constants';

import styles from './MainBlock.module.scss';

const MainBlock = () => {
  const {
    isPanel,
    panelType,
    isModuleDetailed,
    switchPanel,
    interfaceTipsDelay,
  } = rootStore.uiStore;
  const { hasAccess } = rootStore.userDataStore;

  return (
    <div className={styles.wrapper}>
      {MAIN_BTNS.map(({ name, code, className, Component }) => {
        const isActiveBlock = name === panelType;
        const isActivePanel = isPanel && isActiveBlock;

        const isNotAccessControl =
          !hasAccess(SidebarCode.CoordControl) &&
          !hasAccess(SidebarCode.GroupDispatchControl);
        const isControlBlocked =
          name === PanelType.CoordManagement && isNotAccessControl;

        if (isControlBlocked || !hasAccess(code)) return null;

        const content = PANEL_TITLES[name] ?? PANEL_TITLES.profile;

        const btnStyle = classNames({
          [styles.mainBtn]: true,
          [styles.active]: isActivePanel,
          [styles.collapsed]: isActiveBlock && isModuleDetailed,
        });

        const icoStyle = classNames({
          [styles[className]]: true,
          [styles.active]: isActivePanel,
        });

        return (
          <Popover
            content={content}
            key={name as string}
            placement="right"
            tipsDelay={interfaceTipsDelay}
          >
            <button
              className={btnStyle}
              id={name}
              onClick={() => switchPanel(name)}
            >
              <Component className={icoStyle} />
            </button>
          </Popover>
        );
      })}
    </div>
  );
};

export default observer(MainBlock);
