/* eslint-disable camelcase */
import dayjs, { Dayjs } from 'dayjs';
import { z } from 'zod';

import { CommandsSchema } from '../../../../api/mapObjects/trafficLights/commands/plannedCommands.zod';
import { COMMANDS } from '../../../../constants/commands';
import { DATE_FORMAT } from '../../../../constants/constants';
import { INearestPhases } from '../../../../ts/models/tl.model';
import 'dayjs/locale/ru';
import { getTimeWithTimeZone } from '../../TlDetectors/IntancyGraph/TitleBtns/TimeLocalCard/helpers/getTimeWithTimeZone';
import { ITimerInfo } from '../hooks/useTimerPlannedComand';

const { SET_OBJECT_PHASE } = COMMANDS;

export type TCommands = z.infer<typeof CommandsSchema>;

const getPlannedDate = (date: Dayjs) => {
  const formatDate = getTimeWithTimeZone({
    dateJS: date,
    isUTCZero: true,
  }).dateFormattedJS;

  const formatDateNow = getTimeWithTimeZone().dateFormattedJS;

  const isPlanned = formatDate.diff(formatDateNow) > 0;

  return { isPlanned, formatDate };
};

export const getNearestPhase = (commands: N<TCommands[]>): N<ITimerInfo> => {
  if (!commands) return null;

  const filteredCommands = commands.reduce(
    (acc: INearestPhases, item) => {
      const { dtbegin_command, dtend_command, cmd_type, jcmd_sender_add_info } =
        item;

      const dateStart = dayjs(dtbegin_command, DATE_FORMAT, true);
      const dateEnd = dayjs(dtend_command, DATE_FORMAT, true);

      const isValidStart = dateStart.isValid();
      const isValidEnd = dateEnd.isValid();

      if (cmd_type !== SET_OBJECT_PHASE || !isValidStart || !isValidEnd) {
        return acc;
      }

      const plannedStart = getPlannedDate(dateStart);
      const plannedEnd = getPlannedDate(dateEnd);

      if (!plannedEnd.isPlanned) return acc;

      const isNearest = plannedStart.formatDate.diff(acc.startTime) < 0;

      if (isNearest || !acc.startTime)
        return {
          startTime: plannedStart.formatDate,
          endTime: plannedEnd.formatDate,
          phaseNum: Number(jcmd_sender_add_info.command.param1),
        };

      return acc;
    },
    { startTime: null, endTime: null, phaseNum: 0 }
  );

  if (!filteredCommands.startTime || !filteredCommands.endTime) return null;

  return {
    ...filteredCommands,
    startTime: filteredCommands.startTime,
    endTime: filteredCommands.endTime,
  };
};
