import { point as turfPoint, toMercator as turfToMercator } from '@turf/turf';
import axios from 'axios';
import { Feature } from 'ol';
import { Geometry, LineString, Point } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Style } from 'ol/style';

import { Z_INDEX_LAYER } from '../../constants/mapConstants';
import { TMap } from '../../stores/mapStore/mapStore.model';
import { getDirectionsServiceUrl } from '../helpers';
import { IDirectionPoint, IDirectionResult } from '../models/tasks/tasks.model';
import {
  DIRECTION_ROUTE_PASS_STYLE,
  DIRECTION_ROUTE_STYLE,
  ICON_STYLE,
} from '../styles/predefined/graphics';

const DIRECTIONS_ROUTE_LAYER_ID = 'directions_route_layer';
const DIRECTIONS_ROUTE_PASS_LAYER_ID = 'directions_route_pass_layer';
const DIRECTIONS_POINTS_LAYER_ID = 'directions_points_layer';

const STYLES = {
  POINTS: new Style({
    image: ICON_STYLE,
  }),
  ROUTE: DIRECTION_ROUTE_STYLE,
  ROUTE_PASS: DIRECTION_ROUTE_PASS_STYLE,
};

export const getDirectionVariants = async (
  point1: IDirectionPoint,
  point2: IDirectionPoint
): Promise<IDirectionResult[]> => {
  const url = getDirectionsServiceUrl();

  const points = [point1, point2];

  try {
    const action = await axios.post(url, {
      points,
      routeType: 'car',
    });

    const data = action?.data || {};

    const item: IDirectionResult = {
      coordinates: data?.coordinates || [],
      instructions: data?.instructions || [],
      points: [point1, point2],
    };

    return [item];
  } catch (e) {
    return [];
  }
};

const createLayer = (
  map: TMap,
  layerId: string,
  style: Style | Style[]
): VectorLayer<VectorSource> | undefined => {
  if (!map) {
    return;
  }

  let layer = map.getAllLayers().find((item) => item.get('id') === layerId);

  if (!layer) {
    layer = new VectorLayer({
      source: new VectorSource({
        features: [],
      }),
      style,
    });

    map.addLayer(layer);

    layer.setZIndex(Z_INDEX_LAYER);
    layer.set('id', layerId);
  }

  if (layer instanceof VectorLayer) {
    return layer;
  }

  return undefined;
};

const directionPointsToFeatures = (points: IDirectionPoint[]) => {
  const features: Feature[] = points.map((point) => {
    const { x, y } = point;

    const mercatorPoint = turfToMercator(turfPoint([x, y]));

    const geometry = new Point(mercatorPoint.geometry.coordinates);

    return new Feature({
      geometry,
      id: Math.random(),
    });
  });

  return features;
};

const pairToLineString = (
  feature: Feature,
  coordinate: number[]
): Feature<Geometry> | undefined => {
  if (!feature || !coordinate) {
    return;
  }

  const geometry = feature.getGeometry();

  if (geometry instanceof Point) {
    const startCoordinate = geometry.getCoordinates();

    const lineGeometry = new LineString([startCoordinate, coordinate]);

    return new Feature({
      geometry: lineGeometry,
      id: Math.random(),
    });
  }

  return undefined;
};

export const drawDirections = (map: TMap, directions: IDirectionResult[]) => {
  if (!map) {
    return;
  }

  const pointsLayer = createLayer(
    map,
    DIRECTIONS_POINTS_LAYER_ID,
    STYLES.POINTS
  );
  const routesLayer = createLayer(map, DIRECTIONS_ROUTE_LAYER_ID, STYLES.ROUTE);
  const passLayer = createLayer(
    map,
    DIRECTIONS_ROUTE_PASS_LAYER_ID,
    STYLES.ROUTE_PASS
  );

  const routesSource = routesLayer?.getSource();
  const pointsSource = pointsLayer?.getSource();
  const passSource = passLayer?.getSource();

  for (let i = 0; i < directions.length; i++) {
    const direction = directions[i];

    const { coordinates, points } = direction;

    const outCoordinates = coordinates.map((item) => {
      const { x, y } = item;

      return [x, y];
    });

    const geometry = new LineString(outCoordinates);

    const feature = new Feature({
      geometry,
    });

    if (routesSource) {
      routesSource.addFeature(feature);
    }

    const pointsFeatures = directionPointsToFeatures(points);

    if (pointsFeatures && pointsFeatures.length === 2) {
      pointsFeatures.forEach((feature: Feature) => {
        pointsSource?.addFeature(feature);
      });

      const [p1, p2] = pointsFeatures;

      const linePass1 = pairToLineString(p1, outCoordinates[0]);
      const linePass2 = pairToLineString(
        p2,
        outCoordinates[outCoordinates.length - 1]
      );

      const lines: Feature[] = [];

      linePass1 && lines.push(linePass1);
      linePass2 && lines.push(linePass2);

      if (passSource) {
        lines.forEach((line: Feature) => {
          passSource.addFeature(line);
        });
      }
    }
  }
};

export const clearDirections = (map: TMap) => {
  if (!map) {
    return;
  }

  const routesLayer = createLayer(map, DIRECTIONS_ROUTE_LAYER_ID, STYLES.ROUTE);
  const passLayer = createLayer(
    map,
    DIRECTIONS_ROUTE_PASS_LAYER_ID,
    STYLES.ROUTE_PASS
  );
  const pointsLayer = createLayer(
    map,
    DIRECTIONS_POINTS_LAYER_ID,
    STYLES.POINTS
  );

  pointsLayer?.getSource()?.clear();
  routesLayer?.getSource()?.clear();
  passLayer?.getSource()?.clear();
};
