import { Dayjs } from 'dayjs';
import { useCallback, useEffect, useState } from 'react';

import { CommandsListSchema } from '../../../../api/mapObjects/trafficLights/commands/plannedCommands.zod';
import eventBus from '../../../../eventBus';
import { getPlannedCommandsArray } from '../../TlPlanning/helpers/getPlannedCommandsArray';
import { getNearestPhase, TCommands } from '../helpers/getNearestPhase';

interface IUseTimerPlannedComand {
  id: number;
  stopTimerTrigger: boolean;
}

export interface ITimerInfo {
  startTime: Dayjs;
  endTime: Dayjs;
  phaseNum: number;
}

const APP_PATH =
  '.TrafficLightDetailed.TLManagement.hooks.useTimerPlannedComand';

const useTimerPlannedComand = ({
  id,
  stopTimerTrigger,
}: IUseTimerPlannedComand) => {
  const [plannedCommands, setPlannedCommands] = useState<N<TCommands[]>>([]);
  const [timerInfo, setTimerInfo] = useState<N<ITimerInfo>>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const getPlanned = useCallback(async () => {
    setIsError(false);

    const commands = await getPlannedCommandsArray({
      id,
      appPath: APP_PATH,
      isBlockedNotify: true,
    });

    setIsLoading(false);

    if (!commands) {
      return setIsError(true);
    }

    const parserResult = CommandsListSchema.safeParse(commands.dataRows);

    if (!parserResult.success) return setIsError(true);

    setPlannedCommands(parserResult.data ?? null);
  }, [id]);

  useEffect(() => {
    const unsubscribe = eventBus.phaseHoldChange.subscribe(getPlanned);

    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getPlanned();
  }, [getPlanned]);

  useEffect(() => {
    const filteredCommands = getNearestPhase(plannedCommands);

    setTimerInfo(filteredCommands);
  }, [plannedCommands, stopTimerTrigger]);

  return { timerInfo, isLoading, isError };
};

export default useTimerPlannedComand;
